import * as yup from 'yup';
import { Testimonial } from '@web/components';
import { requiredImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';
import { schema as muxVideoSchema } from './mux_video';

export const component = Testimonial;

export const schema = yup
  .object({
    asset: yup.lazy(item => {
      switch (item.component) {
        case 'mux_video':
          return muxVideoSchema;
        default:
          return requiredImageFromCMSSchema;
      }
    }),
    description: yup.string().required(),
    rating: yup.number().required(),
    body: richTextFromCMSSchema.required(),
    name: yup.string().required(),
    level: yup.string(),
  })
  .transform(value => ({
    asset: value.asset[0].asset || value.asset[0],
    description: `${value.pup_age} ${value.breed}`,
    body: value.body,
    name: value.name,
    level: value.level,
    rating: 5,
  }));
