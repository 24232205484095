import * as yup from 'yup';
import { AppCtaComponent } from '@web/components/AppCTAComponent';
import { requiredImageFromCMSSchema } from '../schemas';

export const component = AppCtaComponent;

export const AppLinkSchema = yup
  .object({
    link: yup.string(),
    image: requiredImageFromCMSSchema,
  })
  .transform(value => ({
    link: value.link,
    image: value.image,
  }));

export const schema = yup
  .object({
    heading: yup.string().required(),
    subheading: yup.string(),
    desktopHeight: yup.number().optional(),
    mobileHeight: yup.number().optional(),
    withParallax: yup.boolean(),
    parallaxImage: requiredImageFromCMSSchema,
    image: requiredImageFromCMSSchema,
    imageAlignment: yup.string(),
    appLinks: yup.array(AppLinkSchema),
    level: yup.string(),
  })
  .transform(value => ({
    heading: value.heading,
    subheading: value.subheading,
    desktopHeight:
      value.desktop_height === '' || isNaN(Number(value.desktop_height))
        ? undefined
        : Number(value.desktop_height),
    mobileHeight:
      value.mobile_height === '' || isNaN(Number(value.mobile_height))
        ? undefined
        : Number(value.mobile_height),
    withParallax: value.with_parallax,
    parallaxImage: value.parallax_image,
    image: value.image,
    imageAlignment: value.image_alignment ? value.image_alignment : 'left',
    appLinks: value.app_links,
    level: value.level,
  }));
