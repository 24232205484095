import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledComparisonChartItem,
  ChartItemHeader,
  HeaderThree,
  Paragraph,
} from './styles';

export const FactsTableItem = ({ header, subheader = null }) => {
  return (
    <StyledComparisonChartItem>
      <ChartItemHeader>
        <HeaderThree>{header}</HeaderThree>
        {subheader && <Paragraph>{subheader}</Paragraph>}
      </ChartItemHeader>
    </StyledComparisonChartItem>
  );
};

FactsTableItem.displayName = 'FactsTableItem';

FactsTableItem.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
};
