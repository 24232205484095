import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Letter } from '../Letter';
import {
  StyledValuesLetter,
  Body,
  SectionList,
  Section,
  ImagesBlock,
  ImageWrapper,
  SectionHeader,
  SectionBody,
  Details,
  DetailItem,
  DetailDescription,
} from './styles.js';

export const ValuesLetter = ({
  headline,
  intro,
  sections = [],
  topIcon,
  bottomIcon,
  link,
}) => {
  return (
    <StyledValuesLetter>
      <Letter
        headline={headline}
        topIcon={topIcon}
        bottomIcon={bottomIcon}
        link={link}
      >
        <Body>{intro}</Body>
        <SectionList>
          {sections.map(({ images, heading, body, details }, index) => (
            <Section key={index}>
              {images?.length > 0 && (
                <ImagesBlock>
                  {images.map((image, i) => (
                    <ImageWrapper key={i}>
                      <ResponsiveImage {...image} />
                    </ImageWrapper>
                  ))}
                </ImagesBlock>
              )}
              {heading && <SectionHeader>{heading}</SectionHeader>}
              <SectionBody>{body}</SectionBody>
              {details?.length > 0 && (
                <Details>
                  {details.map(({ iconSrc, iconAlt, description }, i) => (
                    <DetailItem key={i} count={details.length}>
                      <ResponsiveImage
                        src={iconSrc}
                        alt={iconAlt}
                        height={70}
                        width={70}
                      />
                      <DetailDescription>{description}</DetailDescription>
                    </DetailItem>
                  ))}
                </Details>
              )}
            </Section>
          ))}
        </SectionList>
      </Letter>
    </StyledValuesLetter>
  );
};

ValuesLetter.displayName = 'ValuesLetter';

ValuesLetter.propTypes = {
  headline: PropTypes.string.isRequired,
  topIcon: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  intro: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          src: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
          alt: PropTypes.string.isRequired,
          height: PropTypes.number.isRequired,
          width: PropTypes.number.isRequired,
        }),
      ),
      heading: PropTypes.string,
      body: PropTypes.string.isRequired,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          iconSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
          iconAlt: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  bottomIcon: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    variant: PropTypes.string,
  }),
};
