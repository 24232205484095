import * as yup from 'yup';
import { MarketingHeader } from '../MarketingHeader';
import { linkSchema } from '../schemas';
import { schema as slideoutMenuLinkSchema } from './slideout_menu_link';
import { schema as slideDownMenuLinkSchema } from './slide_down_menu_link';
import { schema as referAFriendButtonSchema } from './refer_a_friend_button';

export const component = MarketingHeader;

export const schema = yup
  .object({
    logoUrl: yup.string().required(),
    logoSrText: yup.string().required(),
    links: yup.array(
      yup.lazy(item => {
        if (item.component === 'slideout_menu_link')
          return slideoutMenuLinkSchema;
        if (item.component === 'slide_down_menu_link')
          return slideDownMenuLinkSchema;
        if (item.component === 'refer_a_friend_button')
          return referAFriendButtonSchema;
        return linkSchema;
      }),
    ),
  })
  .transform(value => ({
    logoUrl: value.logo_target.url,
    logoSrText: value.logo_sr_text,
    links: value.left_links || value.navigation_links,
  }));
