import * as yup from 'yup';
import { SplitScreenListBody } from '@web/components';
import { linkSchema, nullableImageFromCMSSchema } from '../schemas';
import { schema as emailCaptureSchema } from './email_capture';

export const component = SplitScreenListBody;

export const schema = yup
  .object({
    eyebrow: yup.string(),
    heading: yup
      .object({
        level: yup.string(),
        position: yup.string(),
        typography: yup.string(),
        text: yup.string(),
      })
      .nullable(),
    backgroundColor: yup.string(),
    cta: yup.lazy(item => {
      if (!item) return yup.mixed().notRequired();
      switch (item?.component) {
        case 'email_capture':
          return emailCaptureSchema;
        default:
          return linkSchema;
      }
    }),
    ctaButtonColor: yup.string(),
    list: yup
      .array(
        yup.object({
          icon: nullableImageFromCMSSchema,
          heading: yup.string().required(),
          headingLevel: yup.string().required(),
          body: yup.string(),
        }),
      )
      .nullable(),
    fontColor: yup.string(),
  })
  .transform(value => ({
    eyebrow: value.eyebrow,
    heading:
      value.heading.map(heading => ({
        component: heading.component,
        level: heading.level,
        position: heading.position,
        typography: heading.typography,
        text: heading.text,
      }))?.[0] || null,
    backgroundColor: value.background_color,
    cta: value.cta?.[0],
    ctaButtonColor: value.cta_button_color,
    list:
      value.list.map(item => ({
        icon: item.icon,
        heading: item.heading,
        headingLevel: item.heading_level,
        body: item.body,
      })) || null,
    fontColor: value.font_color,
  }));
