import * as yup from 'yup';
import { SimpleCalloutPanel } from '@web/components';
import {
  requiredImageFromCMSSchema,
  linkSchema,
  richTextFromCMSSchema,
} from '../schemas';
import { schema as emailCaptureSchema } from './email_capture';

export const component = SimpleCalloutPanel;

export const schema = yup
  .object({
    image: requiredImageFromCMSSchema,
    heading: yup.string().required(),
    headingLevel: yup.string().required(),
    headingTopography: yup.string(),
    description: richTextFromCMSSchema,
    alignImage: yup.mixed().oneOf(['left', 'right']),
    fontColor: yup.string(),
    backgroundColor: yup.string(),
    fillColor: yup.string(),
    cta: yup.lazy(item => {
      if (!item) return yup.mixed().notRequired();
      switch (item.component) {
        case 'email_capture':
          return emailCaptureSchema.required();
        default:
          return linkSchema.required();
      }
    }),
  })
  .transform(value => ({
    image: value.image,
    heading: value.heading,
    headingLevel: value.heading_level,
    headingTopography: value.heading_topography || undefined,
    description: value.description || undefined,
    alignImage: value.align_image || undefined,
    fontColor: value.font_color || undefined,
    backgroundColor: value.background_color || undefined,
    fillColor: value.fill_color || undefined,
    cta: value.cta[0] || undefined,
  }));
