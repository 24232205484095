import React from 'react';
import PropTypes from 'prop-types';
import { SectionIntro } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledBoxContents,
  ContentList,
  Item,
  ImageContainer,
  Description,
  Tag,
} from './styles.js';

export const BoxContents = ({ headline = null, subheadline, items }) => {
  return (
    <StyledBoxContents>
      <SectionIntro headline={headline} subheadline={subheadline} />
      <ContentList>
        {items.map(item => {
          const { text, image, alt, tag } = item;
          return (
            <Item key={text} count={items.length}>
              <ImageContainer>
                <ResponsiveImage
                  src={image}
                  alt={alt}
                  width={202}
                  height={75}
                />
              </ImageContainer>
              <Description>
                {text}
                {tag && <Tag>{tag}</Tag>}
              </Description>
            </Item>
          );
        })}
      </ContentList>
    </StyledBoxContents>
  );
};

BoxContents.displayName = 'BoxContents';

BoxContents.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      tag: PropTypes.string,
    }),
  ).isRequired,
};
