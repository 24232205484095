import * as yup from 'yup';
import { PageTabs } from '@web/components';
import { PAGE_TABS_PAGES_DATA } from '@web/components/PageTabs/constants';

export const component = PageTabs;

export const schema = yup
  .object({
    // TODO(James) flesh out schema here
    pages: yup.array().required(),
  })
  .transform(value => ({
    // TODO(James) replace w/ CMS data
    pages: PAGE_TABS_PAGES_DATA,
  }));
