import * as yup from 'yup';
import { NutrientsDescription } from '@web/molecules';
import { inlineRichTextFromCMSSchema } from '../schemas';

export const component = NutrientsDescription;

export const schema = yup
  .object({
    title: yup.string().required(),
    nutrients: yup.array().required(),
    description: inlineRichTextFromCMSSchema,
  })
  .transform(value => ({
    title: value.title,
    description: value.description,
    nutrients: value.nutrients.nutrients,
  }));
