import * as yup from 'yup';
import { TimelineScrollPromo } from '@web/components';
import { getDimensions, getSrcSets } from '../images';
import { linkSchema, nullableImageFromCMSSchema } from '../schemas';

export const component = TimelineScrollPromo;
const splashImage = yup
  .object({
    src: yup.string().required(),
    alt: yup.string().nullable(),
    srcSet: yup.array().nullable(),
  })
  .transform(value => ({
    alt: value.asset.alt,
    src: value.asset.filename,
    ...getDimensions(value.asset.filename),
    srcSet: getSrcSets(value.asset.filename),
  }));

const step = yup.object({
  headline: yup.string(),
  text: yup.string().required(),
});

export const schema = yup
  .object({
    splashImages: yup.array(splashImage).required(),
    steps: yup.array(step).required(),
    link: linkSchema,
    badge: nullableImageFromCMSSchema,
  })
  .transform(value => ({
    ...value,
    link: value.link[0],
    splashImages: value.splash_images,
  }));
