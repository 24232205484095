import * as yup from 'yup';
import { ConfirmationPoll } from '@web/components';

export const component = ConfirmationPoll;

export const schema = yup
  .object({
    component: yup.string().required(),
    pollPrompt: yup.string().required(),
    options: yup
      .array(
        yup.object({
          name: yup.string().required(),
          value: yup.string().required(),
        }),
      )
      .min(2)
      .max(8)
      .required(),
  })
  .transform(value => ({
    pollPrompt: value.pollPrompt,
    options: value.options,
    component: value.component,
  }));
