'use client';
import styled from 'styled-components';

export const StyledEventsPageCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.background.lightest};
  width: 300px;
  border-radius: ${props => props.theme.spacing(8)};
  justify-content: start;
  margin: auto;
  margin-bottom: ${props => props.theme.spacing(4)};
`;

export const ImageHeader = styled.div`
  display: flex;
  height: 220px;

  img {
    border-top-right-radius: ${props => props.theme.spacing(8)};
    border-top-left-radius: ${props => props.theme.spacing(8)};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(4, 3)};
  height: 220px;
  justify-content: center;
`;

export const ContentDate = styled.h3`
  ${props => props.theme.typography.subheading1};
  color: ${props => props.theme.colors.text.secondary};
  text-transform: uppercase;
`;

export const ContentParagraph = styled.p`
  ${props => props.theme.typography.heading6};
  padding: ${props => props.theme.spacing(4, 0)};
`;

export const ContentLocation = styled.p`
  ${props => props.theme.typography.button};
  padding: ${props => props.theme.spacing(0, 0, 5, 0)};
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;
