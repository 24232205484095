import * as yup from 'yup';
import { ValuesBanner } from '@web/components';
import { nullableImageFromCMSSchema } from '../schemas';

export const component = ValuesBanner;

export const schema = yup.object({
  values: yup
    .array(
      yup.object({
        name: yup.string().required(),
        image: nullableImageFromCMSSchema,
      }),
    )
    .required(),
});
