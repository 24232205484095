import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { Icon } from '@ui/icons';
import { ResponsiveImage } from '@web/molecules';
import { Heading } from '@web/atoms';
import logo from '../../images/Homepage/logo.svg';
import {
  Item,
  Container,
  IconContainer,
  ButtonContainer,
  LogoContainer,
  ItemContainer,
  ImageContainer,
} from './styles';

export const FoodComparisonChartItem = ({
  buttonTarget,
  buttonText,
  textComparisons,
  headerImage,
  isOllieChartType = true,
  isCheckMarkOutline = false,
  disabled = false,
}) => {
  let iconType = isOllieChartType ? 'check' : 'close';
  if (isCheckMarkOutline) {
    iconType += '-circle';
  }

  const iconColor = isOllieChartType
    ? 'status.successPrimary'
    : isCheckMarkOutline
    ? 'status.errorPrimary'
    : undefined;

  return (
    <>
      <ImageContainer>
        <ResponsiveImage {...headerImage} />
      </ImageContainer>
      <Container
        isOllieChartType={isOllieChartType}
        isCheckMarkOutline={isCheckMarkOutline}
      >
        <LogoContainer>
          {isOllieChartType ? (
            // eslint-disable-next-line @next/next/no-img-element
            <img src={logo.src} alt="Ollie logo" />
          ) : (
            <Heading
              backgroundColor="background.dark"
              headingLevel="h3"
              headingText="Kibble"
              position="center"
              typography="heading2"
            />
          )}
        </LogoContainer>
        <ItemContainer>
          {textComparisons?.map((textComparison, index) => (
            <Item key={index}>
              <IconContainer>
                <Icon
                  color={iconColor}
                  iconName={iconType}
                  size="1.2rem"
                  alt="check"
                  id={`chat_item_${index}`}
                />
              </IconContainer>
              {textComparison.label}
            </Item>
          ))}
        </ItemContainer>
        <ButtonContainer>
          {isOllieChartType && (
            <LinkButton
              disabled={disabled}
              href={buttonTarget}
              horizontalPadding={10}
            >
              {buttonText}
            </LinkButton>
          )}
        </ButtonContainer>
      </Container>
    </>
  );
};

FoodComparisonChartItem.displayName = 'FoodComparisonChartItem';

FoodComparisonChartItem.propTypes = {
  headerImage: PropTypes.object.isRequired,
  buttonTarget: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  textComparisons: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  isOllieChartType: PropTypes.bool,
  isCheckMarkOutline: PropTypes.bool,
};
