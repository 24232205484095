import * as yup from 'yup';
import { Footer } from '@web/components';
import { nullableImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';

export const component = Footer;

export const schema = yup
  .object({
    copyright: yup.string().required(),
    message: yup.string().required(),
    variant: yup.mixed().oneOf(['simplified', 'full']).required(),
    bottomLinks: yup.array(
      yup.object({
        path: yup.string().required(),
        text: yup.string().required(),
      }),
    ),
    columnLinks: yup.array(
      yup.object({
        links: yup.array(
          yup.object({
            path: yup.string().required(),
            text: yup.string().required(),
            icon: nullableImageFromCMSSchema,
            blurb: yup.string(),
          }),
        ),
        hideIfAccount: yup.boolean().nullable(),
        businessHours: richTextFromCMSSchema,
        content: richTextFromCMSSchema,
        legalBlurb: richTextFromCMSSchema,
        title: yup.string().required(),
      }),
    ),
  })
  .transform(value => ({
    copyright: value.copyright,
    message: value.message,
    variant: value.variant,
    bottomLinks: value.bottom_links.map(link => ({
      path: link.link_target.url,
      text: link.link_text,
    })),
    columnLinks: value.column_links.map(
      ({ title, business_hours, content, legal_blurb, links }) => ({
        title,
        businessHours: business_hours,
        content,
        legalBlurb: legal_blurb,
        links: links.map(link => ({
          path: link.link_target.url,
          text: link.link_text,
          icon: link.icon,
          blurb: link.blurb,
        })),
      }),
    ),
  }));
