import React from 'react';
import PropTypes from 'prop-types';
import { BreadCrumbLink } from './BreadcrumbLink.js';
import { StyledBreadcrumbs } from './styles.js';

export const Breadcrumbs = ({ listPath }) => {
  return (
    <StyledBreadcrumbs aria-label={'Breadcrumbs'} role="nav">
      {listPath.map(({ link, title }, index) => {
        const isLastItem = index === listPath.length - 1;
        return (
          <BreadCrumbLink
            link={link}
            text={title}
            isLastItem={isLastItem}
            key={index}
          />
        );
      })}
    </StyledBreadcrumbs>
  );
};
Breadcrumbs.displayName = 'Breadcrumbs';
Breadcrumbs.propTypes = {
  listPath: PropTypes.arrayOf(PropTypes.object),
};
