'use client';
import styled from 'styled-components';

export const StyledVerticalPanelWithRails = styled.div`
  overflow: hidden;
`;

export const BodyContent = styled.div`
  margin: ${props => props.theme.spacing(4, 0)};
`;

export const Body = styled.div`
  margin: ${props => props.theme.spacing(10, 'auto')};
  max-width: 430px;
  p {
    ${props => props.theme.typography.heading5}
    margin: ${props => props.theme.spacing(0, 'auto')};
    padding: ${props => props.theme.spacing(0, 4)};

    @media ${props => props.theme.tablet} {
      margin: ${props => props.theme.spacing(0, 'auto')};
      padding: 0;
    }
  }
`;

export const MobilePhotoVariant1 = styled.div`
  border-radius: 32px;
  line-height: 0;
  overflow: hidden;
  width: 44%;
  z-index: 1;
  position: relative;

  &:last-child {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 68%;
    z-index: 0;
  }
`;

export const MobilePhotoVariant2 = styled.div`
  border-radius: 32px;
  line-height: 0;
  overflow: hidden;
  width: 68%;
  &:first-child {
    position: ${props => (props.isThreeImagesRail ? 'relative' : 'absolute')};
    right: 0;
    top: ${props => (props.isThreeImagesRail ? '0%' : '20%')};
    transform: translateY(
      ${props => (props.isThreeImagesRail ? '0' : '-50%')};
    );
    width: ${props => (props.isThreeImagesRail ? '68%' : '44%')};
    z-index: 1;
  }
`;

export const StyledLetter = styled.div`
  background: ${props => props.theme.colors.background.base};
  padding: ${props => props.theme.spacing(5, 5, 14)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(10, 20, 20)};
  }
`;

export const Container = styled.div`
  background: ${props => props.theme.colors.background.lightest};
  border-radius: 32px;
  margin: 0 auto;
  max-width: 880px;
  padding: ${props => props.theme.spacing(2, 5)};
  position: relative;

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(10, 10)};
  }
`;

export const Content = styled.div`
  color: ${props => props.theme.colors.text.secondary};
  text-align: center;
`;

export const PhotosWrapper = styled.div`
  display: none;

  @media ${props => props.theme.tabletL} {
    display: block;
  }
`;

export const PhotoOverlay = styled.div`
  border-radius: 32px;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  &:nth-child(1) {
    width: 170px;
    left: 0;
    top: 9%;
    transform: translateX(-40%);
    z-index: 1;
  }

  &:nth-child(2) {
    width: 260px;
    left: 0;
    top: 22%;
    transform: translateX(-55%);
  }

  &:nth-child(3) {
    width: 240px;
    right: 0;
    top: 10%;
    transform: translateX(50%);
  }
  &:nth-child(4) {
    width: 160px;
    left: 0;
    top: 70%;
    transform: translateX(-45%);
  }
  &:nth-child(5) {
    width: 180px;
    right: 0;
    top: 52%;
    transform: translateX(65%);
    z-index: 1;
  }
  &:nth-child(6) {
    width: 240px;
    right: 0;
    top: 65%;
    transform: translateX(30%);
  }
`;

export const PhotoBlock = styled.div`
  display: flex;
  align-items: center;
  height: 250px;
  justify-content: ${props => (props.isThreeImagesRail ? 'center' : '')};
  margin: ${props => props.theme.spacing('auto')};
  max-width: 285px;
  position: relative;

  @media ${props => props.theme.tabletL} {
    display: none;
  }
`;
