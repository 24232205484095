import * as yup from 'yup';
import { SlideoutMenuLink } from '@web/molecules';
import { schema as productsSummaryCardSchema } from './products_summary_card';
import { schema as addOnSummaryCardsSchema } from './add_on_summary_cards';

export const component = SlideoutMenuLink;

export const schema = yup
  .object({
    link_text: yup.string(),
    menu_header: yup.string(),
    sections: yup.array(
      yup.lazy(section => {
        switch (section.component) {
          case 'add_on_summary_cards':
            return addOnSummaryCardsSchema;
          default:
            return productsSummaryCardSchema;
        }
      }),
    ),
  })
  .transform(value => ({
    linkText: value.link_text,
    menuHeader: value.menu_header,
    sections: value.sections,
    component: 'slideout_menu_link',
  }));
