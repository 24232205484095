export const loadTrustPilot = callback => {
  const existingScript = document.getElementById('TrustPilot');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src =
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.id = 'TrustPilot';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
