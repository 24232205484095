import React from 'react';
import { PhotosGrid } from '../PhotosGrid';
import { PHOTO_GRID_DATA } from '../PhotosGrid/constants';
import { MissionLetter } from '../MissionLetter';
import { MISSION_LETTER_DATA } from '../MissionLetter/constants';
import { VALUES_LETTER_DATA } from '../ValuesLetter/constants';
import { ValuesLetter } from '../ValuesLetter';
import { COMMUNITY_LETTER_DATA } from '../CommunityLetter/constants';
import { CommunityLetter } from '../CommunityLetter';
import { SplitPanels } from '../SplitPanels';
import CommunityPanel from '../../images/about/community-panel.jpg';
import CommunityPanelIconFilled from '../../images/about/community-panel-icon-filled.svg';
import Twitter from '../../images/Icons/twitter.svg';
import Instagram from '../../images/Icons/instagram.svg';
import Facebook from '../../images/Icons/facebook.svg';
import TikTok from '../../images/Icons/tiktok.svg';
import { SplitPanelBody } from '../SplitPanelBody';
import { JoinThePackDescription } from './styles';

const image = {
  alt: 'Human kissing his dog with an Ollie box',
  src: CommunityPanel,
};
const icon = {
  alt: 'Drawing of a dog next to a box',
  src: CommunityPanelIconFilled.src,
};

const socialMediaIcons = [
  {
    alt: 'instagram',
    src: Instagram.src,
    url: 'https://www.instagram.com/myollie/',
  },
  {
    alt: 'tiktok',
    src: TikTok.src,
    url: 'https://www.tiktok.com/@ollie',
  },
  {
    alt: 'facebook',
    src: Facebook.src,
    url: 'https://www.facebook.com/MyOllie',
  },
  {
    alt: 'twitter',
    src: Twitter.src,
    url: 'https://twitter.com/olliepets',
  },
];

export const PAGE_TABS_PAGES_DATA = [
  {
    name: 'Our Mission',
    color: 'hero.sweetPotato',
    content: (
      <>
        <MissionLetter {...MISSION_LETTER_DATA} />
        <PhotosGrid
          heading="Our Ollie Family"
          photos={PHOTO_GRID_DATA}
          link={{ target: '/onboarding/', text: 'Get Started' }}
        />
      </>
    ),
  },
  {
    name: 'Our Values',
    color: 'hero.spinach',
    content: <ValuesLetter {...VALUES_LETTER_DATA} />,
  },
  {
    name: 'Our Community',
    color: 'hero.blueberry',
    content: (
      <>
        <CommunityLetter {...COMMUNITY_LETTER_DATA} />
        <SplitPanels
          image={image}
          alignImage="left"
          backgroundColor="hero.spinach"
          content={
            <SplitPanelBody
              icon={icon}
              heading="Join the pack"
              body={
                <JoinThePackDescription>
                  Our community is open to all! Follow along and tag #MyOllie to
                  show us what you and your pup are up to.
                </JoinThePackDescription>
              }
              socialMediaIcons={socialMediaIcons}
            />
          }
        />
      </>
    ),
  },
];
