import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import NextImage from 'next/image';
import { VisuallyHidden, PillText, Tooltip } from '@web/atoms';
import {
  Card,
  Info,
  StyledImage,
  Name,
  Description,
  Label,
  CheckboxContainer,
  MoreDetails,
  CardContent,
  DisplayedPrice,
  StrikeThroughPrice,
  PricingSymbol,
  InactiveSymbol,
  ActiveSymbol,
  CardHeader,
  BadgesContainer,
  LabelText,
  KeyInfo,
  PricesContainer,
  ButtonWrapper,
} from './styles.js';

export const ProductCard = ({
  label,
  title,
  priceSymbol,
  value,
  description,
  checked = false,
  onDetailsClick,
  showMoreDetails,
  img,
  onChange,
  benefit,
  unavailable,
  unavailableText,
  unavailableLabel,
  notSelectable,
  notSelectableText = 'To choose this item, please remove one of your previous selections.',
  inputType = 'checkbox',
  pricingInfo = null,
  callout,
  showPriceText,
  period = 'week',
  buttonIcon,
  level,
  hidden,
}) => {
  let labelText = label;
  if (unavailable) {
    labelText = unavailableLabel || 'Recipe Unavailable';
  } else if (checked) {
    labelText = label + 'ed' || 'Added';
  }

  period = capitalize(period);

  const getPriceSymbol = () => {
    switch (priceSymbol) {
      case 'low':
        return (
          <PricingSymbol data-testid="pricingLow">
            <ActiveSymbol>$</ActiveSymbol>
            <InactiveSymbol>$$</InactiveSymbol>
          </PricingSymbol>
        );
      case 'mid':
        return (
          <PricingSymbol data-testid="pricingMid">
            <ActiveSymbol>$$</ActiveSymbol>
            <InactiveSymbol>$</InactiveSymbol>
          </PricingSymbol>
        );
      case 'high':
        return (
          <PricingSymbol data-testid="pricingHigh">
            <ActiveSymbol>$$$</ActiveSymbol>
          </PricingSymbol>
        );
      default:
        return (
          <PricingSymbol data-testid="pricingDefault">
            <InactiveSymbol>$$$</InactiveSymbol>
          </PricingSymbol>
        );
    }
  };
  const getProductCard = props => (
    <Card
      {...props}
      checked={checked}
      unavailable={unavailable}
      $notSelectable={notSelectable}
      hidden={hidden}
    >
      <CardContent>
        <Info>
          <CardHeader>
            <StyledImage>
              <NextImage src={img.src} alt={img.alt} width={112} height={112} />
            </StyledImage>
            <KeyInfo>
              <Name as={level}>{title}</Name>
              <BadgesContainer>
                {benefit && (
                  <PillText text={benefit} variant="kale" size="small" />
                )}
                {callout && (
                  <PillText
                    text={callout}
                    variant="blueberry"
                    size="small"
                  ></PillText>
                )}
              </BadgesContainer>
              {priceSymbol && getPriceSymbol()}
              {pricingInfo &&
                !unavailable &&
                (!pricingInfo.discount
                  ? Boolean(pricingInfo.price) && (
                      <DisplayedPrice>
                        {showPriceText && 'Starting at '} ${pricingInfo.price}/
                        {period}
                      </DisplayedPrice>
                    )
                  : Boolean(pricingInfo.price) && (
                      <>
                        {showPriceText && 'Starting at '}
                        <PricesContainer>
                          <StrikeThroughPrice>
                            ${pricingInfo.price}
                          </StrikeThroughPrice>
                          <DisplayedPrice>
                            ${pricingInfo.discount}/{period}
                          </DisplayedPrice>
                        </PricesContainer>
                      </>
                    ))}
            </KeyInfo>
          </CardHeader>
          <Description>
            {unavailable ? unavailableText : description}
          </Description>
        </Info>
        <MoreDetails
          type="button"
          unavailable={unavailable}
          onClick={onDetailsClick}
          disabled={!showMoreDetails}
        >
          View Details
        </MoreDetails>
        <ButtonWrapper>
          <CheckboxContainer
            checked={checked && !unavailable}
            unavailable={unavailable}
            icon={buttonIcon}
          >
            <Label
              htmlFor={`${value}-checkbox-input`}
              checked={checked && !unavailable}
              unavailable={unavailable}
              icon={buttonIcon}
            >
              <VisuallyHidden>
                <input
                  type={inputType}
                  id={`${value}-checkbox-input`}
                  name={value}
                  checked={checked && !unavailable}
                  disabled={unavailable || notSelectable}
                  onChange={() => onChange()}
                />
              </VisuallyHidden>
              <VisuallyHidden>{label + ' ' + capitalize(value)}</VisuallyHidden>
            </Label>
            <LabelText
              checked={checked}
              unavailable={unavailable || notSelectable}
            >
              {labelText}
            </LabelText>
          </CheckboxContainer>
        </ButtonWrapper>
      </CardContent>
    </Card>
  );

  return notSelectable && !unavailable ? (
    <Tooltip trigger={getProductCard} placement="top" offsetY={-200}>
      {notSelectableText}
    </Tooltip>
  ) : (
    getProductCard()
  );
};

ProductCard.displayName = 'ProductCard';
ProductCard.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  priceSymbol: PropTypes.string,
  benefit: PropTypes.string,
  inputType: PropTypes.oneOf(['checkbox', 'radio']),
  unavailable: PropTypes.bool,
  unavailableText: PropTypes.string,
  unavailableLabel: PropTypes.string,
  notSelectable: PropTypes.bool,
  notSelectableText: PropTypes.string,
  value: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  img: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  pricingInfo: PropTypes.shape({
    price: PropTypes.string,
    discount: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onDetailsClick: PropTypes.func,
  callout: PropTypes.string,
  showPriceText: PropTypes.bool,
  showMoreDetails: PropTypes.bool,
  period: PropTypes.string,
  buttonIcon: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
