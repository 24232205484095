import * as yup from 'yup';
import { FullscreenCallout } from '@web/components';
import { linkSchema, requiredImageFromCMSSchema } from '../schemas';
import { schema as emailCaptureSchema } from './email_capture';

export const component = FullscreenCallout;

export const schema = yup
  .object({
    headline: yup.string().required(),
    cta: yup.lazy(item => {
      switch (item.component) {
        case 'email_capture':
          return emailCaptureSchema.required();
        default:
          return linkSchema.required();
      }
    }),
    ctaColor: yup.string(),
    image: requiredImageFromCMSSchema,
    backgroundImage: requiredImageFromCMSSchema,
    level: yup.string(),
  })
  .transform(value => ({
    headline: value.headline,
    cta: value.cta[0],
    ctaColor: value.cta_color || undefined,
    image: value.image,
    backgroundImage: value.background_image,
    level: value.level,
  }));
