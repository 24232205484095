import React from 'react';
import PropTypes from 'prop-types';
import {
  InformationStatsBlock,
  DescriptionParagraph,
  BlockHeading,
} from './styles.js';

export const InfoStatBlock = ({ heading, description, backgroundColor }) => {
  return (
    <InformationStatsBlock backgroundColor={backgroundColor}>
      <BlockHeading>{heading}</BlockHeading>
      <DescriptionParagraph>{description}</DescriptionParagraph>
    </InformationStatsBlock>
  );
};

InfoStatBlock.displayName = 'InfoStatBlock';

InfoStatBlock.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
};
