import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledVerticalPanel,
  Wrapper,
  ContentPanel,
  ContentPanelInner,
} from './styles.js';

export const VerticalPanel = ({ backgroundColor, fillColor, content }) => {
  return (
    <StyledVerticalPanel fillColor={fillColor}>
      <Wrapper>
        <ContentPanel backgroundColor={backgroundColor}>
          <ContentPanelInner>{content}</ContentPanelInner>
        </ContentPanel>
      </Wrapper>
    </StyledVerticalPanel>
  );
};

VerticalPanel.displayName = 'VerticalPanel';

VerticalPanel.propTypes = {
  content: PropTypes.node,
  backgroundColor: PropTypes.string,
  fillColor: PropTypes.string,
};
