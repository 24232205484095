import * as yup from 'yup';
import { RecipeOverview } from '@web/components';
import { schema as imageSchema } from './image';
import { schema as heading } from './heading';
import { schema as recipeNutrient } from './recipe_nutrient';

export const component = RecipeOverview;

export const schema = yup
  .object({
    title: heading,
    description: yup.string().required(),
    buttonText: yup.string().required(),
    image: imageSchema.nullable(),
    imageBackgroundColor: yup.string().nullable(),
    imagePosition: yup.string().nullable(),
    caloriesValue: yup.number().required(),
    recipeBackgroundColor: yup.string().nullable(),
    nutritionalInfoBackgroundColor: yup.string().nullable(),
    urlTarget: yup.string().required(),
    nutritionalInfo: yup.array(recipeNutrient).required(),
  })
  .transform(value => ({
    title: value.title[0],
    description: value.description,
    buttonText: value.button_text,
    image: value.image?.[0] || null,
    imageBackgroundColor: value.image_background_color,
    imagePosition: value.image_position,
    caloriesValue: value.calories_value,
    recipeBackgroundColor: value.recipe_background_color,
    nutritionalInfoBackgroundColor: value.nutritional_info_background_color,
    urlTarget: value.url_target.url,
    nutritionalInfo: value.nutritional_info,
  }));
