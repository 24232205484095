export const relations = [
  'reason_reference.reason',
  'testimonial_reference.testimonial',
  'press_reference.press_quote',
  'landing.header',
  'landing.footer',
  'hover_image_card_reference.hover_image_card',
  'faq_question_reference.faq_question',
  'recipe_ingredients_reference.recipe_ingredients',
  'comparison_chart_row_reference.comparison_chart_row',
  'recipe_nutrient_reference.recipe_nutrient',
  'full_ingredients_modal_reference.full_ingredients_modal',
];
