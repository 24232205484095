'use client';
import styled, { css } from 'styled-components';

export const GridContainer = styled.div`
  margin: auto;
  display: grid;
  column-gap: ${props =>
    props.$gap.column ? props.theme.spacing(props.$gap.column) : 'unset'};
  row-gap: ${props =>
    props.$gap.row ? props.theme.spacing(props.$gap.row) : 'unset'};
  grid-template-columns: repeat(
    ${props => (props.$mobile ? props.$mobile : 12)},
    1fr
  );
  background-color: ${props =>
    props.theme.colors.get(
      props.$backgroundColor,
      props.theme.colors.background.base,
    )};

  ${props =>
    props.$tablet &&
    css`
      @media ${props => props.theme.tablet} {
        grid-template-columns: repeat(${props => props.$tablet}, 1fr);
      }
    `}

  ${props =>
    props.$laptop &&
    css`
      @media ${props => props.theme.laptop} {
        grid-template-columns: repeat(${props => props.$laptop}, 1fr);
      }
    `}
`;

export const CellContainer = styled.div`
  margin: ${props => props.theme.spacing(...props.$margins)};
  ${props => props.theme.calcGridColumn(props.$mobile)}

  ${props =>
    props.$tablet &&
    css`
      @media ${props => props.theme.tablet} {
        ${props => props.theme.calcGridColumn(props.$tablet)}
      }
    `}

  ${props =>
    props.$laptop &&
    css`
      @media ${props => props.theme.laptop} {
        ${props => props.theme.calcGridColumn(props.$laptop)}
      }
    `}

    justify-self: ${props => props.$position};
`;
