import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Letter } from '../Letter';
import {
  StyledMissionLetter,
  Body,
  PhotoOverlay,
  PhotoBlock,
  MobilePhoto,
} from './styles.js';

export const MissionLetter = ({
  headline,
  bodyBlocks = [],
  topIcon,
  bottomIcon,
  link,
  photos = [],
}) => {
  const renderPhotoBlock = index => {
    const photoBlock = photos[index];
    if (!photoBlock) {
      return null;
    }
    return (
      <PhotoBlock>
        {photoBlock.map((photo, i) => (
          <MobilePhoto key={i}>
            <ResponsiveImage {...photo} />
          </MobilePhoto>
        ))}
      </PhotoBlock>
    );
  };

  const renderPhotos = () => (
    <>
      {photos.flat().map((photo, i) => (
        <PhotoOverlay key={i}>
          <ResponsiveImage {...photo} />
        </PhotoOverlay>
      ))}
    </>
  );

  return (
    <StyledMissionLetter>
      <Letter
        headline={headline}
        topIcon={topIcon}
        bottomIcon={bottomIcon}
        link={link}
        photos={renderPhotos()}
      >
        <Body>
          {bodyBlocks.map((body, index) => (
            <Fragment key={index}>
              <p>{body}</p>
              {renderPhotoBlock(index)}
            </Fragment>
          ))}
        </Body>
      </Letter>
    </StyledMissionLetter>
  );
};

MissionLetter.displayName = 'MissionLetter';

MissionLetter.propTypes = {
  headline: PropTypes.string.isRequired,
  bodyBlocks: PropTypes.arrayOf(PropTypes.node).isRequired,
  topIcon: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  bottomIcon: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  photos: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        alt: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
      }),
    ),
  ).isRequired,
};
