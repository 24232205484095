import React from 'react';
import PropTypes from 'prop-types';
import { CellContainer } from './styles.js';
import { COLUMN_SIZE_PROPTYPE } from './constants.js';

export const Cell = ({ columns = {}, children, position, margins = [] }) => {
  const columnsValues =
    typeof columns === 'number'
      ? { mobile: columns, laptop: columns, tablet: columns }
      : columns;

  return (
    <CellContainer
      $mobile={columnsValues.mobile}
      $tablet={columnsValues.tablet}
      $laptop={columnsValues.laptop}
      $position={position}
      $margins={margins}
    >
      {children}
    </CellContainer>
  );
};

Cell.propTypes = {
  columns: PropTypes.oneOfType([
    COLUMN_SIZE_PROPTYPE,
    PropTypes.shape({
      mobile: COLUMN_SIZE_PROPTYPE,
      laptop: COLUMN_SIZE_PROPTYPE,
      tablet: COLUMN_SIZE_PROPTYPE,
    }),
  ]),
  children: PropTypes.node,
  position: PropTypes.oneOf(['start', 'center', 'end']),
  margins: PropTypes.arrayOf(PropTypes.number),
};
