'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Banner } from '@web/atoms';
import { ReporterContext, useReporter } from '../reporter';
import { shared } from '../config';

/**
 * If a section of the page throws an error while rendering,
 * catch & report the error and render nothing in its place.
 *
 * This will ensure the rest of the page can function while
 * we iron out the issue, which can be very helpful for CMS
 * pages which shouldn't bomb out the whole page if we did
 * an integration wrong or the CMS author did something
 * unexpected.
 */
export class NullErrorBoundary extends React.Component {
  static contextType = ReporterContext;

  /**
   * @type {import('../reporter').Reporter}
   */
  context;

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    error: false,
  };

  componentDidCatch(error, errorInfo) {
    this.context.error(error, { contexts: errorInfo });
    this.setState({ error: true });
  }

  render() {
    if (this.state.error) {
      return null;
    }

    return this.props.children;
  }
}

const ErrorWrapper = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.text.lightest};
`;

/**
 * Handles case where the `buildProps` functions throws.
 * Renders nothing and reports the error.
 *
 * @type {React.FC<{ error: Error }>}
 */
export const BuildPropsError = ({ error, component }) => {
  const reporter = useReporter();

  useEffect(() => {
    reporter.error(error);
  }, [reporter, error]);

  return shared.OLLIE_ENV === 'production' ? null : (
    <ErrorWrapper>
      <Banner $bgColor="status.errorPrimary">
        <strong>{`${error.name}: `}</strong>
        {`Component: ${component}. Field: ${error.path}. Message: ${error.message}`}
      </Banner>
    </ErrorWrapper>
  );
};

BuildPropsError.propTypes = {
  error: PropTypes.object.isRequired,
  component: PropTypes.string.isRequired,
};

/**
 * Handles case where a CMS story is not registered in the code.
 * Renders nothing & reports the error.
 *
 * @type {React.FC<{ type: string }>}
 */
export const MissingCMSContentType = ({ type }) => {
  const reporter = useReporter();

  useEffect(() => {
    reporter.error(new Error(`CMS content type "${type}" not registered.`));
  }, [reporter, type]);

  // Render an empty page.
  return null;
};

MissingCMSContentType.displayName = 'MissingCMSContentType';

MissingCMSContentType.propTypes = {
  type: PropTypes.string.isRequired,
};

/**
 * Handles case where a CMS component is not registered in the code.
 * Renders nothing & reports the error.
 *
 * @type {React.FC<{ type: string }>}
 */
export const MissingCMSComponent = ({ type }) => {
  const reporter = useReporter();

  useEffect(() => {
    reporter.error(new Error(`CMS component "${type}" not registered.`));
  }, [reporter, type]);

  // Render an empty page.
  return null;
};

MissingCMSComponent.displayName = 'MissingCMSComponent';

MissingCMSComponent.propTypes = {
  type: PropTypes.string.isRequired,
};
