'use client';
import styled from 'styled-components';
import { generateContentIconStyle } from '@ui/icons';
import { variants } from './variants';

export const StyledCarousel = styled.div`
  padding: 0 ${props => props.theme.spacing(4, 9)};
  position: relative;
  max-width: 100%;

  @media ${props => props.theme.laptop} {
    padding: 0 ${props => props.theme.spacing(4, 2)};
  }
`;

export const SlideViewport = styled.div`
  overflow: hidden;
`;

export const SlideContainer = styled.div`
  display: flex;
`;

export const SlideContent = styled.div`
  height: 100%;
`;

export const DotContainer = styled.div`
  display: flex;
  width: max-content;
  justify-content: space-around;
  margin: auto;
`;

export const ArrowButton = styled.button`
  background: ${props => props.theme.colors.text.primary};
  border: 2px solid ${props => props.theme.colors.text.primary};
  border-radius: 60%;
  padding: 0px;
  width: 50px;
  height: 50px;
  position: ${props => (props.overlappingArrows ? 'absolute' : 'unset')};
  top: ${props => props.buttonPosition}%;
  transform: translateY(
    ${props => (props.overlappingArrows ? props.buttonPosition - 100 : '0')}%
  );
  z-index: 10;
  &:focus {
    border: 2px solid ${props => props.theme.colors.text.primary};
  }
  &:disabled {
    display: none;
  }
  &:hover:not([disabled]) {
    background: ${props => props.theme.colors.text.primary};
    border: 2px solid ${props => props.theme.colors.text.primary};
  }
`;

export const PrevButton = styled(ArrowButton)`
  left: 10px;
  right: unset;
  &::before {
    top: 2px;
    position: relative;
    ${generateContentIconStyle('arrow-left')};
    width: 21px;
    height: 21px;
    background: ${props => props.theme.colors.background.lightest};
  }
`;

export const NextButton = styled(ArrowButton)`
  left: unset;
  right: 10px;
  &::before {
    top: 2px;
    position: relative;
    ${generateContentIconStyle('arrow-forward')};
    width: 21px;
    height: 21px;
    background: ${props => props.theme.colors.background.lightest};
  }
`;
export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${props => props.theme.spacing(props.dotPadding)};
  width: max-content;
  margin: auto;
`;
export const DotButton = styled.button`
  background-color: ${({ active, variant, theme }) =>
    active
      ? theme.colors.get(variants[variant].active.background)
      : 'transparent'};
  border: 1px solid
    ${({ variant, theme }) => theme.colors.get(variants[variant].active.border)};
  border-radius: 60%;
  cursor: pointer;
  display: flex;
  height: 16px;
  width: 16px;
  padding: 0;
  margin: ${props => props.theme.spacing(1)};
  justify-content: center;
  &:focus,
  &:not([disabled]):hover {
    border-width: 2px;
  }
`;

export const Slide = styled.div`
  flex: 0 0 auto;
  width: 100%;
  max-width: 100%;
  position: relative;
  padding: 0 3%;

  @media ${props => props.theme.tablet} {
    flex: none;
    padding: 0;
    width: ${props =>
      props.itemsToShow > 1 ? `calc(100%/(${props.itemsToShow}/2))` : '100%'};
  }

  @media ${props => props.theme.tabletL} {
    width: ${props =>
      props.itemsToShow > 1
        ? `calc(100%/(${props.itemsToShow}/1.33))`
        : '100%'};
  }

  @media (min-width: 1091px) {
    width: ${props =>
      props.itemsToShow ? `calc(100%/${props.itemsToShow})` : 'auto'};
  }
`;

export const ImageDot = styled.button`
  appearance: none;
  background-image: url('${props => props.src}');
  border: 0;
  border-radius: 28px;
  width: 56px;
  height: 56px;
  margin: ${props => props.theme.spacing(0, 2)};
  opacity: ${props => (props.active ? 1 : 0.2)};
  overflow: hidden;
  padding: 0;
  position: relative;
  @media ${props => props.theme.laptop} {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: ${props => props.theme.spacing(0, 4)};
  }
`;
