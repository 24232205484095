import * as yup from 'yup';
import { BoxContents } from '@web/components';
import { BOX_CONTENTS_ITEMS } from '@web/components/BoxContents/constants';

export const component = BoxContents;

export const schema = yup
  .object({
    headline: yup.string(),
    subheadline: yup.string().required(),
    items: yup
      .array(
        yup.object({
          alt: yup.string().required(),
          image: yup.string().required(),
          text: yup.string().required(),
          tag: yup.string(),
        }),
      )
      .required(),
  })
  .transform(value => ({
    ...value,
    // TODO(James) replace w/ actual CMS data
    items: BOX_CONTENTS_ITEMS,
  }));
