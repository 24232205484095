import React from 'react';
import PropTypes from 'prop-types';
import {
  Header,
  Table,
  TableBody,
  TableColumn,
  TableRow,
  Description,
} from './styles';

export const NutrientsDescription = ({
  title = 'Guaranteed Analysis',
  nutrients,
  description,
}) => {
  return (
    <>
      <Header>{title}</Header>
      <Table>
        <TableBody>
          {nutrients.map(({ label, value, index }) => (
            <TableRow key={index}>
              <TableColumn>{label}</TableColumn>
              <TableColumn>{value}</TableColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Description>{description}</Description>
    </>
  );
};

NutrientsDescription.propTypes = {
  title: PropTypes.string.isRequired,
  nutrients: PropTypes.array.isRequired,
  description: PropTypes.string,
};
