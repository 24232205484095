import React from 'react';
import PropTypes from 'prop-types';
import { MetaAttributes } from '../head';

const emptyStringToUndefined = obj => {
  const transformed = {};
  Object.keys(obj).forEach(key => {
    transformed[key] = obj[key] !== '' ? obj[key] : undefined;
  });
  return transformed;
};

export const CMSMeta = ({ content }) => {
  const {
    socialTitle,
    socialDescription,
    seoDescription,
    socialImage,
    seoTitle,
    canonicalUrl,
    indexPage,
  } = emptyStringToUndefined({
    socialTitle: content.social_title,
    seoTitle: content.seo_title,
    socialDescription: content.social_description,
    seoDescription: content.seo_description,
    socialImage: content.social_image,
    canonicalUrl: content.canonical_url,
    indexPage: content.index_page,
  });
  return (
    <MetaAttributes
      socialTitle={socialTitle}
      socialDescription={socialDescription}
      seoDescription={seoDescription}
      socialImage={socialImage}
      seoTitle={seoTitle}
      canonicalUrl={canonicalUrl}
      indexPage={indexPage}
    />
  );
};

CMSMeta.displayName = 'CMSMeta';

CMSMeta.propTypes = {
  content: PropTypes.shape({
    social_title: PropTypes.string,
    seo_title: PropTypes.string,
    social_description: PropTypes.string,
    seo_description: PropTypes.string,
    social_image: PropTypes.string,
    canonical_url: PropTypes.string,
    indexPage: PropTypes.bool,
  }).isRequired,
};
