'use client';
import styled from 'styled-components';

export const TestimonialsHighlightContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${props => props.theme.spacing(3)};
  background-color: ${props =>
    props.backgroundColor && props.theme.colors.get(props.backgroundColor)};
  @media ${props => props.theme.mobileS} {
    flex-wrap: wrap;
  }
`;

export const TestimonialContainer = styled.div`
  max-width: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: ${props => props.theme.spacing(6, 7)};
`;

export const ButtonContainer = styled.div`
  padding: ${props => props.theme.spacing(7, 6)};
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline(props.backgroundColor)}
`;

export const Subtext = styled.p`
  ${({ theme }) => theme.typography.subheading2};
  padding-top: ${props => props.theme.spacing(3)};
`;
