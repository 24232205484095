'use client';
import styled from 'styled-components';

export const Content = styled.div``;

export const StyledStoreLocatorMap = styled.div`
  padding: ${props => props.theme.spacing(2, 0)};
  margin: ${props => props.theme.spacing(2, 0)};
  @media ${props => props.theme.tablet} {
    height: 100vh;
  }

  .store-locator-widget {
    margin-top: 2rem;
    @media ${props => props.theme.tablet} {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  #storerocket-widget {
    border: none;
    ${props => props.theme.fontFamily.base};
    height: 100vh;
  }

  #storerocket-search {
    background-color: ${props => props.theme.colors.background.base};
    padding: ${props => props.theme.spacing(1, 2, 15)};
  }

  .storerocket-query {
    margin-right: ${props => props.theme.spacing(3)};
  }

  .storerocket-search-field {
    color: ${props => props.theme.colors.border.fur} !important;
    font-size: 1rem !important;
    padding: ${props => props.theme.spacing(4)};
    border: 1px solid ${props => props.theme.colors.border.fur} !important;
    ${props => props.theme.typography.body};
  }

  .storerocket-search-field,
  .storerocket-radius-button {
    border: 1px solid ${props => props.theme.colors.border.dark} !important;
  }

  #storerocket-panel {
    background-color: ${props => props.theme.colors.background.base};
  }

  .storerocket-no-results {
    background-color: ${props => props.theme.colors.background.base};
  }

  .storerocket-no-results-content {
    text-align: center;
  }

  .storerocket-result-list-content {
    background-color: ${props => props.theme.colors.background.base};
    padding-top: ${props => props.theme.spacing(5)};
  }

  .storerocket-result {
    border-radius: ${props => props.theme.spacing(8)};
    margin: ${props => props.theme.spacing(4, 0)};
  }

  .storerocket-result-name {
    color: ${props => props.theme.colors.text.primary};
    ${props => props.theme.typography.heading6};
  }

  .storerocket-result-content {
    padding: ${props => props.theme.spacing(1)};
  }

  .storerocket-result-is-open {
    display: none !important;
  }

  .storerocket-result-address,
  .storerocket-result-phone {
    color: ${props => props.theme.colors.text.secondary};
    ${props => props.theme.typography.body};
  }

  .storerocket-result-today-hours {
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.text.tertiary};
    float: right;
  }

  .storerocket-result-distance {
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.text.tertiary};
    background: none;
    padding: 0;
    display: block;
    float: left;
  }

  .storerocket-result.is-active:before {
    background-color: unset;
  }

  .storerocket-result.is-active {
    border: 2px solid ${props => props.theme.colors.secondary.egg};
  }

  .mapboxgl-popup-content {
    border-radius: ${props => props.theme.spacing(7)};
  }

  .storerocket-popup-buttons {
    ${props => props.theme.typography.button}
    border-radius: 500px;
    padding: ${props => props.theme.spacing(1, 0)};
    text-align: center;
    width: 50%;
    background-color: ${props => props.theme.colors.secondary.chia};
    border: ${props => props.theme.colors.secondary.chia};
  }

  #storerocket-widget .storerocket-popup-buttons a {
    background: unset;
    margin: 0;
  }

  .mapboxgl-marker {
    background-repeat: no-repeat;
  }

  .mapboxgl-popup-close-button {
    margin: ${props => props.theme.spacing(2, 2, 0, 0)};
  }

  .storerocket-clear {
    clear: none;
  }
`;
