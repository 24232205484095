'use client';
import styled from 'styled-components';

export const StyledPhotosGrid = styled.div`
  padding: ${props => props.theme.spacing(0, 0, 10)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(0, 0, 20)};
  }
`;

export const Inner = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: ${props => props.theme.spacing(0, 5)};
`;

export const Headline = styled.div`
  ${props => props.theme.typography.heading3}
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: ${props => props.theme.spacing(8)};
  text-align: center;
  @media ${props => props.theme.tablet} {
    ${props => props.theme.typography.headingMastiff}
    margin-bottom: ${props => props.theme.spacing(12)};
  }
`;

export const Grid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${props => props.theme.spacing(5)};

  @media ${props => props.theme.tablet} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: ${props => props.theme.spacing(7)};
  }
`;

export const GridItem = styled.div`
  margin-bottom: ${props => props.theme.spacing(3)};
  @media ${props => props.theme.tablet} {
    margin-bottom: ${props => props.theme.spacing(10)};
  }
`;

export const PhotoWrapper = styled.div`
  border-radius: 32px;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
`;

export const ItemDescription = styled.h4`
  ${props => props.theme.typography.subheading2}
  color: ${props => props.theme.colors.text.secondary};
  margin-top: ${props => props.theme.spacing(3)};
  text-align: center;
  text-transform: uppercase;

  @media ${props => props.theme.tabletL} {
    ${props => props.theme.typography.subheading1}
    margin-top: ${props => props.theme.spacing(6)};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(9)};
  text-align: center;
  ${props => props.theme.getFocusOutline()}
`;
