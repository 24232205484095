import * as yup from 'yup';
import { SocialButton } from '@web/components';
import { requiredImageFromCMSSchema } from '../schemas';

export const component = SocialButton;

export const schema = yup
  .object({
    image: requiredImageFromCMSSchema,
    href: yup.string().required(),
    component: yup.string().required(),
    openNewTab: yup.bool(),
    appType: yup.string().required(),
  })
  .transform(value => ({
    image: value.image,
    href: value.href,
    component: value.component,
    openNewTab: value.opens_new_tab,
    appType: value.app_type,
  }));
