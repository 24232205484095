import React from 'react';
import PropTypes from 'prop-types';
import { CtaButton } from '@web/molecules';
import { ReasonBlock } from '../ReasonBlock';
import {
  StyledValueProps,
  Inner,
  Headline,
  Container,
  Item,
  ButtonWrapper,
  ButtonContainer,
} from './styles.js';

export const ValueProps = ({
  reasons,
  eyebrow = null,
  headline = null,
  link,
  numberedList = false,
  variant = 'primary',
  buttonVariant = 'dark',
}) => {
  return (
    <StyledValueProps variant={variant}>
      <Inner count={reasons.length || 1}>
        {headline && <Headline>{headline}</Headline>}
        <Container>
          {reasons.map((reason, index) => (
            <Item key={reason.headline} count={reasons.length || 1}>
              <ReasonBlock
                image={reason.image}
                headline={reason.headline}
                subheadline={reason.subheadline}
                number={numberedList ? index + 1 : null}
              />
            </Item>
          ))}
        </Container>
        {link && (
          <ButtonContainer>
            <ButtonWrapper>
              <CtaButton variant={buttonVariant} href={link.target}>
                {link.text}
              </CtaButton>
            </ButtonWrapper>
          </ButtonContainer>
        )}
      </Inner>
    </StyledValueProps>
  );
};

ValueProps.displayName = 'ValueProps';

ValueProps.propTypes = {
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string.isRequired,
      subheadline: PropTypes.node.isRequired,
      image: PropTypes.object,
      number: PropTypes.number,
    }),
  ).isRequired,
  eyebrow: PropTypes.string,
  headline: PropTypes.node,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  numberedList: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'egg']),
  buttonVariant: PropTypes.oneOf(['primary', 'dark']),
};
