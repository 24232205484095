import * as yup from 'yup';
import { FeedingGuidelines } from '@web/components/FeedingGuidelines';
import { feedingDropdownSchema } from './feeding_guidelines_dropdown';
import { feedingGuidelinesRecipeSchema } from './feeding_guidelines_recipes';

export const component = FeedingGuidelines;

export const schema = yup
  .object({
    heading: yup.string().required(),
    dropdown: feedingDropdownSchema.optional(),
    feedingGuidelinesRecipes: yup
      .array(feedingGuidelinesRecipeSchema)
      .required(),
    level: yup.string(),
  })
  .transform(value => ({
    heading: value.heading,
    dropdown: value.dropdown[0],
    feedingGuidelinesRecipes: value.feeding_guidelines_recipes,
    level: value.level,
  }));
