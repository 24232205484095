import React from 'react';
import PropTypes from 'prop-types';
import { CtaButton } from '@web/molecules';
import { Heading } from '@web/atoms';
import { useReporter } from '../../reporter';
import { EmailCapture } from '../EmailCaptureBar/EmailCapture';
import { SimpleCalloutCard } from '../SimpleCalloutCard';
import { simpleCalloutCardsButtonClicked } from './events';
import {
  StyledSimpleCalloutCards,
  CardWrapper,
  HeadlineWrapper,
  CtaWrapper,
  InputWrapper,
  ButtonWrapper,
} from './styles';

export const SimpleCalloutCards = ({
  headline,
  cards,
  cta,
  backgroundColor,
  fontColor,
  ctaColor,
}) => {
  const reporter = useReporter();
  return (
    <StyledSimpleCalloutCards $bgColor={backgroundColor}>
      <HeadlineWrapper>
        <Heading
          headingLevel="h2"
          typography="heading2"
          fontColor={fontColor}
          headingText={headline}
          position="center"
          verticalPadding={0}
          horizontalPadding={0}
        />
      </HeadlineWrapper>
      <CardWrapper>
        {cards.map((card, index) => (
          <SimpleCalloutCard
            key={index}
            {...card}
            align={index % 2 === 0 ? 'end' : 'start'}
          />
        ))}
      </CardWrapper>
      {cta && cta.component === 'email_capture' ? (
        <InputWrapper>
          <EmailCapture {...cta} buttonVariant={ctaColor} />
        </InputWrapper>
      ) : (
        <CtaWrapper>
          <ButtonWrapper $bgColor={backgroundColor}>
            <CtaButton
              variant={cta.buttonVariant}
              href={cta.target}
              onClick={() =>
                reporter.tag(
                  simpleCalloutCardsButtonClicked(cta.text, cta.target),
                )
              }
            >
              {cta.text}
            </CtaButton>
          </ButtonWrapper>
        </CtaWrapper>
      )}
    </StyledSimpleCalloutCards>
  );
};

SimpleCalloutCards.propTypes = {
  headline: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }).isRequired,
      heading: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      headingLevel: PropTypes.string,
      icon: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        src: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        width: PropTypes.string,
        height: PropTypes.string,
      }),
    }),
  ).isRequired,
  cta: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    buttonVariant: PropTypes.string,
  }),
  fontColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  ctaColor: PropTypes.string,
};
