import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { ResponsiveImage } from '@web/molecules';
import { MuxVideo } from '../MuxVideo';
import {
  StyledSplitPanels,
  Wrapper,
  VideoContainer,
  ContentPanel,
  ContentPanelInner,
  ImagePanel,
  ImagePadding,
  ImageContainer,
} from './styles.js';

export const SplitPanels = ({
  asset,
  alignAsset = 'right',
  backgroundColor,
  fillColor,
  content,
  // by default, content (icons, header, body copy, buttons, etc.) is vertically centered inside a color block
  // if you wish to change this, pass centered={false} (ie: SplitPanels in CheckoutConfirmationContainer)
  centered = true,
}) => {
  const renderImage = asset => (
    <>
      <ImagePadding />
      <ImageContainer>
        {asset && (
          <ResponsiveImage
            {...omit(asset, ['width', 'height'])}
            fill={true}
            objectFit="cover"
          />
        )}
      </ImageContainer>
    </>
  );

  return (
    <StyledSplitPanels $fillColor={fillColor}>
      <Wrapper>
        {asset?.component === 'mux_video' ? (
          <VideoContainer $alignAsset={alignAsset}>
            <MuxVideo {...asset} />
          </VideoContainer>
        ) : (
          <ImagePanel $alignAsset={alignAsset}>{renderImage(asset)}</ImagePanel>
        )}
        <ContentPanel $backgroundColor={backgroundColor} centered={centered}>
          <ContentPanelInner centered={centered}>{content}</ContentPanelInner>
        </ContentPanel>
      </Wrapper>
    </StyledSplitPanels>
  );
};

SplitPanels.displayName = 'SplitPanels';

SplitPanels.propTypes = {
  asset: PropTypes.object.isRequired,
  content: PropTypes.node,
  alignAsset: PropTypes.oneOf(['left', 'right']),
  backgroundColor: PropTypes.string,
  fillColor: PropTypes.string,
  centered: PropTypes.bool,
};
