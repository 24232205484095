import * as yup from 'yup';
import { CustomerTestimonials } from '@web/components';
import { richTextFromCMSSchema } from '../schemas';

export const component = CustomerTestimonials;

export const schema = yup
  .object({
    headline: yup.string().required(),
    subHeadline: yup.string().required(),
    testimonials: yup.array(
      yup.object({
        body: richTextFromCMSSchema.required(),
        pupName: yup.string().required(),
        breed: yup.string().required(),
        pupAge: yup.string().required(),
      }),
    ),
    level: yup.string(),
  })
  .transform(value => ({
    testimonials: value.testimonials.map(testimonial => ({
      body: testimonial.body,
      pupName: testimonial.name,
      breed: testimonial.breed,
      pupAge: testimonial.pup_age,
    })),
    headline: value.headline,
    subHeadline: value.subheadline,
    level: value.level,
  }));
