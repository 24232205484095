export const interpolate = (value, replacements) => {
  if (typeof value === 'string') {
    for (const key of Object.keys(replacements)) {
      value = value.replace(new RegExp(`{{${key}}}`, 'g'), replacements[key]);
    }
  } else if (Array.isArray(value)) {
    value = value.map(item => interpolate(item, replacements));
  } else if (value && typeof value === 'object') {
    // Handle React elements specifically
    if (typeof value.$$typeof === 'symbol') {
      value = {
        ...value,
        // Only interpolate props, and only if they exist.
        props: interpolate(value.props, replacements),
      };
    } else {
      const newValue = {};
      for (const objKey of Object.keys(value)) {
        newValue[objKey] = interpolate(value[objKey], replacements);
      }
      value = newValue;
    }
  }

  return value;
};
