'use client';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SectionIntro } from '@web/atoms';
import { CarouselDots, CtaButton, ResponsiveImage } from '@web/molecules';
import checkmarkIcon from './checkmark.svg';
import {
  StyledPlansCarousel,
  CarouselWrapper,
  Carousel,
  Slide,
  SlideImagesContainer,
  SlideImages,
  PlanImage,
  ImageWrapper,
  PlanOverview,
  PlanInner,
  PlanName,
  PlanTagline,
  RecipeChip,
  PlanBody,
  BenefitList,
  Benefit,
  CheckmarkContainer,
  DotsWrapper,
  ButtonWrapper,
  ButtonContainer,
} from './styles.js';

const HIDING_DELAY = 700;
const INTERVAL_DELAY = 6000;

export const PlansCarousel = ({
  headline,
  subheadline,
  plans,
  link = null,
  backgroundColor = 'hero.spinach',
  headingFontColor = 'text.primary',
}) => {
  const [currentPlan, setCurrentPlan] = useState(0);
  const [height, setHeight] = useState(700);
  const [isChanging, setIsChanging] = useState(false);
  const containerRef = useRef(null);
  const slideRefs = useRef(plans.map(() => null));
  const intervalRef = useRef();

  const cleanInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const updatePlan = index => {
    cleanInterval();
    setIsChanging(true);
    setTimeout(() => {
      setIsChanging(false);
      setCurrentPlan(index);
    }, HIDING_DELAY);
  };

  const inViewport = () => {
    if (!containerRef.current || window.document.hidden) {
      return false;
    }
    const { top, height } = containerRef.current.getBoundingClientRect();
    const scrolledTo = top < window.innerHeight * (1 - 0.3);
    const notScrolledPast = top + height > window.innerHeight * 0.3;
    return scrolledTo && notScrolledPast;
  };

  const onResize = () => {
    // resize the Carousel components as needed
    const height = slideRefs.current.reduce((acc, ref) => {
      if (!ref) return acc;
      const { height } = ref.getBoundingClientRect();
      return height > acc ? height : acc;
    }, 0);
    setHeight(height);
  };

  useEffect(() => {
    const incrementPlan = isNext => {
      if (!inViewport()) {
        return;
      }
      setIsChanging(true);
      setTimeout(() => {
        setIsChanging(false);
        setCurrentPlan(cur => {
          const count = plans.length;
          let newPlan = cur + (isNext ? 1 : -1);
          if (newPlan < 0) {
            newPlan = count - 1;
          } else if (newPlan >= count) {
            newPlan = 0;
          }
          return newPlan;
        });
      }, HIDING_DELAY);
    };

    const onKeydown = e => {
      switch (e.keyCode) {
        case 37: // Left
          cleanInterval();
          incrementPlan(false);
          break;
        case 39: // Right
          cleanInterval();
          incrementPlan(true);
          break;
        default:
          break;
      }
    };

    // Init interval to automatically change slides
    if (!intervalRef.current) {
      intervalRef.current = setInterval(
        () => incrementPlan(true),
        INTERVAL_DELAY,
      );
    }
    onResize();

    window.addEventListener('resize', onResize);
    document.addEventListener('keydown', onKeydown);
    return () => {
      cleanInterval();
      window.removeEventListener('resize', onResize);
      document.removeEventListener('keydown', onKeydown);
    };
  }, [plans]);

  return (
    <StyledPlansCarousel ref={containerRef} $bgColor={backgroundColor}>
      <SectionIntro
        headline={headline}
        subheadline={subheadline}
        fontColor={headingFontColor}
      />
      <CarouselWrapper>
        <Carousel style={{ height }}>
          {plans.map((plan, index) => {
            const { name, tagline, recipe, body, benefits, images } = plan;
            const active = index === currentPlan;

            return (
              <Slide
                key={plan.name}
                active={active}
                hiding={isChanging}
                ref={ref => (slideRefs.current[index] = ref)}
              >
                <SlideImagesContainer>
                  <SlideImages>
                    {images.map(image => (
                      <PlanImage key={image.src}>
                        <ImageWrapper>
                          <ResponsiveImage
                            src={image.src}
                            alt={image.alt}
                            fill={true}
                            objectFit="cover"
                          />
                        </ImageWrapper>
                      </PlanImage>
                    ))}
                  </SlideImages>
                </SlideImagesContainer>
                <PlanOverview>
                  <PlanInner>
                    <PlanName>{name}</PlanName>
                    <PlanTagline>{tagline}</PlanTagline>
                    <RecipeChip>{recipe}</RecipeChip>
                    <PlanBody>{body}</PlanBody>
                    <BenefitList>
                      {benefits.map(benefit => (
                        <Benefit key={benefit}>
                          <CheckmarkContainer>
                            <ResponsiveImage
                              height={checkmarkIcon.height}
                              width={checkmarkIcon.width}
                              src={checkmarkIcon.src}
                              alt=""
                            />
                          </CheckmarkContainer>
                          {benefit}
                        </Benefit>
                      ))}
                    </BenefitList>
                  </PlanInner>
                </PlanOverview>
              </Slide>
            );
          })}
          <DotsWrapper>
            <CarouselDots
              pages={plans.length}
              activePage={currentPlan}
              onClick={updatePlan}
              variant="darkestFur"
            />
          </DotsWrapper>
        </Carousel>
      </CarouselWrapper>

      {link && (
        <ButtonContainer>
          <ButtonWrapper $bgColor={backgroundColor}>
            <CtaButton variant="dark" href={link.target}>
              {link.text}
            </CtaButton>
          </ButtonWrapper>
        </ButtonContainer>
      )}
    </StyledPlansCarousel>
  );
};
PlansCarousel.displayName = 'PlansCarousel';

PlansCarousel.propTypes = {
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.node.isRequired,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      tagline: PropTypes.string,
      recipe: PropTypes.string,
      body: PropTypes.node.isRequired,
      benefits: PropTypes.array.isRequired,
      images: PropTypes.array.isRequired,
    }),
  ).isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  backgroundColor: PropTypes.string,
  headingFontColor: PropTypes.string,
};
