'use client';
import styled from 'styled-components';

export const AvatarCircle = styled.div`
  overflow: hidden;
  height: ${props => props.theme.spacing(20)};
  width: ${props => props.theme.spacing(20)};
  background-color: ${props =>
    props.backgroundColor &&
    props.theme.colors.withAlpha(props.backgroundColor, 0.15)};
  border-radius: ${props => props.theme.spacing(20)};
  > svg {
    width: 100%;
    height: 100%;
  }
`;
