'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SectionIntro, MarketingDropdown, Eyebrow } from '@web/atoms';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import { Column } from './Column';
import {
  StyledRecipesCombiner,
  Inner,
  Dropdowns,
  MainContent,
  ImageOuter,
  ImageWrapper,
  ImageInner,
  MixDescription,
  Buttons,
  ButtonWrapper,
  MobileButton,
  MobileButtons,
} from './styles.js';

export const RecipesCombiner = ({
  headline,
  subheadline,
  freshOptions,
  bakedOptions,
  images,
  defaultImage,
  defaultDescription,
  eyebrow,
}) => {
  const [freshOption, setFreshOption] = useState(null);
  const [bakedOption, setBakedOption] = useState(null);

  const comboString = `${freshOption || ''}${
    freshOption && bakedOption ? '+' : ''
  }${bakedOption || ''}`;

  const descriptionForSelection = () => {
    const freshDescription =
      freshOption &&
      freshOptions.items.find(({ value }) => value === freshOption)
        ?.description;
    const bakedDescription =
      bakedOption &&
      bakedOptions.items.find(({ value }) => value === bakedOption)
        ?.description;
    if (freshOption && bakedOption) {
      return (
        <>
          {freshDescription}
          <strong>+</strong> {bakedDescription}
        </>
      );
    } else if (freshOption) {
      return freshDescription;
    } else if (bakedOption) {
      return bakedDescription;
    }
    return defaultDescription;
  };

  return (
    <StyledRecipesCombiner>
      {eyebrow && <Eyebrow textAlign="center">{eyebrow}</Eyebrow>}
      <SectionIntro headline={headline} subheadline={subheadline} />
      <Inner>
        <Dropdowns>
          <MarketingDropdown
            name={`${freshOptions.title} options`}
            label={`${freshOptions.mobileDropdownLabel}`}
            selected={freshOption}
            options={[
              {
                value: null,
                label: freshOptions.mobileDropdownLabel,
                description: null,
              },
              ...freshOptions.items,
            ]}
            onChange={value => {
              setFreshOption(value === freshOption ? null : value);
            }}
          />
          <MarketingDropdown
            name={`${bakedOptions.title} options`}
            label={`${bakedOptions.mobileDropdownLabel}`}
            selected={bakedOption}
            options={[
              {
                value: null,
                label: bakedOptions.mobileDropdownLabel,
                description: null,
              },
              ...bakedOptions.items,
            ]}
            onChange={value => {
              setBakedOption(value === bakedOption ? null : value);
            }}
          />
        </Dropdowns>
        <Column
          options={freshOptions}
          activeOption={freshOption}
          setActiveOption={setFreshOption}
        />
        <MainContent>
          <ImageOuter>
            <ImageWrapper>
              {defaultImage && (
                <ResponsiveImage
                  src={defaultImage.src}
                  alt={defaultImage.alt}
                  fill={true}
                  objectFit="contain"
                />
              )}
              {images.map(imageOption => {
                const { value, image } = imageOption;
                return (
                  <ImageInner
                    key={image.src}
                    active={value === comboString}
                    aria-hidden={value !== comboString}
                  >
                    <ResponsiveImage
                      src={image.src}
                      alt={image.alt}
                      fill={true}
                      objectFit="contain"
                    />
                  </ImageInner>
                );
              })}
            </ImageWrapper>
          </ImageOuter>
          <MixDescription>{descriptionForSelection()}</MixDescription>
        </MainContent>
        <Column
          options={bakedOptions}
          activeOption={bakedOption}
          setActiveOption={setBakedOption}
        />
      </Inner>
      <Buttons>
        <ButtonWrapper>
          <CtaButton variant="carrot" href={freshOptions.link.target}>
            {freshOptions.link.text}
          </CtaButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <CtaButton variant="carrot" href={bakedOptions.link.target}>
            {bakedOptions.link.text}
          </CtaButton>
        </ButtonWrapper>
      </Buttons>
      <MobileButtons>
        <MobileButton>
          <CtaButton variant="carrot" href={freshOptions.link.target}>
            {freshOptions.link.text}
          </CtaButton>
        </MobileButton>
        <MobileButton>
          <CtaButton variant="carrot" href={bakedOptions.link.target}>
            {bakedOptions.link.text}
          </CtaButton>
        </MobileButton>
      </MobileButtons>
    </StyledRecipesCombiner>
  );
};

RecipesCombiner.displayName = 'RecipesCombiner';

RecipesCombiner.propTypes = {
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.node.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  freshOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    placeholder: PropTypes.string.isRequired,
    tag: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.node.isRequired,
      }),
    ).isRequired,
    link: PropTypes.shape({
      target: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    mobileDropdownLabel: PropTypes.string,
  }).isRequired,
  bakedOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    placeholder: PropTypes.string.isRequired,
    tag: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.node.isRequired,
      }),
    ).isRequired,
    link: PropTypes.shape({
      target: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    mobileDropdownLabel: PropTypes.string,
  }).isRequired,
  defaultImage: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  defaultDescription: PropTypes.string.isRequired,
  eyebrow: PropTypes.string,
};
