import React from 'react';
import PropTypes from 'prop-types';
import { interpolate } from '@format/interpolate';
import {
  MARK_LINK,
  NODE_PARAGRAPH,
  render,
} from 'storyblok-rich-text-react-renderer';
import { Paragraph, RichTextLink } from '@web/atoms';
import { CMSBlok } from '../translation';
import { RichTextStyled } from './styles';

export const RichText = ({
  content,
  inline = false,
  interpolationValues = {},
  horizontalPadding,
  verticalPadding,
  level,
}) => {
  if (!content) {
    return null;
  }

  const CustomTag = level ? `${level}` : 'div';
  return (
    <RichTextStyled
      as={CustomTag}
      $verticalPadding={Number(verticalPadding) || 0}
      $horizontalPadding={Number(horizontalPadding) || 0}
    >
      {render(interpolate(content, interpolationValues), {
        nodeResolvers: {
          [NODE_PARAGRAPH]: (children, props) =>
            inline ? (
              <>
                {children}
                <br />
              </>
            ) : (
              <Paragraph {...props}>{children}</Paragraph>
            ),
        },
        defaultBlokResolver: (component, props) => (
          <CMSBlok content={{ component, ...props }} />
        ),
        markResolvers: {
          [MARK_LINK]: (children, props) => (
            <RichTextLink underline={true} {...props}>
              {children}
            </RichTextLink>
          ),
        },
      })}
    </RichTextStyled>
  );
};

RichText.displayName = 'RichText';

RichText.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  inline: PropTypes.bool,
  interpolationValues: PropTypes.object,
  horizontalPadding: PropTypes.string,
  verticalPadding: PropTypes.string,
};
