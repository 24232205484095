'use client';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BackgroundColorContainer, Heading } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import {
  ContentContainer,
  ContactInfo,
  ContactMethod,
  IconContainer,
  Heading1,
  PageHeadings,
  StyledGladly,
  ContactsContainer,
  Bubble,
  ContactInfoText,
  ContactInfoTitle,
  HeadingContainer,
  Subheading,
  ContactInfoTextContainer,
  ContactsContainerText,
} from './styles.js';
import { GLADLY_CONFIG, GLADLY_INIT_SCRIPT_ID, loadGladly } from './config';

export const Gladly = ({
  contactHeading,
  contactMethods,
  heading1,
  heading2,
  subheading,
}) => {
  const [loaded, setLoaded] = useState(false);
  const onChatButtonHandler = () => {
    const { Gladly } = window;
    if (Gladly.show) Gladly.show();
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded) {
      window.gladlyHCConfig = GLADLY_CONFIG;
      loadGladly(() => {
        setLoaded(true);
      });

      return function cleanup() {
        // Gladly loads when one of these injected scripts invokes.
        // Remove scripts to allow successful page load if user leaves and comes back.
        document.getElementById(GLADLY_INIT_SCRIPT_ID).remove();
        Array.from(document.getElementsByTagName('script'))
          .filter(scriptTag => scriptTag.src.match(/cdn\.gladly\.com/))
          .forEach(element => element.remove());
        window.gladlyHCLoaded = false;
      };
    }
  }, [loaded]);

  return (
    <BackgroundColorContainer $bgColor="background.base">
      <ContentContainer>
        <PageHeadings>
          <Heading1>{heading1}</Heading1>
          <Heading
            headingText={heading2}
            headingLevel="h2"
            typography="heading1"
          />
        </PageHeadings>
        <StyledGladly id="gladly-help-center" />
        <ContactInfo>
          <HeadingContainer>
            <Heading
              headingText={contactHeading}
              headingLevel="h3"
              typography="heading2"
              position="center"
            />
            <Subheading>{subheading}</Subheading>
          </HeadingContainer>
          <ContactsContainer>
            {contactMethods.map((contact, i) => {
              const [width, height] = [29, 29];
              return (
                <ContactMethod key={i}>
                  <Bubble
                    href={contact.path}
                    onClick={
                      contact.title === 'Live chat with'
                        ? onChatButtonHandler
                        : null
                    }
                  >
                    <IconContainer>
                      <ResponsiveImage
                        src={contact.icon.src}
                        alt={contact.icon.alt}
                        id={`contact_${i}`}
                        width={width}
                        height={height}
                      />
                    </IconContainer>
                  </Bubble>
                  <ContactInfoTitle>{contact.title}</ContactInfoTitle>
                  <ContactInfoTextContainer
                    onClick={
                      contact.title === 'Live chat with'
                        ? onChatButtonHandler
                        : null
                    }
                  >
                    <ContactInfoText href={contact.path}>
                      {contact.text}{' '}
                    </ContactInfoText>
                  </ContactInfoTextContainer>
                </ContactMethod>
              );
            })}
          </ContactsContainer>
          <ContactsContainerText>
            *By texting us, you consent to receive text messages from Ollie at
            the mobile number you used to text and you are opting-in to receive
            future messages or a phone call at the number you provided. Message
            & Data rates may apply. Reply STOP anytime to opt-out.
          </ContactsContainerText>
        </ContactInfo>
      </ContentContainer>
    </BackgroundColorContainer>
  );
};

Gladly.displayName = 'Gladly';
Gladly.propTypes = {
  heading1: PropTypes.string.isRequired,
  heading2: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  contactHeading: PropTypes.string.isRequired,
  contactMethods: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        src: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
      }).isRequired,
    }).isRequired,
  ),
};
