import React from 'react';
import PropTypes from 'prop-types';
import { StyledDateTimeContainer } from './styles';

function addLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

export const DateTimeDisplay = ({ days, hours, minutes, seconds, width }) => {
  return (
    <StyledDateTimeContainer role="timer" width={width}>
      {addLeadingZeros(days, 2)} d : {addLeadingZeros(hours, 2)} h :{' '}
      {addLeadingZeros(minutes, 2)} m : {addLeadingZeros(seconds, 2)} s
    </StyledDateTimeContainer>
  );
};

DateTimeDisplay.propTypes = {
  days: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
  width: PropTypes.string,
};
