import * as yup from 'yup';
import { ReasonBlock } from '@web/components';
import {
  nullableImageFromCMSSchema,
  inlineRichTextFromCMSSchema,
} from '../schemas';

export const component = ReasonBlock;

export const schema = yup
  .object({
    image: nullableImageFromCMSSchema,
    headline: yup.string().required(),
    level: yup.string(),
    subheadline: inlineRichTextFromCMSSchema,
    variant: yup.mixed().oneOf(['large', 'default']),
  })
  .transform(value => ({
    headline: value.headline,
    level: value.level,
    subheadline: value.subheadline,
    image: value.image,
  }));
