import * as yup from 'yup';
import { SimpleCalloutCard } from '@web/components';
import {
  requiredImageFromCMSSchema,
  nullableImageFromCMSSchema,
} from '../schemas';

export const component = SimpleCalloutCard;

export const schema = yup
  .object({
    image: requiredImageFromCMSSchema,
    heading: yup.string().required(),
    body: yup.string().required(),
    icon: nullableImageFromCMSSchema,
    headingLevel: yup.string(),
  })
  .transform(value => ({
    image: value.image,
    heading: value.heading,
    body: value.body,
    icon: value.icon,
    headingLevel: value.heading_level,
  }));
