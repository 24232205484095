import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundColorContainer } from '@web/atoms';
import { Carousel, CtaButton } from '@web/molecules';
import { useReporter } from '../../reporter';
import { BeforeAfterTestimonial } from '../BeforeAfterTestimonial';
import { Testimonial } from './Testimonial';
import {
  TestimonialsContainer,
  Headline,
  ButtonContainer,
  CarouselContainer,
  SubHeadline,
  ContainerHeader,
  ButtonWrapper,
} from './styles';
import { testimonialsButtonClicked, testimonialsCarouselTurn } from './events';

export const Testimonials = ({
  testimonials,
  headline,
  showStars,
  link,
  subheadline,
  backgroundColor = 'background.base',
  level,
}) => {
  const reporter = useReporter();
  const onTestimonialsClicked = target => {
    reporter.tag(testimonialsCarouselTurn(target));
  };

  return (
    <BackgroundColorContainer $bgColor={backgroundColor}>
      <TestimonialsContainer>
        <ContainerHeader>
          {headline && <Headline as={level}>{headline}</Headline>}
          {subheadline && <SubHeadline>{subheadline}</SubHeadline>}
        </ContainerHeader>

        <CarouselContainer>
          <Carousel
            buttonPosition={'45'}
            itemsToShow={4}
            onArrowClick={target => onTestimonialsClicked(target)}
            onDotClick={() => onTestimonialsClicked('dots')}
          >
            {testimonials.map((testimonial, i) =>
              testimonial.component === 'before_after_testimonial' ? (
                <BeforeAfterTestimonial key={i} {...testimonial} />
              ) : (
                <Testimonial
                  showStars={showStars}
                  {...testimonial}
                  key={testimonial.name}
                />
              ),
            )}
          </Carousel>
        </CarouselContainer>
        {link && (
          <ButtonContainer>
            <ButtonWrapper $bgColor={backgroundColor}>
              <CtaButton
                href={link.target}
                variant="sweetPotato"
                onClick={() =>
                  reporter.tag(
                    testimonialsButtonClicked(link.text, link.target),
                  )
                }
              >
                {link.text}
              </CtaButton>
            </ButtonWrapper>
          </ButtonContainer>
        )}
      </TestimonialsContainer>
    </BackgroundColorContainer>
  );
};

Testimonials.displayName = 'Testimonials';

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.string,
    }),
  ).isRequired,
  headline: PropTypes.string,
  link: PropTypes.shape({
    target: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  showStars: PropTypes.bool,
  subheadline: PropTypes.string,
  backgroundColor: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
