import * as yup from 'yup';
import { BeforeAfterTestimonial } from '@web/components';
import { nullableImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';

export const component = BeforeAfterTestimonial;

export const schema = yup
  .object({
    photo1: nullableImageFromCMSSchema,
    photo2: nullableImageFromCMSSchema,
    badge: yup.string(),
    badgeColor: yup.string(),
    description: yup.string(),
    name: yup.string().required(),
    callout: yup.string(),
    body: richTextFromCMSSchema,
    component: yup.string(),
  })
  .transform(value => ({
    photo1: value.images[0].asset,
    photo2: value.images[1].asset,
    badge: value.badge,
    badgeColor: value.badge_color,
    description: value.description,
    name: value.name,
    callout: value.callout,
    body: value.body,
    component: value.component,
  }));
