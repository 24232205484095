import * as yup from 'yup';
import { Quote } from '@web/components';
import { nullableImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';

export const component = Quote;

export const schema = yup
  .object({
    name: yup.string().required(),
    title: yup.string(),
    image: nullableImageFromCMSSchema,
    body: richTextFromCMSSchema.required(),
  })
  .transform(value => ({
    name: value.name,
    title: value.title,
    image: value.image,
    body: value.body,
  }));
