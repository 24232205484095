'use client';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelect } from 'downshift';
import { Icon } from '@ui/icons';
import { ErrorText } from '../ErrorText';
import { VisuallyHidden } from '../VisuallyHidden';
import {
  Label,
  StyledDropdown,
  Toggle,
  Menu,
  Item,
  SelectedItem,
  DropdownLabelWithIcon,
  DropdownIcon,
} from './styles.js';

export const Dropdown = ({
  name,
  label,
  options,
  selected,
  noSelectionLabel,
  fontColor,
  onChange,
  error = null,
  showLabel = true,
  onBlur,
  disabled,
  icon,
  itemsToShow = {
    desktop: 6,
    mobile: 4,
  },
  extraItems,
  onClick,
}) => {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    selectedItem,
    getItemProps,
  } = useSelect({
    // Downshift generated IDs don't work w/ SSR.
    id: name,
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem.value),
    items: options,
    itemToString: option => option?.label,
    selectedItem: options.find(option => option.value === selected) ?? null,
  });
  const buttonRef = useRef(null);

  const getMinValue = ({ mobile, desktop }) => {
    return {
      mobile: Math.min(mobile, options.length),
      desktop: Math.min(desktop, options.length),
    };
  };

  const itemsToShowValues =
    typeof itemsToShow === 'number'
      ? getMinValue({ mobile: itemsToShow, desktop: itemsToShow })
      : getMinValue(itemsToShow);

  const dropDownLabel = showLabel ? (
    <DropdownLabelWithIcon $icon={!!icon}>
      <Label htmlFor={name} $disabled={disabled} {...getLabelProps()}>
        {label}
      </Label>
      {!!icon && <DropdownIcon>{icon}</DropdownIcon>}
    </DropdownLabelWithIcon>
  ) : (
    <VisuallyHidden>
      <Label {...getLabelProps()} htmlFor={name}>
        {label}
      </Label>
    </VisuallyHidden>
  );

  const items = options.map((item, index) => (
    <Item
      $fontColor={fontColor}
      $highlighted={highlightedIndex === index}
      key={item.value}
      {...getItemProps({
        item,
        index,
      })}
    >
      {item.label}
    </Item>
  ));

  useEffect(() => {
    //This is a result of downshift removing their focusing of the toggle element
    // and react hook form's behavior of attempting to focus the triggering element
    // which is the menu, which is then hidden because downshift autocloses it after
    // you select an item
    if (error && document.activeElement !== buttonRef.current)
      buttonRef.current.focus();
  }, [error]);

  return (
    <StyledDropdown>
      {dropDownLabel}
      <Toggle
        type="button"
        disabled={disabled}
        error={error}
        {...getToggleButtonProps(
          {
            onClick,
          },
          {
            suppressRefError: true,
          },
        )}
        ref={buttonRef}
        testID="dropdown-toggle-button"
      >
        <SelectedItem>{selectedItem?.label ?? noSelectionLabel}</SelectedItem>
        <Icon
          iconName={isOpen ? 'chevron-up' : 'chevron-down'}
          alt=""
          size="24px"
          id="dropdown"
        />
      </Toggle>
      <Menu
        {...getMenuProps()}
        onBlur={onBlur}
        $isOpen={isOpen}
        $hasExtraItems={!!extraItems}
        $itemsToShow={itemsToShowValues}
      >
        {isOpen && items}
        {isOpen && extraItems}
      </Menu>
      {error && <ErrorText>{error}</ErrorText>}
    </StyledDropdown>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
  // Should match a value.
  // null/undefined means nothing selected.
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noSelectionLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  showLabel: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  fontColor: PropTypes.string,
  icon: PropTypes.node,
  itemsToShow: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      desktop: PropTypes.number.isRequired,
      mobile: PropTypes.number.isRequired,
    }),
  ]),
  extraItems: PropTypes.node,
  onClick: PropTypes.func,
};
