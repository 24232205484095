'use client';
import styled from 'styled-components';

export const Card = styled.div`
  border-radius: ${({ theme }) => theme.spacing(8)};
  background: ${props => props.theme.colors.background.lightest};
  display: inline-flex;
  flex-direction: column;
  user-select: none;
  position: relative;
  width: 350px;
`;

export const ImageContainer = styled.div`
  border-radius: ${({ theme }) => theme.spacing(4)};
  object-fit: fill;
  height: auto;
  width: 128px;
  min-width: 96px;
  img {
    border-radius: 24px;
  }
  @media ${props => props.theme.tablet} {
    min-height: 134px;
  }
`;

export const CardHeader = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SectionHeader = styled.div`
  ${({ theme }) => theme.typography.heading4}
  ${({ theme }) => theme.fontFamily.base}
  color: ${({ theme }) => theme.colors.text.secondary};
  padding-top: ${({ theme }) => theme.spacing(4)};
`;
export const CardHeaderText = styled.div`
  margin-left: ${({ theme }) => theme.spacing(4)};
  width: 100%;
  @media ${props => props.theme.tablet} {
    min-height: 175px;
    width: 75%;
  }
`;

export const Name = styled.p`
  ${({ theme }) => theme.typography.heading6}
  ${({ theme }) => theme.fontFamily.base}
`;

export const Size = styled.p`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const DisplayedPrice = styled.span`
  ${({ theme }) => theme.typography.body}
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const SavePercentage = styled.span`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.status.successPrimary};
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const HrTag = styled.hr`
  border: none;
  height: 1px;
  background-color: ${({ theme }) =>
    theme.colors.withAlpha('border.fur', 0.25)};
`;

export const StrikeThroughPrice = styled.span`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.text.tertiary};
  margin-right: ${({ theme }) => theme.spacing(1)};
  text-decoration-line: line-through;
  text-transform: capitalize;
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex: 1;
`;

export const Dropdowns = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0;
  width: 100%;
  @media ${props => props.theme.tablet} {
    flex-wrap: nowrap;
    width: auto;
  }
`;

export const ShowMobileSelectionContainer = styled.div`
  width: 100%;
  display: block;
  @media ${props => props.theme.tablet} {
    display: none;
  }
`;

export const ShowDesktopSelectionContainer = styled.div`
  width: 100%;
  display: none;
  @media ${props => props.theme.tablet} {
    display: block;
  }
`;

export const RemoveButtonMobile = styled.div`
  width: 100%;
  display: block;
  text-decoration: underline;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.hero.cranberry};
  @media ${props => props.theme.tablet} {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const RemoveButtonDesktop = styled.div`
  padding-top: ${({ theme }) => theme.spacing(1)};
  text-align: center;
  text-decoration: underline;
  display: none;
  color: ${({ theme }) => theme.colors.hero.cranberry};
  font-weight: bold;
  @media ${props => props.theme.tablet} {
    display: block;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const SelectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacing(2, 0, 4, 0)};
`;

export const DropdownContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const SizeDropdownWrapper = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
  @media ${props => props.theme.tablet} {
    width: 168px;
  }
`;

export const QuantitySizeDropdownsWrapper = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;
  & > div {
    width: 65%;
    margin-bottom: 0;
  }

  & > div:first-child {
    width: 45%;
    margin-right: ${({ theme }) => theme.spacing(4)};
  }

  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  @media ${props => props.theme.tablet} {
    width: auto;
    margin-top: 0;
    & > div {
      min-width: 170px;
      margin-bottom: 0;
    }
    & > div:first-child {
      min-width: 110px;
      margin-right: ${({ theme }) => theme.spacing(4)};
    }
    margin-right: ${({ theme }) => theme.spacing(4)};
  }
  ul {
    z-index: 101;
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  background-color: white;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(3)};
  button {
    padding: ${({ theme }) => theme.spacing(3, 4)};
    @media ${props => props.theme.mobileM} {
      padding: ${({ theme }) => theme.spacing(3, 8)};
    }
    @media ${props => props.theme.tablet} {
      padding: ${({ theme }) => theme.spacing(4, 8)};
    }
  }
`;

export const ExtraContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: ${props => props.theme.spacing(3, 0, 10)};
  &:last-child {
    padding-bottom: ${props => props.theme.spacing(2)};
  }
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(3, 0, 4)};
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;
