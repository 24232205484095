import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { StyledMuxPlayer } from './styles.js';

export const MuxVideo = ({ playbackId, autoplay, loop, thumbnail }) => {
  return (
    <>
      <StyledMuxPlayer
        playbackId={playbackId}
        loop={loop}
        autoPlay={autoplay}
        muted={autoplay}
        poster=""
      >
        <Image
          alt={'thumbnail'}
          slot="poster"
          src={thumbnail}
          width={1280}
          height={960}
          sizes={'(max-width: 768px) 100vw, 50vw'}
          style={{ width: '100%', height: '100%' }}
          priority={true}
        />
      </StyledMuxPlayer>
    </>
  );
};

MuxVideo.propTypes = {
  playbackId: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  thumbnail: PropTypes.string,
};
