import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledComparisonChartItem,
  ChartItemHeader,
  Header,
  Paragraph,
} from './styles';

export const ComparisonChartItem = ({
  header,
  subheader = null,
  level = 'h2',
}) => {
  return (
    <StyledComparisonChartItem>
      <ChartItemHeader>
        <Header as={level}>{header}</Header>
        {subheader && <Paragraph>{subheader}</Paragraph>}
      </ChartItemHeader>
    </StyledComparisonChartItem>
  );
};

ComparisonChartItem.displayName = 'ComparisonChartItem';

ComparisonChartItem.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
