'use client';
import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AccordionWrapper = styled.div``;

export const AccordionContent = styled.div`
  @media ${props => props.theme.tablet} {
    font-size: 1.125rem;
  }
`;

export const DetailBody = styled.div`
  ${props => props.theme.typography.body}
`;

export const StyledAccordionItem = styled.div`
  background: ${props =>
    props.active && props.variant === 'large'
      ? props.theme.colors.background.base
      : props.theme.colors.background.lightest};
  border-radius: ${props => props.theme.spacing(7)};
  padding: ${props => props.theme.spacing(4, 6)};
  position: relative;
  ${props => props.theme.typography.body}

  @media ${props => props.theme.tablet} {
    border-radius: ${props => props.theme.spacing(8)};
    padding: ${props =>
      props.variant === 'large'
        ? props.theme.spacing(4, 13)
        : props.theme.spacing(4, 7)};
  }

  &:not(:first-child) {
    margin-top: ${props => props.theme.spacing(2)};
  }
`;

export const Title = styled.div`
  ${props => props.theme.typography.heading5};

  @media ${props => props.theme.tablet} {
    ${props => props.theme.typography.heading4};
    padding-right: ${props => props.theme.spacing(10)};
  }

  ${({ variant, theme }) =>
    variant === 'toggle' &&
    css`
      ${theme.typography.button}
      padding: ${props => props.theme.spacing(0.75)};
      text-align: center;
      @media ${props => props.theme.tablet} {
        padding: ${props => props.theme.spacing(0.75)};
        ${theme.typography.button}
      }
    `}
`;

export const TitleText = styled.span`
  display: inline-block;
  padding-right: ${props =>
    props.variant !== 'toggle' && props.theme.spacing(10)};
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  @media ${props => props.theme.tablet} {
    right: 6px;
    top: 6px;
  }
`;

export const Content = styled.div`
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.1s;
  opacity: 0;
  margin: ${props =>
    props.variant === 'large'
      ? props.theme.spacing(5, 0)
      : props.theme.spacing(4, 0, 2)};
`;
