'use client';
import styled from 'styled-components';
import { variants } from './variants';

export const StyledCarouselDots = styled.div`
  display: flex;
  justify-content: center;
`;

export const DotWrapper = styled.button`
  background-color: ${({ active, variant, theme }) =>
    active
      ? theme.colors.get(variants[variant].active.background)
      : 'transparent'};
  border: 1px solid
    ${({ variant, theme }) => theme.colors.get(variants[variant].active.border)};
  border-radius: 60%;
  cursor: pointer;
  display: flex;
  height: 16px;
  width: 16px;
  padding: 0;
  margin: ${props => props.theme.spacing(1)};
  justify-content: center;

  &:focus,
  &:not([disabled]):hover {
    border-width: 2px;
  }
`;
