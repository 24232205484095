import styled from 'styled-components';

export const StyledGetSocial = styled.span`
  background: inherit;
  width: 100%;
  max-width: 287px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(8)};
  }
`;

export const StyledGetSocialHeader = styled.span`
  ${props => props.theme.typography.heading4};
  text-align: center;
  color: ${props => props.theme.colors.text.primary};
  padding-bottom: ${props => props.theme.spacing(4)};
`;

export const Description = styled.div`
  ${({ theme }) => theme.typography.button}
  text-align: center;
  color: ${props => props.theme.colors.text.secondary};
  padding-bottom: ${props => props.theme.spacing(6)};
  line-height: 24px;
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`;

export const GetSocialContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
