import React from 'react';
import PropTypes from 'prop-types';
import { CustomerTestimonial } from './CustomerTestimonial';
import { CUSTOMER_TESTIMONIALS } from './constants';
import {
  Container,
  Headline,
  SubHeadline,
  TestimonialContainer,
} from './styles';

export const CustomerTestimonials = ({
  testimonials = CUSTOMER_TESTIMONIALS,
  headline,
  subHeadline,
  level,
}) => {
  return (
    <Container>
      {headline && <Headline as={level}>{headline}</Headline>}
      {subHeadline && <SubHeadline>{subHeadline}</SubHeadline>}
      <TestimonialContainer>
        {testimonials.map(t => (
          <CustomerTestimonial {...t} key={t.pupName} />
        ))}
      </TestimonialContainer>
    </Container>
  );
};

CustomerTestimonials.displayName = 'CustomerTestimonials';

CustomerTestimonials.propTypes = {
  headline: PropTypes.string.isRequired,
  subHeadline: PropTypes.string.isRequired,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      pupName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
