import * as yup from 'yup';
import { requiredImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';
import { schema as socialButtonSchema } from './social_button';
import { schema as listSchema } from './list';

export const responsivePopupModal = yup
  .object({
    desktopImage: requiredImageFromCMSSchema,
    mobileImage: requiredImageFromCMSSchema,
    title: yup.string(),
    subtitle: yup.string(),
    body: richTextFromCMSSchema,
    cta: yup.array(socialButtonSchema),
    qrCode: requiredImageFromCMSSchema,
    qrExplanation: yup.string(),
    list: listSchema,
  })
  .transform(value => ({
    desktopImage: value.desktop_image,
    mobileImage: value.mobile_image,
    title: value.title,
    subtitle: value.subtitle,
    body: value.body,
    cta: value.cta,
    qrCode: value.qr_code,
    qrExplanation: value.qr_explanation,
    list: value.list,
  }));
