'use client';
import styled from 'styled-components';
import { Paragraph } from '@web/atoms';

export const StyledSplitPanelBody = styled.div``;

export const IconContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(6)};
`;

export const Header = styled.h3`
  ${props => props.theme.typography.heading2};
  margin: 0;
`;

// TODO(James) how do we handle rich text with non-standard typographies?
export const Body = styled.div`
  ${Paragraph} {
    ${props => props.theme.typography.heading4};
    margin: ${props => props.theme.spacing(4, 0)};
    @media ${props => props.theme.laptop} {
      ${props => props.theme.typography.heading5};
    }
  }
`;

export const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: ${props => props.theme.spacing(8)};

  & > a {
    margin-right: ${props => props.theme.spacing(5)};
  }
`;
