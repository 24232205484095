'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AccordionItem as Item } from './AccordionItem';
import { AccordionWrapper, AccordionContent, DetailBody } from './styles';

export const Accordion = ({
  details,
  variant = 'default',
  buttonVariant = 'kale',
}) => {
  const [activeItem, setActiveItem] = useState(0);

  const onSelectItem = index => {
    setActiveItem(index === activeItem ? null : index);
  };

  return (
    <AccordionWrapper>
      {details.map(({ title, level, body }, index) => (
        <Item
          title={title}
          key={title}
          variant={variant}
          level={level}
          buttonVariant={buttonVariant}
          isOpen={index === activeItem}
          onClick={() => onSelectItem(index)}
        >
          <AccordionContent>
            <DetailBody>{body}</DetailBody>
          </AccordionContent>
        </Item>
      ))}
    </AccordionWrapper>
  );
};

Accordion.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
      body: PropTypes.node.isRequired,
    }),
  ).isRequired,
  variant: PropTypes.string,
  buttonVariant: PropTypes.string,
};
