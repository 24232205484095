'use client';
import styled from 'styled-components';

export const StyledFaqListItem = styled.div`
  &:not(:first-child) {
    margin-top: ${props => props.theme.spacing(2)};
  }
`;

export const Content = styled.div`
  ${props => props.theme.typography.body2};

  @media ${props => props.theme.tablet} {
    ${props => props.theme.typography.body3};
  }
`;
