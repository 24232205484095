import * as yup from 'yup';
import { SlideDownMenuLink } from '@web/molecules';
import { schema as slideDownCardSchema } from './slide_down_card';

export const component = SlideDownMenuLink;

export const schema = yup
  .object({
    header: yup.string().required(),
    categories: yup.array(slideDownCardSchema).required(),
  })
  .transform(value => ({
    header: value.header,
    categories: value.categories,
    component: 'slide_down_menu_link',
  }));
