import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import { EmailCapture } from '../EmailCaptureBar/EmailCapture';
import {
  StyledFullscreenCallout,
  BackgroundImage,
  ContentWrapper,
  Content,
  ContentImage,
  Headline,
  LinkWrapper,
  InputWrapper,
  ButtonWrapper,
  ButtonContainer,
} from './styles.js';

export const FullscreenCallout = ({
  headline,
  cta,
  backgroundImage,
  mobileBackgroundImage,
  image,
  ctaColor = 'spinach',
  level,
}) => {
  return (
    <StyledFullscreenCallout>
      <BackgroundImage mobileHidden={!!mobileBackgroundImage}>
        <ResponsiveImage
          // Need to omit width & height for layout="fill"
          {...omit(backgroundImage, ['width', 'height'])}
          fill={true}
          objectFit="cover"
        />
      </BackgroundImage>
      {mobileBackgroundImage && (
        <BackgroundImage isMobile>
          <ResponsiveImage
            {...omit(mobileBackgroundImage, ['width', 'height'])}
            fill={true}
            objectFit="cover"
          />
        </BackgroundImage>
      )}
      <ContentWrapper>
        <Content>
          <ContentImage>
            <ResponsiveImage {...image} width={114} height={82} />
          </ContentImage>
          <Headline as={level}>{headline}</Headline>
          <LinkWrapper>
            {cta.component === 'email_capture' ? (
              <InputWrapper>
                <EmailCapture {...cta} buttonVariant={ctaColor} />
              </InputWrapper>
            ) : (
              <ButtonContainer>
                <ButtonWrapper>
                  <CtaButton variant={ctaColor} href={cta.target}>
                    {cta.text}
                  </CtaButton>
                </ButtonWrapper>
              </ButtonContainer>
            )}
          </LinkWrapper>
        </Content>
      </ContentWrapper>
    </StyledFullscreenCallout>
  );
};

FullscreenCallout.displayName = 'FullscreenCallout';

FullscreenCallout.propTypes = {
  headline: PropTypes.string.isRequired,
  ctaColor: PropTypes.string,
  cta: PropTypes.object.isRequired,
  image: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  backgroundImage: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  mobileBackgroundImage: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
