'use client';
import styled from 'styled-components';

export const StyledDropdown = styled.div`
  position: relative;
`;

export const Toggle = styled.button`
  ${props => props.theme.typography.body};
  height: 52px;
  position: relative;
  text-align: left;
  width: 100%;
  color: ${props => props.theme.colors.text.secondary};
  background-color: ${props => props.theme.colors.background.lightest};
  border: ${props => (props.error ? '2px' : '1px')} solid
    ${props =>
      props.error
        ? props.theme.colors.status.errorPrimary
        : props.theme.colors.border.dark};
  border-radius: 4px;
  padding: ${props => props.theme.spacing(4, 6, 4, 4)};

  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: ${props => props.theme.spacing(4)};
  }

  &:hover {
    border-color: ${props => props.theme.colors.background.dark};
  }

  &:focus,
  &:active {
    border: 2px solid ${props => props.theme.colors.border.dark};
    padding-top: calc(${props => props.theme.spacing(4)} - 1px);
    padding-left: calc(${props => props.theme.spacing(4)} - 1px);
    padding-bottom: calc(${props => props.theme.spacing(4)} - 1px);
    padding-right: calc(${props => props.theme.spacing(4)} - 1px);

    svg {
      margin-right: calc(${props => props.theme.spacing(4)} - 1px);
    }
  }

  &:disabled {
    border-color: ${props => props.theme.colors.withAlpha('border.fur', 0.4)};
    background: ${props =>
      props.theme.colors.withAlpha('background.lightest', 0.4)};
    color: ${props => props.theme.colors.withAlpha('text.secondary', 0.4)};

    svg {
      opacity: 0.4;
    }
  }
`;

export const Menu = styled.ul`
  list-style: none;
  z-index: 1;
  background-color: ${props => props.theme.colors.background.lightest};
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  border: 1px solid ${props => props.theme.colors.border.base};
  padding: ${props => props.theme.spacing(3, 2, 2, 2)};
  display: ${props => (props.$isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: ${props => (props.$itemsToShow.mobile < 3 ? 'hidden' : 'auto')};
  height: calc(
    ${props => props.$itemsToShow.mobile + (props.$hasExtraItems ? 1 : 0)} *
      32px + 20px
  );

  @media ${props => props.theme.mobileL} {
    height: calc(
      ${props => props.$itemsToShow.desktop + (props.$hasExtraItems ? 1 : 0)} *
        32px + 20px
    );
  }
`;

export const Item = styled.li`
  ${props => props.theme.typography.body};
  text-align: start;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.text.primary};
  padding: ${props => props.theme.spacing(2, 4)};
  margin-bottom: ${props => props.theme.spacing(1)};
  cursor: pointer;
  width: 100%;
  height: 28px;
  border-radius: ${props => props.theme.spacing(1)};
  background-color: ${props =>
    props.$highlighted
      ? props.theme.colors.background.base
      : props.theme.colors.background.lightest};
  &:last-child {
    color: ${props =>
      props.$fontColor && props.theme.colors.get(props.$fontColor)};
  }
`;

export const Label = styled.label`
  ${props => props.theme.typography.body};
  color: ${props =>
    props.$disabled
      ? props.theme.colors.withAlpha('text.secondary', 0.4)
      : props.theme.colors.text.secondary};
  display: block;
  margin-bottom: ${props => props.theme.spacing(1)};
`;

export const SelectedItem = styled.div`
  position: relative;
  margin-top: -5px;
  z-index: 0;
`;

//Multi Select

export const DropdownLabelWithIcon = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: ${props => props.theme.spacing(0, 0, 2, 0)};
  label {
    margin: ${props => props.$icon && props.theme.spacing(0, 2, 0, 0)};
  }
`;

export const DropdownIcon = styled.div`
  height: 16px;
`;

export const SelectedItemWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(2, 2, 0, 0)};
`;

export const SearchContainer = styled.div`
  ${props => props.theme.typography.body};
  min-height: 52px;
  height: auto;
  position: relative;
  text-align: left;
  width: 100%;
  color: ${props => props.theme.colors.text.secondary};
  background-color: ${props => props.theme.colors.background.lightest};
  border: 1px solid ${props => props.theme.colors.border.dark};
  border-radius: 4px;
  padding: ${props => props.theme.spacing(0, 6, 2, 4)};
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &:focus-within {
    border: 2px solid ${props => props.theme.colors.border.dark};
    max-height: 52px;
    padding-left: 15px;

    /* to prevent shifting on focus*/
    ${Menu} {
      top: calc(100% + 1px);
      width: calc(100% + 2px);
      margin-left: -1px;
    }
    ${SelectedItemWrapper} {
      margin-top: 7px;
    }
  }
`;

export const SearchLabel = styled.label`
  margin-bottom: ${props => props.theme.spacing(1)};
  color: ${props => props.theme.colors.withAlpha('text.tertiary', 1.0)};
  ${props => props.$hideSearchLabel && props.theme.visuallyHidden}
`;

export const InputContainer = styled.div`
  display: flex;
  position: relative;
  max-width: 100%;
  flex-grow: 1;
  padding: ${props => props.theme.spacing(2, 0, 0, 0)};
`;

/** Ensures the InputContainer takes up enough space */
export const InputSizer = styled.div`
  visibility: hidden;
  font-size: 1rem;
`;

export const SearchInput = styled.input`
  border: none;
  background-color: ${props => props.theme.transparent};
  font-size: 1rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
  &:focus,
  &:not([value='']) {
    outline: none;
    padding-top: ${({ theme }) => theme.spacing(2)};

    + ${SelectedItem} {
      ${props => props.theme.visuallyHidden}
    }
  }
`;
export const OpenButton = styled.button`
  position: absolute;
  background-color: ${props => props.theme.transparent};
  border: none;
  right: 18px;
  top: 0;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  z-index: 2;
`;

export const PlaceholderMessageItem = styled.li`
  padding: ${props => props.theme.spacing(1, 0, 1, 4)};
  color: ${props => props.theme.colors.text.secondary};
`;

//Marketing Styles

export const StyledMarketingDropdown = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledMarketingToggle = styled.button`
  ${props => props.theme.typography.button}
  height: 52px;
  position: relative;
  text-align: center;
  width: 100%;
  border-radius: 500px;
  padding: ${props => props.theme.spacing(4, 6, 4, 4)};
  max-width: 330px;
  background-color: ${({ variant, theme }) => theme.colors.get(variant)};
  border: 1px solid ${({ theme }) => theme.colors.border.dark};
  color: ${({ theme }) => theme.colors.get('neutral.darkerFur')};

  /* stylelint-disable-next-line */
  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: ${props => props.theme.spacing(4)};
  }

  &:focus,
  &:hover {
    border-color: ${props => props.theme.colors.border.dark};
    outline: none;
  }

  &:active {
    border-color: ${props => props.theme.colors.text.primary};
  }

  &:disabled {
    opacity: 0.5;
    border-color: ${props => props.theme.colors.withAlpha('border.fur', 0.4)};
    background: ${props =>
      props.theme.colors.withAlpha('background.lightest', 0.4)};
    color: ${props => props.theme.colors.withAlpha('text.secondary', 0.4)};

    svg {
      opacity: 0.4;
    }
  }
`;

export const StyledMarketingLabel = styled(Label)`
  ${props => props.theme.typography.bodyBold};
`;

export const StyledMarketingMenu = styled.ul`
  list-style: none;
  z-index: 1;
  background-color: ${props => props.theme.colors.background.lightest};
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colors.border.base};
  padding: ${props => props.theme.spacing(3, 2, 2, 2)};
  display: ${props => (props.$isOpen ? 'block' : 'none')};
  position: absolute;
  top: 105%;
  left: 0;
  width: 100%;
  overflow: auto;
  height: calc(
    ${props => props.$itemsToShow.mobile + (props.$hasExtraItems ? 1 : 0)} *
      32px + 20px
  );

  @media ${props => props.theme.mobileL} {
    height: calc(
      ${props => props.$itemsToShow.desktop + (props.$hasExtraItems ? 1 : 0)} *
        32px + 20px
    );
  }
`;
