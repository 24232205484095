import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Heading, Cell } from '@web/atoms';
import {
  CardContainer,
  CardContainerHeader,
  CardContainerHeaderText,
  CardContainerContent,
  CardTabsContainer,
  CardTab,
  CardContentImagesContainer,
  CardContentImage,
  CardContentTextContainer,
  ContentTextGrid,
  CardContentTextHeading,
  CardContentTextParagraph,
  CardContentTextIngredientsParagraph,
  CardContentImageHelperText,
  FlexItems,
  PackSizeGrid,
  PackSizeGridItem,
  GAGrid,
} from './styles';

export const FeedingGuidelinesRecipeCard = ({
  selectedRecipe,
  version,
  onVersionChange,
}) => {
  const versions = selectedRecipe.feedingGuidelinesRecipeVersions.map(
    (version, i) => {
      return i;
    },
  );

  const currentVersion =
    selectedRecipe.feedingGuidelinesRecipeVersions[version];

  const tabs = selectedRecipe.feedingGuidelinesRecipeVersions.map(
    (recipeVersion, i) => (
      <CardTab
        key={i}
        onClick={() => onVersionChange(i)}
        tabSelected={version === i}
      >
        {recipeVersion.versionName}
      </CardTab>
    ),
  );

  const guaranteedAnalysis = currentVersion.guaranteedAnalysis.map(
    analysis => ({
      name: analysis.name,
      range: analysis.range,
      percentage: analysis.percentage,
    }),
  );

  const packSize = currentVersion.packSize.map(pack => ({
    label: pack.label,
    size: pack.size,
    weight: pack.weight,
  }));

  return (
    <CardContainer>
      <CardContainerHeader>
        <CardContainerHeaderText>
          <Heading
            headingLevel={selectedRecipe.level}
            headingText={selectedRecipe.heading}
            position="center"
            typography="heading1"
            backgroundColor="background.lightest"
          />
        </CardContainerHeaderText>

        {versions.length > 1 && <CardTabsContainer>{tabs}</CardTabsContainer>}
      </CardContainerHeader>
      {
        <CardContainerContent>
          <CardContentImagesContainer>
            <CardContentImage>
              <ResponsiveImage
                {...currentVersion.packageImage}
                width={215}
                height={175}
              />
            </CardContentImage>
            <CardContentImageHelperText>
              {currentVersion.imageGuide}
            </CardContentImageHelperText>
            {currentVersion.barcodeImage && (
              <CardContentImage>
                <ResponsiveImage
                  {...currentVersion.barcodeImage}
                  width={215}
                  height={200}
                />
              </CardContentImage>
            )}
          </CardContentImagesContainer>
          <CardContentTextContainer>
            <ContentTextGrid>
              <Cell position="start" columns={{ mobile: 1, laptop: 2 }}>
                <CardContentTextHeading>
                  {currentVersion.ingredientsTitle}
                </CardContentTextHeading>
                <CardContentTextIngredientsParagraph>
                  {currentVersion.ingredients}
                </CardContentTextIngredientsParagraph>
              </Cell>
              <Cell columns={{ laptop: 2 }}>
                <FlexItems>
                  <div style={{ width: '100%' }}>
                    <CardContentTextHeading>
                      {currentVersion.guaranteedAnalysisTitle}
                    </CardContentTextHeading>
                    <CardContentTextParagraph>
                      {currentVersion.guaranteedAnalysis && (
                        <GAGrid>
                          <PackSizeGridItem>
                            {guaranteedAnalysis.map(analysis => (
                              <div key={analysis.name}>{analysis.name}</div>
                            ))}
                          </PackSizeGridItem>
                          <PackSizeGridItem>
                            {guaranteedAnalysis.map(analysis => (
                              <div key={analysis.name}>-</div>
                            ))}
                          </PackSizeGridItem>
                          <PackSizeGridItem>
                            {guaranteedAnalysis.map(analysis => (
                              <div key={analysis.name}>{analysis.range}</div>
                            ))}
                          </PackSizeGridItem>
                          <PackSizeGridItem>
                            {guaranteedAnalysis.map(analysis => (
                              <div key={analysis.name}>-</div>
                            ))}
                          </PackSizeGridItem>
                          <PackSizeGridItem>
                            {guaranteedAnalysis.map(analysis => (
                              <div key={analysis.name}>
                                {analysis.percentage}
                              </div>
                            ))}
                          </PackSizeGridItem>
                        </GAGrid>
                      )}
                    </CardContentTextParagraph>
                  </div>
                  <div style={{ width: '100%' }}>
                    <CardContentTextHeading>
                      {currentVersion.calorieContentTitle}
                    </CardContentTextHeading>
                    <CardContentTextParagraph>
                      {currentVersion.calorieContent}
                    </CardContentTextParagraph>
                  </div>
                </FlexItems>
              </Cell>
              <Cell columns={{ laptop: 2 }}>
                <CardContentTextHeading>
                  {currentVersion.packSizeTitle}
                </CardContentTextHeading>
                <CardContentTextParagraph>
                  {currentVersion.packSize && (
                    <PackSizeGrid>
                      <PackSizeGridItem>
                        {packSize.map(pack => (
                          <div key={pack.label}>{pack.label}</div>
                        ))}
                      </PackSizeGridItem>
                      <PackSizeGridItem>
                        {packSize.map(pack => (
                          <p key={pack.label}>-</p>
                        ))}
                      </PackSizeGridItem>
                      <PackSizeGridItem>
                        {packSize.map(pack => (
                          <div key={pack.label}>{pack.size.toUpperCase()}</div>
                        ))}
                      </PackSizeGridItem>
                      <PackSizeGridItem>
                        {packSize.map(pack => (
                          <p key={pack.label}>-</p>
                        ))}
                      </PackSizeGridItem>
                      <PackSizeGridItem>
                        {packSize.map(pack => (
                          <div key={pack.label}>{pack.weight}</div>
                        ))}
                      </PackSizeGridItem>
                    </PackSizeGrid>
                  )}
                </CardContentTextParagraph>
              </Cell>
            </ContentTextGrid>
          </CardContentTextContainer>
        </CardContainerContent>
      }
    </CardContainer>
  );
};

FeedingGuidelinesRecipeCard.displayName = 'FeedingGuidelinesRecipeCard';

FeedingGuidelinesRecipeCard.propTypes = {
  selectedRecipe: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    feedingGuidelinesRecipeVersions: PropTypes.arrayOf(
      PropTypes.shape({
        packageImage: PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }).isRequired,
        barcodeImage: PropTypes.shape({
          src: PropTypes.string.isRequired,
          alt: PropTypes.string.isRequired,
        }),
        versionName: PropTypes.string,
        imageGuide: PropTypes.string,
        ingredientsTitle: PropTypes.string,
        ingredients: PropTypes.node,
        guaranteedAnalysisTitle: PropTypes.string,
        guaranteedAnalysis: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            range: PropTypes.string,
            percentage: PropTypes.string,
          }),
        ),
        calorieContentTitle: PropTypes.string,
        calorieContent: PropTypes.node,
        packSizeTitle: PropTypes.string,
        packSize: PropTypes.arrayOf(
          PropTypes.shape({
            size: PropTypes.string,
            label: PropTypes.string,
            weight: PropTypes.string,
          }),
        ),
      }),
    ).isRequired,
    level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  }).isRequired,
  version: PropTypes.number.isRequired,
  onVersionChange: PropTypes.func.isRequired,
};
