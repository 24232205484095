import React from 'react';
import PropTypes from 'prop-types';
import { BoxedDateTimeDisplay } from '@web/atoms';
import { useCountdown } from '../../hooks';
import { StyledCountdownContainer } from './styles';

export const BoxedCountdownClock = ({ targetDate, textColor }) => {
  const { days, hours, minutes, seconds } = useCountdown(targetDate) || {};

  return (
    <>
      {days !== undefined && (
        <StyledCountdownContainer $textColor={textColor}>
          <BoxedDateTimeDisplay
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            textColor={textColor}
          />
        </StyledCountdownContainer>
      )}
    </>
  );
};

BoxedCountdownClock.propTypes = {
  targetDate: PropTypes.instanceOf(Date).isRequired, //eslint-disable-line react/forbid-prop-types
  textColor: PropTypes.string,
};
