'use client';
import styled from 'styled-components';

export const StyledBanner = styled.div`
  background-color: ${props =>
    props.theme.colors.get(props.$bgColor, props.theme.colors.background.base)};
  padding: ${props => props.theme.spacing(3)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props => props.theme.typography.body};
`;

export const IconContainer = styled.span`
  margin-right: ${props => props.theme.spacing(4)};
`;
