'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Modal } from '../Modal';
import { TabView, AddOnQuickViewForm, PricingInfo } from './components';
import { TABS } from './constants';
import {
  Container,
  Headline,
  Subheadline,
  TabNav,
  TabButton,
  Content,
  SizeAndPrice,
  Size,
  LargeImageContainer,
  MobileImageContainer,
  StyledAddOnQuickViewContainer,
} from './styles';

export const AddOnQuickView = ({
  title,
  sku,
  subtitle,
  details,
  nutritionalInformation,
  fullIngredients,
  size,
  pricingInfo,
  serving,
  onClickClose,
  shown,
  image,
  recurrence,
  unit,
  productType,
  dropdownValues,
  onSubmitAddonAddition,
  defaultValues,
  isReactivating = false,
  level,
}) => {
  const [activeTab, setActiveTab] = useState(TABS[productType][0]);
  const onModalSubmit = values => {
    onSubmitAddonAddition({ sku, ...values, productName: title, productType });
    onClickClose();
  };
  return (
    <Modal
      shown={shown}
      onCloseModal={onClickClose}
      ariaLabel="Add on quick view modal"
      mobileFullWidthOnly={true}
      closeBtnColor="neutral.white"
    >
      <StyledAddOnQuickViewContainer>
        <Container>
          <LargeImageContainer>
            <ResponsiveImage {...image} />
          </LargeImageContainer>
          <MobileImageContainer>
            <ResponsiveImage {...image} />
          </MobileImageContainer>
          <Content>
            <Headline as={level}>{title}</Headline>
            <Subheadline>{subtitle}</Subheadline>
            <SizeAndPrice>
              <Size>{size}</Size>
              <PricingInfo {...pricingInfo} />
            </SizeAndPrice>
            <AddOnQuickViewForm
              onSubmit={onModalSubmit}
              {...dropdownValues}
              recurrence={recurrence}
              unit={unit}
              defaultValues={defaultValues}
              isReactivating={isReactivating}
            />
            <TabNav role="tablist">
              {TABS[productType].map((tab, i) => (
                <TabButton
                  key={i}
                  onClick={() => setActiveTab(tab)}
                  active={activeTab === tab}
                  role="tab"
                  aria-selected={activeTab === tab ? 'true' : 'false'}
                  aria-controls={`tabpanel-${tab}`}
                  id={`tab-${tab}`}
                >
                  {tab}
                </TabButton>
              ))}
            </TabNav>
            <TabView
              activeTab={activeTab}
              details={details}
              nutritionalInfo={nutritionalInformation}
              serving={serving}
              fullIngredients={fullIngredients}
            />
          </Content>
        </Container>
      </StyledAddOnQuickViewContainer>
    </Modal>
  );
};

AddOnQuickView.displayName = 'AddOnQuickView';

AddOnQuickView.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  details: PropTypes.node,
  nutritionalInformation: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fullIngredients: PropTypes.node,
  size: PropTypes.string.isRequired,
  serving: PropTypes.node,
  onClickClose: PropTypes.func.isRequired,
  onSubmitAddonAddition: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
  image: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  dropdownValues: PropTypes.shape({
    dropdowns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
          }).isRequired,
        ).isRequired,
      }).isRequired,
    ).isRequired,
    removeButtonLabel: PropTypes.string.isRequired,
    addButtonLabel: PropTypes.string.isRequired,
    doneButtonLabel: PropTypes.string.isRequired,
  }).isRequired,
  pricingInfo: PropTypes.shape({
    price: PropTypes.string,
    discountedPrice: PropTypes.string,
    save: PropTypes.string,
  }).isRequired,
  sku: PropTypes.string.isRequired,
  productType: PropTypes.oneOf(['TR', 'SP']),
  productName: PropTypes.string,
  defaultValues: PropTypes.shape({
    quantity: PropTypes.number,
    frequency: PropTypes.number,
    recurrence: PropTypes.number,
  }).isRequired,
  isReactivating: PropTypes.bool,
  recurrence: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
