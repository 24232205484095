'use client';
import styled from 'styled-components';

export const StyledImageCarouselCell = styled.div``;

export const ImageButton = styled.button`
  display: none;
  height: auto;
  border: none;
  padding: 0;
  @media ${props => props.theme.tabletS} {
    display: block;
  }
`;

export const Container = styled.div`
  max-width: 589px;
`;
