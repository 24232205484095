import * as yup from 'yup';
import { FoodComparison } from '@web/components';
import { schema as imageSchema } from './image';

export const component = FoodComparison;

const foodComparisonCharts = yup.object({
  isOllieChartType: yup.bool().nullable(),
  isCheckMarkOutline: yup.bool().nullable(),
  buttonText: yup.string().optional(),
  buttonTarget: yup.string().optional(),
  textComparisons: yup.array().required(),
  headerImage: imageSchema.nullable(),
});

const transformChartValues = charts =>
  charts.map(chart => ({
    isOllieChartType: chart.is_ollie_chart_type,
    isCheckMarkOutline: chart.is_check_mark_outline,
    buttonText: chart.button_text,
    buttonTarget: chart.button_target.url,
    headerImage: chart.image?.[0] || null,
    textComparisons: chart.text_comparisons.map(value => ({
      label: value.text_comparison_label,
    })),
  }));

export const schema = yup
  .object({
    charts: yup.array(foodComparisonCharts).required(),
  })
  .transform(value => ({
    charts: transformChartValues(value.charts),
  }));
