'use client';
import styled, { css } from 'styled-components';

export const StyledValueProps = styled.div`
  background-color: ${({ theme }) => theme.colors.background.base};
  color: ${props =>
    props.variant === 'egg'
      ? props.theme.colors.text.primary
      : props.theme.colors.text.secondary};
  padding: ${props => props.theme.spacing(14, 0)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(20, 0)};
  }
  ${props =>
    props.variant === 'egg' &&
    css`
      background-color: ${props => props.theme.colors.secondary.egg};
    `}
`;

export const Inner = styled.div`
  margin: 0 auto;
  max-width: ${props => (props.count > 3 ? '1200px' : '1100px')};
  padding: ${props => props.theme.spacing(0, 5)};
`;

export const Eyebrow = styled.h3`
  ${props => props.theme.typography.subheading1}
  margin-bottom: ${props => props.theme.spacing(2)};
  text-align: center;
`;

export const Headline = styled.div`
  ${props => props.theme.typography.heading2}
  margin: 0 auto;
  max-width: 850px;
  text-align: center;

  > img {
    display: inline-block;
    line-height: 0;
    margin-bottom: -5px;
  }

  @media ${props => props.theme.tablet} {
    margin-bottom: ${props => props.theme.spacing(12)};
  }
`;

export const Container = styled.div`
  @media ${props => props.theme.tablet} {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const Item = styled.div`
  padding: ${props => props.theme.spacing(10, 8, 0)};
  @media ${props => props.theme.tablet} {
    flex: 0 0 auto;
    padding: ${props => props.theme.spacing(0, props.count > 3 ? 4 : 6)};
    width: calc(100% / ${props => props.count});
  }
  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(0, props.count > 3 ? 4 : 10)};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(12)};

  @media ${props => props.theme.laptop} {
    margin-top: ${props => props.theme.spacing(13)};
  }
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
  text-align: center;
`;
