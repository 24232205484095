import * as yup from 'yup';
import { EmailCapture } from '@web/components';

export const component = EmailCapture;

export const schema = yup
  .object({
    listId: yup.string().required(),
    placeholder: yup.string().required(),
    component: yup.string().required(),
    fillColor: yup.string(),
  })
  .transform(value => ({
    listId: value.list_id,
    placeholder: value.placeholder,
    component: value.component,
    fillColor: value.fill_color,
  }));
