import * as yup from 'yup';
import { TestimonialHighlight } from '@web/components';
import { responsiveImageFromCMSSchema } from '../schemas';

export const component = TestimonialHighlight;

export const schema = yup
  .object({
    testimonial: yup.object({
      body: yup.string().required(),
      name: yup.string().required(),
    }),
    image: responsiveImageFromCMSSchema,
    buttonText: yup.string().required(),
    backgroundColor: yup.string().nullable(),
    buttonTarget: yup.string().required(),
  })
  .transform(value => ({
    testimonial: {
      body: value.testimonial.content,
      name: `${value.testimonial.customer} & ${value.testimonial.pet}`,
    },
    image: value.image[0],
    buttonText: value.button_text,
    backgroundColor: value.background_color,
    buttonTarget: value.button_target.url,
  }));
