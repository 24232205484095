import * as yup from 'yup';
import { RichText } from '../RichText';

export const component = RichText;

export const schema = yup
  .object({
    content: yup.object().required(),
    horizontalPadding: yup.string(),
    verticalPadding: yup.string(),
  })
  .required()
  .transform(value => ({
    content: value.rich_text,
    horizontalPadding: value.horizontal_padding,
    verticalPadding: value.vertical_padding,
  }));
