import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { CloseButton } from '../CloseButton';
import { StyledBanner, IconContainer } from './styles';

export const Banner = ({
  icon = null,
  children,
  dismissible = false,
  bgColor,
  onCloseClick,
}) => {
  return (
    <StyledBanner $bgColor={bgColor}>
      {icon && (
        <IconContainer>
          <Icon {...icon} id="banner" />
        </IconContainer>
      )}
      <div>{children}</div>
      {dismissible && <CloseButton onClick={onCloseClick}></CloseButton>}
    </StyledBanner>
  );
};

Banner.displayName = 'Banner';

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.object,
  dismissible: PropTypes.bool,
  bgColor: PropTypes.string,
  onCloseClick: PropTypes.func,
};
