'use client';
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { CtaButton, List } from '@web/molecules';
import { useReporter } from '../../reporter';
import { MuxVideo } from '../MuxVideo';
import { EmailCapture } from '../EmailCaptureBar/EmailCapture';
import freeShippingTruckImage from '../../images/free-shipping-truck.svg';
import moneyBackGuaranteeImage from '../../images/money-back-guarantee.svg';
import { orientationSettings } from './constants';
import { heroButtonClicked } from './events';
import {
  StyledHero,
  ContentContainer,
  Content,
  Header,
  Subheader,
  ButtonWrapper,
  InputWrapper,
  VideoWrapper,
  TextWrapper,
  EyebrowText,
  StyledGuaranteesContainer,
  StyledGuarantee,
  StyledListContainer,
  StyledReviewsLink,
  StyledReviewsLinkContainer,
  StarRating,
} from './styles.js';

export const RapidCroVideoHeroContentTest = ({
  reviewsLink,
  eyebrowText,
  headline,
  subheadline,
  valuePropsList,
  cta,
  backgroundColor = 'secondary.chia',
  fontColor = 'text.lightest',
  horizontalOrientation = 'left',
  ctaColor,
  video,
}) => {
  const reporter = useReporter();

  return (
    <StyledHero backgroundColor={backgroundColor}>
      <ContentContainer
        horizontalOrientation={orientationSettings[horizontalOrientation]}
      >
        <Content
          horizontalOrientation={orientationSettings[horizontalOrientation]}
        >
          <TextWrapper>
            {reviewsLink && (
              <StyledReviewsLinkContainer>
                <StarRating>
                  <StyledReviewsLink
                    fontColor={fontColor}
                    href={reviewsLink.target}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {reviewsLink.text}
                  </StyledReviewsLink>
                </StarRating>
              </StyledReviewsLinkContainer>
            )}
            {eyebrowText && (
              <EyebrowText $fontColor={fontColor}>{eyebrowText}</EyebrowText>
            )}
            {headline && <Header $fontColor={fontColor}>{headline}</Header>}
            {subheadline && (
              <Subheader $fontColor={fontColor}>{subheadline}</Subheader>
            )}
            {valuePropsList && (
              <StyledListContainer fontColor={fontColor}>
                <List icon={valuePropsList.icon} items={valuePropsList.items} />
              </StyledListContainer>
            )}
            {cta && cta.component === 'email_capture' ? (
              <InputWrapper>
                <EmailCapture {...cta} buttonVariant={ctaColor} />
              </InputWrapper>
            ) : (
              cta && (
                <ButtonWrapper backgroundColor={backgroundColor}>
                  <CtaButton
                    href={cta.target}
                    variant={ctaColor}
                    fullWidth={true}
                    hoverIcon={null}
                    onClick={() =>
                      reporter.tag(heroButtonClicked(cta.text, cta.target))
                    }
                  >
                    {cta.text}
                  </CtaButton>
                </ButtonWrapper>
              )
            )}
            <StyledGuaranteesContainer fontColor={fontColor}>
              <StyledGuarantee>
                <Image src={freeShippingTruckImage} alt="delivery truck" />
                <p>FREE Shipping</p>
              </StyledGuarantee>
              <StyledGuarantee>
                <Image
                  src={moneyBackGuaranteeImage}
                  alt="money back guarantee"
                />
                <p>Money back guarantee</p>
              </StyledGuarantee>
            </StyledGuaranteesContainer>
          </TextWrapper>
        </Content>
        <VideoWrapper>
          <MuxVideo
            playbackId={video.playbackId}
            autoplay={video.autoplay}
            loop={video.loop}
            thumbnail={video.thumbnail}
          />
        </VideoWrapper>
      </ContentContainer>
    </StyledHero>
  );
};

RapidCroVideoHeroContentTest.displayName = 'Rapid Cro Video Hero Content Test';

RapidCroVideoHeroContentTest.propTypes = {
  reviewsLink: PropTypes.object,
  eyebrowText: PropTypes.string,
  headline: PropTypes.node,
  subheadline: PropTypes.string,
  valuePropsList: PropTypes.object,
  cta: PropTypes.object,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  image: PropTypes.object.isRequired,
  horizontalOrientation: PropTypes.oneOf(['left', 'center', 'right']),
  ctaColor: PropTypes.string,
  video: PropTypes.object.isRequired,
};
