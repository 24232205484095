import * as yup from 'yup';
import { Gladly } from '@web/components';
import { FAQS_PAGE_CONTENT } from '@web/components/Gladly/constants';

// TODO(James) break up into multiple CMS components
export const component = Gladly;

export const schema = yup
  .object({
    heading1: yup.string().required(),
    heading2: yup.string().required(),
    contactHeading: yup.string().required(),
    subheading: yup.string(),
    // TODO(James) create real schema
    contactMethods: yup.array().required(),
  })
  // TODO(James) replace w/ CMS content
  .transform(() => FAQS_PAGE_CONTENT);
