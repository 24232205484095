'use client';
import styled from 'styled-components';
import { StyledLink } from '../SmolLink/styles.js';

export const StyledBreadcrumbs = styled.ol`
  display: flex;
  height: ${props => props.theme.spacing(12)};
  width: 100%;
  background-color: ${props => props.theme.colors.background.base};
  padding-left: ${props => props.theme.spacing(19.5)};
`;

export const BreadcrumbsContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.spacing(2)};
  ${StyledLink} {
    ${props => props.theme.typography.label}
  }
  &:not(:last-child) {
    margin-right: ${props => props.theme.spacing(2)};
  }
`;

export const WithoutLink = styled.p`
  ${props => props.theme.typography.label}
`;
