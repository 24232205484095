import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { PillText } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import {
  Container,
  Header,
  HeaderImage,
  HeaderBadge,
  Content,
  ContentCallout,
  ContentBenefit,
  ContentDescription,
  Footer,
  FooterButton,
  ButtonWrapper,
} from './styles';

export const ProductValues = ({
  callout,
  benefit,
  badge,
  description,
  image,
  link,
  buttonVariant = 'chia',
}) => {
  return (
    <Container>
      <Header>
        {image?.src && (
          <HeaderImage>
            <ResponsiveImage {...image} />
          </HeaderImage>
        )}
        {badge && (
          <HeaderBadge>
            <PillText text={badge} variant="kale" />
          </HeaderBadge>
        )}
      </Header>
      <Content>
        {callout && <ContentCallout>{callout}</ContentCallout>}
        <ContentBenefit>{benefit}</ContentBenefit>
        {description && <ContentDescription>{description}</ContentDescription>}
      </Content>
      {link && (
        <Footer>
          <FooterButton>
            <ButtonWrapper>
              <LinkButton href={link.target} variant={buttonVariant}>
                {link.text}
              </LinkButton>
            </ButtonWrapper>
          </FooterButton>
        </Footer>
      )}
    </Container>
  );
};

ProductValues.displayName = 'ProductValues';

ProductValues.propTypes = {
  callout: PropTypes.string,
  benefit: PropTypes.string.isRequired,
  badge: PropTypes.string,
  description: PropTypes.node,
  image: PropTypes.object,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  buttonVariant: PropTypes.string,
};
