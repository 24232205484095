import * as yup from 'yup';
import { Breadcrumbs } from '@web/atoms';

export const component = Breadcrumbs;

export const schema = yup
  .object({
    listPath: yup
      .array(
        yup.object({
          title: yup.string().required(),
          link: yup.string(),
        }),
      )
      .required(),
  })
  .transform(value => ({
    listPath: value?.list_path,
  }));
