import * as yup from 'yup';
import { List } from '@web/molecules';

export const component = List;

export const schema = yup
  .object({
    heading: yup.string(),
    icon: yup
      .mixed()
      .oneOf(['bullet', 'checkmark', 'checkmarkSimple', 'checkmarkCircle'])
      .optional(),
    items: yup
      .array(
        yup.object({
          heading: yup.string().nullable(),
          body: yup.string().required(),
        }),
      )
      .required(),
  })
  .transform(value => ({
    heading: value.heading,
    icon: value.icon === '' ? undefined : value.icon,
    items: value.items,
  }));
