import React from 'react';
import PropTypes from 'prop-types';
import { ErrorText } from '../ErrorText';
import { VisuallyHidden } from '../VisuallyHidden';
import {
  StyledRadioInput,
  Label,
  StyledFieldset,
  StyledLegend,
} from './styles.js';

export const Radio = ({
  choices,
  value,
  name,
  legendText,
  onChange,
  disabled,
  direction = 'row',
  error,
  onClick,
  variant,
}) => {
  return (
    <>
      <StyledFieldset>
        <StyledLegend>{legendText}</StyledLegend>
        <StyledRadioInput direction={direction}>
          {choices.map(choice => (
            <Label
              checked={value === choice.value}
              disabled={disabled && value !== choice.value}
              error={error}
              htmlFor={`${name}-${choice.value}`}
              key={choice.value}
              variant={variant}
            >
              <VisuallyHidden>
                <input
                  type="radio"
                  value={choice.value}
                  checked={value === choice.value}
                  id={`${name}-${choice.value}`}
                  onChange={e => onChange(e.target.value)}
                  error={error}
                  onClick={onClick}
                  disabled={disabled && value !== choice.value}
                />
              </VisuallyHidden>
              {choice.name}
            </Label>
          ))}
        </StyledRadioInput>
        {error && <ErrorText>{error}</ErrorText>}
      </StyledFieldset>
    </>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['row', 'column']),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  legendText: PropTypes.string,
  variant: PropTypes.string,
};
