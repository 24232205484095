import * as yup from 'yup';
import { ProductValuesGroup } from '@web/components';
import { schema as productValuesSchema } from './product_values';

export const component = ProductValuesGroup;

export const schema = yup
  .object({
    heading: yup.string().required(),
    subheading: yup.string(),
    productValues: yup.array(productValuesSchema).required(),
    eyebrow: yup.string().nullable(),
  })
  .transform(value => ({
    heading: value.heading,
    subheading: value.subheading,
    productValues: value.product_value_cards,
    eyebrow: value.eyebrow,
  }));
