'use client';
import styled, { css } from 'styled-components';

export const StyledSplitPanels = styled.div`
  width: 100%;
  background: ${props =>
    props.theme.colors.get(
      props.$fillColor,
      props.theme.colors.background.lightest,
    )};
`;

export const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.spacing(4)};
  margin: ${({ theme }) => theme.spacing(0, 'auto')};
  max-width: 1440px;
  row-gap: ${props => props.theme.spacing(5)};
  @media ${props => props.theme.tablet} {
    column-gap: ${props => props.theme.spacing(5)};

    grid-template-columns: 1fr 1fr;
    padding: ${props => props.theme.spacing(4, 10)};
  }

  @media ${props => props.theme.laptopL} {
    padding: ${props => props.theme.spacing(4, 20)};
  }
`;

export const VideoContainer = styled.div`
  border-radius: 40px;
  overflow: hidden;

  mux-player {
    height: 100%;

    &::part(center play button) {
      display: flex;
      min-width: 90px;
      min-height: 90px;
      padding: 22px 22px 22px 24px;
    }
  }

  @media ${props => props.theme.tablet} {
    display: block;
    order: ${props => (props.$alignAsset === 'left' ? -1 : 2)};
  }
`;

export const ContentPanel = styled.div`
  background: ${props =>
    props.theme.colors.get(
      props.$backgroundColor,
      props.theme.colors.hero.spinach,
    )};
  border-radius: 40px;
  ${({ centered }) =>
    centered &&
    css`
      position: relative;
    `}
`;

export const ContentPanelInner = styled.div`
  text-align: left;
  padding: ${props => props.theme.spacing(5, 5)};
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${props => props.theme.desktop} {
    ${({ centered }) =>
      centered &&
      css`
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      `}
    padding: ${props => props.theme.spacing(3, 15)};
  }
`;

export const ImagePanel = styled.div`
  border-radius: 40px;
  position: relative;

  @media ${props => props.theme.tablet} {
    display: block;
    order: ${props => (props.$alignAsset === 'left' ? -1 : 2)};
  }
`;

export const ImagePadding = styled.div`
  padding-bottom: 100%;
`;

export const ImageContainer = styled.div`
  backface-visibility: hidden;
  background: ${props => props.theme.colors.background.dark};
  border-radius: 40px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
`;
