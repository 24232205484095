import * as yup from 'yup';
import { IngredientModal } from '@web/components';
import { richTextFromCMSSchema, requiredImageFromCMSSchema } from '../schemas';
import { schema as recipeNutrient } from './recipe_nutrient';

export const component = IngredientModal;

export const schema = yup
  .object({
    headline: yup.string().required(),
    fullIngredients: richTextFromCMSSchema,
    nutritionalInfo: yup.array(recipeNutrient),
    body: richTextFromCMSSchema,
    image: requiredImageFromCMSSchema,
    value: yup.string().required(),
  })
  .required()
  .transform(value => ({
    headline: value.headline,
    fullIngredients: value.recipe.full_ingredients.body,
    nutritionalInfo:
      value.recipe.nutritional_information.body.nutrients.nutrients,
    body: value.body,
    image: value.image,
    value: value.value,
  }));
