import * as yup from 'yup';
import { Heading } from '@web/atoms';
import { linkSchema } from '../schemas';

export const component = Heading;

export const schema = yup
  .object({
    headingText: yup.string().required(),
    headingLevel: yup.string().required(),
    typography: yup.string().required(),
    position: yup.string().optional(),
    verticalPadding: yup.number().optional(),
    horizontalPadding: yup.number().optional(),
    backgroundColor: yup.string(),
    badge: yup.string(),
    link: linkSchema,
    fontColor: yup.string(),
  })
  .transform(value => ({
    position: value.position,
    typography: value.typography,
    headingLevel: value.heading_level,
    headingText: value.heading_text,
    verticalPadding:
      value.vertical_padding === '' || isNaN(Number(value.vertical_padding))
        ? undefined
        : Number(value.vertical_padding),
    horizontalPadding:
      value.horizontal_padding === '' || isNaN(Number(value.horizontal_padding))
        ? undefined
        : Number(value.horizontal_padding),
    backgroundColor: value.background_color || undefined,
    badge: value.badge,
    link: value.link?.[0] || undefined,
    fontColor: value.font_color,
  }));
