'use client';
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { CarouselDots } from '@web/molecules';
import { Heading } from '@web/atoms';
import { sideScroll } from '../../hooks/index.js';
import {
  TimelineContainer,
  TimelineContent,
  TimelineHeader,
  TimelineMarkerContainer,
  TimelineArrowContainer,
  TimelineArrowLine,
  TimelineArrow,
  CarouselDotsContainer,
  ButtonWrapper,
} from './styles';
import { TimelineMarker } from './TimelineMarker';

export const Timeline = ({
  heading,
  markerColor,
  timelineMarkers,
  link,
  buttonVariant,
}) => {
  const contentWrapper = useRef(null);
  const [activePage, setActivePage] = useState(0);
  const columns = timelineMarkers.length;

  const onScroll = () => {
    let scrollFlag = contentWrapper?.current.scrollLeft;
    if (scrollFlag === 0) {
      setActivePage(0);
    }
    if (scrollFlag > 120 && scrollFlag < 351) {
      setActivePage(1);
    }
    if (scrollFlag > 120 && scrollFlag < 451) {
      setActivePage(1);
    }
    if (scrollFlag > 351 && columns < 4) {
      setActivePage(2);
    }
    if (scrollFlag > 451 && columns === 4) {
      setActivePage(2);
    }
  };

  return (
    <TimelineContainer>
      <TimelineHeader>
        <Heading
          headingLevel="h1"
          typography={'heading1'}
          headingText={heading}
          position="left"
          paddingTop={2}
          backgroundColor={'neutral'}
        />
      </TimelineHeader>
      {timelineMarkers && (
        <TimelineContent ref={contentWrapper} onScroll={onScroll}>
          <TimelineArrowContainer items={timelineMarkers?.length}>
            <TimelineArrowLine />
            <TimelineArrow />
          </TimelineArrowContainer>

          <TimelineMarkerContainer items={timelineMarkers?.length}>
            {timelineMarkers.map((timelineMarker, i) => (
              <TimelineMarker
                key={i}
                markerColor={markerColor}
                {...timelineMarker}
              />
            ))}
          </TimelineMarkerContainer>
        </TimelineContent>
      )}
      <CarouselDotsContainer>
        <CarouselDots
          activePage={activePage}
          pages={3}
          variant="darkestFur"
          onClick={page =>
            sideScroll(columns, page, contentWrapper.current, 25, 250, 260)
          }
        ></CarouselDots>
      </CarouselDotsContainer>
      {link && (
        <ButtonWrapper>
          <LinkButton
            href={link.target}
            variant={buttonVariant}
            horizontalPadding={15}
            verticalPadding={3}
          >
            {link.text}
          </LinkButton>
        </ButtonWrapper>
      )}
    </TimelineContainer>
  );
};

Timeline.displayName = 'Timeline';

Timeline.propTypes = {
  timelineMarkers: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      heading: PropTypes.string,
      body: PropTypes.node,
      markerColor: PropTypes.string,
    }).isRequired,
  ).isRequired,
  heading: PropTypes.string,
  link: PropTypes.shape({
    target: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  markerColor: PropTypes.string,
  buttonVariant: PropTypes.string,
};
