export const horizontalOrientationOptions = ['left', 'center', 'right'];

export const orientationSettings = {
  left: {
    text: 'left',
    justify: 'flex-start',
    align: 'flex-start',
  },
  center: {
    text: 'center',
    justify: 'center',
    align: 'center',
  },
  right: {
    text: 'left',
    justify: 'flex-end',
    align: 'flex-start',
  },
};
