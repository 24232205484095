'use client';
import styled from 'styled-components';

export const StyledListItem = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: ${props => (props.isIcon ? 'auto' : '1fr')} 2fr;

  @media ${props => props.theme.tablet} {
    grid-template-columns: ${props => (props.isIcon ? 'auto' : '1fr')} 3fr;
  }
`;

export const ItemHeading = styled.p`
  ${props => props.theme.typography.heading5};
`;

export const ItemBody = styled.p`
  ${props => props.theme.typography[props.$typography]}
`;

export const ItemIcon = styled.p`
  margin-top: ${props =>
    props.icon === 'checkmarkSimple'
      ? '0px'
      : props.icon === 'checkmarkCircle'
      ? '2px'
      : '6px'}; /* matches ItemBody line height */
  margin-right: ${props => props.theme.spacing(3)};
`;
