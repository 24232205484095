import styled, { css } from 'styled-components';

export const StyledEyebrow = styled.p`
  ${props => props.theme.typography.subheading1};
  color: ${props =>
    props.theme.colors.get(props.fontColor, props.theme.colors.text.secondary)};
  margin-bottom: ${props => props.theme.spacing(4)};
  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `}
`;
