'use client';
import PropTypes from 'prop-types';
import useEmblaCarousel from 'embla-carousel-react';
import { ResponsiveImage } from '../ResponsiveImage';
import { useDotButton, usePrevNextButtons } from './functions';
import {
  StyledCarousel,
  SlideContainer,
  SlideViewport,
  SlideContent,
  DotContainer,
  PrevButton,
  NextButton,
  DotButton,
  Slide,
  BottomContainer,
  ImageDot,
} from './styles.js';

export const Carousel = ({
  children,
  bottomElement,
  dotPadding,
  dotVariant = 'darkerFur',
  buttonPosition = '50',
  overlappingArrows = true,
  showArrows = true,
  showDots = true,
  itemsToShow,
  dotImages,
  onArrowClick,
  onDotClick,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);
  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <StyledCarousel>
      <SlideViewport ref={emblaRef}>
        <SlideContainer>
          {children.map((child, index) => (
            <Slide key={index} itemsToShow={itemsToShow}>
              <SlideContent>{child}</SlideContent>
            </Slide>
          ))}
        </SlideContainer>
      </SlideViewport>
      {showArrows && (
        <>
          <PrevButton
            onClick={() => {
              onPrevButtonClick();
              onArrowClick?.();
            }}
            disabled={prevBtnDisabled}
            buttonPosition={buttonPosition}
            overlappingArrows={overlappingArrows}
          />
          <NextButton
            onClick={() => {
              onNextButtonClick();
              onArrowClick?.();
            }}
            disabled={nextBtnDisabled}
            buttonPosition={buttonPosition}
            overlappingArrows={overlappingArrows}
          />
        </>
      )}
      <BottomContainer dotPadding={dotPadding}>
        {bottomElement}
        {showDots && scrollSnaps.length > 1 && (
          <DotContainer>
            {dotImages
              ? scrollSnaps.map((_, index) => (
                  <ImageDot
                    key={index}
                    onClick={() => {
                      onDotButtonClick(index);
                      onDotClick?.();
                    }}
                    active={index === selectedIndex}
                  >
                    <ResponsiveImage {...dotImages[index]} />
                  </ImageDot>
                ))
              : scrollSnaps.map((_, index) => (
                  <DotButton
                    key={index}
                    onClick={() => {
                      onDotButtonClick(index);
                      onDotClick?.();
                    }}
                    active={index === selectedIndex}
                    variant={dotVariant}
                  />
                ))}
          </DotContainer>
        )}
      </BottomContainer>
    </StyledCarousel>
  );
};

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  buttonPosition: PropTypes.oneOf(['15', '17', '30', '40', '45', '50']),
  dotVariant: PropTypes.string,
  overlappingArrows: PropTypes.bool,
  bottomElement: PropTypes.node,
  dotPadding: PropTypes.number,
  itemsToShow: PropTypes.number,
  dotImages: PropTypes.array,
  onArrowClick: PropTypes.func,
  onDotClick: PropTypes.func,
};
