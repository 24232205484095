'use client';
import styled from 'styled-components';

export const StyledFullscreenCallout = styled.section`
  position: relative;
  z-index: 1;
`;

export const BackgroundImage = styled.div`
  background: ${props => props.theme.colors.background.dark};
  bottom: 0;
  display: ${props => (props.mobileHidden ? 'none' : 'block')};
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  @media ${props => props.theme.tablet} {
    display: ${props => (props.isMobile ? 'none' : 'block')};
  }
  img {
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  min-height: 100vh;
  padding: ${props => props.theme.spacing(14, 4, 40)};

  @media ${props => props.theme.mobileS} {
    flex-direction: row;
  }

  @media ${props => props.theme.tablet} {
    justify-content: center;
    padding: ${props => props.theme.spacing(40, 4)};
    flex-direction: column;
  }
`;

export const Content = styled.div`
  background: ${props => props.theme.colors.background.base};
  border-radius: 32px;
  max-width: 100%;
  padding: ${props => props.theme.spacing(9)};
  text-align: center;
  width: 528px;

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(12)};
  }
`;

export const ContentImage = styled.div`
  height: 80px;
  object-fit: contain;
  object-position: center;
  margin-bottom: ${props => props.theme.spacing(4)};
  width: 100%;
`;

export const Headline = styled.h3`
  ${props => props.theme.typography.heading2}
`;

export const LinkWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(10)};
`;

export const InputWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(20)};
  width: 110%;
  margin-left: -5%;
  @media ${props => props.theme.tabletL} {
    margin-top: ${props => props.theme.spacing(10)};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;
