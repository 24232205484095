import React from 'react';
import PropTypes from 'prop-types';
import { GridContainer } from './styles.js';
import { COLUMN_SIZE_PROPTYPE } from './constants.js';

export const Grid = ({
  gap = {},
  children,
  columns = {},
  backgroundColor = 'background.base',
}) => {
  const columnsValues =
    typeof columns === 'number'
      ? { mobile: columns, laptop: columns, tablet: columns }
      : columns;

  return (
    <GridContainer
      $gap={gap}
      $mobile={columnsValues.mobile}
      $laptop={columnsValues.laptop}
      $tablet={columnsValues.tablet}
      $backgroundColor={backgroundColor}
    >
      {children}
    </GridContainer>
  );
};

Grid.displayName = 'Grid';

Grid.propTypes = {
  columns: PropTypes.oneOfType([
    COLUMN_SIZE_PROPTYPE,
    PropTypes.shape({
      mobile: COLUMN_SIZE_PROPTYPE,
      laptop: COLUMN_SIZE_PROPTYPE,
      tablet: COLUMN_SIZE_PROPTYPE,
    }),
  ]),
  gap: PropTypes.shape({
    row: PropTypes.number,
    column: PropTypes.number,
  }),
  children: PropTypes.node.isRequired,
};
