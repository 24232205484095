import styled, { css, keyframes } from 'styled-components';

// Variable to maintain spacing on mobile
const carouselHorizontalPadding = 5;
// Sets overall height: May need updating if image ratios change
const slidesHeight = '63%';
// Mobile height: May need updating if image ratios change
const mobileImagesHeight = '105%';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(16px);
  }
`;

export const StyledPlansCarousel = styled.section`
  background: ${props => props.theme.colors.get(props.$bgColor)};
  padding: ${props => props.theme.spacing(14, 0)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(20, 0)};
  }
`;

export const CarouselWrapper = styled.div`
  margin: 0 auto;
  max-width: 1100px;
  padding: ${props => props.theme.spacing(12, carouselHorizontalPadding, 0)};
`;

export const Carousel = styled.div`
  min-height: 600px;
  position: relative;
  width: 100%;

  @media ${props => props.theme.tablet} {
    min-height: 500px;
  }
`;

export const ImageWrapper = styled.div`
  animation: ${fadeInUp} 500ms ease-out forwards;
  backface-visibility: hidden;
  box-shadow: 0px 10px 16px rgba(94, 88, 78, 0.1);
  border-radius: 32px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const Slide = styled.div`
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;

  @media ${props => props.theme.tablet} {
    display: flex;
  }

  ${props =>
    props.active &&
    css`
      opacity: 1;
      pointer-events: auto;
      & ${ImageWrapper} {
        animation: ${fadeInUp} 500ms ease-out forwards;
      }
      & ${PlanOverview} {
        animation: ${fadeInUp} 500ms ease-out forwards;
      }
    `}

  ${props =>
    props.hiding &&
    css`
      & ${ImageWrapper} {
        animation: ${fadeOut} 350ms ease-out forwards;
        opacity: 1;
      }
      & ${PlanOverview} {
        animation: ${fadeOut} 350ms ease-out forwards;
        opacity: 1;
      }
    `}
`;

export const SlideImagesContainer = styled.div`
  @media ${props => props.theme.tablet} {
    padding-bottom: ${slidesHeight};
  }
`;

export const SlideImages = styled.div`
  position: absolute;
  padding-bottom: ${mobileImagesHeight};
  right: 0;
  top: 0;
  width: 95%;
  z-index: 2;

  @media ${props => props.theme.tablet} {
    padding-bottom: ${slidesHeight};
    width: 57%;
    z-index: unset;
  }
`;

export const PlanImage = styled.div`
  position: absolute;

  &:nth-child(1) {
    right: 49%;
    top: 39%;
    width: 51%;
    & ${ImageWrapper} {
      animation-delay: 0.2s;
    }
  }

  &:nth-child(2) {
    right: 0;
    top: 10%;
    width: 56%;
    & ${ImageWrapper} {
      animation-delay: 0.35s;
    }
  }

  &:nth-child(3) {
    right: 46%;
    top: 0;
    width: 37%;

    & ${ImageWrapper} {
      animation-delay: 0.55s;
    }
  }
`;

export const PlanOverview = styled.div`
  opacity: 0;
  padding-bottom: ${props => props.theme.spacing(8)};
  padding-top: calc(
    ${mobileImagesHeight} -
      ${props => props.theme.spacing(carouselHorizontalPadding * 2)}
  );
  width: 100%;

  @media ${props => props.theme.tablet} {
    padding-bottom: 0;
    padding-top: 8%;
    position: relative;
    width: 47%;
  }
`;

export const PlanInner = styled.div`
  background: ${props => props.theme.colors.background.base};
  border-radius: 32px;
  box-shadow: 0px 10px 16px rgba(94, 88, 78, 0.1);
  color: ${props => props.theme.colors.text.secondary};
  margin: 0 auto;
  max-width: 380px;
  padding: ${props => props.theme.spacing(18, 8, 10)};

  @media ${props => props.theme.tablet} {
    margin: 0;
    padding: ${props => props.theme.spacing(10, 8)};
    max-width: unset;
  }

  @media ${props => props.theme.tabletL} {
    padding: ${props => props.theme.spacing(18, 16, 14)};
  }
`;

export const PlanName = styled.h3`
  ${props => props.theme.typography.heading2};
  margin-bottom: ${props => props.theme.spacing(1)};
`;

export const PlanTagline = styled.h4`
  ${props => props.theme.typography.subheading1};
  text-transform: uppercase;
`;

export const RecipeChip = styled.span`
  ${props => props.theme.typography.label}
  ${props => props.theme.fontFamily.condensed}
  background-color: ${props => props.theme.colors.neutral.darkerFur};
  border-radius: 32px;
  color: ${props => props.theme.colors.text.lightest};
  display: inline-block;
  margin: ${props => props.theme.spacing(5, 0, 1)};
  padding: ${props => props.theme.spacing(0, 4)};
  text-transform: uppercase;
`;

export const PlanBody = styled.div`
  ${props => props.theme.typography.body};
`;

export const BenefitList = styled.ul`
  list-style: none;
  margin-top: ${props => props.theme.spacing(2)};
  padding: 0;
`;

export const Benefit = styled.li`
  ${props => props.theme.typography.body};
  padding-top: ${props => props.theme.spacing(3)};
  position: relative;
  vertical-align: middle;
`;

export const CheckmarkContainer = styled.div`
  display: inline-block;
  margin-right: ${props => props.theme.spacing(3)};
  transform: translateY(-10%);
  vertical-align: middle;
`;

export const DotsWrapper = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;

  @media ${props => props.theme.tablet} {
    bottom: unset;
    left: unset;
    top: 0;
    padding-top: 52%; /* Needs updating if 2nd image position or size changes */
  }
`;

export const ButtonContainer = styled.div`
  margin-top: ${props => props.theme.spacing(5)};
  @media ${props => props.theme.tablet} {
    margin-top: ${props => props.theme.spacing(14)};
  }
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  ${props => props.theme.getFocusOutline(props.$bgColor)}
`;
