'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AccordionItem } from '@web/molecules';
import { useReporter } from '../../reporter';
import { StyledFaqListItem, Content } from './styles.js';
import { faqExpanded } from './events';

export const FaqListItem = ({ question, answer }) => {
  const [opened, setOpened] = useState(false);
  const reporter = useReporter();

  const onCardClicked = () => {
    if (!opened) {
      reporter.tag(faqExpanded(question));
    }
    setOpened(!opened);
  };

  return (
    <StyledFaqListItem active={opened}>
      <AccordionItem
        onClick={onCardClicked}
        isOpen={opened}
        title={question}
        variant="large"
        id={question}
      >
        <Content>{answer}</Content>
      </AccordionItem>
    </StyledFaqListItem>
  );
};

FaqListItem.displayName = 'FaqListItem';

FaqListItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};
