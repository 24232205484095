'use client';
import React, { useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useIsomorphicLayoutEffect } from '../../hooks';
import { StyledStickyNav } from './styles.js';

export const StickyNav = ({ shown, children, relativePosition }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(1000);

  useIsomorphicLayoutEffect(() => {
    const setCTABarHeight = () => {
      if (ref.current) {
        setHeight(ref.current.offsetHeight);
      }
    };
    const debouncedSetCTABarHeight = debounce(setCTABarHeight, 100);
    setCTABarHeight();
    window.addEventListener('resize', debouncedSetCTABarHeight);
    return () => window.addEventListener('resize', debouncedSetCTABarHeight);
  }, []);

  return (
    <StyledStickyNav
      ref={ref}
      $height={height}
      $shown={shown}
      $relativePosition={relativePosition}
    >
      {children}
    </StyledStickyNav>
  );
};

StickyNav.displayName = 'StickyNav';

StickyNav.propTypes = {
  shown: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
