import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@web/molecules';
import { Quote } from './Quote.js';
import {
  StyledQuotesContainer,
  StyledQuotesHeader,
  QuotesCarouselContainer,
  SingleQuoteContainer,
} from './styles.js';

export const Quotes = ({ header, quotes }) => {
  const renderQuote = quotes => {
    return quotes.length > 1 ? (
      <Carousel itemsToShow={1}>
        {quotes.map((quote, i) => (
          <Quote {...quote} key={quote.name} />
        ))}
      </Carousel>
    ) : (
      <SingleQuoteContainer>
        <Quote {...quotes[0]} key={quotes[0].name} />
      </SingleQuoteContainer>
    );
  };

  return (
    <StyledQuotesContainer>
      <StyledQuotesHeader>{header}</StyledQuotesHeader>
      <QuotesCarouselContainer>
        {quotes && renderQuote(quotes)}
      </QuotesCarouselContainer>
    </StyledQuotesContainer>
  );
};

Quotes.displayName = 'Quotes';

Quotes.propTypes = {
  header: PropTypes.string.isRequired,
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        alt: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
      body: PropTypes.node,
    }),
  ),
};
