import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { useReporter } from '../../reporter';
import { ctaButtonClicked } from './events';
import {
  StyledAppCtaComponent,
  Inner,
  ImageWrapper,
  Content,
  Header,
  SubHeader,
  InnerContent,
  OllieAppButtonsContainer,
  ParallaxWrapper,
} from './styles.js';
import { ParallaxBannerLayer, ParallaxProvider } from './parallax';

export const AppCtaComponent = ({
  heading,
  subheading,
  image,
  imageAlignment,
  parallaxImage,
  appLinks,
  withParallax = true,
  desktopHeight = 600,
  mobileHeight = 530,
  level,
}) => {
  const reporter = useReporter();

  return (
    <ParallaxProvider>
      <ParallaxWrapper
        $desktopHeight={desktopHeight}
        $mobileHeight={mobileHeight}
      >
        <ParallaxBannerLayer speed={withParallax ? 18 : 0}>
          <ResponsiveImage
            alt={parallaxImage.alt ?? 'parallax background'}
            {...parallaxImage}
            className="parallax-background-layer"
            loading="lazy"
          />
        </ParallaxBannerLayer>
        <StyledAppCtaComponent>
          <Inner>
            <ImageWrapper $align={imageAlignment}>
              <ResponsiveImage {...image} />
            </ImageWrapper>
            <Content $align={imageAlignment}>
              <InnerContent>
                {heading && <Header as={level}>{heading}</Header>}
                {subheading && <SubHeader>{subheading}</SubHeader>}
                <OllieAppButtonsContainer>
                  {appLinks?.map(
                    ({ image: { src, alt, height, width }, link }, i) => (
                      <Link
                        key={i}
                        href={link}
                        target="_blank"
                        onClick={() =>
                          reporter.tag(
                            ctaButtonClicked(link, heading || subheading),
                          )
                        }
                        rel="noreferrer"
                      >
                        <ResponsiveImage
                          src={src}
                          alt={alt}
                          height={height}
                          width={width}
                        />
                      </Link>
                    ),
                  )}
                </OllieAppButtonsContainer>
              </InnerContent>
            </Content>
          </Inner>
        </StyledAppCtaComponent>
      </ParallaxWrapper>
    </ParallaxProvider>
  );
};

AppCtaComponent.displayName = 'AppCtaComponent';

AppCtaComponent.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  image: PropTypes.object.isRequired,
  imageAlignment: PropTypes.oneOf(['left', 'right']),
  appLinks: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  ),
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
