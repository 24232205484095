'use client';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  margin: auto;
  margin-bottom: ${({ theme }) => theme.spacing(6)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  max-width: 1100px;
`;

export const HeadingContainer = styled.div`
  @media ${({ theme }) => theme.tabletS} {
    margin: ${({ theme }) => theme.spacing(12, 0)};
  }
`;

export const HeadingContainerText = styled.div`
  display: flex;
  align-items: center;

  @media ${({ theme }) => theme.mobileS} {
    margin: ${({ theme }) => theme.spacing(4, 0)};
  }
`;

export const HeadingContainerDropdown = styled.div`
  margin: ${({ theme }) => theme.spacing(0)} auto
    ${({ theme }) => theme.spacing(4)};
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const DropdownContainer = styled.div`
  @media ${({ theme }) => theme.mobileS} {
    width: 100%;
    margin: ${({ theme }) => theme.spacing(2, 0)};
  }

  @media ${({ theme }) => theme.laptop} {
    width: 75%;
  }

  & ul {
    overflow: scroll;
    height: unset;
  }
`;
