import * as yup from 'yup';
import { ComparisonChartItem } from '@web/components';

export const component = ComparisonChartItem;

export const schema = yup
  .object({
    header: yup.string().required(),
    subheader: yup.string(),
    level: yup.string(),
    comparisonRowMarkers: yup.array(),
  })
  .transform(value => ({
    header: value.header,
    subheader: value.subheader,
    level: value.level,
    comparisonRowMarkers: value.comparison_row_markers,
  }));
