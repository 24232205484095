'use client';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isAfter, getYear, parse } from 'date-fns';
import { LinkButton } from '@ui/button';
import { Dropdown } from '@web/atoms';
import { EventsPageCard } from '../EventsPageCard';
import {
  DropdownContainer,
  StyledEventsPage,
  CardsContainer,
  HeadingText,
  ButtonWrapper,
} from './styles';

export const EventsPage = ({ events, heading, cta }) => {
  const [CardSelected, setCardSelected] = useState();
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    const now = new Date();
    const currentYear = getYear(new Date());
    const sortedAndFiltered = events
      .map(event => ({
        ...event,
        dateObject: parse(
          `${event.date} ${currentYear}`,
          'MMMM d yyyy',
          new Date(),
        ),
      }))
      .filter(event => isAfter(event.dateObject, now))
      .sort((a, b) => a.dateObject - b.dateObject)
      .map(event => {
        const { dateObject, ...rest } = event;
        return rest;
      });
    setFilteredEvents(sortedAndFiltered);
  }, [events]);

  let filterData = [{ label: 'Show all', value: 'Show all' }];
  events.forEach(event => {
    if (!filterData.some(fd => fd.value === event.state)) {
      filterData.push({
        label: event.state,
        value: event.state,
      });
    }
  });

  const onChange = dropdownOption => {
    setCardSelected(dropdownOption);
  };

  let displayEvents =
    CardSelected && CardSelected !== 'Show all'
      ? filteredEvents.filter(event => event.state === CardSelected)
      : filteredEvents;

  return (
    <StyledEventsPage>
      <HeadingText>{heading}</HeadingText>

      <DropdownContainer>
        <Dropdown
          options={filterData}
          noSelectionLabel="Showing all states"
          onChange={onChange}
          selected={CardSelected}
        />
      </DropdownContainer>

      <CardsContainer>
        {displayEvents.map((event, index) => (
          <EventsPageCard {...event} key={index} />
        ))}
      </CardsContainer>
      {cta && (
        <ButtonWrapper>
          <LinkButton href={cta.target} variant="chia" horizontalPadding={25}>
            {cta.text}
          </LinkButton>
        </ButtonWrapper>
      )}
    </StyledEventsPage>
  );
};

EventsPage.propTypes = {
  heading: PropTypes.string.isRequired,
  cta: PropTypes.object,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      title: PropTypes.string,
      location: PropTypes.string,
      state: PropTypes.string,
      link: PropTypes.string,
      image: PropTypes.object,
    }),
  ).isRequired,
};
