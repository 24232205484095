'use client';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: ${props => props.theme.spacing(26, 8, 10, 8)};
  color: ${props =>
    props.isOllieChartType || props.isCheckMarkOutline
      ? null
      : props.theme.colors.text.lightest};
  background-color: ${props =>
    props.isOllieChartType
      ? props.theme.colors.background.base
      : props.isCheckMarkOutline
      ? null
      : props.theme.colors.background.dark};
`;

export const LogoContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(6)};
  max-width: 360px;
`;

export const ImageContainer = styled.div`
  transform: translateY(35%);
  margin: auto;
  overflow: hidden;
  border-radius: 50%;
  height: 200px;
  width: 200px;
`;

export const Item = styled.h2`
  display: flex;
  margin: ${props => props.theme.spacing(5)};
  ${props => props.theme.typography.heading6};
`;

export const ItemContainer = styled.div`
  max-width: 250px;
`;

export const IconContainer = styled.div`
  display: inline;
  position: relative;
  top: 2px;
  right: 5px;
`;

export const ButtonContainer = styled.div`
  margin: ${props => props.theme.spacing(5)};
`;

export const FoodContainer = styled.div`
  margin: auto;
  max-width: 400px;
  @media ${props => props.theme.tablet} {
    max-width: 900px;
  }
`;
