'use client';
import styled from 'styled-components';

export const BeforeAfterTestimonialContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: stretch;
  width: 300px;
  text-align: center;
  border-radius: ${props => props.theme.spacing(8)};
  background: #ffffff;
  margin: ${props => props.theme.spacing(0, 2, 8, 2)};
  padding: ${props => props.theme.spacing(3, 3, 10, 3)};
  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(0, 2, 0, 2)};
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.theme.spacing(4, 1)};
  justify-content: space-around;
  align-items: center;
`;

export const CustomPillText = styled.p`
  ${({ theme }) => theme.typography.body}
  ${({ theme }) => theme.fontFamily.condensed}
  border-radius: 500px;
  padding: ${({ theme }) => theme.spacing(1, 4, 1, 4)};
  margin: ${({ theme }) => theme.spacing(0, 0.5)};
  height: ${props => props.theme.spacing(7.5)};
  text-transform: uppercase;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.bone};
`;

export const BadgeContainer = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing(1, 0, 0, 2)};
`;

export const TextContainer = styled.div`
  color: ${props => props.theme.colors.text.secondary};
  margin: ${props => props.theme.spacing(0, 2)};
  text-align: left;
`;

export const BodyTextContainer = styled.div`
  margin: ${props => props.theme.spacing(2, 0, 0, 0)};
`;
