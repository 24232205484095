'use client';
import styled, { css } from 'styled-components';
import { ParallaxBannerLayer, ParallaxBanner } from './parallax';

export const StyledAppCtaComponent = styled(ParallaxBannerLayer)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ParallaxWrapper = styled(ParallaxBanner)`
  height: ${props => props.$mobileHeight ?? 530}px;
  width: 100%;
  img {
    width: 100%;
  }
  .parallax-background-layer {
    height: auto;
  }
  @media ${props => props.theme.tablet} {
    height: ${props => props.$desktopHeight ?? 600}px;
  }
`;

const getPadding = alignment => {
  if (alignment === 'left')
    return css`
      padding-left: 80px;
      justify-content: left;
    `;
  return css`
    padding-right: 80px;
    justify-content: right;
  `;
};

export const Inner = styled.div`
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: ${props => props.theme.spacing(4, 7, 0, 7)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(7, 7, 0, 7)};
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    height: 100%;
  }
`;

export const OllieAppButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  a {
    &:first-of-type {
      margin-right: 1rem;
    }
  }
`;

export const ImageWrapper = styled.div`
  order: ${({ $align }) => ($align === 'right' ? 2 : 3)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: flex-end;

  img {
    width: revert-layer !important;
  }

  @media ${props => props.theme.tablet} {
    order: ${({ $align }) => ($align === 'right' ? 2 : 1)};
    padding-top: ${({ theme }) => theme.spacing(15)};
    width: 50%;
    justify-content: ${({ $align }) => ($align === 'right' ? 'left' : 'right')};
  }
  text-align: center;
`;

export const Content = styled.div`
  order: ${({ $align }) => ($align === 'right' ? 1 : 2)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  padding-bottom: ${props => props.theme.spacing(1)};
  @media ${props => props.theme.tablet} {
    margin: 0;
    order: ${({ $align }) => ($align === 'right' ? 1 : 2)};
    align-self: center;
    width: 50%;
    ${({ $align }) => getPadding($align)};
    padding-bottom: 0;
  }
`;

export const InnerContent = styled.div`
  max-width: 376px;
  width: auto;
  height: auto;
  text-align: center;
  align-self: flex-end;
  @media ${props => props.theme.tablet} {
    text-align: left;
  }
`;

export const Header = styled.h2`
  ${props => props.theme.typography.heading2}
  color: ${({ theme }) => theme.colors.neutral.darkestFur};
  margin-bottom: ${props => props.theme.spacing(1)};
  @media ${props => props.theme.tablet} {
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

export const SubHeader = styled.p`
  ${props => props.theme.typography.button}
  margin-bottom: ${props => props.theme.spacing(6)};
  color: ${({ theme }) => theme.colors.text.secondary};
  @media ${props => props.theme.tablet} {
    margin-bottom: ${props => props.theme.spacing(8)};
  }
`;

export const Body = styled.p`
  ${props => props.theme.typography.body}
  margin: ${props => props.theme.spacing(4, 0)};
  max-width: 500px;
`;

export const ButtonWrapper = styled.div`
  flex: 0 0 auto;
  padding: ${props => props.theme.spacing(0, 5)};
`;

export const AppLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.spacing(4)};
  margin-top: ${props => props.theme.spacing(6)};

  @media ${props => props.theme.tablet} {
    justify-content: flex-start;
  }
`;
