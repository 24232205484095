'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@web/atoms';
import { StyledPageTabs, PageButtons, PageDropdown, Button } from './styles.js';

export const PageTabs = ({ pages }) => {
  const [activePage, setActivePage] = useState(0);
  return (
    <StyledPageTabs>
      <PageButtons>
        {pages.map(({ name, color }, index) => (
          <Button
            key={name}
            active={index === activePage}
            aria-pressed={index === activePage}
            color={color}
            onClick={() => setActivePage(index)}
            type="button"
          >
            {name}
          </Button>
        ))}
      </PageButtons>
      <PageDropdown>
        <Dropdown
          name="page-select"
          label="Select a page"
          noSelectionLabel="Select a page"
          showLabel={false}
          options={pages.map((page, index) => ({
            value: index,
            label: page.name,
          }))}
          onChange={setActivePage}
          selected={activePage}
        />
      </PageDropdown>
      {pages[activePage].content}
    </StyledPageTabs>
  );
};

PageTabs.displayName = 'PageTabs';

PageTabs.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }).isRequired,
  ).isRequired,
};
