import React from 'react';
import PropTypes from 'prop-types';
import { VisuallyHidden } from '@web/atoms';
import { PupAvatar } from '../PupAvatar';
import {
  StyledTestimonial,
  FiveStarRating,
  PupName,
  Description,
  TitleContainer,
  RatingContainer,
} from './styles.js';

export const CustomerTestimonial = ({ body, breed, pupAge, pupName }) => {
  return (
    <StyledTestimonial>
      <TitleContainer>
        <PupAvatar
          variant="circle"
          fullBleed
          color="secondary.carrot"
          breeds={[breed]}
        />
        <RatingContainer>
          <PupName>{pupName}</PupName>
          <Description>
            <span>{pupAge}</span>
            <span>{breed}</span>
          </Description>
          <FiveStarRating>
            <VisuallyHidden>5 star rating for testimonial</VisuallyHidden>
          </FiveStarRating>
        </RatingContainer>
      </TitleContainer>
      {body}
    </StyledTestimonial>
  );
};

CustomerTestimonial.displayName = 'CustomerTestimonial';

CustomerTestimonial.propTypes = {
  body: PropTypes.node.isRequired,
  breed: PropTypes.string.isRequired,
  pupAge: PropTypes.string.isRequired,
  pupName: PropTypes.string.isRequired,
};
