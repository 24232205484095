'use client';
import styled from 'styled-components';

export const StyledLetter = styled.div`
  background: ${props => props.theme.colors.background.base};
  padding: ${props => props.theme.spacing(5, 5, 14)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(10, 20, 20)};
  }
`;

export const Container = styled.div`
  background: ${props => props.theme.colors.background.lightest};
  border-radius: 32px;
  margin: 0 auto;
  max-width: 880px;
  padding: ${props => props.theme.spacing(10, 5)};
  position: relative;
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(20, 10)};
  }
`;

export const Content = styled.div`
  color: ${props => props.theme.colors.text.secondary};
  text-align: center;
`;

export const TopIcon = styled.div`
  margin-bottom: ${props => props.theme.spacing(4)};
`;

export const Heading = styled.h1`
  ${props => props.theme.typography.heading1}
  margin: 0 auto;
  max-width: 480px;
  padding: ${props => props.theme.spacing(0, 4)};

  @media ${props => props.theme.tablet} {
    padding: 0;
  }
`;

export const BottomIcon = styled.div`
  margin: ${props => props.theme.spacing(10, 0, 14)};
`;

export const PhotosWrapper = styled.div`
  display: none;

  @media ${props => props.theme.tabletL} {
    display: block;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;
