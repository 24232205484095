import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Grid } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import {
  BlogContainer,
  Subtext,
  Topic,
  BlogContent,
  StyledBlogLinks,
  ClickableBlogTitle,
  ImageWrapper,
} from './styles';

export const BlogLinks = ({
  blogs,
  blogHeading,
  fontColor = 'text.secondary',
}) => {
  return (
    <StyledBlogLinks>
      {blogHeading && (
        <Heading
          position="left"
          typography={{ desktop: 'heading2', mobile: 'heading1' }}
          headingText={blogHeading}
          headingLevel="h2"
        />
      )}
      <Grid
        gap={{ row: 4, column: 4 }}
        columns={{ laptop: 3, tablet: 2, mobile: 1 }}
      >
        {blogs.map((blog, index) => (
          <BlogContainer key={index} backgroundColor={blog.backgroundColor}>
            <ImageWrapper>
              {blog.image?.src && (
                <ResponsiveImage style={{ width: '100%' }} {...blog.image} />
              )}
            </ImageWrapper>
            <BlogContent>
              <Topic>{blog.topic}</Topic>
              <ClickableBlogTitle $fontColor={fontColor} href={blog.blogLink}>
                <Heading
                  {...blog.title}
                  backgroundColor={blog.backgroundColor}
                />
                <Subtext>{blog.date}</Subtext>
              </ClickableBlogTitle>
            </BlogContent>
          </BlogContainer>
        ))}
      </Grid>
    </StyledBlogLinks>
  );
};

BlogLinks.displayName = 'BlogLinks';

BlogLinks.propTypes = {
  blogs: PropTypes.arrayOf(
    PropTypes.shape({
      blogLink: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      topic: PropTypes.string.isRequired,
      title: PropTypes.object.isRequired,
      date: PropTypes.string,
      image: PropTypes.object.isRequired,
    }).isRequired,
  ).isRequired,
  blogHeading: PropTypes.string,
  fontColor: PropTypes.string,
};
