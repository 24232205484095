'use client';
import styled from 'styled-components';

export const StyledBoxContents = styled.section`
  background-color: ${props => props.theme.colors.secondary.chia};
  color: ${props => props.theme.colors.text.lighter};
  padding: ${props => props.theme.spacing(14, 0)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(20, 0)};
  }
`;

export const ContentList = styled.div`
  margin: 0 auto;
  padding: 0 10px;
  max-width: 100%;
  width: 1160px;
  @media ${props => props.theme.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const Item = styled.div`
  flex: 0 0 auto;
  margin-top: ${props => props.theme.spacing(12)};
  padding: 0 10px;
  text-align: center;
  @media ${props => props.theme.tablet} {
    margin-top: ${props => props.theme.spacing(14)};
    width: calc(100% / ${props => props.count / 2});
  }
  @media ${props => props.theme.tabletL} {
    width: calc(100% / ${props => props.count});
  }
`;

export const ImageContainer = styled.div`
  height: 75px;
  object-fit: contain;
  object-position: center;
  width: 100%;
`;

export const Description = styled.p`
  ${props => props.theme.typography.subheading1}
  margin-top: ${props => props.theme.spacing(8)};
`;

export const Tag = styled.span`
  background-color: ${props => props.theme.colors.background.base};
  border-radius: 20px;
  color: ${props => props.theme.colors.text.primary};
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  padding: 1px 5px;
  margin-left: 3px;
  margin-top: -2px;
  text-transform: uppercase;
  vertical-align: top;
`;
