'use client';
import styled from 'styled-components';

export const StyledSimpleCalloutPanel = styled.div`
  padding: ${props => props.theme.spacing(14, 0)};
  background: ${props => props.theme.colors.get(props.$fillColor)};
  color: ${props => props.theme.colors.get(props.$fontColor)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(12, 0)};
  }
`;

export const Inner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: ${props => props.theme.spacing(10, 20)};
  width: 90%;
  max-width: 1080px;
  text-align: center;
  background: ${props => props.theme.colors.get(props.$backgroundColor)};
  border-radius: 40px;

  @media ${props => props.theme.tablet} {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    height: 292px;
    width: 80%;
  }
`;

export const ImageWrapper = styled.div`
  padding: ${props => props.theme.spacing(0, 2)};
  order: -1;
  @media ${props => props.theme.tablet} {
    order: ${props => (props.$alignImage === 'left' ? -1 : 2)};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${props => props.theme.mobileS} {
    margin: ${props => props.theme.spacing(0, 8)};
    align-items: center;
  }
  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(0, 8)};
    align-items: start;
  }
`;

export const Body = styled.div`
  ${props => props.theme.typography.paragraph};
  margin: ${props => props.theme.spacing(4, 4)};
  line-height: 1.5;
  padding: ${props => props.theme.spacing(0, 5)};
  min-width: 300px;
  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(4, 0)};
    padding: ${props => props.theme.spacing(0, 5, 0, 0)};
    max-width: 630px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(2, 0)};
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline(props.$backgroundColor)}
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.theme.spacing(10, 0, 0, 1)};
  padding: ${props => props.theme.spacing(1, 0, 0, 0)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(0, 0, 0, 0)};
  }
`;
