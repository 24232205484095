import * as yup from 'yup';
import React from 'react';
import { Grid } from '@web/atoms';
import { CMSBlok } from '../translation';

export const component = Grid;

const yupNumber = yup
  .number()
  .min(1)
  .max(12)
  .nullable()
  .transform(v => (v ? v : null));

export const schema = yup
  .object({
    columns: yup
      .object({
        mobile: yupNumber,
        tablet: yupNumber,
        laptop: yupNumber,
      })
      .nullable(),
    gap: yup
      .object({
        row: yupNumber,
        column: yupNumber,
      })
      .nullable(),
    children: yup.array().required(),
    backgroundColor: yup.string(),
  })
  .transform(value => ({
    columns: {
      mobile: value.mobile,
      tablet: value.tablet,
      laptop: value.laptop,
    },
    gap: {
      row: value.row_gap,
      column: value.column_gap,
    },
    children: value.cells.map(cell => (
      <CMSBlok content={cell} key={cell._uid} />
    )),
    backgroundColor: value.background_color || undefined,
  }));
