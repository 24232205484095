import * as yup from 'yup';
import {
  richTextFromCMSSchema,
  responsiveImageFromCMSSchema,
} from '../schemas';
import { dropdownsSchema, dropdownValuesSchema } from './extra_dropdown_values';

export const extraCardSchema = yup
  .object({
    images: yup.array(responsiveImageFromCMSSchema),
    title: yup.string(),
    productType: yup.string(),
    selectionLabel: yup.string(),
    unavailableText: yup.string(),
    value: yup.string(),
    subtitle: yup.string(),
    sharePath: yup.string(),
    unit: yup.string(),
    pricingInfo: yup.object({
      price: yup.string(),
      discountedPrice: yup.string(),
      saveText: yup.string(),
    }),
    badge: yup.string(),
    products: yup.array(
      yup.object({
        sku: yup.string(),
        size: yup.string(),
      }),
    ),
    description: richTextFromCMSSchema,
    details: richTextFromCMSSchema,
    serving: richTextFromCMSSchema,
    fullIngredients: richTextFromCMSSchema,
    defaultDropdownValues: dropdownValuesSchema,
    nutritionalInformation: yup.array(
      yup.object({
        value: yup.string(),
        label: yup.string(),
      }),
    ),
    dropdowns: yup.array(dropdownsSchema),
  })
  .transform(value => ({
    images: value.images,
    title: value.title,
    productType: value.product_type,
    selectionLabel: value.selection_label,
    unavailableText: value.unavailable_text,
    value: value.value,
    subtitle: value.subtitle,
    sharePath: value.share_path,
    unit: value.unit,
    pricingInfo: {
      price: value.price,
      discountedPrice: value.discounted_price,
      saveText: value.save_text,
    },
    badge: value.badge,
    products: value.products,
    description: value.description,
    details: value.details,
    serving: value.serving,
    fullIngredients: value.full_ingredients.body,
    defaultDropdownValues: value.default_dropdown_values,
    nutritionalInformation: value.nutritional_information,
    dropdowns: value.dropdowns,
  }));
