'use client';
import { useEffect, useState } from 'react';
import { shared } from '../config';
import { relations } from './relations';

export const useCMSEditor = initialContent => {
  // State will not be cleared if user navigates the site in the editor.
  const [syncedContent, setSyncedContent] = useState(null);

  useEffect(() => {
    if (window.storyblok) {
      window.storyblok.init({ accessToken: shared.STORYBLOK_API_ACCESS_TOKEN });
      window.storyblok.on(['published', 'unpublished'], () => {
        window.location.reload();
      });
      window.storyblok.on(['input', 'change'], data => {
        window.storyblok.resolveRelations(data.story, relations, content => {
          setSyncedContent(content);
        });
      });
    }
  }, []);

  return syncedContent ?? initialContent;
};
