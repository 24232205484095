import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Quote, StyledPressLogo, LogoContainer } from './styles';

export const PressLogo = ({ src, alt, quote, width, height }) => {
  return (
    <StyledPressLogo quote={quote} $width={width}>
      <LogoContainer>
        <ResponsiveImage src={src} alt={alt} width={width} height={height} />
      </LogoContainer>
      {quote && <Quote>{quote}</Quote>}
    </StyledPressLogo>
  );
};

PressLogo.displayName = 'PressLogo';

PressLogo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  quote: PropTypes.string,
};
