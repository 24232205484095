'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Cell, Grid } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import { ImageButton, Container } from './styles';

export const ImageCarouselCell = ({ images }) => {
  const [state, setState] = useState(images);

  const handleImageClick = imageIndex => {
    const newImages = [...state];
    newImages[0] = state[imageIndex];
    newImages[imageIndex] = state[0];
    setState(newImages);
  };

  const getImage = (image, index) => {
    if (index === 0) {
      return {
        columnSize: 2,
        component: <ResponsiveImage {...image} height={589} width={589} />,
      };
    }
    return {
      columnSize: 1,
      component: (
        <ImageButton onClick={() => handleImageClick(index)}>
          <ResponsiveImage {...image} height={289} width={289} />
        </ImageButton>
      ),
    };
  };

  return (
    <Container>
      <Grid columns={2} gap={{ row: 6, column: 5 }}>
        {state.map((image, index) => {
          const img = getImage(image, index);
          return (
            <Cell columns={img.columnSize} key={index}>
              {img.component}
            </Cell>
          );
        })}
      </Grid>
    </Container>
  );
};

ImageCarouselCell.displayName = 'ImageCarouselCell';
ImageCarouselCell.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      alt: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
