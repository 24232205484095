'use client';
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToast } from '../../Toast';
import {
  StyledEmailCaptureForm,
  EmailCaptureInput,
  EmailCaptureButton,
  EmailCaptureSpinner,
  EmailCaptureSpinnerContainer,
  ArrowIcon,
  CheckIcon,
} from './styles';

export const EmailCapture = ({
  listId,
  placeholder,
  buttonVariant,
  fillColor,
}) => {
  const [isSubmitButtonActive, setIsSubmitButtonActive] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const iterableAPI = `https://links.iterable.com/lists/publicAddSubscriberForm?publicIdString=${listId}`;
  const { showToast } = useToast();

  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email format').required('Required'),
  });

  const {
    control,
    handleSubmit: handleFormSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleSubmit = async e => {
    if (!e.email) {
      showToast({
        status: 'warning',
        message: 'Please enter a valid email',
        headline: 'Warning',
      });
      return;
    }
    setIsSubmitButtonActive(false);
    const formData = new FormData();
    formData.append('email', e.email);
    const axiosConfig = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    try {
      const response = await axios.post(iterableAPI, formData, axiosConfig);
      const responseMessage = response.data;
      const isSuccessRegister = responseMessage.includes(
        'Thank you for subscribing',
      );
      const isInvalidMail = responseMessage.includes('Valid email required');
      if (isInvalidMail) {
        showToast({
          status: 'error',
          message: 'Not valid email provided',
          headline: 'Error Message',
        });
        setIsSubmitButtonActive(true);
        return;
      }
      setTimeout(() => {
        setIsSubmitted(true);
        setValue('email', 'You’re all set');
        showToast({
          status: 'success',
          message: isSuccessRegister
            ? 'You have joined our newsletter!'
            : 'You are already registered!',
          headline: 'Success',
        });
      }, 2000);
    } catch (error) {
      showToast({
        status: 'error',
        message: 'Your email was not submitted. Please try again.',
        headline: 'Error Message',
      });
      setIsSubmitButtonActive(true);
    }
  };

  useEffect(() => {
    if (errors.email?.message) {
      showToast({
        status: 'error',
        message: 'Please enter a valid email',
        headline: 'Error',
      });
      return;
    }
  }, [errors, showToast]);

  return (
    <StyledEmailCaptureForm onSubmit={handleFormSubmit(handleSubmit)}>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <EmailCaptureInput
            {...field}
            fillColor={fillColor || 'background.base'}
            name="email"
            placeholder={placeholder}
            disabled={isSubmitted}
            {...register('email')}
          />
        )}
      />

      {isSubmitButtonActive && !isSubmitted ? (
        <EmailCaptureButton type="submit" buttonVariant={buttonVariant}>
          <ArrowIcon />
        </EmailCaptureButton>
      ) : isSubmitted ? (
        <EmailCaptureButton
          disabled={isSubmitted}
          buttonVariant={buttonVariant}
        >
          <CheckIcon />
        </EmailCaptureButton>
      ) : (
        <EmailCaptureSpinnerContainer buttonVariant={buttonVariant}>
          <EmailCaptureSpinner buttonVariant={buttonVariant} />
        </EmailCaptureSpinnerContainer>
      )}
    </StyledEmailCaptureForm>
  );
};

EmailCapture.displayName = 'EmailCapture';

EmailCapture.propTypes = {
  listId: PropTypes.string,
  placeholder: PropTypes.string,
  buttonVariant: PropTypes.string,
  fillColor: PropTypes.string,
};
