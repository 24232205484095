'use client';
import styled, { css } from 'styled-components';
import arrowLeft from '../../images/Icons/arrow-left.svg';
import search from '../../images/Icons/search.svg';

export const PageHeadings = styled.div`
  margin: ${props => props.theme.spacing(0, 0, 10)};
`;

export const Heading1 = styled.h1`
  ${props => props.theme.typography.subheading1};
  color: ${props => props.theme.colors.text.tertiary};
`;

export const Subheading = styled.h5`
  ${props => props.theme.typography.heading5};
  color: ${props => props.theme.colors.text.secondary};
`;

export const ContentContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  flex-grow: 1;
  padding: ${props => props.theme.spacing(10, 10, 20, 10)};
  background: ${props => props.theme.colors.background.base};
`;

export const ContactInfo = styled.div`
  margin: ${props => props.theme.spacing(16, 0, 18)};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  max-width: 1280px;

  @media ${props => props.theme.tablet} {
    flex-direction: column;
    align-items: center;

    > :not(:last-child) {
      margin-right: ${props => props.theme.spacing(8)};
    }
  }
`;

export const ContactMethod = styled.div`
  color: ${props => props.theme.colors.text.primary};
  margin: ${props => props.theme.spacing(2, 0, 12)};
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  @media ${props => props.theme.mobileS} {
    &:last-child {
      margin-bottom: ${props => props.theme.spacing(8)};
    }
  }
  @media ${props => props.theme.tablet} {
    &:last-child {
      margin-bottom: ${props => props.theme.spacing(12)};
    }
  }
`;

export const IconContainer = styled.div`
  margin-right: ${props => props.theme.spacing(0)};
  width: 50%;
`;

export const Bubble = styled.a`
  width: 60px;
  height: 60px;
  background-color: ${props => props.theme.colors.neutral.bone};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.88px solid ${props => props.theme.colors.border.dark};
  margin-bottom: 15px;
  cursor: pointer;
  @media ${props => props.theme.tablet} {
    margin-bottom: 20px;
  }
`;

const ContentBox = css`
  border-radius: 32px;
  background: ${props => props.theme.colors.background.lightest};
  padding: ${props => props.theme.spacing(18, 10)};
  margin: 0;
`;

export const StyledGladly = styled.div`
  margin: 0;
  max-width: unset;

  .gladlyHC-faq {
    position: relative; /* relative contains the seach bar */
  }

  /* Search Input Wrapper */
  .gladlyHC-searchContainer {
    border: 1px solid transparent;
    border-radius: 32px;
    background: ${props => props.theme.colors.background.lightest};
    position: absolute;

    &:before {
      content: '';
      background-image: url(${search});
      background-size: 18px 18px;
      width: 18px;
      height: 18px;
      position: absolute;
      left: 24px;
      top: 22px;
      margin-right: ${props => props.theme.spacing(4)};
    }

    &:focus-within {
      border: 1px solid ${props => props.theme.colors.border.dark};
    }
  }

  /* Search Input */
  .gladlyHC-searchInput {
    ${props => props.theme.typography.body}
    height: 60px;
    padding-left: 52px; /* pad for search icon */
    border: none;
    background-color: transparent;

    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }

  /* Search as you type results container */
  .gladlyHC-searchMenuContainer {
    position: relative;
  }

  /* Search as you type results list */
  .gladlyHC-searchMenu {
    ${props => props.theme.typography.button}
    border-top: 1px solid ${props => props.theme.colors.border.dark};
    padding: ${props => props.theme.spacing(4, 2, 6)};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
  }

  /* Search as you type individual result */
  .gladlyHC-searchResult {
    color: ${props => props.theme.colors.text.secondary};
    padding: ${props => props.theme.spacing(1, 4)};
  }

  /* Search as you type individual result highlighted*/
  .gladlyHC-searchResult-highlighted {
    background: ${props => props.theme.colors.background.base};
    border-radius: 4px;
    font-weight: normal;
  }

  /* Primary page faq container */
  .gladlyHC-faqContainer {
    margin-top: ${props =>
      props.theme.spacing(20)}; /* adjust for absolute position search bar */
  }

  /* Primary Page Title*/
  .gladlyHC-faqHeading {
    ${props => props.theme.typography.heading2}
    margin-top: ${props => props.theme.spacing(8)};
  }

  /* Primary page section title */
  .gladlyHC-faqSection-header {
    ${props => props.theme.typography.heading4}
    margin-bottom: ${props => props.theme.spacing(6)};
  }

  /* Primary page section wrapper */
  .gladlyHC-faqSections {
    margin-top: ${props => props.theme.spacing(8)};
    gap: ${props => props.theme.spacing(10)};

    /* Individual section, class also used on "See more" pages */
    .gladlyHC-faqSection {
      ${ContentBox}
      padding: ${props => props.theme.spacing(12, 10)};
    }
  }

  /* column layout */
  .gladlyHC-faqSections-col2 .gladlyHC-faqSection {
    @media ${props => props.theme.tablet} {
      flex-basis: calc(50% - ${props => props.theme.spacing(10)});
    }
  }

  /* Headings */
  .gladlyHC-answerDetail-name,
  .gladlyHC-searchResultsSection-heading,
  .gladlyHC-answerSection .gladlyHC-faqSection-header {
    ${props => props.theme.typography.heading2}
    margin-bottom: ${props => props.theme.spacing(4)};
  }

  /* Lists */
  .gladlyHC-answersIndex-list,
  .gladlyHC-searchResultsSection-results {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  /* List Items */
  .gladlyHC-faqSection-listItem,
  .gladlyHC-answersIndex-item,
  .gladlyHC-searchResultsSection-result {
    ${props => props.theme.typography.button}
    margin-bottom: ${props => props.theme.spacing(4)};

    a {
      color: ${props => props.theme.colors.hero.blueberry};
    }
  }

  /* See more results link at page bottom */
  .gladlyHC-answersIndex-link {
    margin: ${props => props.theme.spacing(10)} auto 0;

    a {
      color: ${props => props.theme.colors.hero.blueberry};
    }
  }

  /* Back link */
  .gladlyHC-answerDetail-backLink {
    ${props => props.theme.typography.body}
    color: ${props => props.theme.colors.text.primary};
    display: flex;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing(10)};

    &:before {
      content: '';
      background-image: url(${arrowLeft});
      background-size: 20px 14px;
      width: 20px;
      height: 14px;
      position: relative;
      margin-right: ${props => props.theme.spacing(4)};
    }
  }

  /* Sub pages wrapper */
  .gladlyHC-answerDetail,
  .gladlyHC-answerSection,
  .gladlyHC-answersIndex-container,
  .gladlyHC-searchResultsContainer {
    ${ContentBox}
    margin: ${props => props.theme.spacing(8)} auto 0;
  }

  /* Single answer page content */
  .gladlyHC-answerDetail-content {
    ${props => props.theme.typography.body}
  }
`;

export const ContactsContainer = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: ${props => props.theme.spacing(11, 4, 0)};
  @media ${props => props.theme.tablet} {
    display: flex;
    width: 90vw;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  @media ${props => props.theme.tabletL} {
    width: 70vw;
  }
`;

export const ContactsContainerText = styled.p`
  ${props => props.theme.typography.body}
  margin: auto;
  text-align: center;
  width: 75%;
`;

export const ContactInfoTitle = styled.div`
  font-weight: 400;
  width: 100vw;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
`;
export const ContactInfoText = styled.a`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors.hero.blueberry};
`;

export const HeadingContainer = styled.div`
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContactInfoTextContainer = styled.div`
  cursor: pointer;
  width: 232px;
`;
