import React from 'react';
import PropTypes from 'prop-types';
import { StyledEyebrow } from './styles';

export const Eyebrow = ({ children, fontColor, textAlign }) => {
  return (
    <StyledEyebrow $fontColor={fontColor} $textAlign={textAlign}>
      {children}
    </StyledEyebrow>
  );
};

Eyebrow.displayName = 'Eyebrow';

Eyebrow.propTypes = {
  children: PropTypes.node.isRequired,
  fontColor: PropTypes.string,
  textAlign: PropTypes.string,
};
