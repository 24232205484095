import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { MuxVideo } from '../MuxVideo';
import {
  StyledSplitPanelBody,
  IconContainer,
  VideoContainer,
  Header,
  Body,
} from './styles.js';

export const VerticalPanelBody = ({ asset, heading, body, level }) => {
  return (
    <StyledSplitPanelBody>
      {asset &&
        (asset?.component === 'image' ? (
          <IconContainer>
            <ResponsiveImage
              src={asset.src}
              alt={asset.alt}
              width={asset.width}
              height={asset.height}
            />
          </IconContainer>
        ) : (
          <VideoContainer>
            <MuxVideo {...asset} />
          </VideoContainer>
        ))}
      {heading && <Header as={level}>{heading}</Header>}
      <Body>{body}</Body>
    </StyledSplitPanelBody>
  );
};

VerticalPanelBody.displayName = 'VerticalPanelBody';

VerticalPanelBody.propTypes = {
  asset: PropTypes.object,
  heading: PropTypes.string,
  body: PropTypes.node,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
