'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@ui/button';
import { RecipeInformationSection } from '../RecipeInformationSection';
import {
  StyledRecipesDetails,
  RecipeSelector,
  OptionWrapper,
  RecipeOption,
  MobileRecipeSelector,
  MobileRecipeHeading,
} from './styles.js';

export const RecipesDetails = ({ recipes }) => {
  const [activeRecipe, setActiveRecipe] = useState(0);
  const currentRecipe = recipes[activeRecipe];

  const incrementRecipe = isNext => {
    setActiveRecipe(currentIndex => {
      let index = (currentIndex += isNext ? 1 : -1);
      if (index < 0) {
        index = recipes.length - 1;
      }
      if (index >= recipes.length) {
        index = 0;
      }
      return index;
    });
  };

  return (
    <StyledRecipesDetails color={currentRecipe.backgroundColor}>
      <RecipeSelector>
        {recipes.map((recipe, i) => (
          <OptionWrapper key={recipe.title}>
            <RecipeOption
              onClick={() => setActiveRecipe(i)}
              active={activeRecipe === i}
              aria-pressed={activeRecipe === i}
            >
              {recipe.title}
            </RecipeOption>
          </OptionWrapper>
        ))}
      </RecipeSelector>
      <MobileRecipeSelector>
        <IconButton
          onClick={() => incrementRecipe(false)}
          type="button"
          aria-label="Previous recipe"
          icon={{
            iconName: 'arrow-left',
            color: 'text.lighter',
            size: '24px',
            alt: '',
            id: 'previous_recipe',
          }}
          horizontalPadding={3}
          verticalPadding={3}
          variant={currentRecipe.buttonVariant}
        />
        <MobileRecipeHeading>{currentRecipe.title}</MobileRecipeHeading>
        <IconButton
          onClick={() => incrementRecipe(true)}
          type="button"
          aria-label="Next recipe"
          icon={{
            iconName: 'arrow-forward',
            color: 'text.lighter',
            size: '24px',
            alt: '',
            id: 'next_recipe',
          }}
          horizontalPadding={3}
          verticalPadding={3}
          variant={currentRecipe.buttonVariant}
        />
      </MobileRecipeSelector>
      {recipes.map((recipe, i) => {
        if (i !== activeRecipe) {
          return null;
        }
        return (
          <RecipeInformationSection
            key={recipe.title}
            title={recipe.title}
            image={recipe.image}
            icon={recipe.icon}
            notice={recipe.notice}
            description={recipe.description}
            details={recipe.details}
            backgroundColor={recipe.backgroundColor}
            buttonVariant={recipe.buttonVariant}
            link={recipe.link}
          />
        );
      })}
    </StyledRecipesDetails>
  );
};

RecipesDetails.displayName = 'RecipesDetails';

RecipesDetails.propTypes = {
  recipes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.object,
      icon: PropTypes.object,
      notice: PropTypes.string,
      details: PropTypes.array,
      link: PropTypes.object,
      description: PropTypes.node.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      buttonVariant: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
