import * as yup from 'yup';
import { SplitScreenGrid } from '@web/components';
import { getDimensions, getSrcSets } from '../images';
import { schema as gridItemSchema } from './split_screen_grid_item';

export const component = SplitScreenGrid;

export const schema = yup
  .object({
    headline: yup.string().nullable(),
    copy: yup.string().nullable(),
    linkTarget: yup.string().nullable(),
    linkText: yup.string().nullable(),
    splashImage: yup
      .object({
        src: yup.string().required(),
        alt: yup.string().nullable(),
        width: yup.number().nullable(),
        height: yup.number().nullable(),
        srcSet: yup.array().nullable(),
      })
      .required(),
    gridItems: yup.array(gridItemSchema).required(),
    gridHeadline: yup.string().nullable(),
    splashFirst: yup.boolean().nullable(),
    rowOnDesktop: yup.boolean().nullable(),
    eyebrow: yup.string().nullable(),
  })
  .transform(value => {
    const { width, height } = getDimensions(
      value.splash_image[0].images[0].asset.filename,
    );

    return {
      headline: value.headline,
      copy: value.main_copy,
      linkTarget: value.link_target?.url,
      linkText: value.link_text,
      splashImage: {
        // splash_image is a required block field that takes in a Responsive Image component
        // thus, why we can always count on at least one image here
        alt: value.splash_image[0].alt,
        src: value.splash_image[0].images[0].asset.filename,
        width,
        height,
        srcSet: getSrcSets(value.splash_image[0].images[0].asset.filename),
      },
      gridItems: value.grid_items,
      gridHeadline: value.grid_headline,
      splashFirst: !value.splash_image_right_positioned,
      rowOnDesktop: value.grid_desktop_layout === 'row',
      eyebrow: value.eyebrow,
    };
  });
