import React from 'react';
import PropTypes from 'prop-types';
import { variants } from './variants';
import { StyledCarouselDots, DotWrapper } from './styles.js';

export const CarouselDots = ({
  pages,
  activePage,
  onClick,
  variant = 'darkerFur',
}) => {
  const pageArray = pages ? [...Array(pages).keys()] : [];

  return (
    <StyledCarouselDots>
      {pageArray.map(page => {
        const isActive = page === activePage;
        return (
          <DotWrapper
            active={isActive}
            disabled={isActive}
            key={page}
            onClick={() => onClick(page)}
            aria-label={`Slide ${page + 1}`}
            aria-current={isActive}
            variant={variant}
          />
        );
      })}
    </StyledCarouselDots>
  );
};

CarouselDots.displayName = 'CarouselDots';

CarouselDots.propTypes = {
  pages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.keys(variants)),
};
