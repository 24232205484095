import * as yup from 'yup';
import { BlogLinks } from '@web/components';
import { requiredImageFromCMSSchema } from '../schemas';
import { schema as heading } from './heading';

export const component = BlogLinks;

const transformBlogValues = blogs =>
  blogs.map(blog => ({
    title: blog.title[0],
    blogLink: blog.blog_target.url,
    topic: blog.subtext,
    date: blog.date,
    image: blog.image[0].asset,
    backgroundColor: blog.background_color,
  }));

export const schema = yup
  .object({
    blogs: yup
      .array(
        yup.object({
          title: heading,
          topic: yup.string().required(),
          date: yup.string(),
          blogLink: yup.string().required(),
          image: requiredImageFromCMSSchema,
          backgroundColor: yup.string().nullable(),
        }),
      )
      .required(),
    fontColor: yup.string(),
    blogHeading: yup.string(),
  })
  .transform(value => ({
    blogs: transformBlogValues(value.blogs),
    fontColor: value.font_color,
    blogHeading: value.blog_heading,
  }));
