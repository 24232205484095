import * as yup from 'yup';
import { ProductValues } from '@web/components';
import {
  richTextFromCMSSchema,
  nullableImageFromCMSSchema,
  linkSchema,
} from '../schemas';

export const component = ProductValues;

export const schema = yup
  .object({
    callout: yup.string().optional(),
    benefit: yup.string().required(),
    badge: yup.string().optional(),
    description: richTextFromCMSSchema.optional(),
    image: nullableImageFromCMSSchema.optional(),
    link: linkSchema.optional(),
    buttonVariant: yup.string().optional(),
  })
  .transform(value => ({
    callout: value.callout,
    benefit: value.benefit,
    badge: value.badge,
    description: value.description,
    image: value.image?.[0]?.asset || null,
    link: value.link?.[0],
    buttonVariant: value.button_variant,
  }));
