import * as yup from 'yup';
import { format, parseISO } from 'date-fns';
import { EventsPage } from '@web/components';
import { linkSchema } from '../schemas';

export const component = EventsPage;

export const schema = yup
  .object({
    heading: yup.string().required(),
    events: yup
      .array(
        yup.object({
          heading: yup.string(),
          date: yup.string(),
          link: yup.string(),
          state: yup.string(),
          image: yup
            .object({
              src: yup.string(),
              alt: yup.string(),
              width: yup.number(),
              height: yup.number(),
            })
            .nullable(),
          location: yup.string(),
        }),
      )
      .required(),
    cta: linkSchema.nullable(),
  })
  .transform(value => ({
    heading: value.heading,
    cta: value.cta?.[0],
    events: value.folder_events_updated.map(({ content: { event } }) => ({
      heading: event.name.text,
      date: format(parseISO(event.start.local), 'MMMM d'),
      link: event.url,
      state: event.venue.address.region,
      image: {
        src: event.logo?.original?.url ?? value.default_image.filename,
        alt: event.name.text,
        width: event.logo?.original?.width ?? 2400,
        height: event.logo?.original?.height ?? 1600,
      },
      location: event.venue.address.localized_area_display,
    })),
  }));
