import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import {
  GridItem,
  GridImageContainer,
  GridItemImageContainer,
  GridItemHeadline,
  GridItemText,
  Badge,
} from './styles';

export const SplitScreenGridItem = ({
  rowOnDesktop = false,
  headline,
  text,
  image,
  alt,
  badge,
  backgroundColor = 'transparent',
}) => (
  <GridItem rowOnDesktop={rowOnDesktop}>
    <GridImageContainer backgroundColor={backgroundColor}>
      {badge && <Badge>{badge}</Badge>}
      <GridItemImageContainer rowOnDesktop={rowOnDesktop}>
        <ResponsiveImage src={image} alt={alt} width={114} height={114} />
      </GridItemImageContainer>
    </GridImageContainer>
    <div>
      <GridItemHeadline>{headline}</GridItemHeadline>
      <GridItemText>{text}</GridItemText>
    </div>
  </GridItem>
);

SplitScreenGridItem.displayName = 'SplitScreenGridItem';

SplitScreenGridItem.propTypes = {
  headline: PropTypes.string,
  text: PropTypes.node,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  badge: PropTypes.string,
  rowOnDesktop: PropTypes.bool,
  backgroundColor: PropTypes.string,
};
