'use client';
import styled from 'styled-components';

export const StyledRecipesDetails = styled.div`
  background-color: ${props => props.theme.colors.get(props.color)}25;
`;

export const RecipeSelector = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.background.dark};
  display: none;
  height: 60px;
  justify-content: center;
  padding: ${props => props.theme.spacing(4)};

  @media ${props => props.theme.tablet} {
    display: flex;
  }
`;

export const OptionWrapper = styled.div`
  flex: 0 0 auto;
  padding: ${props => props.theme.spacing(0, 4)};
`;

export const RecipeOption = styled.button`
  ${props => props.theme.typography.heading4};
  appearance: none;
  background: transparent;
  border: 0;
  color: ${props =>
    props.active
      ? props.theme.colors.text.lighter
      : props.theme.colors.border.fur};
  cursor: pointer;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  padding: 0;

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.text.lighter};
  }
`;

export const MobileRecipeSelector = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.spacing(8, 5, 4)};
  @media ${props => props.theme.tablet} {
    display: none;
  }
`;

export const MobileRecipeHeading = styled.h2`
  ${props => props.theme.typography.heading3}
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.tablet} {
    max-width: 510px;
  }
`;

export const DetailsHeader = styled.h3`
  ${props => props.theme.typography.heading3};
  color: ${props => props.theme.colors.text.light};
  display: none;
  order: -1;

  @media ${props => props.theme.tablet} {
    display: block;
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

export const DetailsIcon = styled.div`
  display: inline-block;
  padding-left: ${props => props.theme.spacing(3)};
  transform: translateY(12.5%);
  vertical-align: top;
`;

export const NoticeBody = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.status.errorPrimary};
  margin-bottom: ${props => props.theme.spacing(6)};
  order: -1;
`;

export const DetailsBody = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.light};
  margin-bottom: ${props => props.theme.spacing(6)};
  order: -1;
`;

export const ButtonWrapper = styled.div`
  margin: ${props => props.theme.spacing(0, 0, 8)};
  order: -1;
  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(6, 0, 0)};
    order: unset;
  }
`;
