import * as yup from 'yup';
import { requiredImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';
import { dropdownsSchema } from './addon_dropdown_values';

export const addonCardSchema = yup
  .object({
    title: yup.string(),
    sku: yup.string(),
    description: richTextFromCMSSchema,
    image: requiredImageFromCMSSchema,
    selectionLabel: yup.string(),
    unavailableText: yup.string(),
    productType: yup.string(),
    subtitle: yup.string(),
    pricingInfo: yup.object({
      price: yup.string(),
      discountedPrice: yup.string(),
      saveText: yup.string(),
    }),
    size: yup.string(),
    dropdowns: yup.array(dropdownsSchema),
    badge: yup.string(),
    badge_dropdown: yup.string(),
    level: yup.string(),
    recurrence: yup.string().nullable(),
    unit: yup.string(),
  })
  .transform(value => ({
    title: value.display_name,
    sku: value.slug_sku,
    description: value.description,
    image: value.image,
    selectionLabel: value.selection_label,
    unavailableText: value.unavailable_text,
    productType: value.product_type,
    subtitle: value.subtitle,
    pricingInfo: {
      price: value.price,
      discountedPrice: value.discounted_price,
      saveText: value.save_text,
    },
    size: value.size,
    dropdowns: value.dropdowns,
    badge: value.badge,
    badgeDropdown: value.badge_dropdown,
    level: value.level,
    unit: value.unit,
  }));
