import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules/index.js';
import { StyledButton } from './styles.js';

export const SocialButton = ({ image, openNewTab = false, href, onClick }) => {
  return (
    <StyledButton href={href} target={openNewTab && '_blank'} onClick={onClick}>
      <ResponsiveImage
        src={image.src}
        alt={image.alt}
        width={150}
        height={45}
        objectFit="contain"
      />
    </StyledButton>
  );
};

SocialButton.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  openNewTab: PropTypes.bool,
  href: PropTypes.string.isRequired,
};
