import * as yup from 'yup';
import { InformationStats } from '@web/components';
import { schema as info_stat_block } from './info_stat_block';

export const component = InformationStats;

export const schema = yup
  .object({
    heading: yup.string(),
    subheading: yup.string(),
    headingLevel: yup.string(),
    statBlocks: yup.array(info_stat_block).required(),
    backgroundColor: yup.string(),
  })
  .transform(value => ({
    heading: value.heading,
    subheading: value.subheading,
    headingLevel: value.heading_level,
    statBlocks: value.stat_blocks,
    backgroundColor: value.background_color,
  }));
