import * as yup from 'yup';
import { SlideDownCard } from '@web/molecules';
import {
  requiredImageFromCMSSchema,
  richTextFromCMSSchema,
  linkSchema,
} from '../schemas.js';

export const component = SlideDownCard;

export const schema = yup
  .object({
    header: yup.string().required(),
    description: richTextFromCMSSchema,
    button: linkSchema.required(),
    image: requiredImageFromCMSSchema,
    buttonVariant: yup.string(),
  })
  .transform(value => ({
    header: value.header,
    description: value.description,
    button: value.button[0],
    image: value.image,
    buttonVariant: value.button_variant,
  }));
