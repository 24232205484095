import * as yup from 'yup';
import { RecipesDetails } from '@web/components';
import { schema as recipeInformationSectionSchema } from './recipe_information_section';

export const component = RecipesDetails;

export const schema = yup
  .object({
    recipes: yup.array(recipeInformationSectionSchema),
  })
  .transform(value => ({
    recipes: value.recipes,
  }));
