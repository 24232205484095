import * as yup from 'yup';
import { ComparisonChart } from '@web/components';
import { schema as comparisonChartRow } from './comparison_chart_row';

export const component = ComparisonChart;

export const schema = yup.object({
  heading: yup.string().required(),
  description: yup.string(),
  rows: yup.array(comparisonChartRow).required(),
  columns: yup.array().required(),
  level: yup.string(),
});
