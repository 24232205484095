'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { CheckmarkIcon } from '@ui/icons';
import { Button } from '@ui/button';
import { Radio } from '@web/atoms';
import { useReporter } from '../../reporter';
import { PollResponseSubmitted } from './events.js';
import {
  StyledPoll,
  QuestionText,
  SubmittedResponseText,
  SubmitWrapper,
} from './styles';

export const ConfirmationPoll = ({ pollPrompt, options }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { handleSubmit, control, setError, reset, getValues } = useForm({
    mode: 'onChange',
  });

  const reporter = useReporter();

  const submitPoll = data => {
    if (data.poll === undefined) {
      setError('poll', { type: 'required', message: 'Field is required' });
      setTimeout(() =>
        reset(getValues(), {
          keepErrors: true,
        }),
      );
    } else {
      setIsSubmitted(true);
      reporter.tag(PollResponseSubmitted(pollPrompt, data.poll));
    }
  };
  return (
    <StyledPoll onSubmit={handleSubmit(submitPoll)}>
      <QuestionText> {pollPrompt}</QuestionText>
      <Controller
        control={control}
        name="poll"
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <>
            <Radio
              {...field}
              choices={options}
              legendText={'confirmation_poll'}
              name="poll"
              disabled={isSubmitted}
              error={error?.message || ''}
            />
          </>
        )}
      />
      <SubmitWrapper>
        {!isSubmitted ? (
          <Button horizontalPadding={23} variant="dark">
            {'Submit'}
          </Button>
        ) : (
          <SubmittedResponseText>
            <CheckmarkIcon size="16" color="text.primary" />
            Thanks, your answer has been submitted
          </SubmittedResponseText>
        )}
      </SubmitWrapper>
    </StyledPoll>
  );
};

ConfirmationPoll.displayName = 'Confirmation Poll';

ConfirmationPoll.propTypes = {
  pollPrompt: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
