import * as yup from 'yup';
import { Dropdown } from '@web/atoms';

export const component = Dropdown;

export const feedingDropdownSchema = yup
  .object({
    name: yup.string().required(),
    label: yup.string().required(),
    noSelectionLabel: yup.string().required(),
    options: yup
      .array(
        yup.object({
          label: yup.string(),
          value: yup.string(),
        }),
      )
      .required(),
  })
  .required()
  .transform(value => ({
    name: value.name,
    label: value.label,
    noSelectionLabel: value.no_selection_label,
    options: value.options,
  }));
