'use client';
import styled from 'styled-components';

// Card styles
export const StyledTestimonial = styled.div`
  background: ${props => props.theme.colors.background.lightest};
  border-radius: 32px;
  break-inside: avoid;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(4)};
  padding: ${props => props.theme.spacing(6)};
  overflow: hidden;
  text-align: left;
  margin-bottom: ${props => props.theme.spacing(4)};
  max-width: 330px;

  svg {
    transform: scaleX(-1);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(4)};
`;

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FiveStarRating = styled.div`
  margin-bottom: ${props => props.theme.spacing(2)};
  &::before {
    font-size: 1rem;
    letter-spacing: 2px;
    content: '★★★★★';
    background-color: ${props => props.theme.colors.hero.sweetPotato};
    background-clip: text;
    /* stylelint-disable-next-line */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const PupName = styled.h4`
  ${props => props.theme.typography.heading5}
`;

// Container styles
export const Container = styled.div`
  background: ${props => props.theme.colors.background.base};
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(4)};
  justify-content: center;
  padding: ${props => props.theme.spacing(4)};
`;

export const TestimonialContainer = styled.div`
  grid-auto-flow: row dense;
  column-count: 1;

  @media ${props => props.theme.tablet} {
    column-count: 2;
  }

  @media ${props => props.theme.laptop} {
    column-count: 3;
  }
`;

export const Description = styled.span`
  ${props => props.theme.typography.label}
  color: ${props => props.theme.colors.text.tertiary};
  display: flex;
  gap: ${props => props.theme.spacing(2)};
`;

export const Headline = styled.h2`
  ${props => props.theme.typography.heading2}
  text-align: center;
`;

export const SubHeadline = styled.p`
  ${props => props.theme.typography.body};
  text-align: center;
  margin-bottom: ${props => props.theme.spacing(4)};
`;
