import * as yup from 'yup';
import { ProductsSummaryDisplay } from '@web/molecules';
import { nullableImageFromCMSSchema } from '../schemas.js';

export const component = ProductsSummaryDisplay;

export const schema = yup
  .object({
    sectionHeader: yup.string(),
    sectionHeaderIcon: nullableImageFromCMSSchema,
    recipeLinks: yup.array(
      yup
        .object({
          img: nullableImageFromCMSSchema,
          href: yup.string(),
          text: yup.string(),
        })
        .transform(icon => ({
          img: icon.image,
          href: icon.href,
          text: icon.text,
        })),
    ),
    ctaHref: yup.string(),
    ctaButtonText: yup.string(),
    ctaButtonVariant: yup.string(),
    description: yup.string(),
    component: yup.string(),
  })
  .transform(section => ({
    sectionHeader: section.header,
    sectionHeaderIcon: section.header_icon,
    recipeLinks: section.recipe_links,
    description: section.description,
    ctaButtonText: section.cta_button_text,
    ctaButtonVariant: section.cta_button_variant,
    ctaHref: section.cta_href,
    component: section.component,
  }));
