import * as yup from 'yup';
import {
  requiredImageFromCMSSchema,
  richTextFromCMSSchema,
  nullableImageFromCMSSchema,
} from '../schemas';

export const feedingGuidelinesRecipeVersionSchema = yup
  .object({
    packageImage: requiredImageFromCMSSchema,
    barcodeImage: nullableImageFromCMSSchema,
    imageGuide: yup.string(),
    versionName: yup.string(),
    ingredientsTitle: yup.string(),
    ingredients: richTextFromCMSSchema,
    guaranteedAnalysisTitle: yup.string(),
    guaranteedAnalysis: yup.array(
      yup.object({
        name: yup.string(),
        range: yup.string(),
        percentage: yup.string(),
      }),
    ),
    calorieContentTitle: yup.string(),
    calorieContent: richTextFromCMSSchema,
    packSizeTitle: yup.string(),
    packSize: yup.array(
      yup.object({
        size: yup.string(),
        label: yup.string(),
        weight: yup.string(),
      }),
    ),
  })
  .transform(value => ({
    packageImage: value.package_image,
    barcodeImage: value.barcode_image,
    imageGuide: value.image_guide,
    versionName: value.version_name,
    ingredientsTitle: value.ingredients_title,
    ingredients: value.ingredients,
    guaranteedAnalysisTitle: value.guaranteed_analysis_title,
    guaranteedAnalysis: value.guaranteed_analysis,
    calorieContentTitle: value.calorie_content_title,
    calorieContent: value.calorie_content,
    packSizeTitle: value.pack_size_title,
    packSize: value.pack_size,
  }));

export const feedingGuidelinesRecipeSchema = yup
  .object({
    heading: yup.string(),
    feedingGuidelinesRecipeVersions: yup.array(
      feedingGuidelinesRecipeVersionSchema,
    ),
    level: yup.string(),
  })
  .transform(value => ({
    heading: value.heading,
    feedingGuidelinesRecipeVersions: value.feeding_guidelines_recipe_versions,
    level: value.level,
  }));
