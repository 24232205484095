import React from 'react';
import * as yup from 'yup';
import { RecipeInformationSection } from '@web/components';
import {
  richTextFromCMSSchema,
  requiredImageFromCMSSchema,
  nullableImageFromCMSSchema,
  linkSchema,
} from '../schemas';
import { CMSBlok } from '../translation';

export const component = RecipeInformationSection;

const detailsSchema = yup.array(
  yup
    .object({
      title: yup.string().required(),
      level: yup.string(),
      body: yup.array(yup.object()),
    })
    .transform(value => ({
      title: value.title,
      level: value.level,
      // anti-pattern to have an index as the key but this only runs once so its ok
      body: value.body.map((blok, i) => <CMSBlok key={i} content={blok} />),
    })),
);

export const schema = yup
  .object({
    title: yup.string(),
    image: requiredImageFromCMSSchema,
    icon: nullableImageFromCMSSchema,
    notice: yup.string(),
    description: richTextFromCMSSchema,
    details: detailsSchema,
    headline: yup.string(),
    subheadline: yup.string(),
    backgroundColor: yup.string(),
    buttonVariant: yup.string(),
    link: linkSchema,
    eyebrow: yup.string().nullable(),
    level: yup.string(),
  })
  .transform(value => ({
    title: value.title,
    headline: value.headline,
    subheadline: value.subheadline,
    description: value.description,
    backgroundColor: value.background_color,
    buttonVariant: value.button_variant,
    image: value.image,
    icon: value.icon,
    details: value.details,
    link: value.link[0],
    eyebrow: value.eyebrow,
    level: value.level,
  }));
