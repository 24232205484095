import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, CtaButton, Accordion } from '@web/molecules';
import {
  StyledDetails,
  DetailsHeader,
  DetailsIcon,
  DetailsDescription,
  DetailsBody,
  NoticeBody,
  ButtonWrapper,
} from './styles';

export const Details = ({
  title,
  icon,
  notice,
  description,
  details,
  buttonVariant = 'spinach',
  link = null,
}) => (
  <StyledDetails>
    {title || description || notice ? (
      <DetailsDescription>
        <DetailsHeader>
          {title}
          {icon && (
            <DetailsIcon>
              <ResponsiveImage
                src={icon.src}
                alt={icon.alt}
                height={icon.height}
                width={icon.width}
              />
            </DetailsIcon>
          )}
        </DetailsHeader>
        {notice && <NoticeBody>{notice}</NoticeBody>}
        {description && <DetailsBody>{description}</DetailsBody>}
      </DetailsDescription>
    ) : null}
    <Accordion details={details} buttonVariant={buttonVariant} />
    {link && (
      <ButtonWrapper>
        <CtaButton href={link.target} variant={buttonVariant} fullWidth>
          {link.text}
        </CtaButton>
      </ButtonWrapper>
    )}
  </StyledDetails>
);

Details.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  notice: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.array.isRequired,
  buttonVariant: PropTypes.string.isRequired,
  link: PropTypes.object,
};
