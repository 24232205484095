import * as yup from 'yup';
import { Quotes } from '@web/components';
import { schema as quote } from './quote';

export const component = Quotes;

export const schema = yup
  .object({
    header: yup.string().required(),
    quotes: yup.array(quote),
  })
  .transform(value => ({
    header: value.header,
    quotes: value.quotes,
  }));
