'use client';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import {
  TimelineScrollPromoOuter,
  TimelineScrollPromoContainer,
  TimelineScrollPromoWrapper,
  StyledTimelineScrollPromo,
  StepsSection,
  StepHeadline,
  StepText,
  Steps,
  Step,
  StepNumber,
  StepBody,
  SplashImageContainer,
  SplashImageFrame,
  SplashImage,
  Badge,
  ButtonWrapper,
} from './styles.js';

const STEP_SCROLL_RATIO = 1;

export const TimelineScrollPromo = ({ steps, splashImages, link, badge }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const containerRef = useRef(null);
  const stepRefs = useRef(steps.map(() => null));

  useEffect(() => {
    const onViewportChange = () => {
      setCurrentStep(cur => {
        const { top } = containerRef.current.getBoundingClientRect();
        const percentScrolled =
          (top / window.innerHeight / (steps.length - 1)) * -1;
        const currentSlide =
          Math.ceil((percentScrolled * steps.length) / STEP_SCROLL_RATIO) - 1;
        if (percentScrolled < 0 || currentSlide < 0) {
          return 0;
        }
        if (currentSlide >= steps.length) {
          return stepRefs.current.length - 1;
        }
        return currentSlide;
      });
    };

    window.addEventListener('scroll', onViewportChange);
    window.addEventListener('resize', onViewportChange);
    return () => {
      window.removeEventListener('scroll', onViewportChange);
      window.removeEventListener('resize', onViewportChange);
    };
  }, [steps]);

  return (
    <TimelineScrollPromoOuter count={steps.length || 1} ref={containerRef}>
      <TimelineScrollPromoContainer>
        <TimelineScrollPromoWrapper>
          <StyledTimelineScrollPromo>
            <SplashImageContainer>
              <SplashImageFrame>
                {splashImages.map((image, index) => (
                  <SplashImage active={index === currentStep} key={image.src}>
                    <ResponsiveImage
                      src={image.src}
                      srcSet={image.srcSet}
                      alt={image.alt}
                      fill={true}
                      objectFit="cover"
                    />
                  </SplashImage>
                ))}
                {badge && (
                  <Badge>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img src={badge.src} alt={badge.alt} />
                  </Badge>
                )}
              </SplashImageFrame>
            </SplashImageContainer>
            <StepsSection>
              <Steps>
                {steps.map((step, index) => (
                  <Step
                    key={step.text}
                    ref={ref => (stepRefs.current[index] = ref)}
                    active={index === currentStep}
                  >
                    <StepNumber>{index + 1}</StepNumber>
                    <StepBody>
                      {step.headline && (
                        <StepHeadline>{step.headline}</StepHeadline>
                      )}
                      <StepText>{step.text}</StepText>
                    </StepBody>
                  </Step>
                ))}
              </Steps>
            </StepsSection>
          </StyledTimelineScrollPromo>
          {link && (
            <ButtonWrapper>
              <CtaButton variant="dark" href={link.target}>
                {link.text}
              </CtaButton>
            </ButtonWrapper>
          )}
        </TimelineScrollPromoWrapper>
      </TimelineScrollPromoContainer>
    </TimelineScrollPromoOuter>
  );
};

TimelineScrollPromo.displayName = 'TimelineScrollPromo';

TimelineScrollPromo.propTypes = {
  splashImages: PropTypes.arrayOf(
    PropTypes.shape({
      srcSet: PropTypes.arrayOf(PropTypes.object).isRequired,
      src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      alt: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
  ),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  badge: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
  }),
};
