import React from 'react';
import * as yup from 'yup';
import { VerticalPanelWithImageRails } from '@web/components';
import { requiredImageFromCMSSchema } from '../schemas';
import { CMSBlok } from '../translation';

export const component = VerticalPanelWithImageRails;

export const schema = yup
  .object({
    topContent: yup.array().required(),
    middleContent: yup.array(),
    bottomContent: yup.array(),
    imageRails: yup.array(requiredImageFromCMSSchema).required(),
  })
  .transform(value => ({
    // anti-pattern to have an index as the key but this only runs once so its ok
    topContent: value.top_content.map((blok, i) => (
      <CMSBlok key={i} content={blok} />
    )),
    middleContent: value.middle_content.map((blok, i) => (
      <CMSBlok key={i} content={blok} />
    )),
    bottomContent: value.bottom_content.map((blok, i) => (
      <CMSBlok key={i} content={blok} />
    )),
    imageRails: value.image_rails.map(image => image.asset),
  }));
