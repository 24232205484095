'use client';
import styled from 'styled-components';

export const SplitScreenGridContainer = styled.section`
  width: 100%;
  max-width: 1440px;
  margin: auto;
`;

export const StyledSplitScreenGrid = styled.div`
  max-width: 1440px;
  display: flex;
  flex-flow: column nowrap;
  padding: 48px 16px;
  margin: 0 auto;
  @media ${props => props.theme.tablet} {
    padding: 64px 48px 16px;
  }
  @media ${props => props.theme.laptop} {
    padding: 64px 80px;
  }
`;

export const Splash = styled.div`
  order: ${props => (props.splashFirst ? '0' : '1')};
  margin-bottom: 32px;
  @media ${props => props.theme.laptop} {
    flex-basis: 60%;
    margin-bottom: 0;
  }
  /* ensure child image fills container */
  img {
    width: 100%;
  }
`;

export const GridImageContainer = styled.div`
  position: relative;
  background-color: ${props => props.backgroundColor};
  border-radius: 8px;
  text-align: center;
  flex-shrink: 0;
  flex-basis: 128px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 88px;
  @media ${props => props.theme.laptop} {
    width: 100%;
  }
`;

export const GridItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0px;
  }
  @media ${props => props.theme.tablet} {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 16px;
    }
    flex-basis: calc(50% - 16px);
  }
  @media ${props => props.theme.laptop} {
    justify-content: unset;
    margin-bottom: ${props => (!props.rowOnDesktop ? '16px' : '32px')};
    flex-flow: ${props => (!props.rowOnDesktop ? 'row nowrap' : 'column')};
    ${GridImageContainer} {
      margin-right: ${props => (!props.rowOnDesktop ? '16px' : '0')};
      margin-bottom: ${props => (!props.rowOnDesktop ? '0' : '16px')};
    }
  }
`;

export const GridItemImageContainer = styled.div`
  /* inflexible, but the designs currently expect
  larger images based on the row vs column layout
  TODO(cn) Find a way to let CMS authors input their own image widths */
  width: ${props => (props.rowOnDesktop ? '56px' : '105px')};
  @media ${props => props.theme.laptop} {
    width: ${props => (props.rowOnDesktop ? '72px' : '114px')};
  }
`;

export const GridItemHeadline = styled.h4`
  font-size: 1rem;
  margin-bottom: 4px;
  line-height: 20px;
`;

export const GridItemText = styled.div`
  font-size: 0.875rem;
  line-height: 21px;
`;

export const Badge = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
  color: ${props => props.theme.colors.text.lightest};
  background: ${props => props.theme.colors.background.dark};
  padding: 4px 8px;
  font-size: 0.6875rem;
  border-radius: 2px;
  @media ${props => props.theme.laptop} {
    font-size: 0.75rem;
  }
`;

export const MainCopyContainer = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

export const Headline = styled.h2`
  margin-bottom: 8px;
  font-size: 1.5rem;
  @media ${props => props.theme.tablet} {
    font-size: 2.5rem;
    line-height: 50px;
  }
`;

export const MainCopy = styled.p`
  font-size: 1rem;
  @media ${props => props.theme.laptop} {
    margin-bottom: 16px;
    font-size: 1.25rem;
  }
`;

export const SplitScreenSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  @media ${props => props.theme.laptop} {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;

export const GridItemsContainer = styled.div`
  flex-basis: calc(40% - 48px);
  @media ${props => props.theme.laptop} {
    margin-bottom: 0;
  }
`;

export const GridHeadline = styled.h3`
  text-align: center;
  margin-bottom: 24px;
  line-height: 30px;
  font-size: 1.25rem;
  @media ${props => props.theme.laptop} {
    font-size: 1.5rem;
  }
`;

export const GridItems = styled.div`
  display: flex;
  flex-flow: column;
  @media ${props => props.theme.tablet} {
    flex-flow: row wrap;
    justify-content: space-between;
  }
  @media ${props => props.theme.laptop} {
    ${props => !props.rowOnDesktop && 'flex-flow: column;'}
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;
