import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@ui/theme';
import { BREEDS_PATHS } from './BreedPaths';
import { AvatarCircle } from './styles';

export const getAvatar = breedNames => {
  const breedName = breedNames.find(name =>
    Object.keys(BREEDS_PATHS).includes(name),
  );
  return BREEDS_PATHS[breedName] ?? BREEDS_PATHS['Generic Dog'];
};

export const PupAvatar = ({
  breeds,
  variant,
  color,
  fullBleed,
  width = 200,
  height = 181,
}) => {
  const Avatar = getAvatar(breeds);
  const SVG = (
    <svg
      title={breeds.join('-')}
      data-testid="PupAvatar"
      width={width}
      height={height}
      viewBox="0 0 200 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Avatar
        color={theme.colors.get(color, '#A69D9C')}
        fullBleed={!!fullBleed}
      />
    </svg>
  );

  if (variant === 'circle') {
    return <AvatarCircle backgroundColor={color}>{SVG}</AvatarCircle>;
  }
  return SVG;
};

PupAvatar.displayName = 'PupAvatar';
PupAvatar.propTypes = {
  breeds: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'circle']),
  fullBleed: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};
