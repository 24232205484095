import React from 'react';
import PropTypes from 'prop-types';
import { SectionIntro, Eyebrow } from '@web/atoms';
import { CtaButton } from '@web/molecules';
import { FaqListItem } from '../FaqListItem';
import { useReporter } from '../../reporter';
import { ctaButtonClicked } from './events';
import {
  StyledFaqList,
  Inner,
  List,
  ButtonContainer,
  ButtonWrapper,
} from './styles.js';

export const FaqList = ({
  faqs,
  headline,
  subheadline,
  link,
  eyebrow,
  level,
}) => {
  const reporter = useReporter();
  return (
    <StyledFaqList>
      <Eyebrow textAlign={'center'}>{eyebrow}</Eyebrow>
      <SectionIntro
        headline={headline}
        subheadline={subheadline}
        headingLevel={level}
      />
      <Inner>
        <List>
          {faqs.map(faq => (
            <FaqListItem
              key={faq.question}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </List>
        {link && (
          <ButtonContainer>
            <ButtonWrapper>
              <CtaButton
                variant="dark"
                href={link.target}
                onClick={() =>
                  reporter.tag(
                    ctaButtonClicked(link.text, headline || subheadline),
                  )
                }
              >
                {link.text}
              </CtaButton>
            </ButtonWrapper>
          </ButtonContainer>
        )}
      </Inner>
    </StyledFaqList>
  );
};

FaqList.displayName = 'FaqList';

FaqList.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    }),
  ).isRequired,
  headline: PropTypes.string.isRequired,
  subheadline: PropTypes.string.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  eyebrow: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
