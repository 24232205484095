import * as yup from 'yup';

export const dropdownsSchema = yup
  .object({
    name: yup.string(),
    label: yup.string(),
    noSelectionLabel: yup.string(),
    options: yup.array(
      yup.object({
        label: yup.string(),
        value: yup.number(),
      }),
    ),
  })
  .transform(value => ({
    name: value.name,
    label: value.label,
    noSelectionLabel: value.no_selection_label,
    options: value.options,
  }));

export const dropdownValuesSchema = yup
  .object({
    defaultValues: yup.object({
      quantity: yup.number(),
      frequency: yup.number(),
    }),
    dropdowns: yup.array(dropdownsSchema),
    addButtonLabel: yup.string(),
    removeButtonLabel: yup.string(),
    doneButtonLabel: yup.string(),
  })
  .transform(value => ({
    defaultValues: {
      quantity: value.default_quantity,
      frequency: value.default_frequency,
    },
    dropdowns: value.dropdowns,
    addButtonLabel: value.add_button_label,
    removeButtonLabel: value.remove_button_label,
    doneButtonLabel: value.done_button_label,
  }));
