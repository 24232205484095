import * as yup from 'yup';
import { OfferCallout } from '@web/components';

export const component = OfferCallout;

export const schema = yup.object({
  lead: yup.string(),
  follow: yup.string(),
  label: yup.string().required(),
  tooltip: yup.string().required(),
});
