import dynamic from 'next/dynamic';

const GenericDog = dynamic(() =>
  import('./genericDog').then(mod => mod.GenericDog),
);
const Chihuahua = dynamic(() =>
  import('./chihuahua').then(mod => mod.Chihuahua),
);
const Corgi = dynamic(() => import('./corgi').then(mod => mod.Corgi));
const Dachshund = dynamic(() =>
  import('./dachshund').then(mod => mod.Dachshund),
);
const FrenchBulldog = dynamic(() =>
  import('./frenchBulldog').then(mod => mod.FrenchBulldog),
);
const GenericLarge = dynamic(() =>
  import('./genericLarge').then(mod => mod.GenericLarge),
);
const GenericMedium = dynamic(() =>
  import('./genericMedium').then(mod => mod.GenericMedium),
);
const GermanShepherd = dynamic(() =>
  import('./germanShepherd').then(mod => mod.GermanShepherd),
);
const GoldenRetriever = dynamic(() =>
  import('./goldenRetriever').then(mod => mod.GoldenRetriever),
);
const GreatDane = dynamic(() =>
  import('./greatDane').then(mod => mod.GreatDane),
);
const Greyhound = dynamic(() =>
  import('./greyhound').then(mod => mod.Greyhound),
);
const Husky = dynamic(() => import('./husky').then(mod => mod.Husky));
const Labradoodle = dynamic(() =>
  import('./labradoodle').then(mod => mod.Labradoodle),
);
const LabradorRetriever = dynamic(() =>
  import('./labradorRetriever').then(mod => mod.LabradorRetriever),
);
const Maltese = dynamic(() => import('./maltese').then(mod => mod.Maltese));
const Pomeranian = dynamic(() =>
  import('./pomeranian').then(mod => mod.Pomeranian),
);
const Poodle = dynamic(() => import('./poodle').then(mod => mod.Poodle));
const ShibaInu = dynamic(() => import('./shibaInu').then(mod => mod.ShibaInu));
const ShihTzu = dynamic(() => import('./shihTzu').then(mod => mod.ShihTzu));
const YorkshireTerrier = dynamic(() =>
  import('./yorkshireTerrier').then(mod => mod.YorkshireTerrier),
);

export const BREEDS_PATHS = {
  Chihuahua,
  Corgi,
  Dachshund,
  'French Bulldog': FrenchBulldog,
  'Generic Dog': GenericDog,
  'Generic Large': GenericLarge,
  'Generic Medium': GenericMedium,
  'German Shepherd Dog': GermanShepherd,
  'Golden Retriever': GoldenRetriever,
  'Great Dane': GreatDane,
  Greyhound,
  Husky,
  Labradoodle,
  'Labrador Retriever': LabradorRetriever,
  Maltese,
  Pomeranian,
  Poodle,
  'Shiba Inu': ShibaInu,
  'Shih Tzu': ShihTzu,
  'Yorkshire Terrier': YorkshireTerrier,
};
