import React from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';
import { PillText, Tooltip } from '@web/atoms';
import {
  Card,
  Info,
  StyledImage,
  Name,
  Description,
  CardContent,
  DisplayedPrice,
  StrikeThroughPrice,
  PricingSymbol,
  InactiveSymbol,
  ActiveSymbol,
  CardHeader,
  BadgesContainer,
  KeyInfo,
  PricesContainer,
} from './styles.js';

export const ProductCardCRO = ({
  label,
  title,
  priceSymbol,
  value,
  description,
  checked = false,
  img,
  onChange,
  benefit,
  unavailable,
  unavailableText,
  notSelectable,
  notSelectableText = 'To choose this item, please remove one of your previous selections.',
  pricingInfo = null,
  callout,
  showPriceText,
  period = 'week',
  level,
}) => {
  const getPriceSymbol = () => {
    switch (priceSymbol) {
      case 'low':
        return (
          <PricingSymbol data-testid="pricingLow">
            <ActiveSymbol>$</ActiveSymbol>
            <InactiveSymbol>$$</InactiveSymbol>
          </PricingSymbol>
        );
      case 'mid':
        return (
          <PricingSymbol data-testid="pricingMid">
            <ActiveSymbol>$$</ActiveSymbol>
            <InactiveSymbol>$</InactiveSymbol>
          </PricingSymbol>
        );
      case 'high':
        return (
          <PricingSymbol data-testid="pricingHigh">
            <ActiveSymbol>$$$</ActiveSymbol>
          </PricingSymbol>
        );
      default:
        return (
          <PricingSymbol data-testid="pricingDefault">
            <InactiveSymbol>$$$</InactiveSymbol>
          </PricingSymbol>
        );
    }
  };

  const getProductCard = props => (
    <Card
      {...props}
      checked={checked}
      unavailable={unavailable}
      $notSelectable={notSelectable}
      aria-label={`${label} ${title}`}
      onClick={onChange}
    >
      <CardContent>
        <StyledImage>
          <NextImage src={img.src} alt={img.alt} width={82} height={82} />
        </StyledImage>
        <Info>
          <CardHeader>
            <KeyInfo>
              <Name as={level}>{title}</Name>
              <BadgesContainer>
                {benefit && (
                  <PillText text={benefit} variant="kale" size="small" />
                )}
                {callout && (
                  <PillText
                    text={callout}
                    variant="blueberry"
                    size="small"
                  ></PillText>
                )}
              </BadgesContainer>
            </KeyInfo>
          </CardHeader>
          {priceSymbol && getPriceSymbol()}
          {pricingInfo &&
            !unavailable &&
            (!pricingInfo.discount
              ? Boolean(pricingInfo.price) && (
                  <DisplayedPrice>
                    {showPriceText && 'From '} ${pricingInfo.price}/{period}
                  </DisplayedPrice>
                )
              : Boolean(pricingInfo.price) && (
                  <>
                    <PricesContainer>
                      {showPriceText && 'From '}
                      <StrikeThroughPrice>
                        ${pricingInfo.price}
                      </StrikeThroughPrice>
                      <DisplayedPrice>
                        ${pricingInfo.discount}/{period}
                      </DisplayedPrice>
                    </PricesContainer>
                  </>
                ))}

          <Description>
            {unavailable ? unavailableText : description}
          </Description>
        </Info>
      </CardContent>
    </Card>
  );

  return notSelectable && !unavailable ? (
    <Tooltip trigger={getProductCard} placement="top" offsetY={-200}>
      {notSelectableText}
    </Tooltip>
  ) : (
    getProductCard()
  );
};

ProductCardCRO.displayName = 'ProductCardCRO';
ProductCardCRO.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  priceSymbol: PropTypes.string,
  benefit: PropTypes.string,
  unavailable: PropTypes.bool,
  unavailableText: PropTypes.string,
  unavailableLabel: PropTypes.string,
  notSelectable: PropTypes.bool,
  notSelectableText: PropTypes.string,
  value: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  img: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  pricingInfo: PropTypes.shape({
    price: PropTypes.string,
    discount: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  callout: PropTypes.string,
  showPriceText: PropTypes.bool,
  period: PropTypes.string,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
