import * as yup from 'yup';
import { MuxVideo } from '@web/components';

export const component = MuxVideo;

export const schema = yup
  .object({
    playbackId: yup.string().required(),
    thumbnail: yup.string(),
    autoplay: yup.bool(),
    loop: yup.bool(),
    component: yup.string(),
  })
  .transform(value => {
    return {
      playbackId: value.mux_asset.playback_ids[0].id,
      thumbnail:
        value.thumbnail ||
        `https://image.mux.com/${value.mux_asset.playback_ids[0].id}/thumbnail.png`,
      autoplay: value.autoplay,
      loop: value.loop,
      component: value.component || 'mux_video',
    };
  });
