'use client';
import styled from 'styled-components';

export const ErrorText = styled.p.attrs(() => ({ role: 'alert' }))`
  color: ${props => props.theme.colors.status.errorPrimary};
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: ${props => props.theme.spacing(2)};
  align-self: flex-start;
`;
