'use client';
import styled from 'styled-components';

export const SectionContainer = styled.div`
  background-color: ${props => props.theme.colors.background.lightest};
  border: 1px ${({ theme }) => theme.colors.withAlpha('border.fur', 0.25)};
  border-style: solid none;
  padding: ${props => props.theme.spacing(4, 0, 3)};
  width: 100%;
`;

export const PlanImage = styled.div`
  border-radius: ${({ theme }) => theme.spacing(4)};
  object-fit: fill;
  height: auto;
  min-width: 96px;
  img {
    border-radius: 24px;
  }
`;

export const StyledInfoWithImages = styled.div`
  display: flex;
  @media ${props => props.theme.tabletL} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const DesktopContainer = styled.div`
  display: none;
  @media ${props => props.theme.tabletL} {
    display: flex;
    flex-wrap: wrap;
    margin: ${props => props.theme.spacing(4, 0, 4)};
  }
`;
export const MobileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacing(4, 0, 4)};
  @media ${props => props.theme.tabletL} {
    display: none;
  }
`;

export const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 3;
  @media ${props => props.theme.tablet} {
    width: 60%;
  }
`;

export const RecipeImageContainer = styled.div`
  display: flex;
  margin: ${props => props.theme.spacing(2, 3, 0)};
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  @media ${props => props.theme.mobileL} {
    display: flex;
    gap: ${props => props.theme.spacing(4)};
    flex-wrap: no-wrap;
  }
`;

export const RecipeImage = styled.span`
  position: relative;
  min-width: 62px;
  @media ${props => props.theme.laptop} {
    border-radius: 10px;
    margin: 0;
  }
`;

export const StyledProductsText = styled.p`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.text.secondary};
  margin: ${props => props.theme.spacing(0, 0, 2, 4)};
  span {
    display: inline-block;
    margin-right: ${props => props.theme.spacing(1)};
  }
`;
export const StyledProductTitle = styled.span`
  ${props => props.theme.typography.bodyBold}
`;

export const Heading = styled.p`
  ${props => props.theme.typography.heading4}
  color: ${props => props.theme.colors.text.secondary};
`;

export const PlanTitle = styled.p`
  ${props => props.theme.typography.heading6}
  color: ${props => props.theme.colors.text.primary};
  margin: ${props => props.theme.spacing(0, 4)};
`;
