'use client';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import { Content, StyledStoreLocatorMap } from './styles.js';

export const StoreLocatorMap = ({ content, accountId }) => {
  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);

  useEffect(() => {
    if (scriptIsLoaded && window.StoreRocket) {
      const config = {
        selector: '.store-locator-widget',
        account: accountId,
      };

      window.StoreRocket.init(config);
    }
  }, [accountId, scriptIsLoaded]);

  return (
    <StyledStoreLocatorMap>
      <Content>{content}</Content>
      <Script
        src="https://cdn.storerocket.io/widget.js"
        onLoad={() => setScriptIsLoaded(true)}
      />
      <div className="store-locator-widget"></div>
    </StyledStoreLocatorMap>
  );
};

StoreLocatorMap.displayName = 'StoreLocatorMap';

StoreLocatorMap.propTypes = {
  accountId: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};
