import * as yup from 'yup';
import { SplitPanelBody } from '@web/components';
import { nullableImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';

export const component = SplitPanelBody;

export const schema = yup
  .object({
    heading: yup.string(),
    body: richTextFromCMSSchema,
    icon: nullableImageFromCMSSchema,
    eyebrow: yup.string().nullable(),
    level: yup.string(),
  })
  .transform(value => ({
    heading: value.heading,
    body: value.body,
    icon: value.icon,
    eyebrow: value.eyebrow,
    level: value.level,
  }));
