import * as yup from 'yup';
import { PressLogo } from '@web/components';

export const component = PressLogo;

export const schema = yup
  .object({
    src: yup.string().required(),
    alt: yup.string().required(),
    width: yup.number(),
    height: yup.number(),
    quote: yup.string().nullable(),
  })
  .transform(value => ({
    src: value.logo_image.filename,
    alt: value.logo_image.alt || value.logo_image.name,
    width: value.logo_image.width,
    height: value.logo_image.height,
    quote: value.quote,
  }));
