import * as yup from 'yup';
import { extraCardSchema } from './extra_card';

export const extraSectionSchema = yup
  .object({
    heading: yup.string().required(),
    extras: yup.array(extraCardSchema).required(),
  })
  .transform(value => ({
    heading: value.heading,
    extras: value.extras,
  }));
