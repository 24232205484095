import React from 'react';
import PropTypes from 'prop-types';
import { IllustrationPill } from '../IllustrationPill';
import { StyledIllustrationPills, Grid, MobileWrapper } from './styles.js';

export const IllustrationPills = ({ pills }) => {
  return (
    <MobileWrapper>
      <StyledIllustrationPills $bgColor="background.base">
        <Grid gridAmount={pills.length}>
          {pills.map((pill, i) => (
            <IllustrationPill key={i} {...pill} />
          ))}
        </Grid>
      </StyledIllustrationPills>
    </MobileWrapper>
  );
};

IllustrationPills.displayName = 'IllustrationPills';

IllustrationPills.propTypes = {
  pills: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};
