import * as yup from 'yup';
import { AddOnQuickView } from '@web/components';
import { requiredImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';
import { dropdownValuesSchema } from './addon_dropdown_values';

export const component = AddOnQuickView;

export const addonModalSchema = yup
  .object({
    value: yup.string(),
    title: yup.string(),
    productName: yup.string(),
    productType: yup.string(),
    sku: yup.string(),
    subtitle: yup.string(),
    details: richTextFromCMSSchema,
    fullIngredients: richTextFromCMSSchema,
    size: yup.string(),
    serving: richTextFromCMSSchema,
    pricingInfo: yup.object({
      price: yup.string(),
      discountedPrice: yup.string(),
      saveText: yup.string(),
    }),
    image: requiredImageFromCMSSchema,
    nutritionalInformation: yup.array(
      yup.object({
        value: yup.string(),
        label: yup.string(),
      }),
    ),
    dropdownValues: dropdownValuesSchema,
    recurrence: yup.string().nullable(),
    unit: yup.string(),
    badge: yup.string(),
    badgeDropdown: yup.string(),
  })
  .transform(value => ({
    title: value.title,
    value: value.value,
    productName: value.product_name,
    productType: value.product_type,
    sku: value.sku,
    subtitle: value.subtitle,
    details: value.details,
    fullIngredients: value.full_ingredients.body,
    size: value.size,
    image: value.image,
    serving: value.serving,
    pricingInfo: {
      price: value.price,
      discountedPrice: value.discounted_price,
      saveText: value.save_text,
    },
    nutritionalInformation: value.nutritional_information,
    dropdownValues: value.dropdown_values,
    recurrence: value.recurrence,
    unit: value.unit,
    badge: value.badge,
    badgeDropdown: value.badge_dropdown,
  }));
