import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import { Eyebrow } from '@web/atoms';
import { useReporter } from '../../reporter';
import { SplitScreenGridItem } from './SplitScreenGridItem';
import { splitScreenGridButtonClicked } from './events';
import {
  SplitScreenGridContainer,
  StyledSplitScreenGrid,
  MainCopyContainer,
  Headline,
  MainCopy,
  SplitScreenSection,
  Splash,
  GridItemsContainer,
  GridHeadline,
  GridItems,
  ButtonContainer,
  ButtonWrapper,
} from './styles.js';

export const SplitScreenGrid = ({
  headline,
  copy,
  linkTarget,
  linkText,
  splashImage,
  gridItems,
  gridHeadline,
  splashFirst = true,
  rowOnDesktop = false,
  eyebrow,
}) => {
  const reporter = useReporter();
  const trackEvent = splitScreenGridButtonClicked(linkText, linkTarget);

  return (
    <SplitScreenGridContainer>
      <StyledSplitScreenGrid>
        {(headline || copy) && (
          <MainCopyContainer>
            {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
            {headline && <Headline>{headline}</Headline>}
            {copy && <MainCopy>{copy}</MainCopy>}
            {linkText && linkTarget && (
              <ButtonContainer>
                <ButtonWrapper>
                  <LinkButton
                    href={linkTarget}
                    horizontalPadding={19}
                    onClick={() => reporter.tag(trackEvent)}
                  >
                    {linkText}
                  </LinkButton>
                </ButtonWrapper>
              </ButtonContainer>
            )}
          </MainCopyContainer>
        )}
        <SplitScreenSection>
          <Splash splashFirst={splashFirst}>
            <ResponsiveImage
              alt={splashImage.alt}
              src={splashImage.src}
              srcSet={splashImage.srcSet}
              width={splashImage.width}
              height={splashImage.height}
            />
          </Splash>
          <GridItemsContainer>
            {gridHeadline && <GridHeadline>{gridHeadline}</GridHeadline>}
            <GridItems rowOnDesktop={rowOnDesktop}>
              {gridItems.map(
                ({ headline, text, image, alt, badge, backgroundColor }) => (
                  <SplitScreenGridItem
                    headline={headline}
                    text={text}
                    image={image}
                    alt={alt}
                    key={headline}
                    badge={badge}
                    rowOnDesktop={rowOnDesktop}
                    backgroundColor={backgroundColor}
                  />
                ),
              )}
            </GridItems>
          </GridItemsContainer>
        </SplitScreenSection>
      </StyledSplitScreenGrid>
    </SplitScreenGridContainer>
  );
};

SplitScreenGrid.displayName = 'SplitScreenGrid';

SplitScreenGrid.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.string,
  linkTarget: PropTypes.string,
  linkText: PropTypes.string,
  splashImage: PropTypes.object.isRequired,
  gridItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  gridHeadline: PropTypes.string,
  splashFirst: PropTypes.bool,
  rowOnDesktop: PropTypes.bool,
  eyebrow: PropTypes.string,
};
