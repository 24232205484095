'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const StyledBlogLinks = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const ImageWrapper = styled.div`
  display: block;
`;

export const BlogContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.get(props.backgroundColor)};
  border-radius: 32px;
  overflow: hidden;
  margin-top: ${props => props.theme.spacing(4)};

  &:hover {
    box-shadow: 0px 10px 16px rgba(94, 88, 78, 0.1);
  }
`;

export const BlogContent = styled.div`
  margin: ${props => props.theme.spacing(6, 6, 14)};
`;

export const Subtext = styled.p`
  ${props => props.theme.typography.body}
  margin-bottom: ${props => props.theme.spacing(2)};
  color: ${props => props.theme.colors.text.secondary};
`;

export const Topic = styled.p`
  ${props => props.theme.typography.subheading2}
  margin-bottom: ${props => props.theme.spacing(1)};
  color: ${props => props.theme.colors.text.secondary};
`;

export const ClickableBlogTitle = styled(Link)`
  color: ${props => props.theme.colors.get(props.$fontColor)};
  text-decoration: none;
  &::before {
    cursor: pointer;
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;
