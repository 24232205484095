'use client';
import styled, { createGlobalStyle } from 'styled-components';

export const CloseButtonContainer = styled.div`
  top: ${props => props.theme.spacing(4)};
  right: ${props => props.theme.spacing(4)};
  position: absolute;
  z-index: 10000;
`;

export const MobileButtonContainer = styled.div`
  display: block;
  @media ${props => props.theme.tabletS} {
    display: none;
  }
`;
export const DesktopButtonContainer = styled.div`
  display: none;
  @media ${props => props.theme.tabletS} {
    display: block;
  }
`;

export const ModalDialog = styled.div`
  background: ${props => props.theme.colors.background.lightest};
  width: 100%;
  overflow: scroll;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${props => props.theme.laptop} {
    width: unset;
    height: initial;
    padding: ${props => props.theme.spacing(6)};
    overflow: unset;
  }
`;
export const MobilePopUpModalDialog = styled.div`
  background: ${props => props.theme.colors.background.lightest};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props =>
    props.$popupMobileDialoguePadding
      ? props.theme.spacing(props.$popupMobileDialoguePadding)
      : props.theme.spacing(0)};

  @media ${props => props.theme.laptop} {
    width: unset;
    height: initial;
    padding: ${props => props.theme.spacing(0, 5)};
    overflow: unset;
  }
`;

export const Container = styled.div`
  display: ${props => (props.shown ? `flex` : `none`)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: ${props => props.theme.spacing(2)};
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export const GlobalStyle = createGlobalStyle`
  .ModalOverlay {
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    z-index: 10000;

    @media ${props => props.theme.laptop} {
      padding: ${props => props.theme.spacing(3)};
      bottom: 0;
      justify-content: center;
    }
  }

  .ModalContent {
    inset: unset;
    padding: 0;
    position: relative;
    border: 0;
    overflow: hidden;
    width: 100%;
    ${props => (props.mobileFullScreen ? 'height: 100%;' : '')}
    ${props =>
      props.mobileFullScreen
        ? ''
        : 'box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08), 0px 0px 8px 2px rgba(0, 0, 0, 0.08);'}

    @media ${props => props.theme.laptop} {
      max-width: none;
      border-radius: 32px;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08),
        0px 0px 8px 2px rgba(0, 0, 0, 0.08);
      width: initial;
      height: initial;
      max-height: 100vh;
    }
  }

  .ReactModal__Body--open{
    overflow: hidden;
  }

`;
export const MobilePopUpGlobalStyle = createGlobalStyle`
  .ModalOverlay {
    position:fixed;
    top: 0rem;
    left: 0rem;
    right: 0rem;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    z-index: 10000;
    justify-content: center;
    height: 100vh;
    padding: ${props => props.theme.spacing(5)};

    @media ${props => props.theme.laptop} {
      padding: ${props => props.theme.spacing(3)};
      bottom: 0;
    }
  }

  .ModalContent {
    inset: unset;
    padding: 0;
    position: relative;
    border: 0;
    border-radius: 32px;
    overflow: hidden;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08), 0px 0px 8px 2px rgba(0, 0, 0, 0.08);

    @media ${props => props.theme.mobileL} {
      max-width: none;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08),
        0px 0px 8px 2px rgba(0, 0, 0, 0.08);
      width: initial;
      height: initial;
      max-height: 100vh;
    }
  }

  .ReactModal__Body--open{
    overflow: hidden;
  }
`;
