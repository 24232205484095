'use client';
import styled, { css } from 'styled-components';

const FULL_WIDTH = '875px';

export const StyledAddOnQuickViewContainer = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.laptop} {
    border-radius: 32px;
    min-height: 452px;
    max-width: ${FULL_WIDTH};
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  @media ${({ theme }) => theme.laptop} {
    flex-direction: row;
  }
`;

const imageContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  @media ${({ theme }) => theme.laptop} {
    border-radius: 32px;
  }
`;

export const LargeImageContainer = styled.div`
  ${imageContainer}
  width: 80%;
  margin: ${({ theme }) => theme.spacing(4)} auto;
  flex: none;
  display: none;

  @media ${({ theme }) => theme.laptop} {
    width: 100%;
    max-width: 500px;
    display: flex;
  }
`;

export const MobileImageContainer = styled.div`
  ${imageContainer}
  margin-bottom: 0;
  height: 100%;
  display: block;
  flex: none;

  @media ${({ theme }) => theme.laptop} {
    display: none;
    max-width: 300px;
    margin: ${({ theme }) => theme.spacing(6)} auto;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing(4, 4, 40)};

  @media ${({ theme }) => theme.laptop} {
    max-width: none;
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(8)};
    padding-bottom: initial;
    padding-right: initial;
  }
`;

export const Headline = styled.h1`
  ${({ theme }) => theme.typography.heading3};
  @media ${({ theme }) => theme.laptop} {
    ${({ theme }) => theme.typography.heading3};
    padding-bottom: 0;
    text-align: left;
    max-width: 300px;
  }
`;

export const Subheadline = styled.h2`
  ${({ theme }) => theme.typography.heading5};
  padding-bottom: ${({ theme }) => theme.spacing(1)};

  @media ${({ theme }) => theme.laptop} {
    text-align: left;
    padding-bottom: 0;
  }
`;

export const SizeAndPrice = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  @media ${({ theme }) => theme.laptop} {
    flex-direction: row;
    justify-content: left;
    ${({ theme }) => theme.typography.body};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
    text-align: left;
  }
`;

export const Size = styled.div`
  color: ${({ theme }) => theme.colors.text.tertiary};

  @media ${({ theme }) => theme.laptop} {
    margin-right: ${({ theme }) => theme.spacing(4)};
  }
`;

export const Price = styled.div`
  font-weight: bold;

  .full-price {
    color: ${({ theme }) => theme.colors.text.tertiary};
    margin: 0 ${({ theme }) => theme.spacing(2)};
  }

  .percentage-off {
    font-weight: normal;
  }
`;

export const DisplayedPrice = styled.span`
  ${({ theme }) => theme.typography.body}
  margin-right: ${({ theme }) => theme.spacing(1)};
  font-weight: bold;
`;

export const SavePercentage = styled.span`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.status.errorPrimary};
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const StrikeThroughPrice = styled.span`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.status.errorPrimary};
  margin-right: ${({ theme }) => theme.spacing(1)};
  text-decoration-line: line-through;
  text-transform: capitalize;
`;

export const TabNav = styled.div`
  margin: ${({ theme }) => theme.spacing(4)} 0;
  text-align: center;

  @media ${({ theme }) => theme.laptop} {
    margin: ${({ theme }) => theme.spacing(6)} 0;
    text-align: left;
  }
`;

export const TabButton = styled.button`
  ${({ theme }) => theme.typography.subheading2};
  border: 0;
  color: ${props =>
    props.active
      ? props.theme.colors.text.primary
      : props.theme.colors.border.fur};
  padding: 2px;
  margin-right: ${({ theme }) => theme.spacing(2)};
  text-transform: uppercase;
  user-select: none;
  border-bottom: ${({ active, theme }) =>
    active ? `1px solid ${theme.colors.text.primary}` : '0'};
  background-color: transparent;

  @media ${({ theme }) => theme.laptop} {
    margin-right: ${({ theme }) => theme.spacing(6)};
  }
  &:focus {
    border-radius: ${props => props.theme.spacing(2)};
    outline: 2px solid ${({ theme }) => theme.colors.border.dark};
  }
`;

export const Body = styled.div`
  ${({ theme }) => theme.typography.body};
  text-align: left;
  width: 100%;
  min-height: 156px;

  @media ${({ theme }) => theme.laptop} {
    width: 343px;
    min-height: 132px;
  }
`;

export const StyledForm = styled.form`
  max-width: none;
  width: 100%;
  @media ${({ theme }) => theme.laptop} {
    max-width: 415px;
    padding-bottom: none;
  }
`;

export const Dropdowns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(6)} auto;

  > div {
    width: 100%;

    &:first-of-type {
      margin-right: 1rem;
      max-width: 120px;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: ${props => props.theme.spacing(4)};
  padding-right: ${props => props.theme.spacing(8)};
  padding-bottom: ${props => props.theme.spacing(4)};
  background: linear-gradient(0deg, white, transparent);
  height: 100px;

  @media ${props => props.theme.laptop} {
    width: initial;
    position: initial;
    bottom: initial;
    padding: initial;
    background: initial;
    height: initial;
    align-items: initial;
  }
`;

export const ButtonSizing = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacing(2)};
  width: 19rem;
  margin: auto;

  @media ${props => props.theme.laptop} {
    width: initial;
  }

  &:focus-within {
    border-radius: ${props => props.theme.spacing(2)};
    outline: 2px solid ${({ theme }) => theme.colors.neutral.darkestFur};
  }
`;
