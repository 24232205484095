'use client';
import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing(8)};
  background-color: ${({ theme }) => theme.colors.background.lightest};
  padding: ${({ theme }) => theme.spacing(4, 6)};

  @media ${({ theme }) => theme.mobileS} {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media ${({ theme }) => theme.tabletL} {
    flex-direction: column;
  }
`;

export const CardContainerHeader = styled.div`
  width: 95%;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const CardContainerHeaderText = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};

  @media ${({ theme }) => theme.mobileS} {
    ${({ theme }) => theme.typography.heading2};
  }
  @media ${({ theme }) => theme.tabletL} {
    ${({ theme }) => theme.typography.heading1};
  }
`;

export const CardTabsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1.5px ${({ theme }) => theme.colors.text.tertiary};
  margin: auto;
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2)};
`;

export const CardTab = styled.button`
  color: ${props =>
    props.tabSelected
      ? props.theme.colors.text.secondary
      : props.theme.colors.text.tertiary};
  border-bottom: ${props => (props.tabSelected ? 'solid 2px black ' : 'none')};
  padding: ${props => props.theme.spacing(1)};
  border-top: none;
  border-left: none;
  border-right: none;
  background: none;
  text-transform: uppercase;
  text-align: center;

  @media ${({ theme }) => theme.mobileS} {
    ${({ theme }) => theme.typography.subheading2};
    margin: ${({ theme }) => theme.spacing(0, 1)};
  }
  @media ${({ theme }) => theme.tabletL} {
    ${({ theme }) => theme.typography.subheading1};
    margin: ${({ theme }) => theme.spacing(0, 3)};
  }
`;

export const CardContainerContent = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(4)};
  flex-wrap: nowrap;

  @media ${({ theme }) => theme.mobileS} {
    flex-direction: column;
  }
  @media ${({ theme }) => theme.tabletL} {
    flex-direction: row;
    padding-top: ${({ theme }) => theme.spacing(8)};
  }
`;

export const CardContentImagesContainer = styled.div`
  display: flex;
  flex: 40%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(6)};

  @media ${({ theme }) => theme.mobileS} {
    margin: ${({ theme }) => theme.spacing(4)} auto auto;
  }
  @media ${({ theme }) => theme.tabletL} {
    margin: ${({ theme }) => theme.spacing(6)} auto auto;
  }
`;

export const CardContentTextContainer = styled.div`
  display: flex;
  flex: 60%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  text-align: left;
  width: 100%;
  margin: auto;
`;

export const CardContentTextHeading = styled.p`
  ${({ theme }) => theme.typography.subheading1};
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const CardContentTextParagraph = styled.div`
  ${({ theme }) => theme.typography.body};
  padding: ${({ theme }) => theme.spacing(1, 0)};
  margin: ${({ theme }) => theme.spacing(1, 0)};
  text-align: left;

  @media ${({ theme }) => theme.mobileS} {
    width: 100%;
  }
  @media ${({ theme }) => theme.tabletL} {
    width: 90%;
  }
`;

export const PackSizeGrid = styled.div`
  display: flex;
  flex-direction: row;
  @media ${({ theme }) => theme.mobileS} {
    width: 100%;
    justify-content: space-between;
  }
  @media ${({ theme }) => theme.tabletL} {
    justify-content: unset;
  }
`;

export const PackSizeGridItem = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};

  & > p {
    padding: ${({ theme }) => theme.spacing(0, 2)};
  }

  &:first-of-type {
    color: ${({ theme }) => theme.colors.text.primary};
    ${({ theme }) => theme.typography.bodyBold};
  }
`;

export const CardContentTextIngredientsParagraph = styled(
  CardContentTextParagraph,
)`
  p:last-of-type {
    ${({ theme }) => theme.typography.label};
    color: ${({ theme }) => theme.colors.text.tertiary};

    @media ${({ theme }) => theme.mobileS} {
      margin: ${({ theme }) => theme.spacing(6, 0, 0)};
    }
    @media ${({ theme }) => theme.tabletL} {
      margin: ${({ theme }) => theme.spacing(6, 0)};
    }
  }
`;

export const GAGrid = styled.div`
  display: flex;
  flex-direction: row;
  @media ${({ theme }) => theme.mobileS} {
    width: 100%;
    justify-content: space-between;
  }
`;

export const GridSpan = styled.span`
  white-space: nowrap;
  text-align: left;
  color: ${({ theme }) => theme.colors.text.secondary};

  &:first-of-type {
    color: ${({ theme }) => theme.colors.text.primary};
    ${({ theme }) => theme.typography.bodyBold};
    margin-left: 0px;
  }

  &:last-of-type {
    display: none;
  }

  margin: auto;
`;

export const ContentTextGrid = styled.div`
  max-width: 1440px;
  margin: auto;
  display: grid;
  column-gap: 12px;
  grid-template-columns: repeat(1, 1fr);

  @media ${({ theme }) => theme.mobileS} {
    row-gap: 0px;
  }
  @media ${({ theme }) => theme.tabletL} {
    row-gap: 12px;
  }
`;

export const CardContentImage = styled.div`
  margin: auto;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const CardContentImageHelperText = styled.p`
  padding: ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => theme.typography.bodyBold};
  text-align: center;

  @media ${({ theme }) => theme.mobileS} {
    width: 85%;
  }
  @media ${({ theme }) => theme.tabletL} {
    width: 65%;
  }
`;

export const GridItem = styled.p`
  display: flex;
  margin: auto;
`;

export const FlexItems = styled.div`
  margin: auto;
  display: flex;
  width: 100%;

  @media ${({ theme }) => theme.mobileS} {
    flex-direction: column;
  }
  @media ${({ theme }) => theme.tabletL} {
    flex-direction: row;
  }
`;
