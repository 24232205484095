export const testimonialsButtonClicked = (text, target) => ({
  type: 'Testimonials Component Button Clicked',
  data: {
    text,
    target,
  },
});

export const testimonialsCarouselTurn = target => ({
  type: 'Testimonial Carousel Turn',
  data: {
    target,
  },
});
