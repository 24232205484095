'use client';
import styled, { css } from 'styled-components';

export const StyledReasonBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Headline = styled.h4`
  ${props => props.theme.typography.subheading1}
  margin-top: ${props => props.theme.spacing(10)};
`;

export const Subheadline = styled.div`
  ${props => props.theme.typography.body}
  margin-top: ${props => props.theme.spacing(2)};
`;

export const ReasonNumber = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.hero.spinach};
  border-radius: 35px;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 70px;
  ${props =>
    props.number === 2 &&
    css`
      background-color: ${props.theme.colors.hero.sweetPotato};
    `}
  ${props =>
    props.number === 3 &&
    css`
      background-color: ${props.theme.colors.border.fur};
    `}
`;

export const NumberText = styled.span`
  ${props => props.theme.typography.heading2};
  color: ${props => props.theme.colors.text.secondary};
`;

export const ImageContainer = styled.div`
  max-width: 110px;
`;
