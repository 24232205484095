import * as yup from 'yup';

export const schema = yup
  .object({
    text: yup.string().required(),
    component: yup.string().required(),
  })
  .transform(value => ({
    text: value.button_text,
    component: value.component,
  }));
