import * as yup from 'yup';
import { AddOnSummaryCards } from '@web/molecules';
import { schema as addOnSummaryCardSchema } from './add_on_summary_card';

export const component = AddOnSummaryCards;

export const schema = yup
  .object({
    title: yup.string(),
    cards: yup.array(addOnSummaryCardSchema),
  })
  .transform(value => ({
    title: value.title,
    cards: value.cards,
    component: 'add_on_summary_cards',
  }));
