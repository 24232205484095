import * as yup from 'yup';
import { ResponsiveImage } from '@web/molecules';
import { getDimensions } from '../images';

export const component = ResponsiveImage;

export const schema = yup
  .object({
    src: yup.string().required(),
    alt: yup.string().required(),
    width: yup.number().required(),
    height: yup.number().required(),
  })
  .transform(value => {
    if (!value) {
      return null;
    }

    const src = value.asset.filename;
    const alt = value.asset.name || value.asset.alt;
    const { width, height } = getDimensions(src);
    return {
      src,
      alt,
      width,
      height,
    };
  });
