'use client';
import styled from 'styled-components';

export const StyledFactsTable = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.get(props.background)};
  display: flex;
  width: 100vw;
  flex-direction: column;
  padding-bottom: ${props => props.theme.spacing(4)};
`;

export const Container = styled.div`
  max-width: 1320px;
  align-items: center;
  color: ${props => props.theme.colors.text.secondary};
  display: block;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;
  margin-bottom: ${props => props.theme.spacing(4)};
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  @media ${props => props.theme.mobileL} {
    padding: ${props => props.theme.spacing(0, 12)};
  }
  @media ${props => props.theme.tabletL} {
    padding: ${props => props.theme.spacing(0, 12)};
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 1900px;
  margin: ${props => props.theme.spacing(12, 0)};
`;

export const TableColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props =>
    props.highlighted
      ? props => props.theme.colors.secondary.chia
      : props => props.theme.colors.background.base};
  width: 32vw;
  max-width: 600px;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-radius: ${props => (props.highlighted ? '16px' : '0px')};
  border-right: solid 0.5px
    ${props =>
      props.highlighted
        ? props => props.theme.colors.border.bone
        : props => props.theme.colors.border.fur};
  position: ${props => (props.highlighted ? 'sticky' : 'relative')};
  z-index: ${props => (props.highlighted ? '1' : '0')};
  left: 135px;
  &:last-child {
    border-right: none;
  }
  @media ${props => props.theme.mobileL} {
    min-width: 150px;
    left: ${props => (props.highlighted ? '226px' : '0vw')};
    flex: 1;
    width: ${props =>
      props.highlighted && props.items === 2
        ? '30%'
        : props.highlighted && props.items === 3
        ? '25%'
        : '24%'};
  }
  @media ${props => props.theme.tabletL} {
    left: ${props => (props.highlighted ? '200px' : '0vw')};
    flex: 1;
    width: ${props =>
      props.highlighted && props.items === 2
        ? '32%'
        : props.highlighted && props.items === 3
        ? '22%'
        : '12%'};
  }
  @media (min-width: 1321px) {
    left: ${props => (props.highlighted ? 'calc((100vw / 2) - 948px)' : '0vw')};
    width: ${props =>
      props.highlighted && props.items === 2
        ? '25%'
        : props.highlighted && props.items === 3
        ? '17%'
        : '12%'};
  }
`;

export const ColumnItems = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.background.base};
  position: absolute;
  left: 20;
  z-index: 2;
  flex: 50%;
  flex-direction: column;
  width: 135px;
  @media ${props => props.theme.mobileL} {
    margin-left: ${props => props.theme.spacing(-6)};
    width: 250px;
  }
  @media ${props => props.theme.tabletL} {
    margin-left: ${props => props.theme.spacing(-12)};
    width: 400px;
  }
`;

export const ColumnsContainer = styled.div`
  display: flex;
  z-index: 1;
  margin-left: 0px;
  width: 122%;
  @media ${props => props.theme.mobileL} {
    margin-left: 225px;
  }
  @media ${props => props.theme.tabletL} {
    margin-left: 354px;
  }
`;

// Comparison Chart Item Styles
export const StyledComparisonChartItem = styled.div`
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-height: 80px;
  height: 130px;
  padding: ${props => props.theme.spacing(2, 2)};
  &:nth-child(n + 2) {
    border-top: solid 0.5px #a69991;
  }
  &:first-child {
    height: 60px;
  }
  @media ${props => props.theme.laptop} {
    height: 100px;
  }
`;

export const TableColumnItem = styled.div`
  display: flex;
  padding: ${props => props.theme.spacing(0, 2)};
  width: 100%;
  justify-content: center;
  color: ${props =>
    props.highlighted
      ? props.theme.colors.background.lightest
      : props.theme.colors.text.primary};
  align-items: center;
  min-height: 80px;
  height: 130px;
  border-top: solid 0.5px
    ${props =>
      props.highlighted
        ? props.theme.colors.background.bone
        : props.theme.colors.border.fur};
  &:first-child {
    border-top: none;
    height: 60px;
  }
  @media ${props => props.theme.laptop} {
    height: 100px;
    padding: ${props => props.theme.spacing(0, 2)};
  }
`;

export const ChartItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  padding-right: ${props => props.theme.spacing(1)};
  text-align: left;
  flex-wrap: wrap;
  color: ${props => props.theme.colors.text.primary};
`;

export const HeaderThree = styled.h3`
  ${props => props.theme.typography.heading6}
  font-weight: normal;
  font-size: 16px;
  @media ${props => props.theme.laptop} {
    font-size: 20px;
  }
`;

export const Paragraph = styled.p`
  ${props => props.theme.typography.body};
  font-size: 10px;
  text-align: left;
  margin: ${props => props.theme.spacing(1, 0)};
  @media ${props => props.theme.laptop} {
    font-size: 16px;
  }
`;

export const ChartItemIcons = styled.div`
  display: flex;
  height: 100%;
  flex: 0 0 100px;
  justify-self: flex-end;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.background.dark};
  @media ${props => props.theme.laptop} {
    flex-basis: 135px;
  }
`;

export const ColumnHeader = styled.h5`
  ${props => props.theme.typography.heading5}
  font-weight: bold;
`;

export const CarouselDotsContainer = styled.div`
  display: flex;
  @media ${props => props.theme.tabletS} {
    display: none;
  }
`;
