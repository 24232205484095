'use client';
import styled from 'styled-components';
import { generateContentIconStyle } from '@ui/icons';

export const StyledRadioInput = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ theme }) => theme.spacing(4)};
  width: 100%;
`;

export const Label = styled.label`
  align-items: center;
  background-color: ${({ checked, variant, theme }) =>
    checked && variant !== 'light'
      ? theme.colors.background.dark
      : checked && variant === 'light'
      ? theme.colors.secondary.egg
      : !checked && variant === 'light'
      ? theme.colors.withAlpha('border.fur', 0.2)
      : theme.colors.background.lightest};
  border: 2px solid
    ${({ error, disabled, theme }) =>
      error
        ? theme.colors.status.errorPrimary
        : disabled
        ? 'transparent'
        : theme.colors.border.dark};
  border-radius: 500px;
  color: ${({ checked, variant, theme }) =>
    checked && variant !== 'light'
      ? theme.colors.text.lightest
      : checked && variant === 'light'
      ? theme.colors.text.primary
      : theme.colors.text.primary};
  cursor: pointer;
  display: grid;
  grid-template-columns: 30px 1fr;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(4)};
  text-align: center;

  ${({ theme }) => theme.typography.body}

  &:hover,
  &:focus-within {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    border: 2px solid
      ${({ error, theme, disabled }) =>
        error
          ? theme.colors.status.errorPrimary
          : disabled
          ? 'transparent'
          : theme.colors.hero.spinach};
  }

  &::before {
    ${({ checked }) =>
      checked
        ? generateContentIconStyle('radiobox-marked')
        : generateContentIconStyle('radiobox-blank')};
    width: 24px;
    height: 24px;
    background-color: ${({ checked, variant, theme }) =>
      checked && variant !== 'light'
        ? theme.colors.background.lightest
        : checked && variant === 'light'
        ? theme.colors.border.dark
        : theme.colors.border.dark};
  }
  text-wrap: nowrap;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const StyledFieldset = styled.fieldset`
  border-width: 0px;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const StyledLegend = styled.legend`
  position: absolute;
  height: 1px;
  width: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;
