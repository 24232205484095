import DentalChews from '../../images/our-food/addons/Dental-Chews.jpg';
import BellyRubsImage from '../../images/our-food/addons/Probiotic-Chews.jpg';

export const quantityDropdownTreatProps = {
  name: 'quantity',
  label: 'Quantity',
  noSelectionLabel: 'Select',
  options: [
    { label: '1 Bag', value: 1 },
    { label: '2 Bags', value: 2 },
    { label: '3 Bags', value: 3 },
    { label: '4 Bags', value: 4 },
    { label: '5 Bags', value: 5 },
    { label: '6 Bags', value: 6 },
  ],
};
export const frequencyDropdownProps = {
  name: 'frequency',
  label: 'Frequency',
  noSelectionLabel: 'Select',
  options: [
    { label: 'Every box', value: 1 },
    { label: 'Every other box', value: 2 },
  ],
};

export const quantityDropdownSupplementProps = {
  name: 'quantity',
  label: 'Quantity',
  noSelectionLabel: 'Select',
  options: [
    { label: '1 Jar', value: 1 },
    { label: '2 Jars', value: 2 },
    { label: '3 Jars', value: 3 },
    { label: '4 Jars', value: 4 },
    { label: '5 Jars', value: 5 },
    { label: '6 Jars', value: 6 },
  ],
};

export const ADDON_SKUS = {
  beefjerky: 'JERKYSTRIPS-BE',
  chickenjerky: 'JERKYSTRIPS-CH',
  turkeyjerky: 'JERKYSTRIPS-TU',
  zoomies: 'ZOOMIES01',
  bellyrubs: 'BELLYRUBS01',
  chillpills: 'CHILLPILLS01',
  sweetpotatoes: 'SWEETPOTATOSLICES1',
  dentalchewxs: 'SUPP-DENT-XS',
  dentalchewsm: 'SUPP-DENT-SM',
  dentalchewmd: 'SUPP-DENT-MD',
  dentalchewlg: 'SUPP-DENT-LG',
  trainingtreatspb: 'TREAT-PBTRAINING-08',
  trainingtreatsparm: 'TREAT-PARMTRAINING-08',
  sweetpotatocrunch: 'TREAT-SWEETPOTATOCRNCH-08',
  applecrunchtreat: 'TREAT-APPLECRNCH-08',
  skinsupplement: 'SUPP-SKIN',
  immunesupplement: 'SUPP-IMMUNE',
};

export const dropdowns = [
  quantityDropdownTreatProps,
  frequencyDropdownProps,
  quantityDropdownSupplementProps,
];
export const allExtras = [
  {
    label: 'Add to your plan',
    sku: ADDON_SKUS.bellyrubs,
    productName: 'Belly Rubs',
    title: 'Probiotic supplement',
    productType: 'SP',
    size: '1 jar (60 chews)',
    pricingInfo: { price: '20', discountedPrice: '15', saveText: '(Save 25%)' },
    image: {
      src: BellyRubsImage.src,
      alt: 'Belly Rubs Supplement Image',
      width: 128,
      height: 128,
    },
    dropdowns: [frequencyDropdownProps, quantityDropdownTreatProps],
    defaultDropdownValues: {
      quantity: 1,
      frequency: 2,
    },
  },
  {
    label: 'Add to your plan',
    value: 'Dental Chews (XS)',
    sku: 'SUPP-DENT-XS',
    productName: 'Dental Chews (XS)',
    title: 'Dental Chews',
    productType: 'SP',
    defaultDropdownValues: {
      quantity: 1,
      frequency: 1,
    },
    dropdowns: [frequencyDropdownProps, quantityDropdownTreatProps],
    size: '1 bag (5oz.)',
    pricingInfo: { price: '24', discountedPrice: '18', saveText: '(Save 33%)' },
    image: {
      src: DentalChews.src,
      alt: 'Dental Chews Image',
      width: DentalChews.width,
      height: DentalChews.height,
    },
  },
  {
    label: 'Add to your plan',
    value: 'Dental Chews (SM)',
    sku: 'SUPP-DENT-SM',
    productName: 'Dental Chews (SM)',
    title: 'Dental Chews',
    productType: 'SP',
    defaultDropdownValues: {
      quantity: 1,
      frequency: 1,
    },
    dropdowns: [frequencyDropdownProps, quantityDropdownTreatProps],
    size: '1 bag (5oz.)',
    pricingInfo: { price: '24', discountedPrice: '18', saveText: '(Save 33%)' },
    image: {
      src: DentalChews.src,
      alt: 'Dental Chews Image',
      width: DentalChews.width,
      height: DentalChews.height,
    },
  },
  {
    label: 'Add to your plan',
    value: 'Dental Chews (MD)',
    sku: 'SUPP-DENT-MD',
    productName: 'Dental Chews (MD)',
    title: 'Dental Chews',
    productType: 'SP',
    defaultDropdownValues: {
      quantity: 1,
      frequency: 1,
    },
    dropdowns: [frequencyDropdownProps, quantityDropdownTreatProps],
    size: '1 bag (5oz.)',
    pricingInfo: { price: '27', discountedPrice: '18', saveText: '(Save 33%)' },
    image: {
      src: DentalChews.src,
      alt: 'Dental Chews Image',
      width: DentalChews.width,
      height: DentalChews.height,
    },
  },
  {
    label: 'Add to your plan',
    value: 'Dental Chews (LG)',
    sku: 'SUPP-DENT-LG',
    productName: 'Dental Chews (LG)',
    title: 'Dental Chews',
    productType: 'SP',
    defaultDropdownValues: {
      quantity: 1,
      frequency: 1,
    },
    dropdowns: [frequencyDropdownProps, quantityDropdownTreatProps],
    size: '1 bag (5oz.)',
    pricingInfo: { price: '27', discountedPrice: '18', saveText: '(Save 33%)' },
    image: {
      src: DentalChews.src,
      alt: 'Dental Chews Image',
      width: DentalChews.width,
      height: DentalChews.height,
    },
  },
  {
    label: 'Add to your plan',
    value: 'Dental Chews (XL)',
    sku: 'SUPP-DENT-XL',
    productName: 'Dental Chews (XL)',
    title: 'Dental Chews',
    productType: 'SP',
    defaultDropdownValues: {
      quantity: 1,
      frequency: 1,
    },
    dropdowns: [frequencyDropdownProps, quantityDropdownTreatProps],
    size: '1 bag (5oz.)',
    pricingInfo: { price: '27', discountedPrice: '18', saveText: '(Save 33%)' },
    image: {
      src: DentalChews.src,
      alt: 'Dental Chews Image',
      width: DentalChews.width,
      height: DentalChews.height,
    },
  },
];

export const selectedExtrasDentalChew = [
  {
    sku: 'JERKYSTRIPS-CH',
    dropdownValues: {
      quantity: 2,
      frequency: 1,
    },
  },
  {
    sku: 'SUPP-DENT-MD',
    dropdownValues: {
      quantity: 2,
      frequency: 1,
    },
  },
];

export const selectedExtras = [
  {
    sku: ADDON_SKUS.bellyrubs,
    dropdownValues: {
      quantity: 2,
      frequency: 1,
    },
  },
];

export const DENTAL_CHEWS_SKUS = [
  'SUPP-DENT-XS',
  'SUPP-DENT-SM',
  'SUPP-DENT-MD',
  'SUPP-DENT-LG',
  'SUPP-DENT-XL',
];
