import * as yup from 'yup';
import { HoverImageCard } from '@web/components';
import {
  nullableImageFromCMSSchema,
  requiredImageFromCMSSchema,
  inlineRichTextFromCMSSchema,
} from '../schemas';

export const component = HoverImageCard;

export const schema = yup
  .object({
    header: yup.string(),
    body: inlineRichTextFromCMSSchema,
    image: requiredImageFromCMSSchema,
    hoverImage: nullableImageFromCMSSchema,
    badge: yup.string(),
    level: yup.string(),
  })
  .transform(value => ({
    header: value.header,
    body: value.body,
    image: value.image,
    hoverImage: value.hover_image,
    badge: value.badge,
    level: value.level,
  }));
