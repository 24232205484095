import * as yup from 'yup';
import { TimelineMarker } from '@web/components/Timeline/TimelineMarker';
import { richTextFromCMSSchema } from '../schemas';

export const component = TimelineMarker;

export const schema = yup
  .object({
    date: yup.string().required(),
    heading: yup.string(),
    body: richTextFromCMSSchema,
  })
  .transform(value => ({
    date: value.date,
    heading: value.heading,
    body: value.body,
  }));
