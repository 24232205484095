import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import {
  StyledPhotosGrid,
  Inner,
  Headline,
  Grid,
  GridItem,
  PhotoWrapper,
  ItemDescription,
  ButtonContainer,
  ButtonWrapper,
} from './styles.js';

export const PhotosGrid = ({ heading, photos, link }) => {
  return (
    <StyledPhotosGrid>
      <Inner>
        <Headline>{heading}</Headline>
        <Grid>
          {photos.map(photo => (
            <GridItem key={photo.description}>
              <PhotoWrapper>
                <ResponsiveImage
                  alt={photo.alt}
                  src={photo.src}
                  fill={true}
                  objectFit="cover"
                />
              </PhotoWrapper>
              <ItemDescription>{photo.description}</ItemDescription>
            </GridItem>
          ))}
        </Grid>
        {link && (
          <ButtonContainer>
            <ButtonWrapper>
              <CtaButton variant="egg" href={link.target}>
                {link.text}
              </CtaButton>
            </ButtonWrapper>
          </ButtonContainer>
        )}
      </Inner>
    </StyledPhotosGrid>
  );
};

PhotosGrid.displayName = 'PhotosGrid';

PhotosGrid.propTypes = {
  heading: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      alt: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
};
