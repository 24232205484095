import React from 'react';
import PropTypes from 'prop-types';
import { StyledSectionIntro, Header, Subheader } from './styles.js';

export const SectionIntro = ({
  headline = null,
  subheadline,
  maxWidth = null,
  fontColor,
  headingLevel = 'h2',
}) => {
  return (
    <StyledSectionIntro maxWidth={maxWidth} $fontColor={fontColor}>
      {headline && <Header as={headingLevel}>{headline}</Header>}
      <Subheader>{subheadline}</Subheader>
    </StyledSectionIntro>
  );
};
SectionIntro.displayName = 'SectionIntro';

SectionIntro.propTypes = {
  headline: PropTypes.string,
  subheadline: PropTypes.node.isRequired,
  maxWidth: PropTypes.number,
  fontColor: PropTypes.string,
  headingLevel: PropTypes.string,
};
