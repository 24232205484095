export const variants = {
  bone: {
    active: {
      background: 'neutral.bone',
      border: 'neutral.bone',
    },
  },
  darkerFur: {
    active: {
      background: 'neutral.darkerFur',
      border: 'neutral.darkerFur',
    },
  },
  darkestFur: {
    active: {
      background: 'neutral.darkestFur',
      border: 'neutral.darkestFur',
    },
  },
};
