import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledNutritionalInfo,
  NutritionTable,
  NutritionTableBody,
  NutritionTableRow,
  NutritionTableData,
  NutritionTableHeader,
} from './styles';

export const NutritionalInfo = ({
  nutritionalInfo,
  role = 'tabpanel',
  id,
  ariaLabelledby,
  hidden = false,
}) => (
  <StyledNutritionalInfo
    role={role}
    id={id}
    aria-labelledby={ariaLabelledby}
    hidden={hidden}
  >
    <NutritionTable>
      <NutritionTableBody>
        {nutritionalInfo.map(item => (
          <NutritionTableRow key={`${item.label}-${item.value}`}>
            <NutritionTableHeader scope="row">
              {item.label}
            </NutritionTableHeader>
            <NutritionTableData>{item.value}</NutritionTableData>
          </NutritionTableRow>
        ))}
      </NutritionTableBody>
    </NutritionTable>
  </StyledNutritionalInfo>
);

NutritionalInfo.displayName = 'NutritionInfo';
NutritionalInfo.propTypes = {
  nutritionalInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  role: PropTypes.string,
  id: PropTypes.string,
  ariaLabelledby: PropTypes.string,
  hidden: PropTypes.bool,
};
