'use client';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: ${props => props.theme.spacing(3, 3, 6)};
  max-width: 1440px;
  margin: ${props => props.theme.spacing(2)} auto;
`;

export const StyledSubHeading = styled.h3`
  ${props => props.theme.typography.body}
  text-align: center;
  padding: ${props => props.theme.spacing(2)};
`;

export const StyledHeadingContainer = styled.div`
  padding: ${props => props.theme.spacing(2)};
  margin: ${props => props.theme.spacing(2)};
`;

export const StyledProductValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${props => props.theme.spacing(2)};
  margin: auto;
  gap: ${props => props.theme.spacing(3)};
  align-items: center;
  justify-content: center;

  @media ${props => props.theme.mobileS} {
    flex-direction: column;
  }

  @media ${props => props.theme.tabletS} {
    flex-direction: row;
  }
`;
