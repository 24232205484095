'use client';
import styled from 'styled-components';

export const StyledValuesLetter = styled.div``;

export const Body = styled.p`
  ${props => props.theme.typography.heading5}
  margin: ${props => props.theme.spacing(10, 'auto')};
  max-width: 480px;
  padding: ${props => props.theme.spacing(0, 4)};

  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(10, 'auto')};
    padding: 0;
  }
`;

export const SectionList = styled.div`
  margin-top: ${props => props.theme.spacing(17)};
`;

export const SectionHeader = styled.h4`
  ${props => props.theme.typography.subheading1}
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const SectionBody = styled.p`
  ${props => props.theme.typography.heading3}
  margin: 0 auto;
  max-width: 700px;
`;

export const ImagesBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing(15)};
`;

export const ImageWrapper = styled.div`
  border-radius: 32px;
  line-height: 0;
  overflow: hidden;

  &:last-child {
    margin-left: ${props => props.theme.spacing(-7)};
  }
`;

export const Section = styled.div`
  margin: ${props => props.theme.spacing(10, 'auto', 0)};
  &:nth-child(even) {
    ${ImageWrapper} {
      &:first-child {
        z-index: 20;
      }
    }
  }
`;

export const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(7)};
`;

export const DetailItem = styled.div`
  padding: ${props => props.theme.spacing(7, 4)};
  text-align: center;
  width: 100%;
  @media ${props => props.theme.tablet} {
    width: ${props => ([3, 5, 6].includes(props.count) ? 33.33 : 40)}%;
  }
`;

export const DetailDescription = styled.p`
  ${props => props.theme.typography.body}
  margin-top: ${props => props.theme.spacing(2)};
`;
