import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledIllustrationPill,
  InfoContainer,
  PillText,
  PillImage,
} from './styles.js';

export const IllustrationPill = ({ icon, text, backgroundColor }) => {
  return (
    <StyledIllustrationPill backgroundColor={backgroundColor}>
      <InfoContainer>
        <PillText>{text}</PillText>
        <PillImage>
          <ResponsiveImage {...icon} />
        </PillImage>
      </InfoContainer>
    </StyledIllustrationPill>
  );
};
IllustrationPill.displayName = 'IllustrationPill';

IllustrationPill.propTypes = {
  icon: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};
