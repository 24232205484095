'use client';
import styled from 'styled-components';

export const StyledCommunityLetter = styled.div``;

export const Body = styled.p`
  ${props => props.theme.typography.heading5}
  margin: ${props => props.theme.spacing(10, 'auto')};
  max-width: 480px;
  padding: ${props => props.theme.spacing(0, 4)};

  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(10, 'auto')};
    padding: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

export const ImagesBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing(15)};
`;

export const ImageWrapper = styled.div`
  border-radius: 32px;
  line-height: 0;
  overflow: hidden;

  &:last-child {
    margin-left: ${props => props.theme.spacing(-7)};
  }
`;
