import * as yup from 'yup';
import { HoverImageCards } from '@web/components';
import { inlineRichTextFromCMSSchema, linkSchema } from '../schemas';
import { schema as hoverImageCardSchema } from './hover_image_card';

export const component = HoverImageCards;

export const schema = yup
  .object({
    headline: yup.string().nullable(),
    subheadline: inlineRichTextFromCMSSchema,
    items: yup.array(hoverImageCardSchema).required(),
    isCarousel: yup.bool(),
    link: linkSchema,
    buttonVariant: yup.string(),
    backgroundColor: yup.string(),
    eyebrow: yup.string().nullable(),
    level: yup.string().nullable(),
  })
  .transform(value => ({
    headline: value.headline,
    subheadline: value.subheadline,
    items: value.items,
    isCarousel: value.is_carousel,
    link: value.link[0],
    buttonVariant: value.button_variant,
    backgroundColor: value.background_color,
    eyebrow: value.eyebrow,
    level: value.level,
  }));
