import styled from 'styled-components';
import { StyledRadioInput } from '@web/atoms/Radio/styles';

export const QuestionText = styled.p`
  ${props => props.theme.typography.heading5}
  margin-bottom:${props => props.theme.spacing(4)};
`;

export const StyledPoll = styled.form`
  width: 100%;
  margin-top: ${props => props.theme.spacing(4)};
  display: flex;
  flex-direction: column;
  text-align: center;
  background: ${props => props.theme.colors.neutral.bone};
  justify-items: center;
  padding: ${props => props.theme.spacing(7, 4, 8)};
  overflow: hidden;
  border-radius: ${props => props.theme.spacing(8)};

  @media ${props => props.theme.laptop} {
    margin: ${props => props.theme.spacing(10, 0)};
    max-width: 860px;
    padding: 24px 17%;
  }
  ${StyledRadioInput} {
    display: flex;
    flex-direction: column;
    @media ${props => props.theme.laptop} {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const SubmittedResponseText = styled.p`
  ${props => props.theme.typography.body};
`;

export const SubmitWrapper = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(6)};
`;
