import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledReasonBlock,
  Headline,
  Subheadline,
  ReasonNumber,
  NumberText,
  ImageContainer,
} from './styles.js';

export const ReasonBlock = ({
  image,
  headline,
  level = 'h2',
  subheadline,
  number,
}) => {
  return (
    <StyledReasonBlock>
      {!number ? (
        <ImageContainer>
          <ResponsiveImage {...image} objectFit="contain" />
        </ImageContainer>
      ) : (
        <ReasonNumber number={number}>
          <NumberText>{number}</NumberText>
        </ReasonNumber>
      )}
      <Headline as={level}>{headline}</Headline>
      <Subheadline>{subheadline}</Subheadline>
    </StyledReasonBlock>
  );
};

ReasonBlock.displayName = 'ReasonBlock';

ReasonBlock.propTypes = {
  headline: PropTypes.string.isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  subheadline: PropTypes.node.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  number: PropTypes.number,
};
