'use client';
import styled from 'styled-components';
import { BackgroundColorContainer } from '@web/atoms';

export const CalloutContainer = styled(BackgroundColorContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spacing(14, 0)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(20, 0)};
  }
`;

export const StyledHeadingWrapper = styled.div`
  padding: ${props => props.theme.spacing(4)};
  width: fit-content;
  max-width: 1096px;
`;

export const ImageContainer = styled.div`
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(0, 4, 0, 4)};
  }
`;

export const LinkWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(4)};
`;

export const InputWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(20)};
  width: 100%;
  @media ${props => props.theme.tabletL} {
    margin-top: ${props => props.theme.spacing(10)};
  }
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline(props.$bgColor)}
`;
