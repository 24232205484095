import React from 'react';
import * as yup from 'yup';
import { SplitScreenPromo } from '@web/components';
import { CMSBlok } from '../translation';
import { linkSchema } from '../schemas';
import { schema as imageSchema } from './image';

export const component = SplitScreenPromo;

export const schema = yup
  .object({
    contentBgColor: yup.string().nullable(),
    fontColor: yup.string().nullable(),
    imageBgColor: yup.string().nullable(),
    imageFirst: yup.boolean(),
    image: imageSchema.nullable(),
    link: linkSchema.nullable(),
    content: yup.array(yup.object()),
    eyebrow: yup.string().nullable(),
    ctaButtonColor: yup.string(),
  })
  .transform(value => ({
    contentBgColor: value.content_background_color,
    link: value.link?.[0] || null,
    fontColor: value.font_color,
    imageBgColor: value.image_background_color,
    image: value.image?.[0] || null,
    imageFirst: value.image_first_desktop,
    content:
      value.content?.map(innerContent => (
        <CMSBlok key={innerContent._uid} content={innerContent} />
      )) || [],
    eyebrow: value.eyebrow,
    ctaButtonColor: value.cta_button_color,
  }));
