import * as yup from 'yup';
import { FactsTableItem } from '@web/components';

export const component = FactsTableItem;

export const schema = yup
  .object({
    heading: yup.string().required(),
    subheading: yup.string(),
    factsTableFacts: yup.array(),
  })
  .transform(value => ({
    heading: value.heading,
    subheading: value.subheading,
    factsTableFacts: value.facts_table_facts,
  }));
