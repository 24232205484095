import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import BeefPack from '../../images/our-food/new-beef-pack.png';
import TurkeyPack from '../../images/our-food/new-turkey-pack.png';
import ChickenPack from '../../images/our-food/new-chicken-pack.png';
import PorkPack from '../../images/our-food/pork-pack.png';
import LambPack from '../../images/our-food/new-lamb-pack.png';
import BeefBag from '../../images/our-food/beef-baked-bag.png';
import ChickenBag from '../../images/our-food/chicken-baked-bag.png';
import { ResponsiveImage } from '../ResponsiveImage';
import {
  SectionContainer,
  PlanInfo,
  PlanImage,
  PlanTitle,
  RecipeImageContainer,
  RecipeImage,
  Heading,
  StyledProductsText,
  StyledProductTitle,
  StyledInfoWithImages,
  DesktopContainer,
  MobileContainer,
} from './styles.js';

const recipeImages = {
  fresh: {
    beef: BeefPack,
    chicken: ChickenPack,
    turkey: TurkeyPack,
    lamb: LambPack,
    pork: PorkPack,
  },
  dry: {
    beef: BeefBag,
    chicken: ChickenBag,
  },
};

export const RecommendedPlanFoodSection = ({
  planTitle,
  img,
  recipes,
  calories,
}) => {
  const formatRecipes = recipes => {
    const capitalizedRecipes = recipes.map(recipe => capitalize(recipe));
    if (recipes.length < 2) {
      return capitalizedRecipes;
    }
    if (recipes.length === 2) {
      return capitalizedRecipes.join(' and ');
    } else {
      return (
        capitalizedRecipes.slice(0, -1).join(', ') +
        ', and ' +
        capitalizedRecipes.slice(-1)
      );
    }
  };
  return (
    <SectionContainer>
      <Heading>Food</Heading>
      <DesktopContainer>
        <PlanImage>
          <ResponsiveImage
            src={img.src}
            alt={img.alt}
            height={128}
            width={128}
          />
        </PlanImage>
        <StyledInfoWithImages>
          <PlanInfo>
            <PlanTitle>{planTitle}</PlanTitle>
            <StyledProductsText>
              <StyledProductTitle>Fresh: </StyledProductTitle>
              {recipes.fresh && formatRecipes(recipes.fresh)} <br />
              <StyledProductTitle>Baked: </StyledProductTitle>
              {recipes.dry && formatRecipes(recipes.dry)} <br />
              {parseInt(calories)} calories per day
            </StyledProductsText>
          </PlanInfo>
          <RecipeImageContainer>
            {recipes.fresh?.map((recipe, index) => (
              <RecipeImage key={index}>
                <ResponsiveImage
                  src={recipeImages.fresh[recipe]}
                  alt={`${recipe} pack`}
                  height={62}
                  width={84}
                />
              </RecipeImage>
            ))}
            {recipes.dry?.map((recipe, index) => (
              <RecipeImage key={index}>
                <ResponsiveImage
                  src={recipeImages.dry[recipe]}
                  alt={`${recipe} bag`}
                  width={62}
                  height={84}
                />
              </RecipeImage>
            ))}
          </RecipeImageContainer>
        </StyledInfoWithImages>
      </DesktopContainer>
      <MobileContainer>
        <PlanImage>
          <ResponsiveImage
            src={img.src}
            alt={img.alt}
            height={128}
            width={128}
          />
        </PlanImage>
        <PlanInfo>
          <PlanTitle>{planTitle}</PlanTitle>
          <StyledProductsText>
            <StyledProductTitle>Fresh: </StyledProductTitle>
            {recipes.fresh && formatRecipes(recipes.fresh)} <br />
            <StyledProductTitle>Baked: </StyledProductTitle>
            {recipes.dry && formatRecipes(recipes.dry)} <br />
            {parseInt(calories)} calories per day
          </StyledProductsText>
        </PlanInfo>
        <RecipeImageContainer>
          {recipes.fresh?.map((recipe, index) => (
            <RecipeImage key={index}>
              <ResponsiveImage
                src={recipeImages.fresh[recipe]}
                alt={`${recipe} pack`}
                height={62}
                width={84}
              />
            </RecipeImage>
          ))}
          {recipes.dry?.map((recipe, index) => (
            <RecipeImage key={index}>
              <ResponsiveImage
                src={recipeImages.dry[recipe]}
                alt={`${recipe} bag`}
                width={62}
                height={84}
              />
            </RecipeImage>
          ))}
        </RecipeImageContainer>
      </MobileContainer>
    </SectionContainer>
  );
};

RecommendedPlanFoodSection.propTypes = {
  planTitle: PropTypes.string.isRequired,
  recipes: PropTypes.shape({
    fresh: PropTypes.arrayOf(PropTypes.string),
    dry: PropTypes.arrayOf(PropTypes.string),
  }),
};
