'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ui/button';
import { Icon } from '@ui/icons';
import { Dropdown } from '@web/atoms';
import { ResponsiveImage } from '../ResponsiveImage';
import { DENTAL_CHEWS_SKUS } from './constants';
import {
  ImageContainer,
  CardInfo,
  CardHeader,
  DisplayedPrice,
  StrikeThroughPrice,
  CardHeaderText,
  Name,
  Size,
  SavePercentage,
  QuantitySizeDropdownsWrapper,
  SectionHeader,
  SectionContainer,
  ExtraContainer,
  DropdownContainer,
  Dropdowns,
  ShowDesktopSelectionContainer,
  ShowMobileSelectionContainer,
  RemoveButtonDesktop,
  RemoveButtonMobile,
  ButtonWrapper,
  HrTag,
} from './styles.js';

export const RecommendedExtraSection = ({
  allExtras,
  unavailableExtras = [],
  selectedExtras = [],
  removeExtra,
  onExtrasSelectionChange,
  onAddExtrasClick,
  recommendedDentalSku,
  selectionLabel = 'Add to your plan',
}) => {
  let labelText = selectionLabel;

  const availableExtras = allExtras.filter(
    ({ sku }) => !unavailableExtras.includes(sku),
  );

  const filteredAvailableExtras = availableExtras.filter(
    extra =>
      !DENTAL_CHEWS_SKUS.includes(extra.sku) ||
      extra.sku === recommendedDentalSku,
  );

  const initialDropdownState = filteredAvailableExtras.reduce(
    (acc, availableExtra) => {
      const { sku, defaultDropdownValues } = availableExtra;
      acc[sku] = defaultDropdownValues;
      return acc;
    },
    {},
  );
  const [dropdownValues, setDropDownValues] = useState(initialDropdownState);
  if (!filteredAvailableExtras.length) return null;
  return (
    <SectionContainer>
      <SectionHeader>Extras</SectionHeader>
      {filteredAvailableExtras.map((availableExtra, idx) => {
        const {
          title,
          size,
          image,
          pricingInfo,
          sku: availableSku,
          defaultDropdownValues,
          dropdowns,
        } = availableExtra;
        const { price, discountedPrice, saveText } = pricingInfo;
        const quantityField = dropdowns.find(item => item.name === 'quantity');
        const frequencyField = dropdowns.find(
          item => item.name === 'frequency',
        );

        const selectedExtraInfo = selectedExtras.find(
          ({ sku }) => availableSku === sku,
        );

        const isSelected = !!selectedExtraInfo;

        return (
          <>
            <ExtraContainer>
              <CardHeader>
                <ImageContainer>
                  <ResponsiveImage
                    src={image.src}
                    alt={image.alt}
                    height={image.height || 128}
                    width={image.width || 128}
                  />
                  {isSelected && (
                    <RemoveButtonDesktop
                      onClick={() => removeExtra(availableSku)}
                    >
                      Remove
                    </RemoveButtonDesktop>
                  )}
                </ImageContainer>
                <CardHeaderText>
                  <Name>{title}</Name>
                  <Size>{size}</Size>
                  {pricingInfo && (
                    <div>
                      {parseFloat(discountedPrice) < parseFloat(price) // don't show discount information if there is no savings
                        ? Boolean(price) && (
                            <>
                              <StrikeThroughPrice>${price}</StrikeThroughPrice>
                              <DisplayedPrice>
                                ${discountedPrice}
                              </DisplayedPrice>
                              {Boolean(saveText) && (
                                <SavePercentage>{saveText}</SavePercentage>
                              )}
                            </>
                          )
                        : Boolean(price) && (
                            <DisplayedPrice>${price}</DisplayedPrice>
                          )}
                    </div>
                  )}
                  {isSelected && (
                    <RemoveButtonMobile
                      onClick={() => removeExtra(availableSku)}
                    >
                      Remove
                    </RemoveButtonMobile>
                  )}

                  <CardInfo>
                    {!isSelected && (
                      <ButtonWrapper>
                        <Button
                          variant={'primary'}
                          onClick={e => {
                            e.preventDefault();
                            onAddExtrasClick({
                              sku: availableSku,
                              frequency: defaultDropdownValues.quantity,
                              quantity: defaultDropdownValues.frequency,
                            });
                          }}
                        >
                          <>
                            <Icon
                              iconName="plus"
                              size={1}
                              id="icon_button_addon"
                              color="text.lightest"
                              alt="Add"
                            />
                            {labelText}
                          </>
                        </Button>
                      </ButtonWrapper>
                    )}
                    <ShowDesktopSelectionContainer>
                      <DropdownContainer>
                        {isSelected && (
                          <>
                            <Dropdowns>
                              <QuantitySizeDropdownsWrapper>
                                {quantityField && (
                                  <Dropdown
                                    key={0}
                                    noSelectionLabel={'Select'}
                                    selected={
                                      dropdownValues[availableSku].quantity
                                    }
                                    onChange={value => {
                                      onExtrasSelectionChange({
                                        sku: availableSku,
                                        frequency:
                                          dropdownValues[availableSku]
                                            .frequency,
                                        quantity: value,
                                      });
                                      setDropDownValues({
                                        ...dropdownValues,
                                        [availableSku]: {
                                          frequency:
                                            dropdownValues[availableSku]
                                              .frequency,
                                          quantity: value,
                                        },
                                      });
                                    }}
                                    {...quantityField}
                                  />
                                )}
                                {frequencyField && (
                                  <Dropdown
                                    key={1}
                                    noSelectionLabel={'Select'}
                                    selected={
                                      dropdownValues[availableSku].frequency
                                    }
                                    onChange={value => {
                                      onExtrasSelectionChange({
                                        sku: availableSku,
                                        frequency: value,
                                        quantity:
                                          dropdownValues[availableSku].quantity,
                                      });
                                      setDropDownValues({
                                        ...dropdownValues,
                                        [availableSku]: {
                                          quantity:
                                            dropdownValues[availableSku]
                                              .quantity,
                                          frequency: value,
                                        },
                                      });
                                    }}
                                    {...frequencyField}
                                  />
                                )}
                              </QuantitySizeDropdownsWrapper>
                            </Dropdowns>
                          </>
                        )}
                      </DropdownContainer>
                    </ShowDesktopSelectionContainer>
                  </CardInfo>
                </CardHeaderText>
              </CardHeader>
              <ShowMobileSelectionContainer>
                <DropdownContainer>
                  {isSelected && (
                    <>
                      <Dropdowns>
                        <QuantitySizeDropdownsWrapper>
                          {quantityField && (
                            <Dropdown
                              key={0}
                              noSelectionLabel={'Select'}
                              selected={dropdownValues[availableSku].quantity}
                              onChange={value => {
                                onExtrasSelectionChange({
                                  sku: availableSku,
                                  frequency:
                                    dropdownValues[availableSku].frequency,
                                  quantity: value,
                                });
                                setDropDownValues({
                                  ...dropdownValues,
                                  [availableSku]: {
                                    frequency:
                                      dropdownValues[availableSku].frequency,
                                    quantity: value,
                                  },
                                });
                              }}
                              {...quantityField}
                            />
                          )}
                          {frequencyField && (
                            <Dropdown
                              key={1}
                              noSelectionLabel={'Select'}
                              selected={dropdownValues[availableSku].frequency}
                              onChange={value => {
                                onExtrasSelectionChange({
                                  sku: availableSku,
                                  frequency: value,
                                  quantity:
                                    dropdownValues[availableSku].quantity,
                                });
                                setDropDownValues({
                                  ...dropdownValues,
                                  [availableSku]: {
                                    quantity:
                                      dropdownValues[availableSku].quantity,
                                    frequency: value,
                                  },
                                });
                              }}
                              {...frequencyField}
                            />
                          )}
                        </QuantitySizeDropdownsWrapper>
                      </Dropdowns>
                    </>
                  )}
                </DropdownContainer>
              </ShowMobileSelectionContainer>
            </ExtraContainer>
            {idx + 1 < filteredAvailableExtras.length && <HrTag />}
          </>
        );
      })}
    </SectionContainer>
  );
};

RecommendedExtraSection.displayName = 'RecommendedExtraSection';
RecommendedExtraSection.propTypes = {
  allExtras: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      size: PropTypes.string,

      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        width: PropTypes.string,
        height: PropTypes.string,
      }).isRequired,
      sku: PropTypes.string,
      selectionLabel: PropTypes.string,
      defaultDropdownValues: PropTypes.shape({
        quantity: PropTypes.number.isRequired,
        frequency: PropTypes.number.isRequired,
      }),
      dropdowns: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          noSelectionLabel: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string.isRequired,
              value: PropTypes.number.isRequired,
            }).isRequired,
          ).isRequired,
        }).isRequired,
      ).isRequired,
      pricingInfo: PropTypes.shape({
        price: PropTypes.string,
        discountedPrice: PropTypes.string,
        saveText: PropTypes.string,
      }),
    }),
  ),
  unavailableExtras: PropTypes.arrayOf(PropTypes.string),
  selectedExtras: PropTypes.arrayOf(
    PropTypes.shape({
      sku: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      frequency: PropTypes.number.isRequired,
    }),
  ),
  recommendedDentalSku: PropTypes.string,
  removeExtra: PropTypes.func.isRequired,
  onExtrasSelectionChange: PropTypes.func.isRequired,
  onAddExtrasClick: PropTypes.func.isRequired,
  selectionLabel: PropTypes.string,
};
