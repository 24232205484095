import * as yup from 'yup';
import { IllustrationPill } from '@web/components';
import { requiredImageFromCMSSchema } from '../schemas';

export const component = IllustrationPill;

export const schema = yup
  .object({
    icon: requiredImageFromCMSSchema,
    text: yup.string().required(),
    backgroundColor: yup.string(),
  })
  .transform(value => ({
    icon: value.icon,
    text: value.text,
    backgroundColor: value.background_color,
  }));
