'use client';
import styled from 'styled-components';

export const StyledTrustPilot = styled.div`
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
  padding: 42px 0 64px;
  @media ${props => props.theme.laptop} {
    padding: 84px 0;
  }
`;

export const Header = styled.h3`
  text-align: center;
  padding: 0 64px;
  font-size: 1.5625rem;
  margin-bottom: 32px;
  @media ${props => props.theme.laptop} {
    margin-bottom: 40px;
    font-size: 2.1875rem;
  }
`;
