export const ctaButtonClicked = (ctaText, headerText = '') => ({
  type: 'Button Clicked',
  data: {
    cta_text: ctaText,
    component_heading: headerText,
  },
});

export const appStoreCTAButtonClicked = appType => ({
  type: `App ${appType} Download Clicked`,
});
