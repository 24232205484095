'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Footer } from '@web/components';
import { useExperiment, SHOW_LAZYLOAD_HOMEPAGE } from '../experiments';
import { MarketingHeader } from './MarketingHeader';
import {
  NullErrorBoundary,
  BuildPropsError,
  MissingCMSContentType,
  MissingCMSComponent,
} from './errors';
import * as builders from './builders';

/**
 * Using the componentMapToBuilder, generates the elements
 * needed to render it from the CMS data provided.
 *
 * @param {{ component: string }} content
 * @returns {React.ReactNode}
 */
const componentToElement = content => {
  // eslint-disable-next-line import/namespace
  const builder = builders[content.component];
  if (!builder) {
    return <MissingCMSComponent type={content.component} />;
  }

  const { component: Component, schema } = builder;

  // We need this try / catch because error boundaries
  // don't apply to SSR. Remove this and use NullErrorBoundary
  // when that changes, which is not expected to be soon.
  try {
    return <Component {...schema.validateSync(content)} />;
  } catch (err) {
    return <BuildPropsError error={err} component={content.component} />;
  }
};

export const CMSBlok = ({ content }) => {
  return <NullErrorBoundary>{componentToElement(content)}</NullErrorBoundary>;
};

CMSBlok.propTypes = {
  content: PropTypes.object.isRequired,
};

/**
 * Component for the `landing` CMS content type.
 *
 * @type {React.FC<{ content: any, isHomePage: boolean }>}
 */
const CMSLanding = ({ content, isHomePage }) => {
  const componentTypes = [
    'hero',
    'video_hero',
    'rapid_cro_video_hero_content_test',
    'header',
    'simplified_hero',
  ];

  const { value: showLazyLoad } = useExperiment(SHOW_LAZYLOAD_HOMEPAGE, false);

  return (
    <>
      {content.header ? (
        <MarketingHeader
          {...builders.header.schema.validateSync(content.header)}
          hidePromoBar={content.hide_promo_bar}
        />
      ) : null}
      <main id="main">
        {content.body?.map((component, key) => {
          const isComponentValid =
            componentTypes.includes(component.component) || key < 5;
          return isComponentValid ? (
            <CMSBlok key={component._uid} content={component} />
          ) : isHomePage && showLazyLoad ? (
            <LazyLoadComponent key={key}>
              <CMSBlok content={component} />
            </LazyLoadComponent>
          ) : (
            <CMSBlok key={component._uid} content={component} />
          );
        })}
      </main>
      {content.footer ? (
        <LazyLoadComponent>
          <Footer {...builders.footer.schema.validateSync(content.footer)} />
        </LazyLoadComponent>
      ) : null}
    </>
  );
};

CMSLanding.propTypes = {
  content: PropTypes.object.isRequired,
  isHomePage: PropTypes.bool.isRequired,
};

/**
 * Maps the story-level content type to the CMS component for it.
 * This is intentionally less restrictive because these will need
 * to be custom made for the given content type.
 *
 * @type {Record<string, React.ComponentType<{ content: any, isHomePage: boolean }>>}
 */
const storyMapToComponent = {
  landing: CMSLanding,
};

export const VALID_STORIES = Object.keys(storyMapToComponent);

/**
 * Root CMS story component. Translates provide content to the correct
 * underlying component.
 *
 * @type {React.FC<{ content: any, isHomePage: boolean }>}
 */
export const CMSStory = ({ content, isHomePage }) => {
  const Story = storyMapToComponent[content.component];

  return Story ? (
    <Story content={content} isHomePage={isHomePage} />
  ) : (
    <MissingCMSContentType type={content.component} />
  );
};

CMSStory.propTypes = {
  content: PropTypes.object.isRequired,
  isHomePage: PropTypes.bool.isRequired,
};
