'use client';
import styled from 'styled-components';

export const StyledLearnMoreContainer = styled.div`
  color: ${({ $fontColor, theme }) => theme.colors.get($fontColor)};
  background-color: ${({ $backgroundColor, theme }) =>
    theme.colors.get($backgroundColor)};
  border-radius: 32px;
  text-align: center;
  padding: ${props => props.theme.spacing(8)};

  @media ${({ theme }) => theme.mobileS} {
    width: 100%;
  }

  @media ${({ theme }) => theme.tabletS} {
    margin: auto;
  }
`;

export const InfoContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(4)};
  margin: ${props => props.theme.spacing(6, 0, 4)};
`;

export const BodyText = styled.p`
  ${({ theme }) => theme.typography.body};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline(props.$backgroundColor)}
`;
