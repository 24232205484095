'use client';
import React, { useRef, useState } from 'react';
import { useKeyboardEvent } from '@hooks/use-keyboard-event';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import { TooltipContainer, TooltipContent } from './styles.js';

export const Tooltip = ({
  trigger,
  children,
  placement = 'left',
  colorVariant = 'dark',
  offsetX = 0,
  offsetY = 20,
}) => {
  const tooltipContainerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [triggerElement, setTriggerElement] = useState(null);
  const [tooltipElement, setTooltipElement] = useState(null);
  useKeyboardEvent('Escape', () => setIsOpen(false));

  const tooltipListeners = {
    onMouseEnter: () => setIsOpen(true),
    onMouseLeave: e => {
      const boundary = tooltipContainerRef.current.getBoundingClientRect();
      if (
        //  If the tooltip is within the trigger element, we don't want it
        //  close just because it "left" the trigger element
        e.clientX <= boundary.left ||
        e.clientX >= boundary.right ||
        e.clientY <= boundary.top ||
        e.clientY >= boundary.bottom
      ) {
        setIsOpen(false);
      }
    },
    onFocus: () => setIsOpen(true),
    onBlur: () => setIsOpen(false),
  };

  const { styles, attributes } = usePopper(triggerElement, tooltipElement, {
    placement,
    modifiers: [{ name: 'offset', options: { offset: [offsetX, offsetY] } }],
  });
  return (
    <TooltipContainer ref={tooltipContainerRef}>
      {trigger({ ref: setTriggerElement, ...tooltipListeners })}
      {isOpen && (
        <TooltipContent
          ref={setTooltipElement}
          style={styles.popper}
          variant={colorVariant}
          {...attributes.popper}
        >
          {children}
        </TooltipContent>
      )}
    </TooltipContainer>
  );
};

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  colorVariant: PropTypes.string,
  trigger: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  placement: PropTypes.oneOf([
    'auto',
    'auto-end',
    'auto-start',
    'top',
    'top-end',
    'top-start',
    'right',
    'right-end',
    'right-start',
    'bottom',
    'bottom-end',
    'bottom-start',
    'left',
    'left-end',
    'left-start',
  ]),
};
