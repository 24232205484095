import * as yup from 'yup';
import { SimpleCallout } from '@web/components';
import { requiredImageFromCMSSchema, linkSchema } from '../schemas';

export const component = SimpleCallout;

export const schema = yup
  .object({
    image: requiredImageFromCMSSchema,
    body: yup.string().required(),
    level: yup.string(),
    link: linkSchema.required(),
  })
  .transform(value => ({
    image: value.image,
    body: value.body,
    level: value.level,
    link: value.link[0],
  }));
