import * as yup from 'yup';
import { Timeline } from '@web/components/Timeline';
import { linkSchema } from '../schemas';
import { schema as TimelineMarker } from './timeline_marker';

export const component = Timeline;

export const schema = yup
  .object({
    heading: yup.string(),
    timelineMarkers: yup.array(TimelineMarker).required(),
    markerColor: yup.string(),
    link: linkSchema,
    buttonVariant: yup.string(),
  })
  .transform(value => ({
    heading: value.heading,
    timelineMarkers: value.timeline_markers,
    markerColor: value.marker_color,
    link: value.link[0] || undefined,
    buttonVariant: value.button_variant,
  }));
