import React from 'react';
import PropTypes from 'prop-types';
import { CloseButton } from '@web/atoms';
import ReactModal from './react-modal';
import {
  GlobalStyle,
  CloseButtonContainer,
  ModalDialog,
  MobileButtonContainer,
  DesktopButtonContainer,
  MobilePopUpGlobalStyle,
  MobilePopUpModalDialog,
} from './styles.js';

export const Modal = ({
  shown,
  ariaLabel,
  onCloseModal,
  children,
  mobileFullScreen,
  mobileFullWidthOnly,
  closeBtnColor,
  mobilePopUp = false,
  popupMobileDialoguePadding = 0,
}) => {
  return (
    <>
      {!mobilePopUp ? (
        <GlobalStyle
          mobileFullScreen={mobileFullScreen}
          mobileFullWidthOnly={mobileFullWidthOnly}
        />
      ) : (
        <MobilePopUpGlobalStyle />
      )}
      <ReactModal
        isOpen={shown}
        className="ModalContent"
        overlayClassName="ModalOverlay"
        onRequestClose={onCloseModal}
        contentLabel={ariaLabel}
      >
        <CloseButtonContainer>
          <DesktopButtonContainer>
            <CloseButton onClick={onCloseModal} />
          </DesktopButtonContainer>
          <MobileButtonContainer>
            <CloseButton color={closeBtnColor} onClick={onCloseModal} />
          </MobileButtonContainer>
        </CloseButtonContainer>
        {!mobilePopUp ? (
          <ModalDialog mobileFullScreen={mobileFullScreen}>
            {children}
          </ModalDialog>
        ) : (
          <MobilePopUpModalDialog
            $popupMobileDialoguePadding={popupMobileDialoguePadding}
          >
            {children}
          </MobilePopUpModalDialog>
        )}
      </ReactModal>
    </>
  );
};

Modal.displayName = 'Modal';

Modal.propTypes = {
  shown: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  mobileFullScreen: PropTypes.bool,
  mobileFullWidthOnly: PropTypes.bool,
  closeBtnColor: PropTypes.string,
  mobilePopUp: PropTypes.bool,
  popupMobileDialoguePadding: PropTypes.number,
};
