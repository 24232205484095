import * as yup from 'yup';
import { ValueProps } from '@web/components';
import {
  linkSchema,
  inlineRichTextFromCMSSchema,
  createInlineRichTextFromCMSSchema,
} from '../schemas';
import { schema as reasonSchema } from './reason';

export const component = ValueProps;

export const schema = yup
  .object({
    variant: yup.string().oneOf(['primary', 'egg']).required(),
    buttonVariant: yup.string().oneOf(['primary', 'dark']).required(),
    eyebrow: yup.string(),
    headline: yup.mixed().when('level', {
      is: val => !!val,
      then: yup.lazy((_, ctx) => {
        return createInlineRichTextFromCMSSchema(ctx.parent.level);
      }),
      otherwise: inlineRichTextFromCMSSchema, // Default schema if `level` is not provided
    }),
    reasons: yup.array(reasonSchema),
    link: linkSchema,
    numberedList: yup.boolean(),
  })
  .camelCase()
  .transform(value => ({
    ...value,
    link: value.link[0],
  }));
