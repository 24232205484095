import * as yup from 'yup';
import { FlagshipABTest } from '@web/components/FlagshipABTest';
import { CMSBlok } from '../translation';

export const component = FlagshipABTest;

const transformVariantBlocks = variants =>
  variants.reduce((acc, variant) => {
    const { variant_name, blocks } = variant;
    // Now just directly mapping variant name to the transformed blocks
    acc[variant_name] = blocks.map((content, idx) => (
      <CMSBlok key={idx} content={content} />
    ));
    return acc;
  }, {});

export const schema = yup
  .object({
    testName: yup.string().required(),
    variants: yup.lazy(obj =>
      yup.object(
        Object.keys(obj).reduce((acc, key) => {
          // Each key in the object will correspond to a variant name
          acc[key] = yup.array();
          return acc;
        }, {}),
      ),
    ),
  })
  .transform(value => ({
    testName: value.test_name,
    variants: transformVariantBlocks(value.variants),
  }));
