import * as yup from 'yup';
import { SimpleCalloutPromo } from '@web/components';
import { requiredImageFromCMSSchema, linkSchema } from '../schemas';
import { schema as emailCaptureSchema } from './email_capture';

export const component = SimpleCalloutPromo;

export const schema = yup
  .object({
    heading: yup.string().required(),
    headingLevel: yup.string().required(),
    headingTypography: yup.string(),
    image: requiredImageFromCMSSchema.required(),
    cta: yup.lazy(item => {
      if (!item) return yup.mixed().notRequired();
      switch (item.component) {
        case 'email_capture':
          return emailCaptureSchema;
        default:
          return linkSchema;
      }
    }),
    buttonVariant: yup.string(),
    fontColor: yup.string(),
    backgroundColor: yup.string(),
  })
  .transform(value => ({
    heading: value.heading,
    headingLevel: value.heading_level,
    headingTypography: value.heading_typography,
    image: value.image,
    cta: value.cta?.[0],
    fontColor: value.font_color,
    backgroundColor: value.background_color,
    buttonVariant: value.cta_color,
  }));
