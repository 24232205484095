import React from 'react';
import * as yup from 'yup';
import { StoreLocatorMap } from '@web/components';
import { CMSBlok } from '../translation';

export const component = StoreLocatorMap;

export const schema = yup
  .object({
    accountId: yup.string().required(),
    content: yup.array(yup.object()).required(),
  })
  .transform(value => ({
    accountId: value.account_id,
    content:
      value.content?.map(innerContent => (
        <CMSBlok key={innerContent._uid} content={innerContent} />
      )) || [],
  }));
