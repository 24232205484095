'use client';
import styled from 'styled-components';

export const StyledVerticalPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${props =>
    props.theme.colors.get(
      props.fillColor,
      props.theme.colors.background.lightest,
    )};
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.spacing(6)};
  margin: ${({ theme }) => theme.spacing(0, 'auto')};
  max-width: 1440px;
  @media ${props => props.theme.tablet} {
    width: 40%;
  }
`;

export const ContentPanel = styled.div`
  background: ${props =>
    props.theme.colors.get(
      props.backgroundColor,
      props.theme.colors.hero.spinach,
    )};
  border-radius: 40px;
`;

export const ContentPanelInner = styled.div`
  text-align: center;
  padding: ${props => props.theme.spacing(8)};
`;
