import * as yup from 'yup';
import React from 'react';
import { Cell } from '@web/atoms';
import { CMSBlok } from '../translation';

export const component = Cell;

const yupNumber = yup
  .number()
  .min(1)
  .max(12)
  .nullable()
  .transform(v => (v ? v : null));

export const schema = yup
  .object({
    columns: yup
      .object({
        mobile: yupNumber,
        tablet: yupNumber,
        laptop: yupNumber,
      })
      .nullable(),
    position: yup.string().optional(),
    children: yup.array().required(),
  })
  .transform(value => ({
    columns: {
      mobile: value.mobile,
      tablet: value.tablet,
      laptop: value.laptop,
    },
    position: value.position,
    children: value.content.map(child => (
      <CMSBlok content={child} key={child._uid} />
    )),
  }));
