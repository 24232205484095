import * as yup from 'yup';
import { Hero } from '@web/components';
import {
  linkSchema,
  createInlineRichTextFromCMSSchema,
  inlineRichTextFromCMSSchema,
  headlineVariantsSchema,
} from '../schemas';
import { schema as flexibleImage } from './flexible_picture';
import { schema as emailCaptureSchema } from './email_capture';

export const component = Hero;

export const schema = yup
  .object({
    // headline: yup.object(),
    headline: yup.mixed().when('level', {
      is: val => !!val,
      then: yup.lazy((_, ctx) => {
        return createInlineRichTextFromCMSSchema(ctx.parent.level);
      }),
      otherwise: inlineRichTextFromCMSSchema, // Default schema if `level` is not provided
    }),
    headlineVariants: yup.mixed().when('level', {
      is: val => !!val,
      then: yup.lazy((_, ctx) => {
        return headlineVariantsSchema(ctx.parent.level);
      }),
      otherwise: headlineVariantsSchema(),
    }),
    subheadline: yup.string().nullable(),
    cta: yup.lazy(item => {
      switch (item?.component) {
        case 'email_capture':
          return emailCaptureSchema;
        default:
          return linkSchema;
      }
    }),
    image: flexibleImage,
    backgroundColor: yup.string(),
    fontColor: yup.string(),
    horizontalOrientation: yup.mixed().oneOf(['left', 'center', 'right']),
    ctaColor: yup.string(),
    targetDate: yup.date().nullable(),
    eyebrow: yup.string().nullable(),
    level: yup.string(),
  })
  .transform(value => ({
    headline: value.headline,
    headlineVariants: value.headline_variants,
    subheadline: value.subheadline,
    cta: value.cta[0] || undefined,
    image: value.image[0],
    // These need to fallback to `undefined`
    // so the defaults in the component kick in.
    // Otherwise, they're passed forward as empty strings.
    backgroundColor: value.background_color || undefined,
    fontColor: value.font_color || undefined,
    horizontalOrientation: value.horizontal_orientation,
    ctaColor: value.cta_color || undefined,
    targetDate:
      value.countdown_clock?.length > 0
        ? value.countdown_clock[0].target_date.split(' ').join('T') + ':00Z'
        : null,
    eyebrow: value.eyebrow,
    level: value.level,
  }));
