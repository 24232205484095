import Email from '../../images/Icons/email.svg';
import Phone from '../../images/Icons/phone2.svg';
import Sms from '../../images/Icons/sms.svg';
import Chat from '../../images/Icons/chat2.svg';

// TODO(James) flip these back to "normal" when the data actually comes from the CMS.
export const FAQS_PAGE_CONTENT = {
  heading1: 'Frequently Asked Questions',
  heading2: 'Get the answers to your questions',
  contactHeading: 'Still need help? Contact us.',
  subheading: 'Available in English or Español',
  contactMethods: [
    {
      title: 'Email us',
      text: 'caninecare@myollie.com',
      path: 'mailto:caninecare@myollie.com',
      icon: {
        src: Email.src,
        alt: 'Email',
      },
    },
    {
      title: 'Call us',
      text: '844-88-OLLIE (65543)',
      path: 'tel:+18448865543',
      icon: {
        src: Phone.src,
        alt: 'Phone',
      },
    },
    {
      title: 'Live chat with',
      text: 'canine support',
      icon: {
        src: Chat.src,
        alt: 'Sms',
      },
    },
    {
      title: 'Text us*',
      text: '844-88-OLLIE (65543)',
      path: 'sms:+18448865543',
      icon: {
        src: Sms.src,
        alt: 'Sms',
      },
    },
  ],
};
