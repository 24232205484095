import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Heading } from '@web/atoms';
import { EmailCapture } from './EmailCapture';
import {
  EmailCaptureBarContainer,
  LeftSection,
  TextSection,
  RightSection,
} from './styles';

export const EmailCaptureBar = ({
  image,
  heading,
  subheading,
  headingLevel,
  emailCapture,
  buttonVariant,
}) => {
  return (
    <EmailCaptureBarContainer>
      <LeftSection>
        {image && (
          <ResponsiveImage
            src={image.src}
            alt={image.alt}
            width={80}
            height={60}
          />
        )}
        <TextSection>
          <Heading
            headingLevel={headingLevel}
            typography="heading4"
            headingText={heading}
            position="left"
            backgroundColor="neutral.bone"
          />
          <p>{subheading}</p>
        </TextSection>
      </LeftSection>

      <RightSection>
        <EmailCapture
          {...emailCapture}
          buttonVariant={buttonVariant || 'chia'}
        />
      </RightSection>
    </EmailCaptureBarContainer>
  );
};

EmailCaptureBar.displayName = 'EmailCaptureBar';

EmailCaptureBar.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  emailCapture: PropTypes.shape({
    listId: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
  buttonVariant: PropTypes.string,
};
