import { string, array, object } from 'yup';
import { RecipesCombiner } from '@web/components';
import {
  inlineRichTextFromCMSSchema,
  linkSchema,
  nullableImageFromCMSSchema,
  requiredImageFromCMSSchema,
} from '../schemas';

export const component = RecipesCombiner;

const optionsSchema = object({
  title: string().required(),
  level: string(),
  placeholder: string().required(),
  tag: string(),
  items: array(
    object({
      value: string().required(),
      label: string().required(),
      description: inlineRichTextFromCMSSchema.required(),
    }).transform(item => ({
      value: item.value,
      label: item.label,
      description: item.description,
    })),
  ).required(),
  link: linkSchema,
  mobileDropdownLabel: string(),
})
  .transform(value => {
    return {
      title: value.title,
      level: value.level,
      placeholder: value.placeholder,
      tag: value.tag,
      items: value.items,
      link: value.link[0],
      mobileDropdownLabel: value.mobile_dropdown_label,
    };
  })
  .required();

export const schema = object({
  headline: string().required(),
  subheadline: string().required(),
  images: array(
    object({
      value: string().required(),
      image: requiredImageFromCMSSchema,
    }),
  ),
  freshOptions: optionsSchema,
  bakedOptions: optionsSchema,
  defaultImage: nullableImageFromCMSSchema,
  defaultDescription: string().required(),
  eyebrow: string(),
}).transform(value => {
  return {
    headline: value.headline,
    subheadline: value.subheadline,
    images: value.images.map(image => {
      return {
        value: image.value.join('+'),
        image: image.image,
      };
    }),
    freshOptions: value.fresh_options[0],
    bakedOptions: value.baked_options[0],
    defaultImage: value.default_image,
    defaultDescription: value.default_description,
    eyebrow: value.eyebrow,
  };
});
