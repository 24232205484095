'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { useSelect } from 'downshift';
import { ErrorText } from '../ErrorText';
import { VisuallyHidden } from '../VisuallyHidden';
import {
  StyledMarketingDropdown,
  StyledMarketingToggle,
  StyledMarketingMenu,
  SelectedItem,
  Item,
  StyledMarketingLabel,
} from './styles.js';

export const MarketingDropdown = ({
  name,
  label,
  options,
  selected,
  noSelectionLabel,
  fontColor,
  onChange,
  error = null,
  showLabel = true,
  onBlur,
  disabled,
  itemsToShow = {
    desktop: 6,
    mobile: 4,
  },
  extraItems,
  onClick,
}) => {
  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    selectedItem,
    getItemProps,
  } = useSelect({
    // Downshift generated IDs don't work w/ SSR.
    id: name,
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem.value),
    items: options,
    itemToString: option => option?.label,
    selectedItem: options.find(option => option.value === selected) ?? null,
  });

  const getMinValue = ({ mobile, desktop }) => {
    return {
      mobile: Math.min(mobile, options.length),
      desktop: Math.min(desktop, options.length),
    };
  };

  const itemsToShowValues =
    typeof itemsToShow === 'number'
      ? getMinValue({ mobile: itemsToShow, desktop: itemsToShow })
      : getMinValue(itemsToShow);

  const dropDownLabel = showLabel ? (
    <StyledMarketingLabel
      htmlFor={name}
      $disabled={disabled}
      {...getLabelProps()}
    >
      {label}
    </StyledMarketingLabel>
  ) : (
    <VisuallyHidden>
      <StyledMarketingLabel htmlFor={name}>{label}</StyledMarketingLabel>
    </VisuallyHidden>
  );

  const items = options.map((item, index) => (
    <Item
      $fontColor={fontColor}
      $highlighted={highlightedIndex === index}
      key={item.value}
      {...getItemProps({
        item,
        index,
      })}
    >
      {item.label}
    </Item>
  ));

  return (
    <StyledMarketingDropdown>
      {dropDownLabel}
      <StyledMarketingToggle
        type="button"
        $disabled={disabled}
        error={error}
        {...getToggleButtonProps({
          onClick,
        })}
        testID="dropdown-toggle-button"
        variant={selectedItem ? 'secondary.egg' : 'neutral.lightBone'}
      >
        <SelectedItem>{selectedItem?.label ?? noSelectionLabel}</SelectedItem>
        <Icon
          iconName={isOpen ? 'chevron-up' : 'chevron-down'}
          alt={isOpen ? 'Up arrow' : 'Down arrow'}
          size="30px"
          id="marketing_dropdown"
        />
      </StyledMarketingToggle>
      <StyledMarketingMenu
        {...getMenuProps()}
        onBlur={onBlur}
        $isOpen={isOpen}
        $hasExtraItems={!!extraItems}
        $itemsToShow={itemsToShowValues}
      >
        {isOpen && items}
        {isOpen && extraItems}
      </StyledMarketingMenu>
      {error && <ErrorText>{error}</ErrorText>}
    </StyledMarketingDropdown>
  );
};

MarketingDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ).isRequired,
  // Should match a value.
  // null/undefined means nothing selected.
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noSelectionLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  showLabel: PropTypes.bool,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  fontColor: PropTypes.string,
  itemsToShow: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      desktop: PropTypes.number.isRequired,
      mobile: PropTypes.number.isRequired,
    }),
  ]),
  extraItems: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.string,
};
