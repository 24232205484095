'use client';
import styled from 'styled-components';

export const StyledIllustrationPill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.theme.colors.get(
      props.backgroundColor,
      props.theme.colors.background.lightest,
    )};
  border: none;
  color: black;
  padding: ${props => props.theme.spacing(1, 2)};
  text-align: center;
  text-decoration: none;
  height: 3.75rem;
  width: 175px;
  max-width: 200px;
  /* Border & spacing equates to 100px */
  border-radius: ${props => props.theme.spacing(25)};

  &:last-child {
    grid-area: 3 / 1 / 4 / 3;

    @media ${props => props.theme.tabletS} {
      grid-area: auto;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing(0, 2)};
`;

export const PillText = styled.p`
  ${({ theme }) => theme.typography.heading4};
  color: ${({ theme }) => theme.colors.text.primary};
  margin: ${props => props.theme.spacing(1, 3)};
`;

export const PillImage = styled.div`
  display: flex;
  align-items: center;
`;
