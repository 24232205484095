'use client';
import styled from 'styled-components';

export const StyledSimplifiedHero = styled.div`
  background-color: ${props =>
    props.theme.colors.get(
      props.backgroundColor,
      props.theme.colors.background.base,
    )};
  color: ${props => props.theme.colors.text.secondary};
  padding: ${props => props.theme.spacing(13, 0)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(12, 0, 14)};
  }
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  margin: 16px auto 0 auto;
  ${props => props.theme.getFocusOutline(props.$backgroundColor)}
`;
