import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledEventsPageCard,
  ImageHeader,
  Content,
  ContentDate,
  ContentParagraph,
  ContentLocation,
  ButtonContainer,
  ButtonWrapper,
} from './styles';

export const EventsPageCard = ({ date, title, location, image, link }) => {
  return (
    <StyledEventsPageCard>
      <ImageHeader>
        <ResponsiveImage {...image} objectFit="cover" />
      </ImageHeader>

      <Content>
        <ContentDate>{date}</ContentDate>
        <ContentParagraph>{title}</ContentParagraph>
        <ContentLocation>{location}</ContentLocation>
        <ButtonContainer>
          <ButtonWrapper>
            <LinkButton
              href={link}
              variant={'chia'}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              View event details
            </LinkButton>
          </ButtonWrapper>
        </ButtonContainer>
      </Content>
    </StyledEventsPageCard>
  );
};

EventsPageCard.displayName = 'EventsPageCard';

EventsPageCard.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};
