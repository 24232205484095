'use client';
import styled from 'styled-components';

export const StyledSimpleCallout = styled.div`
  padding: ${props => props.theme.spacing(14, 0)};

  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(20, 0)};
  }
`;

export const Inner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1080px;
  text-align: center;

  @media ${props => props.theme.tablet} {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
`;

export const ImageWrapper = styled.div`
  flex: 0 0 auto;
  padding: ${props => props.theme.spacing(0, 10)};
`;

export const Content = styled.div`
  flex: 1 1 100%;
  padding: ${props => props.theme.spacing(0, 5)};

  @media ${props => props.theme.tablet} {
    margin: 0;
  }
`;

export const Body = styled.p`
  ${props => props.theme.typography.heading2}
  margin: ${props => props.theme.spacing(8, 0, 10)};
  max-width: 500px;

  @media ${props => props.theme.tablet} {
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;

export const ButtonContainer = styled.div`
  flex: 0 0 auto;
  padding: ${props => props.theme.spacing(0, 5)};
`;
