import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledInformationStats,
  InformationStatsBlocksContainer,
  Paragraph,
  Heading,
} from './styles.js';
import { InfoStatBlock } from './InfoStatBlock.js';

export const InformationStats = ({
  heading,
  subheading,
  headingLevel,
  statBlocks,
  backgroundColor,
}) => {
  const calculateColumns =
    statBlocks.length % 4 === 0 || statBlocks.length < 4 ? 4 : 3;

  return (
    <StyledInformationStats $backgroundColor={backgroundColor}>
      <Heading as={headingLevel} position="center">
        {heading}
      </Heading>
      <Paragraph>{subheading}</Paragraph>

      <InformationStatsBlocksContainer $columns={calculateColumns}>
        {statBlocks.map((item, index) => (
          <InfoStatBlock {...item} key={index} />
        ))}
      </InformationStatsBlocksContainer>
    </StyledInformationStats>
  );
};

InformationStats.displayName = 'InformationStats';

InformationStats.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  statBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
  backgroundColor: PropTypes.string,
};
