import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundColorContainer } from '@web/atoms';
import { Carousel } from '@web/molecules';
import { PressLogo } from './PressLogo';
import {
  StyledPressLogos,
  MobilePressLogoContainer,
  PressLogoContainer,
  LogoWrapper,
  HeadingContainer,
  Heading,
} from './styles.js';
import { PRESS_LOGOS_MOBILE_LIMIT } from './constants';

export const PressLogos = ({
  headingText,
  position = 'center',
  typography = 'heading2',
  backgroundColor = 'secondary.kale',
  fontColor = 'text.lightest',
  publications,
  verticalPadding = 6,
  horizontalPadding = 0,
  level,
}) => {
  typography =
    typeof typography === 'string'
      ? { desktop: typography, mobile: typography }
      : typography;

  const renderPublication = ({ src, quote, alt, width, height }) => {
    return (
      <LogoWrapper
        count={publications.length}
        $isCarouselHide={publications.length <= PRESS_LOGOS_MOBILE_LIMIT}
        key={src}
      >
        <PressLogo
          quote={quote}
          src={src}
          alt={alt}
          width={width}
          height={height}
        />
      </LogoWrapper>
    );
  };

  return (
    <BackgroundColorContainer $bgColor={backgroundColor}>
      <StyledPressLogos
        $verticalPadding={verticalPadding}
        $horizontalPadding={horizontalPadding}
      >
        {headingText && (
          <HeadingContainer
            $fontColor={fontColor}
            $position={position}
            $typography={typography}
          >
            <Heading as={level}>{headingText}</Heading>
          </HeadingContainer>
        )}
        <MobilePressLogoContainer
          $isCarouselHide={publications.length <= PRESS_LOGOS_MOBILE_LIMIT}
        >
          {publications.length > PRESS_LOGOS_MOBILE_LIMIT && (
            <Carousel dotPadding={6} dotVariant="bone" showArrows={false}>
              {publications.map(renderPublication)}
            </Carousel>
          )}
          {publications.length <= PRESS_LOGOS_MOBILE_LIMIT &&
            publications.map(renderPublication)}
        </MobilePressLogoContainer>
        <PressLogoContainer>
          {publications.map(renderPublication)}
        </PressLogoContainer>
      </StyledPressLogos>
    </BackgroundColorContainer>
  );
};

PressLogos.displayName = 'PressLogos';

PressLogos.propTypes = {
  headingText: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right', 'center']),
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  typography: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
    }),
  ]),
  publications: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      quote: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ).isRequired,
  horizontalPadding: PropTypes.number,
  verticalPadding: PropTypes.number,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
