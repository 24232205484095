import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledQuoteContainer,
  StyledQuoteBodyContainer,
  StyledQuoteAuthorContainer,
  StyledQuoteImage,
  StyledQuoteName,
  StyledQuoteTitle,
} from './styles.js';

export const Quote = ({ name, title, image, body }) => {
  return (
    <StyledQuoteContainer>
      {body && <StyledQuoteBodyContainer>{body}</StyledQuoteBodyContainer>}
      <StyledQuoteAuthorContainer>
        {image && (
          <StyledQuoteImage>
            <ResponsiveImage
              src={image.src}
              alt={image.alt}
              width={image.width}
              height={image.height}
            />
          </StyledQuoteImage>
        )}
        <StyledQuoteName>{name}</StyledQuoteName>
        {title && <StyledQuoteTitle>{title}</StyledQuoteTitle>}
      </StyledQuoteAuthorContainer>
    </StyledQuoteContainer>
  );
};

Quote.displayName = 'Quote';

Quote.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  body: PropTypes.node,
};
