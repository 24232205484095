import React from 'react';
import PropTypes from 'prop-types';
import { Eyebrow, Heading } from '@web/atoms';
import { ProductValues as ProductValuesCard } from '../ProductValues';
import {
  StyledContainer,
  StyledSubHeading,
  StyledHeadingContainer,
  StyledProductValuesContainer,
} from './styles.js';

export const ProductValuesGroup = ({
  heading,
  subheading,
  productValues,
  eyebrow,
}) => {
  return (
    <StyledContainer>
      <StyledHeadingContainer>
        {eyebrow && <Eyebrow textAlign="center">{eyebrow}</Eyebrow>}
        <Heading
          headingLevel="h2"
          headingText={heading}
          position="center"
          typography="heading2"
        ></Heading>
        {subheading && <StyledSubHeading>{subheading}</StyledSubHeading>}
      </StyledHeadingContainer>
      <StyledProductValuesContainer>
        {productValues &&
          productValues.map((productValue, index) => (
            <ProductValuesCard key={index} {...productValue} />
          ))}
      </StyledProductValuesContainer>
    </StyledContainer>
  );
};

ProductValuesGroup.displayName = 'ProductValuesGroup';

ProductValuesGroup.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  productValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  eyebrow: PropTypes.string,
};
