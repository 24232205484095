import React, { useState } from 'react';
import { interpolate } from '@format/interpolate';
import PropTypes from 'prop-types';
import { formatPrice } from '@format/currency';
import { Button } from '@ui/button';
import { Dropdown } from '@web/atoms';
import { NutritionalInfo } from '../NutritionalInfo';
import {
  Body,
  StyledForm,
  Dropdowns,
  Price,
  DisplayedPrice,
  StrikeThroughPrice,
  SavePercentage,
  ButtonSizing,
  ButtonContainer,
} from './styles';

export const TabView = ({
  activeTab,
  details,
  serving,
  nutritionalInfo,
  fullIngredients,
}) => {
  switch (activeTab) {
    case 'details':
      return (
        <Body
          role="tabpanel"
          id="tabpanel-details"
          aria-labelledby="tab-details"
          hidden={activeTab !== 'details'}
        >
          {details}
        </Body>
      );
    case 'nutrition':
      return (
        <NutritionalInfo
          role="tabpanel"
          id="tabpanel-nutrition"
          ariaLabelledby="tab-nutrition"
          hidden={activeTab !== 'nutrition'}
          nutritionalInfo={nutritionalInfo}
        />
      );
    case 'serving':
      return (
        <Body
          role="tabpanel"
          id="tabpanel-serving"
          aria-labelledby="tab-serving"
          hidden={activeTab !== 'serving'}
        >
          {serving}
        </Body>
      );
    case 'ingredients':
      return (
        <Body
          role="tabpanel"
          id="tabpanel-ingredients"
          aria-labelledby="tab-ingredients"
          hidden={activeTab !== 'ingredients'}
        >
          {fullIngredients}
        </Body>
      );
    default:
      break;
  }
};

TabView.propTypes = {
  activeTab: PropTypes.string.isRequired,
  details: PropTypes.node,
  nutritionalInfo: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  serving: PropTypes.node,
  fullIngredients: PropTypes.node,
};

export const AddOnQuickViewForm = ({
  onSubmit,
  dropdowns,
  recurrence = null,
  unit,
  defaultValues,
  removeButtonLabel,
  addButtonLabel,
  doneButtonLabel,
  isReactivating = false,
}) => {
  const recurrenceValue = JSON.parse(recurrence);
  const [dropdownValue, setDropDownValue] = useState({
    quantity: defaultValues.quantity,
    frequency: defaultValues.frequency,
    recurrence: recurrenceValue,
  });

  const quantityField = dropdowns.find(item => item.name === 'quantity');
  //function to get the current unit
  quantityField.options.map(
    (option, index) =>
      (quantityField.options[index].label = interpolate(option.label, {
        unit: unit,
      })),
  );

  const dynamicOptionQuantity = isReactivating
    ? quantityField.options.slice(0, 3)
    : quantityField.options;
  const quantityFieldDynamicOptions = {
    ...quantityField,
    options: dynamicOptionQuantity,
  };

  // display correct frequency options based on recurrence
  let frequencyField = dropdowns.find(item => item.name === 'frequency');
  let frequencyOptions =
    recurrenceValue !== null
      ? frequencyField.options.filter(e => e.label === 'Next box only')
      : frequencyField.options.filter(e => e.label !== 'Next box only');
  frequencyField = { ...frequencyField, options: [...frequencyOptions] };
  return (
    <StyledForm
      onSubmit={event => {
        event.preventDefault();
        onSubmit({
          ...dropdownValue,
          checked: true,
        });
      }}
    >
      <Dropdowns>
        {quantityField && (
          <Dropdown
            key={0}
            noSelectionLabel={'Select'}
            selected={dropdownValue[quantityField.name]}
            onChange={value => {
              setDropDownValue({
                ...dropdownValue,
                [quantityField.name]: value,
              });
            }}
            {...quantityFieldDynamicOptions}
          />
        )}
        {frequencyField && (
          <Dropdown
            key={1}
            noSelectionLabel={'Select'}
            selected={dropdownValue[frequencyField.name]}
            onChange={value => {
              setDropDownValue({
                ...dropdownValue,
                [frequencyField.name]: value,
              });
            }}
            {...frequencyField}
          />
        )}
      </Dropdowns>
      <ButtonContainer>
        <ButtonSizing>
          {!defaultValues.checked && (
            <Button
              type="submit"
              disabled={!dropdownValue.quantity || !dropdownValue.frequency}
              fullWidth
            >
              {addButtonLabel}
            </Button>
          )}
          {defaultValues.checked && (
            <>
              <Button
                type="button"
                onClick={event => {
                  event.preventDefault();
                  onSubmit({
                    ...dropdownValue,
                    checked: false,
                  });
                }}
                variant="destructive"
              >
                {removeButtonLabel}
              </Button>
              <Button
                type="submit"
                disabled={!dropdownValue.quantity || !dropdownValue.frequency}
                fullWidth
              >
                {doneButtonLabel}
              </Button>
            </>
          )}
        </ButtonSizing>
      </ButtonContainer>
    </StyledForm>
  );
};

AddOnQuickViewForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  dropdowns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  defaultValues: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    frequency: PropTypes.number.isRequired,
    checked: PropTypes.bool,
  }).isRequired,
  removeButtonLabel: PropTypes.string.isRequired,
  addButtonLabel: PropTypes.string.isRequired,
  doneButtonLabel: PropTypes.string.isRequired,
  isReactivating: PropTypes.bool,
};

export const PricingInfo = ({ price, discountedPrice, saveText }) => (
  <Price>
    {parseFloat(discountedPrice) < parseFloat(price) ? (
      <>
        <StrikeThroughPrice>{formatPrice(price)}</StrikeThroughPrice>
        <DisplayedPrice>{formatPrice(discountedPrice)}</DisplayedPrice>
        {Boolean(saveText) && <SavePercentage>{saveText}</SavePercentage>}
      </>
    ) : (
      formatPrice(price)
    )}
  </Price>
);

PricingInfo.propTypes = {
  price: PropTypes.string.isRequired,
  discountedPrice: PropTypes.string,
  saveText: PropTypes.string,
};
