'use client';
import styled from 'styled-components';

export const EndorsementsOutsideContainer = styled.section`
  background-color: ${props => props.theme.colors.neutral.lightBone};
  padding: ${props => props.theme.spacing(14, 0)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(20, 0)};
  }
`;

export const BodyContainer = styled.div`
  max-width: 930px;
  margin-bottom: ${props => props.theme.spacing(8)};
`;

export const StyledEndorsement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Headline = styled.h2`
  ${props => props.theme.typography.subheading1}
  color: ${props => props.theme.colors.text.secondary};
  margin-bottom: ${props => props.theme.spacing(4)};
  text-align: center;
`;

export const Body = styled.p`
  ${props => props.theme.typography.heading2}
  color: ${props => props.theme.colors.text.secondary};
  text-align: center;
`;

export const Name = styled.p`
  color: ${props => props.theme.colors.neutral.darkestFur};
  ${props => props.theme.typography.button}
  margin-bottom: ${props => props.theme.spacing(8)};
`;

export const Buttons = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const Input = styled.input`
  height: 100px;
  border-radius: 62px;
`;

export const ImageButton = styled.button`
  appearance: none;
  background-image: url('${props => props.src}');
  border: 0;
  border-radius: 28px;
  width: 56px;
  height: 56px;
  margin: ${props => props.theme.spacing(0, 2)};
  opacity: ${props => (props.activePage ? 1 : 0.2)};
  overflow: hidden;
  padding: 0;
  position: relative;
  @media ${props => props.theme.tablet} {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin: ${props => props.theme.spacing(0, 4)};
  }
`;

export const GetStartedContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(12)};
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;
