import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledVerticalPanelWithRails,
  PhotoBlock,
  MobilePhotoVariant1,
  MobilePhotoVariant2,
  Body,
  PhotoOverlay,
  Container,
  Content,
  StyledLetter,
  PhotosWrapper,
  BodyContent,
} from './styles';

export const VerticalPanelWithImageRails = ({
  topContent,
  middleContent,
  bottomContent,
  imageRails,
}) => {
  const isThreeImagesRail = imageRails.length === 3;
  return (
    <StyledVerticalPanelWithRails>
      <StyledLetter>
        <Container>
          <Content>
            <Body>
              {topContent.map((body, index) => (
                <BodyContent key={index}>{body}</BodyContent>
              ))}

              <PhotoBlock>
                <MobilePhotoVariant1>
                  <ResponsiveImage {...imageRails[0]} />
                </MobilePhotoVariant1>
                <MobilePhotoVariant1>
                  <ResponsiveImage {...imageRails[1]} />
                </MobilePhotoVariant1>
              </PhotoBlock>

              {middleContent.map((body, index) => (
                <BodyContent key={index}>{body}</BodyContent>
              ))}
              <PhotoBlock isThreeImagesRail={isThreeImagesRail}>
                <MobilePhotoVariant2 isThreeImagesRail={isThreeImagesRail}>
                  <ResponsiveImage {...imageRails[2]} />
                </MobilePhotoVariant2>
                {imageRails[3] && (
                  <MobilePhotoVariant2>
                    <ResponsiveImage {...imageRails[3]} />
                  </MobilePhotoVariant2>
                )}
              </PhotoBlock>

              {bottomContent.map((body, index) => (
                <BodyContent key={index}>{body}</BodyContent>
              ))}

              {imageRails[4] && (
                <PhotoBlock>
                  <MobilePhotoVariant1>
                    <ResponsiveImage {...imageRails[4]} />
                  </MobilePhotoVariant1>
                  {imageRails[5] && (
                    <MobilePhotoVariant1>
                      <ResponsiveImage {...imageRails[5]} />
                    </MobilePhotoVariant1>
                  )}
                </PhotoBlock>
              )}
            </Body>
          </Content>
          <PhotosWrapper>
            {imageRails.map((photo, i) => (
              <PhotoOverlay key={i}>
                <ResponsiveImage {...photo} />
              </PhotoOverlay>
            ))}
          </PhotosWrapper>
        </Container>
      </StyledLetter>
    </StyledVerticalPanelWithRails>
  );
};

VerticalPanelWithImageRails.displayName = 'VerticalPanelWithImageRails';

VerticalPanelWithImageRails.propTypes = {
  imageRails: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  ).isRequired,
  topContent: PropTypes.array.isRequired,
  middleContent: PropTypes.array,
  bottomContent: PropTypes.array,
};
