import * as yup from 'yup';
import { ImageCarouselCell } from '@web/components';
import { schema as imageSchema } from './image';

export const component = ImageCarouselCell;

export const schema = yup
  .object({ images: yup.array(imageSchema.required()).required() })
  .transform(value => ({
    images: value.images,
  }));
