import React from 'react';
import * as yup from 'yup';
import { SplitPanels } from '@web/components';
import { CMSBlok } from '../translation';
import { requiredImageFromCMSSchema } from '../schemas';
import { schema as muxVideoSchema } from './mux_video';

export const component = SplitPanels;

export const schema = yup
  .object({
    asset: yup.lazy(item => {
      switch (item.component) {
        case 'mux_video':
          return muxVideoSchema;
        default:
          return requiredImageFromCMSSchema;
      }
    }),
    alignAsset: yup.mixed().oneOf(['left', 'right']),
    backgroundColor: yup.string(),
    fillColor: yup.string(),
    content: yup.array(yup.object()),
    eyebrow: yup.string().nullable(),
  })
  .transform(value => ({
    asset: value.asset[0]?.asset || value.asset[0] || value.image,
    alignAsset: value.align_asset || undefined,
    backgroundColor: value.background_color || undefined,
    fillColor: value.fill_color || undefined,
    content: value.content.map(innerContent => (
      <CMSBlok key={innerContent._uid} content={innerContent} />
    )),
    eyebrow: value.eyebrow,
  }));
