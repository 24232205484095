import * as yup from 'yup';
import { SimpleCalloutCards } from '@web/components';
import { inlineRichTextFromCMSSchema, linkSchema } from '../schemas';
import { schema as simpleCalloutCardSchema } from './simple_callout_card';

export const component = SimpleCalloutCards;

export const schema = yup
  .object({
    headline: inlineRichTextFromCMSSchema,
    cards: yup.array(simpleCalloutCardSchema).required(),
    backgroundColor: yup.string().optional(),
    cta: linkSchema.optional(),
    ctaColor: yup.string(),
    fontColor: yup.string(),
  })
  .transform(value => ({
    headline: value.headline,
    cards: value.cards,
    backgroundColor: value.background_color,
    cta: value.cta?.[0] || null,
    ctaColor: value.cta_color || undefined,
    fontColor: value.font_color,
  }));
