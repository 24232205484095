'use client';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledValuesBanner,
  ScrollContainer,
  MarqueeOne,
  MarqueeTwo,
  ValuesWrapper,
  ValueList,
  Value,
  IconWrapper,
  Text,
} from './styles.js';

const SPEED_CONSTANT = 30;

export const ValuesBanner = ({ values }) => {
  const containerRef = useRef(null);
  const marquee1Ref = useRef(null);

  const [width, setWidth] = useState(null);
  const [repeat, setRepeat] = useState(1);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!marquee1Ref.current) return;
    const { width } = marquee1Ref.current.getBoundingClientRect();
    setWidth(width);
  }, [repeat]);

  useEffect(() => {
    const onViewportChange = () => {
      if (!marquee1Ref.current) return;
      const { width } = marquee1Ref.current.getBoundingClientRect();
      if (!width || width < 0) {
        return;
      }
      const innerWidth = window.innerWidth;
      const repeat = Math.ceil(innerWidth / width);
      setRepeat(repeat);

      setTimeout(() => {
        setLoaded(true);
      }, 250);
    };

    window.addEventListener('resize', onViewportChange);
    onViewportChange();
    return () => {
      window.removeEventListener('resize', onViewportChange);
    };
  }, [values]);

  const speed = `${width * SPEED_CONSTANT}ms`;
  const delay = `${width * -0.5 * SPEED_CONSTANT}ms`;

  const content = (
    <>
      {range(repeat).map((e, i) => (
        <ValuesWrapper key={i}>
          <ValueList>
            {values.map(({ image, name }) => (
              <Value key={name}>
                {image && (
                  <IconWrapper>
                    <ResponsiveImage
                      src={image.src}
                      alt={image.alt}
                      width={image.width}
                      height={image.height}
                    />
                  </IconWrapper>
                )}
                <Text>{name}</Text>
              </Value>
            ))}
          </ValueList>
        </ValuesWrapper>
      ))}
    </>
  );

  return (
    <StyledValuesBanner ref={containerRef} loaded={loaded}>
      <ScrollContainer>
        <MarqueeOne
          ref={marquee1Ref}
          style={{ animationDuration: speed, animationDelay: delay }}
        >
          {content}
        </MarqueeOne>
        <MarqueeTwo style={{ animationDuration: speed }}>{content}</MarqueeTwo>
      </ScrollContainer>
    </StyledValuesBanner>
  );
};

ValuesBanner.displayName = 'ValuesBanner';

ValuesBanner.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.shape({
        src: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
          .isRequired,
        alt: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
};
