import React from 'react';
import PropTypes from 'prop-types';
import { useOffer } from '../../offer';
import { TextCallout } from '../TextCallout';

export const OfferCallout = ({ lead, follow, label, tooltip }) => {
  const offer = useOffer();
  const offerText = offer.formattedAmount ? `${offer.formattedAmount} OFF` : '';

  return (
    <>
      {lead}
      <TextCallout text={offerText} label={label}>
        {tooltip}
      </TextCallout>
      {follow}
    </>
  );
};
OfferCallout.displayName = 'OfferCallout';
OfferCallout.propTypes = {
  lead: PropTypes.string,
  follow: PropTypes.string,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};
