import * as yup from 'yup';
import { LearnMore } from '@web/components';
import { requiredImageFromCMSSchema } from '../schemas';

export const component = LearnMore;

export const schema = yup
  .object({
    img: requiredImageFromCMSSchema,
    heading: yup.string().required(),
    body: yup.string().required(),
    buttonLabel: yup.string().required(),
    buttonUrl: yup.string().required(),
    fontColor: yup.string(),
    backgroundColor: yup.string().nullable(),
    buttonVariant: yup.string().nullable(),
    level: yup.string(),
  })
  .transform(value => ({
    img: value.image,
    heading: value.heading,
    body: value.body,
    buttonLabel: value.button_label,
    buttonUrl: value.button_url.url,
    fontColor: value.font_color,
    backgroundColor: value.background_color,
    buttonVariant: value.button_variant,
    level: value.level,
  }));
