import React from 'react';
import PropTypes from 'prop-types';
import {
  BaseHeader,
  LeftMenuLinks,
  CenterMenuLinks,
  DiscountBanner,
  UserMenuLinks,
} from '@web/molecules';

export const MarketingHeader = ({
  links,
  logoUrl,
  logoSrText,
  sticky = true,
  hidePromoBar,
}) => {
  const topHeaderSegment = !hidePromoBar && <DiscountBanner />;
  const rightNavSegment = <UserMenuLinks />;
  const leftNavSegment = <LeftMenuLinks links={links} />;
  const centerNavSegment = <CenterMenuLinks links={links} />;

  return (
    <BaseHeader
      logoUrl={logoUrl}
      logoSrText={logoSrText}
      topSegment={topHeaderSegment}
      rightSegment={rightNavSegment}
      centerSegment={centerNavSegment}
      leftSegment={leftNavSegment}
      sticky={sticky}
      stickyBanner={false}
    />
  );
};

MarketingHeader.displayName = 'MarketingHeader';

MarketingHeader.propTypes = {
  links: PropTypes.array.isRequired,
  logoUrl: PropTypes.string,
  logoSrText: PropTypes.string,
  sticky: PropTypes.bool,
  hidePromoBar: PropTypes.bool,
};
