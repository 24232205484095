'use client';
import styled from 'styled-components';

export const Header = styled.h6`
  ${props => props.theme.typography.heading6}
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin: ${props => props.theme.spacing(3, 0)};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  border-radius: 40px;

  &:nth-child(odd) {
    background: ${props => props.theme.colors.background.base};
  }
`;

export const TableColumn = styled.td`
  ${props => props.theme.typography.body}
  padding: ${props => props.theme.spacing(1, 4)};

  &:first-child {
    ${props => props.theme.typography.heading6}
    border-radius: 32px 0 0 32px;
  }
  &:last-child {
    border-radius: 0 32px 32px 0;
    word-spacing: ${props => props.theme.spacing(5)};
  }
`;

export const Description = styled.p`
  ${props => props.theme.typography.body}
`;
