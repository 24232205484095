import * as yup from 'yup';
import { ResponsiveImage } from '@web/molecules';
import {
  getDimensions,
  getSrcSets,
  hasDimensions,
  storyblokAspectRatio,
} from '../images';

export const Component = ResponsiveImage;

// Image sizes inspired by next/image defaults
const imageWidthsByBreakpoint = {
  mobile: [256, 384, 500],
  tablet: [640, 750, 828, 1080],
  desktop: [1200, 1920, 2048, 3840],
};

const flexibleImageSchema = yup
  .object({
    alt: yup.string().required(),
    src: yup.string().required(),
    picture: yup.boolean().required(),
  })
  .transform(({ alt, images }) => {
    const breakpoints = images.reduce(
      (bps, { image, breakpoint, width }) => ({
        ...bps,
        [breakpoint]: { image, width },
      }),
      {},
    );

    // Fill in missing breakpoints from other breakpoints.
    Object.assign(breakpoints, {
      desktop: breakpoints.desktop ?? breakpoints.tablet ?? breakpoints.mobile,
      tablet: breakpoints.tablet ?? breakpoints.desktop ?? breakpoints.mobile,
      mobile: breakpoints.mobile ?? breakpoints.tablet ?? breakpoints.desktop,
    });

    // Build up srcSets from mobile -> desktop.
    const srcSets = ['mobile', 'tablet', 'desktop'].flatMap(breakpoint => {
      const { image, width } = breakpoints[breakpoint];
      return imageWidthsByBreakpoint[breakpoint].map(imageWidth => ({
        srcSet: getSrcSets(image.filename, imageWidth),
        aspectRatio: storyblokAspectRatio(image.filename),
        sizes: width,
        media: `(max-width: ${imageWidth}px)`,
      }));
    });

    return {
      alt,
      picture: true,
      srcSet: srcSets,
      src: `${breakpoints.desktop.image.filename}/m/`,
    };
  })
  .default(undefined);

const singleImageSchema = yup
  .object({
    src: yup.string().required(),
    alt: yup.string().required(),
    picture: yup.boolean().required(),
    width: yup.number().nullable(),
    height: yup.number().nullable(),
  })
  .transform(({ alt, images }) => {
    const [{ image }] = images;
    const dimensions = hasDimensions(image.filename)
      ? getDimensions(image.filename)
      : { width: image.width, height: image.height };

    return {
      alt,
      picture: false,
      src: image.filename,
      ...dimensions,
    };
  })
  .default(undefined);

export const schema = yup.lazy(value =>
  // TODO(James) `lazy` gets run again with the transform result.
  // This convoluted ternary handles both before & after.
  // Could be a bug but not going to dig into it now.
  value.picture === false
    ? singleImageSchema
    : value.picture === true
    ? flexibleImageSchema
    : value.images.length === 1
    ? singleImageSchema
    : flexibleImageSchema,
);
