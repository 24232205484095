import * as yup from 'yup';
import { FaqList } from '@web/components';
import { linkSchema } from '../schemas';
import { schema as faqQuestionSchema } from './faq_question';

export const component = FaqList;

export const schema = yup
  .object({
    headline: yup.string().required(),
    subheadline: yup.string().required(),
    faqs: yup.array(faqQuestionSchema).required(),
    link: linkSchema,
    level: yup.string(),
  })
  .transform(value => ({
    ...value,
    link: value.link[0],
    level: value.level,
  }));
