'use client';
import styled from 'styled-components';

export const StyledMissionLetter = styled.div`
  overflow: hidden;
`;

export const Body = styled.div`
  margin: ${props => props.theme.spacing(10, 'auto')};
  max-width: 430px;
  p {
    ${props => props.theme.typography.heading5}
    margin: ${props => props.theme.spacing(5, 'auto')};
    padding: ${props => props.theme.spacing(0, 4)};

    @media ${props => props.theme.tablet} {
      margin: ${props => props.theme.spacing(5, 'auto')};
      padding: 0;
    }
  }
`;

export const PhotoOverlay = styled.div`
  border-radius: 32px;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  &:nth-child(1) {
    left: 0;
    top: 25%;
    transform: translateX(-55%);
  }
  &:nth-child(2) {
    left: 0;
    top: 9%;
    transform: translateX(-40%);
  }
  &:nth-child(3) {
    right: 0;
    top: 10%;
    transform: translateX(50%);
  }
  &:nth-child(4) {
    left: 0;
    top: 70%;
    transform: translateX(-45%);
  }
  &:nth-child(5) {
    right: 0;
    top: 65%;
    transform: translateX(30%);
  }
  &:nth-child(6) {
    right: 0;
    top: 50%;
    transform: translateX(65%);
  }
`;

export const PhotoBlock = styled.div`
  margin: ${props => props.theme.spacing(8, 'auto')};
  max-width: 285px;
  padding: 0;
  position: relative;
  @media ${props => props.theme.tabletL} {
    display: none;
  }
`;

export const MobilePhoto = styled.div`
  border-radius: 32px;
  line-height: 0;
  overflow: hidden;
  width: 68%;

  &:last-child {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 44%;
  }
`;
