import React from 'react';
import RedPaw from '../../images/about/red-paw.svg';
import OllieLogo from '../../images/Homepage/ollie-logo-inline.svg';
import AboutMission1 from '../../images/about/mission/Ollie-about-mission-1.jpg';
import AboutMission2 from '../../images/about/mission/Ollie-about-mission-2.jpg';
import AboutMission3 from '../../images/about/mission/Ollie-about-mission-3.jpg';
import AboutMission4 from '../../images/about/mission/Ollie-about-mission-4.jpg';
import AboutMission5 from '../../images/about/mission/Ollie-about-mission-5.jpg';
import AboutMission6 from '../../images/about/mission/Ollie-about-mission-6.jpg';

const BODY_BLOCKS = [
  <>
    We believe a dog’s health, happiness, and longevity begins with their food —
    and superior ingredients and cooking techniques lead to the best nutrition.
    Based on the expertise of pet health authorities, we create the highest
    quality food in human-grade kitchens with the best ingredients available.
  </>,
  <>
    Because for dog people like us, our pups are more than pets. They’re our
    best friends, our protectors, furry members of our family. They’ve got
    quirks and character that are unmatched, and they’ve got needs that are
    unique to them.
  </>,
  <>
    Our healthy, all-natural food is perfectly portioned for your dog to provide
    the nutrients they need and deliver visible results, like shiny coats, great
    energy, and better digestion.
  </>,
  <>
    At Ollie, we see every dog as an individual, and we’re committed to helping
    them thrive and live as long as canine-ly possible. It makes us a better
    business, a better company, and above all, better humans.
  </>,
];

export const MISSION_LETTER_DATA = {
  headline: 'We do it for the dogs.',
  bodyBlocks: BODY_BLOCKS,
  topIcon: {
    src: RedPaw,
    alt: 'red paw icon',
    height: 46,
    width: 46,
  },
  bottomIcon: {
    src: OllieLogo,
    alt: 'Ollie',
    height: 39,
    width: 79,
  },
  photos: [
    [
      {
        src: AboutMission1,
        alt: 'fork with a piece of meat',
        height: 351,
        width: 263,
      },
      {
        src: AboutMission2,
        alt: "small puppy in a woman's arms",
        height: 219,
        width: 169,
      },
    ],
    [
      {
        src: AboutMission3,
        alt: 'dog on bed being hugged',
        height: 306,
        width: 240,
      },
      {
        src: AboutMission4,
        alt: 'tiny dog eating out of a big red bowl',
        height: 216,
        width: 170,
      },
    ],
    [
      {
        src: AboutMission5,
        alt: 'bowl of food in hands',
        height: 306,
        width: 240,
      },
      {
        src: AboutMission6,
        alt: 'dog with sky backdrop',
        height: 217,
        width: 170,
      },
    ],
  ],
  link: {
    text: 'How Ollie Works',
    target: '/how-it-works',
    variant: 'sweetPotato',
  },
};
