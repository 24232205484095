import * as yup from 'yup';
import { CountdownClockHeading } from '@web/components';
import { linkSchema } from '../schemas';

export const component = CountdownClockHeading;

export const schema = yup
  .object({
    targetDate: yup.date().required(),
    countdownText: yup.string(),
    link: linkSchema,
    backgroundColor: yup.string(),
    textColor: yup.string(),
  })
  .transform(value => ({
    countdownText: value.countdown_text,
    targetDate: value.target_date.split(' ').join('T') + ':00Z',
    link: value.link[0],
    backgroundColor: value.background_color,
    textColor: value.text_color,
  }));
