/* eslint-disable @next/next/no-sync-scripts */
import React from 'react';
import Head from 'next/head';
import { shared } from '../config';

export const Bridge = () =>
  shared.STORYBLOK_API_ACCESS_TOKEN ? (
    <Head>
      <script
        src={`//app.storyblok.com/f/storyblok-latest.js?t=${shared.STORYBLOK_API_ACCESS_TOKEN}`}
      ></script>
    </Head>
  ) : null;
