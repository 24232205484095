import * as yup from 'yup';
import { Endorsements } from '@web/components';
import { linkSchema, requiredImageFromCMSSchema } from '../schemas';

export const component = Endorsements;

export const schema = yup
  .object({
    headline: yup.string().required(),
    endorsements: yup
      .array(
        yup.object({
          name: yup.string().required(),
          body: yup.string().required(),
          photo: requiredImageFromCMSSchema,
        }),
      )
      .required(),
    link: linkSchema.nullable(),
    level: yup.string(),
  })
  .transform(value => ({
    headline: value.headline,
    endorsements: value.endorsements,
    link: value.link[0],
    level: value.level,
  }));
