import * as yup from 'yup';
import { TestimonialRoundImage } from '@web/components';
import { schema as imageSchema } from './image';

export const component = TestimonialRoundImage;

export const schema = yup
  .object({
    testimonial: yup.object({
      body: yup.string().required(),
      customerName: yup.string().required(),
    }),
    image: imageSchema.nullable(),
  })
  .transform(value => ({
    image: value.image?.[0] || null,
    testimonial: {
      body: value.testimonial.content,
      customerName: `${value.testimonial.customer} & ${value.testimonial.pet}`,
    },
  }));
