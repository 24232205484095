'use client';
import styled from 'styled-components';

export const RecipeText = styled.p`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.text.primary};
  margin-top: ${props => props.theme.spacing(6)};
  margin-bottom: ${props => props.theme.spacing(11)};
  text-align: center;
`;

export const Border = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border.dark};
  margin: ${props => props.theme.spacing(4, 0)};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AnalysisText = styled.span`
  ${props => props.theme.typography.body}
`;

export const CaloriesContainer = styled.div`
  padding: ${props => props.theme.spacing(13, 16, 11, 14)};
  background-color: ${props =>
    props.backgroundColor && props.theme.colors.get(props.backgroundColor)};
  grid-row: 3 / span 1;

  @media ${props => props.theme.laptop} {
    grid-column: ${props => (props.imageFirst ? '2 ' : '1')};
    grid-row: 2;
  }
`;

export const RecipeContainer = styled.div`
  text-align: center;
  padding: ${props => props.theme.spacing(18, 9, 15)};
  background-color: ${props =>
    props.backgroundColor && props.theme.colors.get(props.backgroundColor)};
  @media ${props => props.theme.laptop} {
    grid-column: auto;
    grid-row: 1;
  }
`;

export const AnalysisContainer = styled.div`
  margin-top: ${props => props.theme.spacing(2)};
`;

export const ImageContainer = styled.div`
  background-color: ${props =>
    props.backgroundColor && props.theme.colors.get(props.backgroundColor)};
  @media ${props => props.theme.laptop} {
    grid-column: ${props => (props.imageFirst ? '1' : '2')};
    grid-row: 1 / span 2;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  min-width: 400px;
  height: auto;
  background-color: ${props =>
    props.backgroundColor && props.theme.colors.get(props.backgroundColor)};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  background-color: ${props =>
    props.backgroundColor && props.theme.colors.get(props.backgroundColor)};

  @media ${props => props.theme.laptop} {
    display: grid;
    align-items: auto;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline(props.backgroundColor)}
`;
