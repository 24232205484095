'use client';
import styled from 'styled-components';

export const StyledFaqList = styled.div`
  background: ${props => props.theme.colors.secondary.egg};
  padding: ${props => props.theme.spacing(14, 0)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(20, 0)};
  }
`;

export const Inner = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: ${props => props.theme.spacing(0, 5)};
  width: 846px;
`;

export const List = styled.h2`
  padding-top: ${props => props.theme.spacing(8)};
  @media ${props => props.theme.laptop} {
    padding-top: ${props => props.theme.spacing(10)};
  }
`;

export const ButtonContainer = styled.div`
  margin-top: ${props => props.theme.spacing(10)};
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  ${props => props.theme.getFocusOutline()}
`;
