'use client';
import styled from 'styled-components';

export const StyledStickyNav = styled.div`
  width: 100%;
  position: ${props => (props.$relativePosition ? 'relative' : 'fixed')};
  bottom: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateY(
    ${props =>
      props.$shown || props.$relativePosition
        ? `0px`
        : `${props.$height + 10}px`}
  );
  background: ${props => props.theme.colors.background.lightest};
  z-index: 100;
  box-shadow: 0 -4px 16px 0 rgba(94, 88, 78, 0.1);
`;
