import * as yup from 'yup';
import { EmailCaptureBar } from '@web/components';
import { nullableImageFromCMSSchema } from '../schemas';
import { schema as emailCaptureSchema } from './email_capture';

export const component = EmailCaptureBar;

export const schema = yup
  .object({
    heading: yup.string().required(),
    headingLevel: yup.string(),
    subheading: yup.string(),
    buttonVariant: yup.string(),
    image: nullableImageFromCMSSchema,
    emailCapture: emailCaptureSchema.required(),
  })
  .transform(value => ({
    heading: value.heading,
    headingLevel: value.heading_level,
    subheading: value.subheading,
    buttonVariant: value.button_variant,
    image: value.image[0]?.asset || null,
    emailCapture: value.email_capture[0],
  }));
