'use client';
import styled from 'styled-components';

export const StyledCountdownHeadingContainer = styled.div`
  ${props => props.theme.typography.heading4}
  width: 100%;
  background-color: ${({ theme, $backgroundColor }) =>
    theme.colors.get($backgroundColor, 'background.base')};
  color: ${({ theme, $textColor }) => theme.colors.get($textColor, '#fff')};
  padding: ${props => props.theme.spacing(4)};
  display: flex;
  gap: ${props => props.theme.spacing(3)};
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.tablet} {
    flex-direction: row;
    justify-content: center;
    padding: ${props => props.theme.spacing(6)};
  }
`;
