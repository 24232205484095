'use client';
import styled from 'styled-components';

export const StyledTestimonial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;
  max-width: 500px;
`;

export const ImageContainer = styled.div`
  display: inline-block;
  position: relative;
  overflow: hidden;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-bottom: ${props => props.theme.spacing(8)};
`;

export const Subtext = styled.p`
  ${({ theme }) => theme.typography.subheading2};
  margin-top: ${props => props.theme.spacing(8)};
`;

export const StarRatingContainer = styled.div`
  margin: ${props => props.theme.spacing(8, 0)};
`;
