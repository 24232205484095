import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { SmolLink } from '../SmolLink';
import { BreadcrumbsContainer, WithoutLink } from './styles';

export const BreadCrumbLink = ({ link, text, isLastItem }) => {
  const variantColor = isLastItem ? 'text.primary' : 'text.secondary';
  return (
    <BreadcrumbsContainer>
      {link ? (
        <SmolLink href={link} underline={!isLastItem} variant={variantColor}>
          {text}
        </SmolLink>
      ) : (
        <WithoutLink>{text}</WithoutLink>
      )}
      {!isLastItem && (
        <Icon
          iconName="slash-forward"
          size="16px"
          alt={text}
          color="text.secondary"
          id="bread_crumb"
        />
      )}
    </BreadcrumbsContainer>
  );
};

BreadCrumbLink.displayName = 'BreadCrumbLink';

BreadCrumbLink.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
  isLastItem: PropTypes.bool,
};
