'use client';
import styled from 'styled-components';

export const StyledRecipeInformationSection = styled.div`
  background-color: ${props => props.theme.colors.get(props.backgroundColor)};
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(15, 5)};

  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(10, 20)};
  }
  @media ${props => props.theme.laptopL} {
    padding: ${props => props.theme.spacing(20, 25, 15)};
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(0, 0, 8, 0)};
  text-align: center;
`;

export const Subheadline = styled.p`
  ${props => props.theme.typography.heading5};
`;

export const Headline = styled.h2`
  ${props => props.theme.typography.heading2}
`;

export const Container = styled.div`
  @media ${props => props.theme.tablet} {
    display: flex;
  }
`;

export const ImagePanel = styled.div`
  padding: ${props => props.theme.spacing(0, 5)};
  max-height: 500px;
  @media ${props => props.theme.tablet} {
    flex: 0 0 auto;
    padding: ${props => props.theme.spacing(4, 0, 10, 13)};
    width: 50%;
  }

  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(9, 0, 15, 18)};
  }

  @media ${props => props.theme.laptopL} {
    padding: ${props => props.theme.spacing(12, 0, 16, 20)};
  }
`;

export const ImageOuter = styled.div`
  height: auto;
  padding-bottom: 100%;
  position: relative;
  @media ${props => props.theme.tablet} {
    height: 100%;
  }
`;

export const ImageWrapper = styled.div`
  border-radius: 32px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
`;

export const DetailsWrapper = styled.div`
  padding: ${props => props.theme.spacing(5, 5, 10)};
  @media ${props => props.theme.tablet} {
    flex: 0 0 auto;
    min-height: calc(100vh - 60px);
    padding: ${props => props.theme.spacing(8, 13)};
    width: 50%;
  }
  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(10, 20)};
  }
  @media ${props => props.theme.laptopL} {
    padding: ${props => props.theme.spacing(13, 25, 15)};
  }
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  @media ${props => props.theme.tablet} {
    max-width: 510px;
  }
`;

export const DetailsHeader = styled.h3`
  ${props => props.theme.typography.heading3};
  color: ${props => props.theme.colors.text.light};
  display: none;
  order: -1;

  @media ${props => props.theme.tablet} {
    display: block;
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

export const DetailsIcon = styled.div`
  display: inline-block;
  padding-left: ${props => props.theme.spacing(3)};
  transform: translateY(12.5%);
  vertical-align: top;
`;

export const DetailsDescription = styled.div``;

export const NoticeBody = styled.p`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.status.errorPrimary};
  margin-bottom: ${props => props.theme.spacing(6)};
  order: -1;
`;

export const DetailsBody = styled.div`
  ${props => props.theme.typography.body};
  color: ${props => props.theme.colors.text.lightest};
  margin-bottom: ${props => props.theme.spacing(6)};
  order: -1;
`;

export const ButtonWrapper = styled.div`
  margin: ${props => props.theme.spacing(0, 0, 8)};
  order: -1;
  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(6, 0, 0)};
    order: unset;
  }
`;
