'use client';
import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  border-radius: ${({ theme }) => theme.spacing(8)};
  gap: ${({ theme }) => theme.spacing(3)};
  background-color: ${props => props.theme.colors.neutral.bone};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(5, 0, 3)};
  margin: ${({ theme }) => theme.spacing(1, 4)};
`;

export const HeaderImage = styled.div`
  width: 130px;
  height: 130px;
  border-radius: ${({ theme }) => theme.spacing(4)};
  overflow: hidden;
`;

export const HeaderBadge = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: ${({ theme }) => theme.spacing(1, 4)};
  margin: ${({ theme }) => theme.spacing(1)};
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const ContentCallout = styled.p`
  ${props => props.theme.typography.subheading2};
`;

export const ContentBenefit = styled.p`
  ${props => props.theme.typography.heading4};
`;

export const ContentDescription = styled.div`
  ${props => props.theme.typography.body};

  p {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(2, 0)};
  }

  ul {
    padding-inline-start: ${({ theme }) => theme.spacing(6.5)};
  }
`;

export const Footer = styled.div`
  text-align: center;
  margin: ${({ theme }) => theme.spacing(2, 4)};
  padding: ${({ theme }) => theme.spacing(2, 0, 6)};
`;

export const FooterButton = styled.div`
  display: flex;
  justify-content: center;

  a {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;
