'use client';
import styled from 'styled-components';

export const TimelineContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(10, 2)};
`;

export const TimelineContent = styled.div`
  width: 100%;
  max-width: 1320px;
  overflow-x: scroll;
  padding: ${props => props.theme.spacing(3, 2)};
  margin: ${props => props.theme.spacing(0, 0, 9, 0)};
  display: flex;
  flex-direction: column;
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const TimelineHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing(12)};
`;

export const TimelineMarkerContainer = styled.div`
  display: flex;
  width: ${props => (props.items === 4 ? '350%' : '240%')};
  align-items: start;
  justify-content: center;
  padding: ${props => props.theme.spacing(3, 7, 7, 7)};
  @media ${props => props.theme.tabletS} {
    display: flex;
    width: 100%;
  }
`;

export const TimelineArrowContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${props => (props.items === 4 ? '350%' : '240%')};
  @media ${props => props.theme.tabletS} {
    width: 97%;
  }
`;

export const TimelineArrowLine = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.background.dark};
  width: 100%;
`;

export const TimelineArrow = styled.div`
  border: ${({ theme }) => theme.colors.border.dark} solid;
  border-width: 0 2px 2px 0;
  padding: ${props => props.theme.spacing(1)};
  transform: rotate(-45deg);
  margin-left: ${props => props.theme.spacing(-2.5)};
`;

export const StyledTimelineMarker = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: ${props => props.theme.spacing(10)};
  width: 100%;
  @media ${props => props.theme.tabletS} {
    width: 60vw;
  }
`;

export const SecondaryColorText = styled.div`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const TimelineMarkerTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-top: ${props => props.theme.spacing(6)};
  text-align: left;
`;

export const TimelineMarkerDot = styled.div`
  ${props => props.theme.getContrastColors(props.variant)}
  height: ${props => props.theme.spacing(7)};
  width: ${props => props.theme.spacing(7)};
  border-radius: 50%;
  display: inline-block;
  margin-top: ${props => props.theme.spacing(-7.5)};
  left: 1;
  margin-left: -95%;
`;

export const StyledParagraph = styled.div`
  ${({ theme }) => theme.typography.body}
  margin-top: ${props => props.theme.spacing(2)};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: left;
  max-height: 250px;
`;

export const CarouselDotsContainer = styled.div`
  display: flex;
  margin: ${props => props.theme.spacing(0, 0, 6, 0)};
  @media ${props => props.theme.tabletS} {
    display: none;
  }
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;
