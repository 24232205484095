'use client';
import styled from 'styled-components';

export const TimelineScrollPromoOuter = styled.section`
  background-color: ${({ theme }) => theme.colors.background.base};
  @media ${props => props.theme.tablet} {
    display: flex;
    height: ${props => 100 * props.count}vh;
    flex-direction: column;
  }
`;

export const TimelineScrollPromoContainer = styled.div`
  @media ${props => props.theme.tablet} {
    top: 0;
    position: sticky;
  }
`;

export const TimelineScrollPromoWrapper = styled.div`
  background-color: ${props => props.theme.colors.background.base};
  color: ${props => props.theme.colors.text.secondary};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(14, 5)};
  margin: 0 auto;
  max-width: 1440px;

  @media ${props => props.theme.tablet} {
    min-height: 100vh;
    padding: ${props => props.theme.spacing(21, 10, 10)};
  }

  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(21, 20, 18)};
  }
`;

export const StyledTimelineScrollPromo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing(10)};
  width: 100%;

  @media ${props => props.theme.tablet} {
    flex-flow: row nowrap;
  }
`;

export const StepsSection = styled.div`
  flex: 1;
  @media ${props => props.theme.tablet} {
    flex: 0 0 auto;
    padding-left: ${props => props.theme.spacing(5)};
    width: 50%;
  }

  @media ${props => props.theme.laptop} {
    padding-left: ${props => props.theme.spacing(15)};
  }
`;
export const Steps = styled.div`
  position: relative;
  z-index: 0;
`;

export const Step = styled.div`
  display: flex;
  flex-flow: row nowrap;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
  @media ${props => props.theme.tablet} {
    opacity: ${props => (props.active ? '1' : '0.2')};
  }
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing(6)};

    @media ${props => props.theme.laptop} {
      margin-bottom: ${props => props.theme.spacing(10)};
    }
  }
`;
export const StepBody = styled.div`
  text-align: left;
`;

export const StepNumber = styled.div`
  background-color: ${props => props.theme.colors.secondary.egg};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.theme.spacing(14)};
  width: ${props => props.theme.spacing(14)};
  flex: 0 0 auto;
  ${props => props.theme.typography.heading2};
  margin-right: ${props => props.theme.spacing(5)};
  @media ${props => props.theme.tabletL} {
    height: ${props => props.theme.spacing(17)};
    width: ${props => props.theme.spacing(17)};
  }
  @media ${props => props.theme.laptop} {
    margin-right: ${props => props.theme.spacing(8)};
  }
`;

export const SplashImageContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(10)};
  padding: ${props => props.theme.spacing(0, 3)};
  width: 100%;
  @media ${props => props.theme.tablet} {
    flex: 0 0 auto;
    margin-bottom: 0;
    padding: ${props => props.theme.spacing(0, 12, 0, 0)};
    width: 50%;
  }
`;

export const SplashImageFrame = styled.div`
  border-radius: 32px;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
  @media ${props => props.theme.tablet} {
    border-radius: 40px;
  }
`;

export const SplashImage = styled.div`
  bottom: 0;
  left: 0;
  opacity: ${props => (props.active ? 1 : 0)};
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.5s ease-in-out;

  &:not(:first-child) {
    display: none;
    @media ${props => props.theme.tablet} {
      display: block;
    }
  }
  @media ${props => props.theme.tablet} {
    opacity: ${props => (props.active ? 1 : 0)};
  }
`;

export const StepHeadline = styled.h3`
  ${props => props.theme.typography.heading3};
  padding-bottom: ${props => props.theme.spacing(1)};
  @media ${props => props.theme.tabletL} {
    padding-bottom: ${props => props.theme.spacing(2)};
  }
`;

export const StepText = styled.p`
  ${props => props.theme.typography.body};
  margin-bottom: 0;
  @media ${props => props.theme.tabletL} {
    ${props => props.theme.typography.body3};
  }
`;

export const Badge = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
  max-width: 118px;

  @media ${props => props.theme.tablet} {
    right: 32px;
    top: 32px;
  }
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;
