'use client';
import styled from 'styled-components';

export const TestimonialContainer = styled.div`
  background-color: ${props =>
    props.$bgColor && props.theme.colors.get(props.$bgColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${props => props.theme.laptop} {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${props => props.theme.spacing(1, 4, 1, 4)};
  @media ${props => props.theme.laptop} {
    max-width: 50%;
    margin: ${props => props.theme.spacing(16)};
  }
`;

export const ImageContainer = styled.div`
  height: 350px;
  max-width: 400px;
  overflow: hidden;
  margin: ${props => props.theme.spacing(4)};
`;

export const Subtext = styled.p`
  ${({ theme }) => theme.typography.subheading2};
  margin-top: ${props => props.theme.spacing(6)};
`;

export const StarRatingContainer = styled.div`
  display: block;
  margin: ${props => props.theme.spacing(6, 0)};
`;
