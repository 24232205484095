import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledBoxedDateTimeContainer,
  StyledBoxContainer,
  StyledBoxTimeSlot,
  StyledBoxTitleSlot,
} from './styles';

function addLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

export const BoxedDateTimeDisplay = ({
  days,
  hours,
  minutes,
  seconds,
  textColor = 'text.primary',
}) => {
  return (
    <StyledBoxedDateTimeContainer role="timer">
      <StyledBoxContainer>
        <StyledBoxTimeSlot $textColor={textColor}>
          {addLeadingZeros(days, 2)}
        </StyledBoxTimeSlot>
        <StyledBoxTitleSlot $textColor={textColor}>Days</StyledBoxTitleSlot>
      </StyledBoxContainer>
      <StyledBoxContainer>
        <StyledBoxTimeSlot $textColor={textColor}>
          {addLeadingZeros(hours, 2)}
        </StyledBoxTimeSlot>
        <StyledBoxTitleSlot $textColor={textColor}>Hours</StyledBoxTitleSlot>
      </StyledBoxContainer>
      <StyledBoxContainer>
        <StyledBoxTimeSlot $textColor={textColor}>
          {addLeadingZeros(minutes, 2)}
        </StyledBoxTimeSlot>
        <StyledBoxTitleSlot $textColor={textColor}>Minutes</StyledBoxTitleSlot>
      </StyledBoxContainer>
      <StyledBoxContainer>
        <StyledBoxTimeSlot $textColor={textColor}>
          {addLeadingZeros(seconds, 2)}
        </StyledBoxTimeSlot>
        <StyledBoxTitleSlot $textColor={textColor}>Seconds</StyledBoxTitleSlot>
      </StyledBoxContainer>
    </StyledBoxedDateTimeContainer>
  );
};

BoxedDateTimeDisplay.propTypes = {
  days: PropTypes.number.isRequired,
  hours: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  seconds: PropTypes.number.isRequired,
  textColor: PropTypes.string,
};
