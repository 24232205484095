import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import {
  StyledLetter,
  Container,
  Content,
  TopIcon,
  Heading,
  BottomIcon,
  PhotosWrapper,
  ButtonContainer,
  ButtonWrapper,
} from './styles.js';

export const Letter = ({
  headline,
  children,
  topIcon,
  bottomIcon,
  link,
  photos = null,
}) => {
  return (
    <StyledLetter>
      <Container>
        <Content>
          <TopIcon>
            <ResponsiveImage {...topIcon} />
          </TopIcon>
          <Heading>{headline}</Heading>
          {children}
          <BottomIcon>
            <ResponsiveImage {...bottomIcon} />
          </BottomIcon>
          {link && (
            <ButtonContainer>
              <ButtonWrapper>
                <CtaButton variant={link.variant} href={link.target}>
                  {link.text}
                </CtaButton>
              </ButtonWrapper>
            </ButtonContainer>
          )}
        </Content>
        {photos && <PhotosWrapper>{photos}</PhotosWrapper>}
      </Container>
    </StyledLetter>
  );
};

Letter.displayName = 'Letter';

Letter.propTypes = {
  headline: PropTypes.string.isRequired,
  topIcon: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  children: PropTypes.node,
  bottomIcon: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    variant: PropTypes.string,
  }),
  photos: PropTypes.node,
};
