import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from '@web/molecules';
import { FoodComparisonChartItem } from './FoodComparisonChartItem';
import { FoodContainer } from './styles';

export const FoodComparison = ({ charts }) => {
  return (
    <FoodContainer>
      <Carousel dotPadding={8} buttonPosition="17">
        {charts.map((props, index) => (
          <FoodComparisonChartItem key={index} {...props} />
        ))}
      </Carousel>
    </FoodContainer>
  );
};

FoodComparison.displayName = 'FoodComparison';

FoodComparison.propTypes = {
  charts: PropTypes.array.isRequired,
};
