'use client';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, CarouselDots } from '@web/molecules';
import { Heading } from '@web/atoms';
import { FactsTableItem } from '../FactsTable/FactsTableItem';
import { sideScroll } from '../../hooks';
import OllieIcon from './ollie_icon.svg';
import {
  StyledFactsTable,
  Container,
  Content,
  TableColumn,
  TableColumnItem,
  ColumnHeader,
  ColumnsContainer,
  ColumnItems,
  CarouselDotsContainer,
  Paragraph,
} from './styles.js';

export const FactsTable = ({ heading, description, rows, columns }) => {
  const contentWrapper = useRef(null);
  const [activePage, setActivePage] = useState(0);

  const onScroll = () => {
    let scrollFlag = contentWrapper?.current.scrollLeft;
    if (scrollFlag === 0) {
      setActivePage(0);
    }
    if (scrollFlag > 90 && scrollFlag < 260) {
      setActivePage(1);
    }
    if (scrollFlag > 200 && columns.length === 4) {
      setActivePage(2);
    }
    if (scrollFlag > 300 && columns.length > 4) {
      setActivePage(2);
    }
  };

  return (
    <StyledFactsTable background={'background.base'}>
      <Heading
        headingLevel="h2"
        typography={'heading2'}
        headingText={heading}
        position="center"
        paddingTop={2}
      />
      <Paragraph>{description}</Paragraph>
      <Container ref={contentWrapper} onScroll={onScroll}>
        <Content>
          {rows && (
            <ColumnItems>
              <FactsTableItem key={'empty'} header={''} subheader={''} />
              {rows.map((feature, i) => (
                <FactsTableItem
                  key={i}
                  header={feature.heading}
                  subheader={feature.subheading}
                />
              ))}
            </ColumnItems>
          )}
          {columns && (
            <ColumnsContainer items={columns.length}>
              {columns.map((column, i) => (
                <TableColumn
                  label={column.label}
                  highlighted={column.highlighted}
                  key={i}
                  items={columns.length}
                >
                  <TableColumnItem>
                    {column.highlighted === true ? (
                      <ResponsiveImage
                        src={OllieIcon.src}
                        alt="Ollie logo"
                        width={OllieIcon.width}
                        height={OllieIcon.height}
                      />
                    ) : (
                      <ColumnHeader>{column.label}</ColumnHeader>
                    )}
                  </TableColumnItem>

                  {rows.map((row, j) => (
                    <TableColumnItem highlighted={column.highlighted} key={j}>
                      {row.factsTableFacts[i]?.text}
                    </TableColumnItem>
                  ))}
                </TableColumn>
              ))}
            </ColumnsContainer>
          )}
        </Content>
      </Container>
      <CarouselDotsContainer>
        <CarouselDots
          activePage={activePage}
          pages={3}
          variant="darkestFur"
          onClick={page =>
            sideScroll(columns, page, contentWrapper.current, 25, 150, 160)
          }
        ></CarouselDots>
      </CarouselDotsContainer>
    </StyledFactsTable>
  );
};

FactsTable.displayName = 'FactsTable';

FactsTable.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      subheading: PropTypes.string,
      factTableFacts: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      highlighted: PropTypes.bool,
    }),
  ).isRequired,
};
