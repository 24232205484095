import React from 'react';
import PropTypes from 'prop-types';
import { Body, Name, StyledEndorsement, BodyContainer } from './styles';

export const Endorsement = ({ body, name }) => {
  return (
    <StyledEndorsement>
      <BodyContainer>
        <Body>{body}</Body>
      </BodyContainer>
      <Name>{name}</Name>
    </StyledEndorsement>
  );
};

Endorsement.displayName = 'Endorsement';

Endorsement.propTypes = {
  body: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
