import { shared } from '../../config';

export const GLADLY_CONFIG = {
  api: shared.GLADLY_API_URL || 'https://olliepets.gladly.com',
  orgId: shared.GLADLY_ORG_ID || 'Rn_90a4GRL-IIrUIIzmfwA',
  brandId: shared.GLADLY_BRAND_ID || '2L4mlRaVRPG89zAcVQyPtQ',
  cdn: shared.GLADLY_CDN || 'https://cdn.gladly.com',
  selector: '#gladly-help-center',
};

export const GLADLY_INIT_SCRIPT_ID = 'GladlyInitScript';

export const loadGladly = callback => {
  const existingScript = document.getElementById(GLADLY_INIT_SCRIPT_ID);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://cdn.gladly.com/help-center/hcl.js';
    script.id = GLADLY_INIT_SCRIPT_ID;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
