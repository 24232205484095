import * as yup from 'yup';
import { SplitScreenGridItem } from '@web/components';
import { richTextFromCMSSchema } from '../schemas';

export const component = SplitScreenGridItem;

export const schema = yup
  .object({
    headline: yup.string().nullable(),
    text: richTextFromCMSSchema,
    image: yup.string().required(),
    alt: yup.string().required(),
    badge: yup.string().nullable(),
    backgroundColor: yup.string().nullable(),
  })
  .transform(({ image, badge, headline, text, background_color }) => ({
    headline,
    badge,
    text: text,
    backgroundColor: background_color,
    image: image.filename,
    alt: image.alt || image.name,
  }));
