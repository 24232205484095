import React from 'react';
import PropTypes from 'prop-types';
import { DateTimeDisplay, SmolLink } from '@web/atoms';
import { useCountdown } from '../../hooks';
import { StyledCountdownHeadingContainer } from './styles';

export const CountdownClockHeading = ({
  countdownText,
  targetDate,
  link,
  backgroundColor,
  textColor,
}) => {
  const { days, hours, minutes, seconds } = useCountdown(targetDate) || {};

  return (
    <>
      {days !== undefined && (
        <StyledCountdownHeadingContainer
          $backgroundColor={backgroundColor}
          $textColor={textColor}
        >
          {countdownText}
          <DateTimeDisplay
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
          {link && (
            <SmolLink underline href={link.target} variant="text.lightest">
              {link.text}
            </SmolLink>
          )}
        </StyledCountdownHeadingContainer>
      )}
    </>
  );
};

CountdownClockHeading.propTypes = {
  targetDate: PropTypes.instanceOf(Date).isRequired, //eslint-disable-line react/forbid-prop-types
  countdownText: PropTypes.string.isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  backgroundColor: PropTypes.string.isRequired,
  textColor: PropTypes.string,
};
