import styled from 'styled-components';

export const CardContainer = styled.div`
  max-width: 383px;
  margin-bottom: ${props => props.theme.spacing(2)};
  @media ${props => props.theme.tablet} {
    justify-self: ${props => props.$align};
    margin-bottom: 0;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  img {
    border-radius: 40px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${props => props.theme.typography.subheading1};
  margin-top: ${props => props.theme.spacing(4)};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const NumberCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.theme.spacing(8)};
  height: ${props => props.theme.spacing(8)};
`;

export const BodyWrapper = styled.p`
  ${props => props.theme.typography.body};
  ${props => props.theme.fontFamily.base}
  color: ${props => props.theme.colors.text.secondary};
  text-align: left;
`;
