import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@web/atoms';
import { StyledRecipeNutrient } from './styles.js';

export const RecipeNutrient = ({ label, value }) => {
  return (
    <StyledRecipeNutrient>
      <Heading headingLevel="h3" typography="heading6" headingText={label} />
      <span>{value}</span>
    </StyledRecipeNutrient>
  );
};
RecipeNutrient.displayName = 'RecipeNutrient';
RecipeNutrient.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
