import BoxFoodPacks from '../../images/Homepage/box-food-packs.svg';
import BoxFeedingGuide from '../../images/Homepage/box-feeding-guide.svg';
import BoxScoops from '../../images/Homepage/box-scoops.svg';
import BoxPuptainer from '../../images/Homepage/box-puptainer.svg';

// We're plucking off `.src` here because this constant is hard-coded as the "CMS data"
// The `yup` schemas are expecting a string here for `image`.
// TODO(James) flip these back to "normal" when the data actually comes from the CMS.
export const BOX_CONTENTS_ITEMS = [
  {
    alt: 'ollie food packs illustration',
    image: BoxFoodPacks.src,
    text: 'First two weeks of food',
  },
  {
    alt: 'ollie feeding guide illustration',
    image: BoxFeedingGuide.src,
    text: 'Feeding guide',
  },
  {
    alt: 'two scoopers for food',
    image: BoxScoops.src,
    text: 'Scoop',
    tag: 'Free',
  },
  {
    alt: 'pup-tainer illustration',
    image: BoxPuptainer.src,
    text: 'Pup-tainer',
    tag: 'Free',
  },
];
