import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import { Heading } from '@web/atoms';
import { EmailCapture } from '../EmailCaptureBar/EmailCapture';
import {
  StyledSimpleCalloutPanel,
  Inner,
  ImageWrapper,
  Content,
  Body,
  ButtonWrapper,
  InputWrapper,
  ButtonContainer,
} from './styles';

export const SimpleCalloutPanel = ({
  image,
  heading,
  headingLevel,
  headingTopography,
  backgroundColor = 'neutral.bone',
  fillColor = 'background.base',
  fontColor = 'text.primary',
  cta,
  description,
  alignImage = 'left',
}) => {
  return (
    <StyledSimpleCalloutPanel $fillColor={fillColor} $fontColor={fontColor}>
      <Inner $backgroundColor={backgroundColor}>
        <ImageWrapper $alignImage={alignImage}>
          <ResponsiveImage
            {...image}
            width={image.width}
            height={image.height}
          />
        </ImageWrapper>
        <Content>
          <Heading
            headingLevel={headingLevel}
            typography={headingTopography}
            headingText={heading}
            backgroundColor={backgroundColor}
            position="center"
          />
          <Body>{description}</Body>

          {cta?.component === 'link' ? (
            <ButtonContainer>
              <ButtonWrapper $backgroundColor={backgroundColor}>
                <CtaButton href={cta.target} variant="chia">
                  {cta.text}
                </CtaButton>
              </ButtonWrapper>
            </ButtonContainer>
          ) : (
            cta?.component === 'email_capture' && (
              <InputWrapper>
                <EmailCapture {...cta} buttonVariant={'chia'} />
              </InputWrapper>
            )
          )}
        </Content>
      </Inner>
    </StyledSimpleCalloutPanel>
  );
};

SimpleCalloutPanel.displayName = 'SimpleCalloutPanel';

SimpleCalloutPanel.propTypes = {
  image: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  cta: PropTypes.object,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
    .isRequired,
  headingTopography: PropTypes.string,
  backgroundColor: PropTypes.string,
  fillColor: PropTypes.string,
  fontColor: PropTypes.string,
  description: PropTypes.node,
  alignImage: PropTypes.string,
};
