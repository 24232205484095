import * as yup from 'yup';
import { AddOnSummaryCard } from '@web/molecules';
import { nullableImageFromCMSSchema } from '../schemas.js';

export const component = AddOnSummaryCard;

export const schema = yup
  .object({
    title: yup.string(),
    image: nullableImageFromCMSSchema,
    ctaHref: yup.string(),
    ctaButtonText: yup.string(),
    ctaButtonVariant: yup.string(),
    description: yup.string(),
  })
  .transform(section => ({
    title: section.title,
    image: section.image,
    description: section.description,
    ctaButtonText: section.cta_button_text,
    ctaButtonVariant: section.cta_button_variant,
    ctaHref: section.cta_href,
  }));
