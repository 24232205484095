import React from 'react';
import PropTypes from 'prop-types';
import { Carousel, CtaButton } from '@web/molecules';
import { Endorsement } from './Endorsement';
import {
  EndorsementsOutsideContainer,
  Headline,
  ButtonWrapper,
  GetStartedContainer,
} from './styles';

export const Endorsements = ({ headline, endorsements, link, level }) => {
  const dotImages = endorsements.map(e => e.photo);

  return (
    <EndorsementsOutsideContainer>
      <Headline as={level}>{headline}</Headline>
      <Carousel itemsToShow={1} showArrows={false} dotImages={dotImages}>
        {endorsements.map(e => (
          <Endorsement {...e} key={e.name} />
        ))}
      </Carousel>
      {link && (
        <GetStartedContainer>
          <ButtonWrapper>
            <CtaButton variant="spinach" href={link.target}>
              {link.text}
            </CtaButton>
          </ButtonWrapper>
        </GetStartedContainer>
      )}
    </EndorsementsOutsideContainer>
  );
};

Endorsements.displayName = 'Endorsements';

Endorsements.propTypes = {
  headline: PropTypes.string.isRequired,
  endorsements: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      photo: PropTypes.object.isRequired,
    }),
  ).isRequired,
  link: PropTypes.shape({
    target: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
