'use client';
import styled from 'styled-components';

export const StyledList = styled.div`
  display: grid;
  gap: ${props => (props.$gap ? props.theme.spacing(props.$gap) : 0)};
`;

export const ListHeader = styled.h2`
  ${props => props.theme.typography.heading3}
  padding-bottom: ${props => props.theme.spacing(3)};
`;
