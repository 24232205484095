'use client';
import styled from 'styled-components';

export const StyledTestimonial = styled.div`
  align-self: flex-start;
  text-align: left;
  transition: 0.3s;
  background: #ffffff;
  border-radius: 32px;
  overflow: hidden;
  max-width: 450px;
  margin: auto;
  @media ${props => props.theme.tablet} {
    margin: ${props => props.theme.spacing(0, 3)};
  }
`;

export const TextContainer = styled.div`
  padding: ${props => props.theme.spacing(4, 6, 6)};
`;

export const StarRating = styled.div`
  margin-bottom: ${props => props.theme.spacing(2)};
  &::before {
    font-size: 1rem;
    letter-spacing: 2px;
    content: '★★★★★';
    background-color: ${props => props.theme.colors.hero.sweetPotato};
    background-clip: text;
    /* stylelint-disable-next-line */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const Name = styled.h4`
  ${props => props.theme.typography.heading5}
`;

export const Description = styled.span`
  ${props => props.theme.typography.label}
  color: ${props => props.theme.colors.text.tertiary};
`;

export const TestimonialsContainer = styled.section`
  max-width: 1440px;
  margin: auto;
  padding: ${props => props.theme.spacing(14, 0)};
  display: flex;
  flex-flow: column nowrap;
  ${StyledTestimonial} {
    margin-bottom: ${props => props.theme.spacing(8)};
    @media ${props => props.theme.tablet} {
      margin-bottom: ${props => props.theme.spacing(12)};
    }
  }
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(16, 5)};
  }
  @media ${props => props.theme.laptop} {
    padding: ${props => props.theme.spacing(20, 10)};
  }
`;

export const Headline = styled.h2`
  ${props => props.theme.typography.heading2}
  @media ${props => props.theme.laptop} {
    ${props => props.theme.typography.headingMastiff}
  }
`;

export const SubHeadline = styled.h3`
  ${({ theme }) => theme.typography.subheading4};
`;

export const ContainerHeader = styled.div`
  color: ${props => props.theme.colors.text.secondary};
  grid-column: 1 / span 3;
  text-align: center;
  margin-bottom: ${props => props.theme.spacing(8)};
  @media ${props => props.theme.laptop} {
    margin-bottom: ${props => props.theme.spacing(12)};
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline(props.$bgColor)}
`;

export const CarouselContainer = styled.div`
  margin-bottom: 0;
`;

export const AssetWrapper = styled.div`
  max-height: fit-content;
  @media ${props => props.theme.tablet} {
    max-height: 200px;
    overflow: hidden;
  }
  mux-player {
    &::part(center play button) {
      display: flex;
      min-width: 90px;
      min-height: 90px;
      padding: 22px 22px 22px 24px;
    }
  }
`;
