import {
  treatsModalsContent,
  supplementsModalsContent,
} from '../../testing/modalConstants';

export const TABS = {
  TR: ['details', 'nutrition', 'ingredients'],
  SP: ['details', 'nutrition', 'serving', 'ingredients'],
};

export const addonsModalsContent = [
  ...treatsModalsContent,
  ...supplementsModalsContent,
];

export const pupInfo = {
  pupName: 'Panda',
  pupPronoun: 'him',
  addonServingFrequency: '2 weeks',
};

export const ADD_ON_QUICK_VIEW_PROPS = {
  onClickClose: () => {},
  onSubmitAddonAddition: () => {},
  checkedAddons: [],
  pupInfo,
};
