'use client';
import styled, { css } from 'styled-components';

const FULL_WIDTH = '875px';
const MOBILE_WIDTH = '311px';
const FULL_WIDTH_BREAKPOINT = `(min-width: 923px)`; // width + padding

export const StyledIngredientModal = styled.div`
  min-height: 675px;
  max-width: ${MOBILE_WIDTH};
  border-radius: 32px;
  @media ${FULL_WIDTH_BREAKPOINT} {
    min-height: 452px;
    max-width: ${FULL_WIDTH};
  }
`;

export const Container = styled.div`
  display: flex;
`;

const imageContainer = css`
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const LargeImageContainer = styled.div`
  ${imageContainer}
  flex: 0 0 420px;
  display: none;
  @media ${FULL_WIDTH_BREAKPOINT} {
    display: flex;
  }
`;

export const MobileImageContainer = styled.div`
  ${imageContainer}
  height: 180px;
  margin-bottom: ${props => props.theme.spacing(8)};
  @media ${FULL_WIDTH_BREAKPOINT} {
    display: none;
  }
`;

export const Content = styled.div`
  @media ${FULL_WIDTH_BREAKPOINT} {
    padding-top: ${props => props.theme.spacing(8)};
    padding-left: ${props => props.theme.spacing(10)};
  }
`;

export const Headline = styled.h1`
  ${props => props.theme.typography.heading3};
  padding-bottom: ${props => props.theme.spacing(4)};
  @media ${FULL_WIDTH_BREAKPOINT} {
    ${props => props.theme.typography.heading2};
    padding-bottom: ${props => props.theme.spacing(6)};
  }
`;

export const Subheadline = styled.h3`
  ${props => props.theme.typography.heading6};
  padding-bottom: ${props => props.theme.spacing(2)};
`;

export const TabNav = styled.div`
  margin-bottom: ${props => props.theme.spacing(10)};
`;

export const TabButton = styled.button`
  ${props => props.theme.typography.subheading2};
  background: none;
  border: 0;
  color: ${props =>
    props.active
      ? props.theme.colors.text.primary
      : props.theme.colors.border.fur};
  padding: 2px;
  margin-right: ${props => props.theme.spacing(6)};
  text-transform: uppercase;
  user-select: none;
  border-bottom: ${props =>
    props.active ? `1px solid ${props.theme.colors.text.primary}` : '0'};

  &:focus {
    border-radius: ${props => props.theme.spacing(2)};
    outline: 2px solid ${({ theme }) => theme.colors.border.dark};
  }
`;

export const StyledNutritionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.theme.spacing(7)};

  @media ${props => props.theme.laptop} {
    max-width: 50%;
  }
`;

export const Body = styled.div`
  ${props => props.theme.typography.body};
`;
