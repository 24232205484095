import * as yup from 'yup';
import { FactsTable } from '@web/components';
import { schema as factsTableRow } from './facts_table_row';

export const component = FactsTable;

export const schema = yup.object({
  heading: yup.string().required(),
  description: yup.string(),
  rows: yup.array(factsTableRow).required(),
  columns: yup.array().required(),
});
