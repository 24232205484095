import * as yup from 'yup';
import { PressLogos } from '@web/components';
import { schema as pressQuoteSchema } from './press_quote';

export const component = PressLogos;

export const schema = yup
  .object({
    headingText: yup.string(),
    typography: yup.string(),
    position: yup.string(),
    backgroundColor: yup.string(),
    fontColor: yup.string(),
    publications: yup.array(pressQuoteSchema).required(),
    verticalPadding: yup.number(),
    horizontalPadding: yup.number(),
    level: yup.string(),
  })
  .transform(value => ({
    headingText: value.heading_text,
    typography: value.typography || undefined,
    position: value.position || undefined,
    backgroundColor: value.background_color || undefined,
    fontColor: value.font_color || undefined,
    publications: value.publications,
    verticalPadding:
      value.vertical_padding === '' || isNaN(Number(value.vertical_padding))
        ? undefined
        : Number(value.vertical_padding),
    horizontalPadding:
      value.horizontal_padding === '' || isNaN(Number(value.horizontal_padding))
        ? undefined
        : Number(value.horizontal_padding),
    level: value.level,
  }));
