'use client';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledTrustPilot, Header } from './styles.js';
import { TrustPilotCarousel } from './TrustPilotCarousel';
import { loadTrustPilot } from './trustpilot';

export const TrustPilot = ({ header, level }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadTrustPilot(() => {
      setLoaded(true);
    });
  });

  return (
    <StyledTrustPilot>
      <Header as={level}>{header}</Header>
      {loaded && <TrustPilotCarousel />}
    </StyledTrustPilot>
  );
};

TrustPilot.displayName = 'TrustPilot';

TrustPilot.propTypes = {
  header: PropTypes.string.isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};
