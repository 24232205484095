'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Modal } from '../Modal';
import { NutritionalInfo } from '../NutritionalInfo';
import {
  Body,
  Container,
  Headline,
  Subheadline,
  TabNav,
  TabButton,
  Content,
  LargeImageContainer,
  MobileImageContainer,
  StyledIngredientModal,
} from './styles';

const tabs = {
  nutrition: 'nutrition',
  ingredients: 'full ingredients',
};

export const useIngredientModal = modals => {
  const [modal, setModal] = useState({ shown: false, value: 'beef' });
  const currentModal = modals.find(cont => cont.value === modal.value);

  const onRecipeDetailsClick = recipe => {
    setModal({ shown: true, value: recipe });
  };

  const onClickClose = () => {
    setModal(prev => ({ ...prev, shown: false }));
  };

  return {
    // The CMS schema adds `shown: true` for preview mode,
    // so we need to overwrite with local value.
    // TODO(James) maybe a better way of setting preview-specific props?
    modal: { ...currentModal, ...modal, onClickClose },
    onRecipeDetailsClick,
  };
};

export const IngredientModal = ({
  headline,
  fullIngredients,
  nutritionalInfo,
  body,
  onClickClose,
  shown,
  image,
}) => {
  const [activeTab, setActiveTab] = useState(tabs.nutrition);

  return (
    <Modal
      shown={shown}
      onCloseModal={onClickClose}
      ariaLabel="Full ingredients modal"
    >
      <StyledIngredientModal>
        <Container>
          <LargeImageContainer>
            <ResponsiveImage {...image} />
          </LargeImageContainer>
          <Content>
            <Headline>{headline}</Headline>
            <MobileImageContainer>
              <ResponsiveImage {...image} />
            </MobileImageContainer>
            <TabNav role="tablist">
              <TabButton
                onClick={() => setActiveTab(tabs.nutrition)}
                active={activeTab === tabs.nutrition}
                role="tab"
                aria-expanded={activeTab === tabs.nutrition}
              >
                {tabs.nutrition}
              </TabButton>
              <TabButton
                onClick={() => setActiveTab(tabs.ingredients)}
                active={activeTab === tabs.ingredients}
                role="tab"
                aria-expanded={activeTab === tabs.ingredients}
              >
                {tabs.ingredients}
              </TabButton>
            </TabNav>
            {activeTab === tabs.nutrition ? (
              <>
                <Subheadline>Guaranteed Analysis</Subheadline>
                <NutritionalInfo
                  nutritionalInfo={nutritionalInfo}
                  role="tabpanel"
                  id="tabpanel-nutrition"
                  ariaLabelledby="tab-nutrition"
                  hidden={activeTab !== tabs.nutrition}
                />
                <Body>{body}</Body>
              </>
            ) : (
              <Body>{fullIngredients}</Body>
            )}
          </Content>
        </Container>
      </StyledIngredientModal>
    </Modal>
  );
};

IngredientModal.displayName = 'IngredientModal';

IngredientModal.propTypes = {
  headline: PropTypes.string.isRequired,
  fullIngredients: PropTypes.node.isRequired,
  nutritionalInfo: PropTypes.array.isRequired,
  body: PropTypes.node.isRequired,
  onClickClose: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
  image: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};
