import BridgetAndGary from '../../images/about/family/bridget-and-gary.jpg';
import CarolineAndGelli from '../../images/about/family/caroline-and-gelli.jpg';
import DoriAndSophie from '../../images/about/family/dori-and-sophie.jpg';
import HillaryAndFanny from '../../images/about/family/hillary-and-fanny.jpg';
import JeremyAndGruff from '../../images/about/family/jeremy-and-gruff.jpg';
import JulieAndGray from '../../images/about/family/julia-and-gray.jpg';
import KatherineAndDesi from '../../images/about/family/katherine-and-desi.jpg';
import KellyAndCj from '../../images/about/family/kelly-and-cj.jpg';
import MeghanAndZuko from '../../images/about/family/meghan-and-zuko.jpg';
import NatalieAndGary from '../../images/about/family/natalie-and-gary.jpg';
import StephenAndLayla from '../../images/about/family/stephen-and-layla.jpg';
import WillAndLucky from '../../images/about/family/will-and-lucky.jpg';

export const PHOTO_GRID_DATA = [
  {
    alt: 'pup with human',
    src: BridgetAndGary,
    description: 'Bridget and Gary',
  },
  {
    alt: 'pup with human',
    src: CarolineAndGelli,
    description: 'Caroline and Gelli',
  },
  {
    alt: 'pup with human',
    src: DoriAndSophie,
    description: 'Dori and Sophie',
  },
  {
    alt: 'pup with human',
    src: HillaryAndFanny,
    description: 'Hillary and Fanny',
  },
  {
    alt: 'pup with human',
    src: JeremyAndGruff,
    description: 'Jeremy and Gruff',
  },
  {
    alt: 'pup with human',
    src: JulieAndGray,
    description: 'Julia and Gray',
  },
  {
    alt: 'pup with human',
    src: KatherineAndDesi,
    description: 'Katherine and Desi',
  },
  {
    alt: 'pup with human',
    src: KellyAndCj,
    description: 'Kelly and CJ',
  },
  {
    alt: 'pup with human',
    src: MeghanAndZuko,
    description: 'Meghan and Zuko',
  },
  {
    alt: 'pup with human',
    src: NatalieAndGary,
    description: 'Natalie and Gary',
  },
  {
    alt: 'pup with human',
    src: StephenAndLayla,
    description: 'Stephen and Layla',
  },
  {
    alt: 'pup with human',
    src: WillAndLucky,
    description: 'Will and Lucky',
  },
];
