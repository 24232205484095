'use client';
import styled, { keyframes, css } from 'styled-components';
import { variants } from '@ui/button';

const rotate = keyframes`
  to {
    transform: rotate(380deg);
  }
`;

export const EmailCaptureBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 310px;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.neutral.bone};
  padding: ${props => props.theme.spacing(0, 8)};
  @media ${props => props.theme.tabletL} {
    flex-direction: row;
    height: 124px;
    justify-content: center;
  }
`;

export const RightSection = styled.div`
  display: flex;
`;

export const TextSection = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  line-height: ${props => props.theme.spacing(7.5)};
  flex-direction: column;
  margin: ${props => props.theme.spacing(2, 2, 0, 8)};
  @media ${props => props.theme.tabletL} {
    align-items: start;
    line-height: ${props => props.theme.spacing(7.5)};
    text-align: left;
    justify-content: start;
    margin: ${props => props.theme.spacing(2, 2, 0, 8)};
  }
`;

export const LeftSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${props => props.theme.tabletL} {
    width: 35%;
    flex-direction: row;
  }
`;

export const StyledEmailCaptureForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.theme.spacing(3.75)};
  margin-top: ${props => props.theme.spacing(-10)};
  @media ${props => props.theme.tabletL} {
    margin-top: 0px;
  }
`;

export const EmailCaptureInput = styled.input`
  ${props => props.theme.getContrastColors(props.fillColor)}
  width: 280px;
  height: ${props => props.theme.spacing(15)};
  border-radius: 71px;
  border: none;
  padding-left: ${props => props.theme.spacing(7.5)};
  ${props => props.theme.typography.body};
  font-size: ${props => props.theme.spacing(4.5)};
  @media ${props => props.theme.tabletL} {
    width: 325px;
  }
`;

export const EmailCaptureButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.secondary.chia};
  color: white;
  height: ${props => props.theme.spacing(12)};
  width: ${props => props.theme.spacing(12)};
  border-radius: 50%;
  border: none;
  margin-left: -58px;
  ${({ buttonVariant, theme }) => css`
    background-color: ${theme.colors.get(
      variants[buttonVariant].active.background,
    )};
    color: ${theme.colors.get(variants[buttonVariant].active.text)};
  `}
`;

export const ArrowIcon = styled.div`
  box-sizing: border-box;
  position: relative;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 0 0 2px white;
  width: ${props => props.theme.spacing(5)};
  height: ${props => props.theme.spacing(1.5)};
  &:after {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: ${props => props.theme.spacing(2.5)};
    height: ${props => props.theme.spacing(2.5)};
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
    right: 0;
    bottom: ${props => props.theme.spacing(-1)};
  }
`;

export const CheckIcon = styled.div`
  transform: rotate(45deg);
  height: ${props => props.theme.spacing(5)};
  width: ${props => props.theme.spacing(3)};
  margin-bottom: 5px;
  border-bottom: ${props => props.theme.spacing(1)} solid white;
  border-right: ${props => props.theme.spacing(1)} solid white;
`;

export const EmailCaptureSpinner = styled.button`
  border-radius: 50%;
  width: ${props => props.theme.spacing(9.5)};
  height: ${props => props.theme.spacing(9.5)};
  display: inline-block;
  border-top: 5px solid white;
  border-left: 5px solid #79829c;
  border-right: 5px solid white;
  border-bottom: 5px solid white;
  transform: rotate(45deg);
  animation: ${rotate} 3s linear infinite;
  margin-left: 5px;
  margin-top: 5px;
  ${({ buttonVariant, theme }) => css`
    background-color: ${theme.colors.get(
      variants[buttonVariant].active.background,
    )};
    color: ${theme.colors.get(variants[buttonVariant].active.text)};
  `}
`;

export const EmailCaptureSpinnerContainer = styled.div`
  opacity: 0.5;
  margin-left: -58px;
  height: ${props => props.theme.spacing(12)};
  width: ${props => props.theme.spacing(12)};
  border-radius: 50%;
  border: none;
  ${({ buttonVariant, theme }) => css`
    background-color: ${theme.colors.get(
      variants[buttonVariant].active.background,
    )};
    color: ${theme.colors.get(variants[buttonVariant].active.text)};
  `}
`;
