'use client';
import styled from 'styled-components';

export const StyledInformationStats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${props => props.theme.spacing(10)};

  ${props => props.theme.getContrastColors(props.$backgroundColor)}
`;

export const InformationStatsBlocksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${props => props.theme.spacing(6)};
  padding: ${props => props.theme.spacing(6)};
  width: 80vw;

  @media ${props => props.theme.tabletL} {
    display: grid;
    grid-template-columns: repeat(${props => props.$columns}, 1fr);
    grid-gap: ${props => props.theme.spacing(6)};
    max-width: 1440px;
    width: 60vw;
    grid-template-rows: 1fr;
  }
`;

export const InformationStatsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 160px;
  padding: 20px;
  margin: ${props => props.theme.spacing(2, 0)};
  border-radius: ${props => props.theme.spacing(8)};
  background-color: ${({ theme }) => theme.colors.background.dark};
  color: ${({ theme }) => theme.colors.neutral.white};
  @media ${props => props.theme.tabletL} {
    padding: 20px;
    margin: ${props => props.theme.spacing(0)};
    width: 100%;
    height: 100%;
  }
`;

export const BlockHeading = styled.p`
  ${({ theme }) => theme.typography.heading1};
  background-color: ${({ theme }) => theme.colors.background.dark};
  text-align: center;
`;

export const Paragraph = styled.p`
  ${props => props.theme.typography.body};
  text-align: left;
  margin: ${props => props.theme.spacing(0)};
`;

export const DescriptionParagraph = styled.p`
  ${props => props.theme.typography.body};
  text-align: center;
`;

export const Heading = styled.h1`
  text-align: ${props => props.position};
  padding-top: ${props => props.theme.spacing(props.paddingTop)};
  font-size: 40px;
  font-weight: 400;
`;
