'use client';
import styled from 'styled-components';
import { Paragraph } from '@web/atoms';

export const StyledSplitPanelBody = styled.div``;

export const IconContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(6)};
`;

export const Header = styled.h3`
  ${props => props.theme.typography.subheading2};
  margin: 0;
  @media ${props => props.theme.laptop} {
    ${props => props.theme.typography.subheading1};
  }
`;

export const Body = styled.div`
  ${Paragraph} {
    ${props => props.theme.typography.heading4};
    margin: ${props => props.theme.spacing(4, 0)};
    @media ${props => props.theme.laptop} {
      ${props => props.theme.typography.heading3};
    }
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  border-radius: 32px;
  overflow: hidden;
  mux-player {
    &::part(center play button) {
      display: flex;
      min-width: 90px;
      min-height: 90px;
      padding: 22px 22px 22px 24px;
    }
  }
`;
