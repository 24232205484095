import * as yup from 'yup';
import { InfoStatBlock } from '@web/components/InformationStats/InfoStatBlock';

export const component = InfoStatBlock;

export const schema = yup
  .object({
    heading: yup.string(),
    description: yup.string(),
  })
  .transform(value => ({
    heading: value.heading,
    description: value.description,
  }));
