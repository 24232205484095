import * as yup from 'yup';
import { PlansCarousel } from '@web/components';
import {
  inlineRichTextFromCMSSchema,
  linkSchema,
  richTextFromCMSSchema,
  requiredImageFromCMSSchema,
} from '../schemas';

export const component = PlansCarousel;

export const schema = yup
  .object({
    headline: yup.string().required(),
    subheadline: inlineRichTextFromCMSSchema,
    plans: yup
      .array(
        yup.object({
          name: yup.string(),
          tagline: yup.string(),
          recipe: yup.string(),
          body: richTextFromCMSSchema,
          benefits: yup.array(yup.string()).required(),
          images: yup.array(requiredImageFromCMSSchema).required(),
        }),
      )
      .required(),
    link: linkSchema,
    backgroundColor: yup.string(),
    headingFontColor: yup.string(),
  })
  .transform(value => ({
    ...value,
    link: value.link[0],
    plans: value.plans.map(plan => ({
      ...plan,
      // Source: textarea
      benefits: plan.benefits.split('\n'),
      images: plan.images.map(image => image.asset),
    })),
    backgroundColor: value.background_color || undefined,
    headingFontColor: value.heading_font_color || undefined,
  }));
