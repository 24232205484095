import React from 'react';
import BluePaw from '../../images/about/blue-paw.svg';
import OllieLogoInline from '../../images/Homepage/ollie-logo-inline.svg';
import OllieAboutCommubnity1 from '../../images/about/community/Ollie-about-community-1.jpg';
import OllieAboutCommunity2 from '../../images/about/community/Ollie-about-community-2.jpg';

export const COMMUNITY_LETTER_DATA = {
  headline: 'We do it together.',
  intro:
    'We love partnering with like-minded people who want the best for our dogs and our planet. Ollie Ambassadors are dog lovers who have made it their life’s work to leave a paw-sitive mark on the world. Get to know them below!',
  topIcon: {
    src: BluePaw,
    alt: 'blue paw icon',
    height: 46,
    width: 46,
  },
  bottomIcon: {
    src: OllieLogoInline,
    alt: 'Ollie',
    height: 39,
    width: 79,
  },
  link: {
    text: 'Get started',
    target: '/onboarding/',
    variant: 'egg',
  },
  body: (
    <>
      We believe in the power of community to make a real change. Interested in
      helping us spread our mission and change dogs’ lives? Apply{' '}
      <a href="mailto:ambassadorteam@myollie.com">here</a> to become an Ollie
      Ambassador.
    </>
  ),
  images: [
    {
      src: OllieAboutCommubnity1,
      alt: "Little dog in woman's arms",
      height: 351,
      width: 263,
    },
    {
      src: OllieAboutCommunity2,
      alt: 'Little dog standing on top of an Ollie box',
      height: 216,
      width: 169,
    },
  ],
};
