import React from 'react';
import PropTypes from 'prop-types';
import { VisuallyHidden } from '@web/atoms';
import { OptionsColumn, Heading, Tag, OptionCheckbox, Check } from './styles';

export const Column = ({ options, activeOption, setActiveOption }) => {
  return (
    <OptionsColumn>
      <Heading as={options.level || 'h2'}>
        {options.title}
        {options.tag && <Tag>{options.tag}</Tag>}
      </Heading>
      {options.items.map(({ label, value }) => {
        const inputId = `checkbox-${value}`;
        return (
          <OptionCheckbox
            key={value}
            active={activeOption === value}
            htmlFor={inputId}
          >
            <VisuallyHidden>
              <input
                type="checkbox"
                id={inputId}
                value={value}
                checked={activeOption === value}
                onChange={event => {
                  const { value } = event.target;
                  setActiveOption(value === activeOption ? null : value);
                }}
              />
            </VisuallyHidden>
            <Check />
            {label}
          </OptionCheckbox>
        );
      })}
    </OptionsColumn>
  );
};

Column.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.string.isRequired,
    level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    placeholder: PropTypes.string.isRequired,
    tag: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.node.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  activeOption: PropTypes.string,
  setActiveOption: PropTypes.func.isRequired,
};
