import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage, CtaButton } from '@web/molecules';
import { EmailCapture } from '../EmailCaptureBar/EmailCapture';
import {
  ButtonWrapper,
  InputWrapper,
  StyledSplitScreenListBody,
  HeadingContainer,
  EyebrowContainer,
  ListContainer,
  ContentContainer,
  ListItem,
  ListImageContainer,
  ListContentContainer,
  ListContentHeader,
  ListContentBody,
  ContentContainerHeading,
} from './styles';

export const SplitScreenListBody = ({
  eyebrow,
  heading,
  fontColor = 'text.primary',
  backgroundColor = 'hero.spinach',
  cta,
  ctaButtonColor = 'primary',
  list,
}) => {
  return (
    <StyledSplitScreenListBody
      $backgroundColor={backgroundColor}
      $fontColor={fontColor}
    >
      <ContentContainer>
        <HeadingContainer>
          {eyebrow && <EyebrowContainer>{eyebrow}</EyebrowContainer>}
          {heading && (
            <ContentContainerHeading
              $bgColor={backgroundColor}
              as={heading.level || 'h1'}
              $typography={heading.typography || 'heading2'}
              $position={heading.position || 'center'}
              $fontColor={fontColor}
            >
              {heading.text}
            </ContentContainerHeading>
          )}
        </HeadingContainer>
        <ListContainer>
          {list &&
            list.map((item, index) => (
              <ListItem key={index}>
                {item.icon && (
                  <ListImageContainer>
                    <ResponsiveImage {...item.icon} height={32} width={32} />
                  </ListImageContainer>
                )}
                <ListContentContainer>
                  <ListContentHeader as={item.headingLevel}>
                    {item.heading}
                  </ListContentHeader>
                  {item.body && <ListContentBody>{item.body}</ListContentBody>}
                </ListContentContainer>
              </ListItem>
            ))}
        </ListContainer>

        {cta?.component === 'link' ? (
          <ButtonWrapper $backgroundColor={backgroundColor}>
            <CtaButton
              href={cta.target}
              variant={ctaButtonColor}
              headerText={heading.text}
            >
              {cta.text}
            </CtaButton>
          </ButtonWrapper>
        ) : (
          cta?.component === 'email_capture' && (
            <InputWrapper>
              <EmailCapture {...cta} buttonVariant={ctaButtonColor} />
            </InputWrapper>
          )
        )}
      </ContentContainer>
    </StyledSplitScreenListBody>
  );
};

SplitScreenListBody.displayName = 'SplitScreenListBody';

SplitScreenListBody.propTypes = {
  eyebrow: PropTypes.string,
  heading: PropTypes.shape({
    text: PropTypes.string,
    level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    position: PropTypes.oneOf(['left', 'center', 'right']),
    typography: PropTypes.string,
  }),
  fontColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  cta: PropTypes.object,
  ctaButtonColor: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      heading: PropTypes.string.isRequired,
      headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
        .isRequired,
      body: PropTypes.string,
    }),
  ),
};
