import styled from 'styled-components';
import get from 'lodash/get';

export const StyledSplitScreenListBody = styled.div`
  background: ${props => props.theme.colors.get(props.$backgroundColor)};
  color: ${props => props.theme.colors.get(props.$fontColor)};
  width: 100%;
`;

export const EyebrowContainer = styled.p`
  ${props => props.theme.typography.subheading1};
  margin-top: ${props => props.theme.spacing(2)};
  padding-top: ${props => props.theme.spacing(2)};

  @media ${props => props.theme.mobileS} {
    text-align: center;
  }

  @media ${props => props.theme.tablet} {
    text-align: left;
  }
`;

export const HeadingContainer = styled.div``;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: ${props => props.theme.spacing(6)};
  padding-bottom: ${props => props.theme.spacing(6)};

  @media ${props => props.theme.mobileS} {
    align-items: center;
  }

  @media ${props => props.theme.tablet} {
    align-items: flex-start;
  }
`;

export const ContentContainerHeading = styled.h2`
  background-color: ${props =>
    props.$bgColor && props.theme.colors.get(props.$bgColor)};
  ${props => get(props.theme.typography, props.$typography)};
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};

  @media ${props => props.theme.mobileS} {
    text-align: center;
  }

  @media ${props => props.theme.tablet} {
    text-align: ${props => props.$position};
  }
`;

export const ListContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.spacing(8)};
`;

export const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${props => props.theme.spacing(4)};
`;

export const ListImageContainer = styled.div`
  padding: ${props => props.theme.spacing(1, 0)};
  margin: ${props => props.theme.spacing(1, 0)};
`;

export const ListContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.spacing(2)};
  width: 100%;
`;

export const ListContentHeader = styled.h2`
  ${props => props.theme.typography.subheading1};
`;

export const ListContentBody = styled.div`
  ${props => props.theme.typography.body};
`;

export const LinkWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(4)};
`;

export const InputWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(20)};
  display: flex;
  @media ${props => props.theme.tabletL} {
    margin-top: ${props => props.theme.spacing(10)};
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${props => props.theme.getFocusOutline(props.$backgroundColor)}
`;
