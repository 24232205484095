'use client';
import styled from 'styled-components';

export const StyledSectionIntro = styled.div`
  margin: 0 auto;
  max-width: ${props => props.maxWidth || 870}px;
  padding: ${props => props.theme.spacing(0, 5)};
  text-align: center;
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
`;

export const Header = styled.h2`
  ${props => props.theme.typography.heading2}
  margin-bottom: 0.5em;
`;

export const Subheader = styled.div`
  ${props => props.theme.typography.heading5}
`;
