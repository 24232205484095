'use client';
import styled from 'styled-components';

export const StyledQuoteContainer = styled.div`
  padding: ${props => props.theme.spacing(2, 0)};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const StyledQuoteBodyContainer = styled.div`
  @media ${props => props.theme.laptop} {
    margin: ${props => props.theme.spacing(1)};
    padding: ${props => props.theme.spacing(1)};
  }
  margin-bottom: ${props => props.theme.spacing(2)};
  padding-bottom: ${props => props.theme.spacing(4)};
  ${props => props.theme.typography.heading4}
  color: ${props => props.theme.colors.text.secondary};
`;

export const StyledQuoteAuthorContainer = styled.div`
  text-align: center;
`;

export const StyledQuoteImage = styled.div`
  height: ${props => props.theme.spacing(18)};
  width: ${props => props.theme.spacing(18)};
  display: inline-flex;

  img {
    border-radius: 50%;
    text-align: center;
  }
`;

export const StyledQuoteName = styled.div`
  margin: ${props => props.theme.spacing(1)};
  padding: ${props => props.theme.spacing(1)};
  margin-bottom: 0;
  padding-bottom: 0;
  color: ${props => props.theme.colors.text.primary};
`;

export const StyledQuoteTitle = styled.div`
  margin: ${props => props.theme.spacing(0.5)};
  padding: ${props => props.theme.spacing(0.5)};
  color: ${props => props.theme.colors.text.tertiary};
`;

export const StyledQuotesContainer = styled.div`
  background-color: ${props => props.theme.colors.background.lightest};
  text-align: center;
`;

export const StyledQuotesHeader = styled.div`
  color: ${props => props.theme.colors.text.primary};
  ${props => props.theme.typography.heading2}
  padding-top: ${props => props.theme.spacing(4)};
`;

export const QuotesCarouselContainer = styled.div`
  padding: 0px;
  @media ${props => props.theme.laptop} {
    padding-bottom: ${props => props.theme.spacing(7)};
  }
`;

export const SingleQuoteContainer = styled.div`
  padding-bottom: 36px;
`;
