'use client';
import styled from 'styled-components';

export const StyledNutritionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  min-height: 156px;

  @media ${({ theme }) => theme.laptop} {
    width: 343px;
    min-height: 120px;
  }
`;

export const NutritionTable = styled.table`
  border: none;
  border-collapse: collapse;
  width: 100%;
`;

export const NutritionTableBody = styled.tbody`
  border: none;
  border-collapse: collapse;
  width: 100%;
`;

export const NutritionTableRow = styled.tr`
  width: 100%;
  height: 33px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.fur};
  &:last-of-type {
    border: 0;
  }
`;

export const NutritionTableData = styled.td`
  padding: 0;
  width: 50%;
  line-height: 1.5;
  text-align: right;
`;

export const NutritionTableHeader = styled.th`
  padding: 0;
  width: 50%;
  line-height: 1.5;
`;
