import * as yup from 'yup';
import { VerticalPanelBody } from '@web/components';
import { nullableImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';
import { schema as muxVideoSchema } from './mux_video';

export const component = VerticalPanelBody;

export const schema = yup
  .object({
    heading: yup.string(),
    body: richTextFromCMSSchema,
    asset: yup.lazy(item => {
      if (!item) return yup.mixed().notRequired();
      switch (item.component) {
        case 'mux_video':
          return muxVideoSchema;
        default:
          return nullableImageFromCMSSchema;
      }
    }),
    level: yup.string(),
  })
  .transform(value => ({
    heading: value.heading,
    body: value.body,
    asset:
      (value.asset && (value.asset[0].asset || value.asset[0])) || undefined,
    level: value.level,
  }));
