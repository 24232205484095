'use client';
import styled, { css } from 'styled-components';

export const StyledRecipesCombiner = styled.div`
  background-color: ${props => props.theme.colors.neutral.bone};
  color: ${props => props.theme.colors.text.secondary};
  padding: ${props => props.theme.spacing(13, 0)};
  position: relative;
  z-index: 2;
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(20, 0)};
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 900px;
  padding: ${props => props.theme.spacing(0, 5)};
  @media ${props => props.theme.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Dropdowns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: ${props => props.theme.spacing(10)};
  width: 100%;
  max-width: 330px;
  @media ${props => props.theme.tablet} {
    display: none;
  }
`;

export const OptionsColumn = styled.div`
  display: none;
  padding-top: ${props => props.theme.spacing(8)};
  text-align: center;
  @media ${props => props.theme.tablet} {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    width: 160px;
  }
`;

export const Heading = styled.h4`
  ${props => props.theme.typography.heading3}
  margin-bottom: ${props => props.theme.spacing(3)};
  position: relative;
`;

export const Tag = styled.span`
  ${props => props.theme.typography.label}
  ${props => props.theme.fontFamily.condensed}
  background-color: ${props => props.theme.colors.text.secondary};
  border-radius: 20px;
  color: ${props => props.theme.colors.neutral.lightBone};
  display: inline-block;
  line-height: 1;
  margin-left: ${props => props.theme.spacing(1)};
  padding: ${props => props.theme.spacing(1, 2)};
  position: absolute;
  text-transform: uppercase;
  vertical-align: top;
`;

export const Check = styled.div`
  background-color: ${props => props.theme.colors.neutral.lightBone};
  border-radius: ${props => props.theme.spacing(2)};
  border: 1px solid ${props => props.theme.colors.text.primary};
  height: ${props => props.theme.spacing(4)};
  width: ${props => props.theme.spacing(4)};
  position: absolute;
  left: ${props => props.theme.spacing(4)};
  top: 50%;
  transform: translateY(-50%);

  &:after {
    background-color: ${props => props.theme.colors.text.primary};
    border-radius: ${props => props.theme.spacing(1)};
    content: '';
    display: none;
    height: ${props => props.theme.spacing(2)};
    width: ${props => props.theme.spacing(2)};
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`;

export const OptionCheckbox = styled.label`
  ${props => props.theme.typography.heading5}
  background-color: ${props => props.theme.colors.neutral.lightBone};
  border-radius: 32px;
  border: 1px solid rgba(94, 88, 78, 0.3);
  cursor: pointer;
  display: block;
  margin-top: ${props => props.theme.spacing(3)};
  padding: ${props => props.theme.spacing(4, 10, 4, 14)};
  position: relative;
  width: 100%;

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.text.primary};
  }

  ${props =>
    props.active &&
    css`
      background-color: ${props => props.theme.colors.secondary.egg};
      ${Check} {
        &:after {
          display: block;
        }
      }
    `}
`;

export const MainContent = styled.div`
  margin-top: ${props => props.theme.spacing(-5)};
  padding: ${props => props.theme.spacing(0, 2)};
  width: 100%;
  @media ${props => props.theme.tablet} {
    flex: 1 1 100%;
    margin-top: ${props => props.theme.spacing(-10)};
    padding: 0;
  }
`;

export const ImageOuter = styled.div`
  margin: 0 auto;
  max-width: 430px;
`;

export const ImageWrapper = styled.div`
  border-radius: 32px;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  z-index: -1;
`;

export const ImageInner = styled.div`
  opacity: ${props => (props.active ? 1 : 0)};
  pointer-events: ${props => (props.active ? 'auto' : 'none')};
  transition: opacity 500ms;
`;

export const MixDescription = styled.p`
  ${props => props.theme.typography.button}
  margin-top: ${props => props.theme.spacing(-3)};
  text-align: center;
  @media ${props => props.theme.tablet} {
    margin-top: ${props => props.theme.spacing(-8)};
    min-height: 65px;
    padding: ${props => props.theme.spacing(0, 5)};
  }
  > strong {
    ${props => props.theme.typography.subheading1}
    text-transform: uppercase;
  }
`;

export const Buttons = styled.div`
  display: none;
  justify-content: center;
  padding: ${props => props.theme.spacing(8, 5, 0)};

  @media ${props => props.theme.tablet} {
    display: flex;
  }
`;

export const ButtonWrapper = styled.div`
  padding: ${props => props.theme.spacing(0, 3)};
`;
export const MobileButtons = styled.div`
  margin-top: ${props => props.theme.spacing(8)};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const MobileButton = styled.div`
  text-align: center;
  @media ${props => props.theme.tablet} {
    display: none;
  }
`;
