import * as yup from 'yup';
import { addonCardSchema } from './addon_card';

export const addonSectionSchema = yup
  .object({
    heading: yup.string(),
    addons: yup.array(addonCardSchema),
  })
  .transform(value => ({
    heading: value.heading,
    addons: value.addons,
  }));
