import * as yup from 'yup';
import { Testimonials } from '@web/components';
import { linkSchema } from '../schemas';
import { schema as testimonial } from './testimonial';
import { schema as before_after_testimonial } from './before_after_testimonial';

export const component = Testimonials;

const differentTestimonialsArraySchema = yup.array(
  yup.lazy(item => {
    if (item.component === 'testimonial') {
      return testimonial;
    }
    return before_after_testimonial;
  }),
);

export const schema = yup
  .object({
    testimonials: differentTestimonialsArraySchema.required(),
    headline: yup.string(),
    link: linkSchema,
    showStars: yup.boolean(),
    subheadline: yup.string(),
    backgroundColor: yup.string(),
    level: yup.string(),
  })
  .transform(value => ({
    testimonials: value.testimonials,
    headline: value.headline,
    subheadline: value.subheadline,
    link: value.link[0],
    showStars: value.show_stars,
    backgroundColor: value.background_color || undefined,
    level: value.level,
  }));
