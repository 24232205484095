import React from 'react';
import PropTypes from 'prop-types';
import { PillText } from '@web/atoms';
import {
  SHIPPED_STATUS,
  DELIVERED_STATUS,
  AWAITING_FULFILLMENT_STATUS,
  REFUNDED_STATUS,
  CANCELLED_STATUS,
} from './constants.js';

export const OrderPill = ({ status }) => {
  if (status === SHIPPED_STATUS)
    return <PillText text={status} variant={'spinach'} size="large" />;
  if (status === DELIVERED_STATUS)
    return <PillText text={status} variant={'light'} size="large" />;
  if (status === CANCELLED_STATUS)
    return <PillText text={status} variant={'cranberry'} size="large" />;
  if (status === REFUNDED_STATUS)
    return <PillText text={status} variant={'warning'} size="large" />;
  if (status === AWAITING_FULFILLMENT_STATUS)
    return <PillText text={status} variant={'blueberry'} size="large" />;
  return null;
};

OrderPill.displayName = 'OrderPill';
OrderPill.propTypes = {
  status: PropTypes.string,
};
