import ollieValues1Img from '../../images/about/values/Ollie-about-values-1.jpg';
import ollieValues2Img from '../../images/about/values/Ollie-about-values-2.jpg';
import ollieValues3Img from '../../images/about/values/Ollie-about-values-3.jpg';
import ollieValues4Img from '../../images/about/values/Ollie-about-values-4.jpg';
import ollieValues5Img from '../../images/about/values/Ollie-about-values-5.jpg';
import ollieValues6Img from '../../images/about/values/Ollie-about-values-6.jpg';
import foodDonationsIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_FoodDonations.svg';
import employeeFairWageIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_EmployeeFairWage.svg';
import employeePTOVolunteerIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_EmployeePTOVolunteer.svg';
import complianceProcessIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_ResponsibleSourcingComplianceProcess.svg';
import employeeBenefitsIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_EmployeeBenefits.svg';
import canineCouncilIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_CanineCouncil.svg';
import carbonFootprintIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_CarbonFootprint.svg';
import responsibleSourcingPartnersIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_ResponsibleSourcingPartners.svg';
import responsibleSourcingIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_ResponsibleSourcing.svg';
import recyclingIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_Recyclablitiy.svg';
import reduceEnergyIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_ReduceEnergy.svg';
import researchingVeganIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_ResearchingVegan.svg';
import maxRecyclingMatsIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_MaximizingRecyclableMaterial.svg';
import plantTreeIcon from '../../images/about/icons/Ollie_Site_Icons_working_final_PlantTree.svg';
import spinachPawIcon from '../../images/about/spinach-paw.svg';
import ollieLogo from '../../images/Homepage/ollie-logo-inline.svg';

export const VALUES_LETTER_DATA = {
  headline: 'We do it to make a difference.',
  intro:
    'As a company, we’re committed to cultivating a healthy ecosystem for our dogs to thrive in by creating a real difference at scale. We focus on three Wellness pillars to help build a better world for our pups.',
  sections: [
    {
      images: [
        {
          src: ollieValues1Img,
          alt: 'Serious dog with happy human portrait',
          height: 351,
          width: 263,
        },
        {
          src: ollieValues2Img,
          alt: 'Serious dog with happy human portrait',
          height: 219,
          width: 169,
        },
      ],
      heading: '1. Social Wellness',
      body: 'We support the prosperity, health, and happiness of our employees, dogs, and communities. That’s why we hold ourselves — and our partners — to inclusive and just practices, because we believe that when everyone feels supported, seen, and heard, we all thrive.',
      details: [
        {
          iconSrc: foodDonationsIcon,
          iconAlt: 'TK',
          description:
            'Providing ongoing food donations to shelters and rescues in our communities and for our ambassador causes.',
        },
        {
          iconSrc: employeeFairWageIcon,
          iconAlt: 'TK',
          description:
            'Providing all Ollie employees with fair and competitive wages and a holistic, industry-leading benefits package.',
        },
        {
          iconSrc: employeePTOVolunteerIcon,
          iconAlt: 'TK',
          description:
            'Organizing and providing paid time off for employee volunteering in our local communities.',
        },
        {
          iconSrc: complianceProcessIcon,
          iconAlt: 'TK',
          description:
            'Designing an impact-driven Responsible Sourcing and Compliance process, starting with Ollie’s Supplier Code of Conduct.',
        },
      ],
    },
    {
      images: [
        {
          src: ollieValues3Img,
          alt: 'Carrots eggs and rosemary with chicken on a fork',
          height: 182,
          width: 282,
        },
        {
          src: ollieValues4Img,
          alt: 'Man getting kisses from his small dog',
          height: 351,
          width: 263,
        },
      ],
      heading: '2. Dog Wellness',
      body: 'We believe that a dog’s health, happiness, and longevity come from nutrition, physical fitness, and mental well-being. We’re committed to educating ourselves, our customers, and dog parents everywhere to help dogs live their fullest lives.',
      details: [
        {
          iconSrc: canineCouncilIcon,
          iconAlt: 'TK',
          description:
            'Creating a Canine Council of scientists, behaviorists, and nutritionists to steer our nutrition philosophies, inform product innovation, and help guide dogs to their healthiest lives.',
        },
        {
          iconSrc: employeeBenefitsIcon,
          iconAlt: 'TK',
          description:
            'Providing Ollie employees with benefits and tools that support our own dogs’ health, wellbeing, and happiness.',
        },
      ],
    },
    {
      images: [
        {
          src: ollieValues5Img,
          alt: 'Puppy wandering in the grass',
          height: 351,
          width: 263,
        },
        {
          src: ollieValues6Img,
          alt: 'Back half of a dog in front of a blue sky',
          height: 216,
          width: 169,
        },
      ],
      heading: '3. Environmental Wellness',
      body: 'Healthy dogs need healthy humans — and a healthy planet too! We’re committed to transparency and accountability around our food, from where our ingredients are grown to how they’re packaged and delivered.',
      details: [
        {
          iconSrc: carbonFootprintIcon,
          iconAlt: 'TK',
          description:
            'Finding less emissions-intensive food, manufacturing, and delivery options. ',
        },
        {
          iconSrc: responsibleSourcingIcon,
          iconAlt: 'TK',
          description: 'Sourcing responsibly and wasting minimally.',
        },
        {
          iconSrc: recyclingIcon,
          iconAlt: 'TK',
          description: 'Striving to make our packaging 100% recyclable.',
        },
      ],
    },
    {
      body: 'Our key goals are focused on future-proofing our business and doing our part to ensure the survival of our planet. At Ollie, we believe a habitable planet is the only option for humans and dogs alike.',
      details: [
        {
          iconSrc: responsibleSourcingPartnersIcon,
          iconAlt: 'TK',
          description:
            'Selecting partners with established and responsible sourcing practices.',
        },
        {
          iconSrc: reduceEnergyIcon,
          iconAlt: 'TK',
          description:
            'Working with distributors and suppliers to reduce energy, water, and waste in our food production.',
        },
        {
          iconSrc: plantTreeIcon,
          iconAlt: 'TK',
          description:
            'Planting a tree for every new customer to help offset our carbon footprint.',
        },
        {
          iconSrc: maxRecyclingMatsIcon,
          iconAlt: 'TK',
          description:
            'Maximizing the percentage of recyclable materials in our packaging to be 100% recyclable or recycled in the near future.',
        },
        {
          iconSrc: researchingVeganIcon,
          iconAlt: 'TK',
          description:
            'Researching and formulating vegetarian and vegan products.',
        },
      ],
    },
  ],
  topIcon: {
    src: spinachPawIcon,
    alt: 'blue paw icon',
    height: 46,
    width: 46,
  },
  bottomIcon: {
    src: ollieLogo,
    alt: 'Ollie',
    height: 39,
    width: 79,
  },
  link: {
    text: 'Get started',
    target: '/onboarding/',
    variant: 'egg',
  },
};
