import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@ui/button';
import {
  StyledAccordionItem,
  Title,
  TitleText,
  ButtonWrapper,
  Content,
} from './styles.js';

export const AccordionItem = ({
  title,
  children,
  isOpen = false,
  onClick,
  level,
  variant = 'default',
  buttonVariant = 'primary',
  id = null,
}) => {
  return (
    <StyledAccordionItem variant={variant} active={isOpen}>
      <Title onClick={onClick} variant={variant} as={level || 'h2'}>
        <TitleText variant={variant}>
          {variant === 'toggle' && (isOpen ? 'Hide' : 'Show')} {title}
        </TitleText>
        <ButtonWrapper>
          <IconButton
            aria-label={isOpen ? 'Hide details' : 'Show details'}
            aria-pressed={isOpen}
            horizontalPadding={4}
            verticalPadding={4}
            variant={buttonVariant}
            icon={{
              iconName: isOpen ? 'minus-thick' : 'plus-thick',
              size: '17px',
              color: 'text.lighter',
              alt: '',
              id: id,
            }}
          />
        </ButtonWrapper>
      </Title>
      {isOpen && <Content>{children}</Content>}
    </StyledAccordionItem>
  );
};

AccordionItem.displayName = 'AccordionItem';

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  variant: PropTypes.oneOf(['default', 'large', 'toggle']),
  buttonVariant: PropTypes.oneOf([
    'primary',
    'secondary',
    'dark',
    'carrot',
    'chia',
    'cranberry',
    'blueberry',
    'egg',
    'sweetPotato',
    'kale',
    'spinach',
    'bone',
  ]),
  id: PropTypes.string.isRequired,
};
