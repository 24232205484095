import * as yup from 'yup';
import { CtaButton } from '@web/molecules';

export const component = CtaButton;

export const schema = yup
  .object({
    href: yup.string().required(),
    variant: yup.string().required(),
    children: yup.string().required(),
    openNewTab: yup.boolean(),
  })
  .transform(value => ({
    href: value.href.url,
    variant: value.button_variant,
    children: value.button_label,
    openNewTab: value.opens_new_tab || false,
  }));
