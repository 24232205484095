import HomepageHero2020Desktop from '../../images/Homepage/HomepageHero2020xDesktop.png';
import HomepageHero2020Mobile from '../../images/Homepage/HomepageHero2020xMobile@3x.png';
import HomepageHero2020Tablet from '../../images/Homepage/HomepageHero2020xTablet.png';

export const HERO_IMAGE = {
  picture: true,
  alt: 'Alt',
  src: HomepageHero2020Desktop.src,
  srcSet: [
    {
      srcSet: [
        {
          src: HomepageHero2020Mobile.src,
          width: 1122,
          height: 1530,
        },
      ],
      media: '(max-width: 767px)',
      sizes: '100vw',
      aspectRatio: '136.3636364%',
    },
    {
      srcSet: [
        {
          src: HomepageHero2020Tablet.src,
          width: 1536,
          height: 1664,
        },
      ],
      media: '(max-width: 1023px)',
      sizes: '100vw',
      aspectRatio: '108.3333333%',
    },
    {
      srcSet: [
        {
          src: HomepageHero2020Desktop.src,
          width: 2560,
          height: 960,
        },
      ],
      sizes: '100vw',
      aspectRatio: '37.0656371%',
    },
  ],
};

export const horizontalOrientationOptions = ['left', 'center', 'right'];

export const orientationSettings = {
  left: {
    text: 'left',
    justify: 'flex-start',
    align: 'flex-start',
  },
  center: {
    text: 'center',
    justify: 'center',
    align: 'center',
  },
  right: {
    text: 'left',
    justify: 'flex-end',
    align: 'flex-start',
  },
};
