import * as yup from 'yup';
import { GetSocial } from '@web/components/GetSocial';
import { requiredImageFromCMSSchema, richTextFromCMSSchema } from '../schemas';

export const component = GetSocial;
// schema for the individual social icon component
export const GetSocialIconSchema = yup
  .object({
    image: requiredImageFromCMSSchema,
    href: yup.string().required(),
    opens_new_tab: yup.boolean().default(false),
  })
  .transform(value => ({
    image: value.image,
    href: value.href,
    opensNewTab: value.opens_new_tab,
  }));

export const schema = yup
  .object({
    component: yup.string().required(),
    header: yup.string().required(),
    description: richTextFromCMSSchema,
    icons: yup.array().of(GetSocialIconSchema).min(1).max(6).required(),
  })
  .transform(value => ({
    component: value.component,
    header: value.header,
    description: value.description,
    icons: value.icons.map((icon, idx) => ({
      image: icon.image,
      href: icon.href,
      opens_new_tab: icon.opens_new_tab,
    })),
  }));
