import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import { Letter } from '../Letter';
import {
  StyledCommunityLetter,
  Body,
  ImagesBlock,
  ImageWrapper,
} from './styles.js';

export const CommunityLetter = ({
  headline,
  intro,
  images = null,
  body,
  topIcon,
  bottomIcon,
  link,
}) => {
  return (
    <StyledCommunityLetter>
      <Letter
        headline={headline}
        topIcon={topIcon}
        bottomIcon={bottomIcon}
        link={link}
      >
        <Body>{intro}</Body>
        <Body>{body}</Body>
        {images?.length && (
          <ImagesBlock>
            {images.map((image, i) => (
              <ImageWrapper key={i}>
                <ResponsiveImage {...image} />
              </ImageWrapper>
            ))}
          </ImagesBlock>
        )}
      </Letter>
    </StyledCommunityLetter>
  );
};

CommunityLetter.displayName = 'CommunityLetter';

CommunityLetter.propTypes = {
  headline: PropTypes.string.isRequired,
  topIcon: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  intro: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      alt: PropTypes.string.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ),
  bottomIcon: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
    variant: PropTypes.string,
  }),
};
