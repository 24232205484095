'use client';
import styled, { css } from 'styled-components';
import get from 'lodash/get';

export const StyledPressLogos = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: ${props =>
    props.theme.spacing(props.$verticalPadding, props.$horizontalPadding)};
  @media ${props => props.theme.tablet} {
    max-width: 640px;
  }
`;

export const MobilePressLogoContainer = styled.div`
  ${props =>
    props.$isCarouselHide &&
    css`
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: flex-start;
    `}
  @media ${props => props.theme.tablet} {
    display: none;
  }
`;

export const PressLogoContainer = styled.div`
  display: none;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  @media ${props => props.theme.tablet} {
    display: flex;
  }
  @media ${props => props.theme.laptop} {
    flex-flow: row nowrap;
    align-items: unset;
    max-width: 1260px;
    margin: 0 auto;
  }
`;

export const HeadingContainer = styled.div`
  ${props => get(props.theme.typography, props.$typography.mobile)};
  text-align: ${props => props.$position};
  padding: ${props => props.theme.spacing(1, 4)};
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
  @media ${props => props.theme.laptop} {
    ${props => get(props.theme.typography, props.$typography.desktop)};
  }
`;

export const Heading = styled.h1``;

export const LogoWrapper = styled.div`
  flex: 0 0 auto;
  margin: ${props => props.theme.spacing(9, 0, 6)};
  ${props =>
    props.$isCarouselHide &&
    css`
      width: 30%;
      padding: 0px 5px 0px 5px;
    `}
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(0, 6)};
    width: ${props => `${100 / props.count}%`};
  }
`;

export const StyledPressLogo = styled.div`
  max-width: 100%;
  text-align: center;
  ${props => !props.quote && 'align-self: center;'}

  img {
    width: 100%;
  }
  @media ${props => props.theme.tablet} {
    width: 350px;
    img {
      width: auto;
    }
  }
`;

export const LogoContainer = styled.div`
  max-width: 100%;
  width: 170px;
  height: 60px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

export const Quote = styled.p`
  ${props => props.theme.typography.heading5}
  color: ${props => props.theme.colors.text.lighter};
  margin-top: ${props => props.theme.spacing(10)};
  overflow-wrap: break-word;
`;
