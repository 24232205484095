'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import {
  StyledCollapsibleMenu,
  MenuButton,
  ExpandedMenu,
  IconContainer,
} from './styles.js';

export const CollapsibleMenu = ({ menuText, children }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = () => setOpenMenu(!openMenu);
  return (
    <StyledCollapsibleMenu>
      <MenuButton open={openMenu} onClick={handleOpenMenu}>
        {menuText}
        <IconContainer>
          {openMenu ? (
            <Icon
              iconName="minus"
              alt=""
              size={1}
              id="minus_collpapsible_menu"
            />
          ) : (
            <Icon iconName="plus" alt="" size={1} id="plus_collpapsible_menu" />
          )}
        </IconContainer>
      </MenuButton>
      {openMenu && <ExpandedMenu>{children}</ExpandedMenu>}
    </StyledCollapsibleMenu>
  );
};

CollapsibleMenu.propTypes = {
  menuText: PropTypes.string.isRequired,
  children: PropTypes.node,
};
