'use client';
import styled, { css } from 'styled-components';

export const TooltipContainer = styled.div``;

const getColorVariant = props =>
  props.variant === 'light'
    ? css`
        background: ${props.theme.colors.background.lightest};
        color: ${props.theme.colors.text.primary};
        box-shadow: 3px 3px 5px lightgrey;
      `
    : css`
        background: ${props.theme.colors.background.dark};
        color: ${props.theme.colors.text.lightest};
      `;

export const TooltipContent = styled.div`
  ${props => props.theme.typography.body};
  ${props => getColorVariant(props)}
  padding: ${props => props.theme.spacing(6)};
  text-align: center;
  width: 315px;
  border-radius: 32px;
  z-index: 1;
`;
