import React from 'react';
import PropTypes from 'prop-types';
import { VisuallyHidden } from '@web/atoms';
import { ResponsiveImage } from '@web/molecules';
import { MuxVideo } from '../MuxVideo';
import {
  StyledTestimonial,
  StarRating,
  Name,
  Description,
  TextContainer,
  AssetWrapper,
} from './styles.js';

export const Testimonial = ({
  asset,
  rating,
  body,
  name,
  level = 'h2',
  description,
  showStars = true,
}) => {
  return (
    <StyledTestimonial>
      <AssetWrapper>
        {asset?.component === 'image' ? (
          <ResponsiveImage
            src={asset.src}
            alt={asset.alt}
            width={asset.width}
            height={asset.height}
          />
        ) : (
          <MuxVideo {...asset} />
        )}
      </AssetWrapper>
      <TextContainer>
        <Name as={level}>{name}</Name>
        <Description>{description}</Description>
        {showStars && (
          <StarRating rating={rating}>
            <VisuallyHidden>
              {rating} star rating for testimonial
            </VisuallyHidden>
          </StarRating>
        )}
        {body}
      </TextContainer>
    </StyledTestimonial>
  );
};

Testimonial.displayName = 'Testimonial';

Testimonial.propTypes = {
  asset: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  rating: PropTypes.number,
  body: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  showStars: PropTypes.bool,
};
