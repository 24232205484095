'use client';
import styled from 'styled-components';

export const StyledPageTabs = styled.div`
  background-color: ${({ theme }) => theme.colors.background.base};
`;

export const PageButtons = styled.div`
  display: none;
  justify-content: center;
  padding: ${props => props.theme.spacing(2, 5)};
  @media ${props => props.theme.tablet} {
    display: flex;
  }
`;

export const PageDropdown = styled.div`
  padding: ${props => props.theme.spacing(2, 5, 0)};
  @media ${props => props.theme.tablet} {
    display: none;
  }
`;

export const Button = styled.button`
  ${props => props.theme.typography.heading5}
  appearance: none;
  background-color: ${({ active, color, theme }) =>
    active ? theme.colors.get(color) : theme.colors.background.lightest};
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.border.dark : theme.colors.border.fur};
  border-radius: 32px;
  margin: ${props => props.theme.spacing(0, 2)};
  padding: ${props => props.theme.spacing(2, 6)};

  &:hover,
  &:focus {
    border-color: ${props => props.theme.colors.border.dark};
  }
`;

export const JoinThePackDescription = styled.h3`
  ${props => props.theme.typography.heading3}
`;
