'use client';
import styled from 'styled-components';

export const StyledEventsPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${props => props.theme.spacing(5)};
  padding-bottom: ${props => props.theme.spacing(5)};
  max-width: 1440px;
  margin: auto;
`;

export const HeadingText = styled.h1`
  ${props => props.theme.typography.heading2};
  color: ${props => props.theme.colors.text.primary};
  text-align: center;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  @media ${props => props.theme.tabletL} {
    width: 510px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(4, 2)};
  flex-wrap: wrap;
  gap: ${props => props.theme.spacing(2)};
  margin: auto;
  @media ${props => props.theme.tablet} {
    width: 90%;
  }
  @media ${props => props.theme.laptop} {
    width: unset;
  }
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline()}
`;
