import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { ResponsiveImage } from '@web/molecules';
import { Heading } from '@web/atoms';
import {
  ImageContainer,
  TestimonialContainer,
  Subtext,
  StarRatingContainer,
  ContentContainer,
} from './styles';

export const TestimonialSquareImage = ({ testimonial, image, bgColor }) => (
  <TestimonialContainer $bgColor={bgColor}>
    <ContentContainer>
      <StarRatingContainer>
        {Array.from('stars').map((_, index) => (
          <Icon
            key={index}
            iconName="star"
            size="1.7rem"
            color="background.dark"
            alt="star"
            id={`testimonial_square_${index}`}
          />
        ))}
      </StarRatingContainer>
      <Heading
        headingLevel="h5"
        headingText={testimonial.body}
        position="center"
        typography="heading4"
      />
      <Subtext>{testimonial.customerName}</Subtext>
    </ContentContainer>
    {image?.src && (
      <ImageContainer>
        <ResponsiveImage {...image} />
      </ImageContainer>
    )}
  </TestimonialContainer>
);

TestimonialSquareImage.displayName = 'TestimonialSquareImage';

TestimonialSquareImage.propTypes = {
  image: PropTypes.object,
  bgColor: PropTypes.string,
  testimonial: PropTypes.object.isRequired,
};
