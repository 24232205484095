import React from 'react';
import * as yup from 'yup';
import { getDimensions, hasDimensions } from './images';
import { RichText } from './RichText';

const imageFromCMSSchema = nullable => {
  return yup
    .object({
      src: nullable ? yup.string().nullable() : yup.string().required(),
      alt: nullable ? yup.string().nullable() : yup.string().required(),
      width: yup.number().nullable(),
      height: yup.number().nullable(),
    })
    .transform(value => {
      if (!value?.filename) {
        return null;
      }

      const dimensions = hasDimensions(value.filename)
        ? getDimensions(value.filename)
        : { width: value.width, height: value.height };
      return {
        src: value.filename,
        alt: value.alt || value.name,
        ...dimensions,
        component: 'image',
      };
    });
};

export const nullableImageFromCMSSchema = imageFromCMSSchema(true).nullable();
export const requiredImageFromCMSSchema = imageFromCMSSchema(false).required();

export const responsiveImageFromCMSSchema = yup
  .object({
    src: yup.string().nullable(),
    alt: yup.string().nullable(),
    width: yup.number().nullable(),
    height: yup.number().nullable(),
  })
  .nullable()
  .transform(value => {
    const responsiveImage = value?.images?.[0]?.asset;
    if (responsiveImage?.filename) {
      return {
        src: responsiveImage.filename,
        alt: responsiveImage.alt || responsiveImage.name,
        ...getDimensions(responsiveImage.filename),
      };
    }
    return null;
  });

export const richTextFromCMSSchema = yup
  .object()
  .transform(value => <RichText content={value} />);

export const inlineRichTextFromCMSSchema = yup
  .object()
  .transform(value => <RichText content={value} inline />);

export const createInlineRichTextFromCMSSchema = level =>
  yup
    .object()
    .transform(value => <RichText content={value} inline level={level} />);

export const linkSchema = yup
  .object({
    target: yup
      .string()
      .required()
      .transform(target => {
        if (target.startsWith('http') || target.startsWith('mailto:')) {
          return target;
        }

        if (!target.startsWith('/')) {
          target = '/' + target;
        }

        const url = new URL(target, 'https://www.myollie.com'); // now target is a valid relative path
        if (!url.pathname.endsWith('/')) {
          url.pathname += '/';
        }

        return url.pathname + url.search;
      }),
    text: yup.string().required(),
  })
  // Yup will default a missing object to an empty object with default props
  .default(undefined)
  .transform(
    link =>
      link && {
        text: link.link_text,
        target: link.link_target.cached_url || link.link_target.url,
        component: 'link',
      },
  );

export const headlineVariantsSchema = level => {
  const headingSchema = level
    ? createInlineRichTextFromCMSSchema(level)
    : inlineRichTextFromCMSSchema;
  return yup
    .array(
      yup.object({
        variant: yup.string().required(),
        heading: headingSchema,
      }),
    )
    .nullable();
};
