import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { ResponsiveImage } from '@web/molecules';
import { Heading } from '@web/atoms';
import {
  StyledTestimonial,
  ImageContainer,
  Subtext,
  StarRatingContainer,
} from './styles';

const STARS_AMOUNT = 5;

export const TestimonialRoundImage = ({ testimonial, image }) => (
  <StyledTestimonial>
    <StarRatingContainer>
      {Array(STARS_AMOUNT)
        .fill()
        .map((_, index) => (
          <Icon
            key={index}
            iconName="star"
            size="1.7rem"
            color="background.dark"
            alt="star"
            id={`testimonial_round_${index}`}
          />
        ))}
    </StarRatingContainer>
    {image?.src && (
      <ImageContainer>
        <ResponsiveImage {...image} />
      </ImageContainer>
    )}
    <Heading
      headingLevel="h5"
      headingText={testimonial.body}
      position="center"
      typography="heading4"
    />
    <Subtext>{testimonial.customerName}</Subtext>
  </StyledTestimonial>
);

TestimonialRoundImage.displayName = 'TestimonialRoundImage';

TestimonialRoundImage.propTypes = {
  image: PropTypes.object,
  testimonial: PropTypes.object.isRequired,
};
