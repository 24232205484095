import * as yup from 'yup';
import { IllustrationPills } from '@web/components';
import { schema as illustrationPillSchema } from './illustration_pill';

export const component = IllustrationPills;

export const schema = yup
  .object({
    pills: yup.array(illustrationPillSchema).required(),
  })
  .transform(value => ({
    pills: value.pills,
  }));
