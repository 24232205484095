import React from 'react';
import PropTypes from 'prop-types';
import { PillText } from '@web/atoms';
import {
  useOffer,
  MARKETING_CODE_TYPE,
  REACTIVATION_CODE_TYPE,
} from '../../offer';

export const PromoPill = ({
  message = 'off your starter box',
  variant,
  category = MARKETING_CODE_TYPE,
}) => {
  const offer = useOffer();
  let offerAmount = offer.formattedAmount;
  if (category === REACTIVATION_CODE_TYPE) {
    offerAmount = offer.formattedAmount ?? '50%';
  }
  return offer.category === category && offer.formattedAmount ? (
    <PillText text={`${offerAmount} ${message}`} variant={variant} />
  ) : null;
};

PromoPill.displayName = 'PromoPill';
PromoPill.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
  category: PropTypes.oneOf([MARKETING_CODE_TYPE, REACTIVATION_CODE_TYPE]),
};
