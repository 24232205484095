export const CUSTOMER_TESTIMONIALS = [
  {
    body: "Bama loves his Ollie food, & won't eat anything else now! We've tried all 4 flavors, but beef & lamb are his faves. He LOVES the Jerky treats too! Which really surprised me because he usually won't eat dog treats!",
    pupName: 'Bama',
    pupAge: '3 yr old',
    breed: 'French Bulldog',
  },
  {
    body: "When I first put the food in Juno's bowl he went right to it and ate it all immediately even pushed the bowl across the kitchen. I have never seen him eat his food as soon as I put it down. I knew then that I made the right decision for my dog. Ever since then he has become a dog who enjoys his food.",
    pupName: 'Juno',
    pupAge: '1 yr old',
    breed: 'Dachshund',
  },
  {
    body: 'My dog used to leave his expensive breed specialized dog food in his bowl for hours before touching it. Since switching to Ollie he literally comes and finds me in the house and whines to be fed. He wags his tail when I feed him every time. He’s never done that before.',
    pupName: 'Hudson',
    pupAge: '5 yr old',
    breed: 'Labrador Retriever',
  },
  {
    body: "Leia has a sensitive tummy and used to not eat (or throw up) her dry kibble. She literally jumps for joy when we pull Ollie out of the fridge. What a huge difference Ollie has made in our dog's life.",
    pupName: 'Leia',
    pupAge: '3 yr old',
    breed: 'Chihuahua',
  },
  {
    body: 'Bella is an older dog and weight control is a concern, so I love how Ollie is perfectly measured for her diet.',
    pupName: 'Bella',
    pupAge: '9 yr old',
    breed: 'German Shepherd',
  },
  {
    body: "Javi, my mini goldendoodle has been LOVING meal time. He licks his bowl clean at every meal. Before Ollie, we'd have to basically beg him to eat.",
    pupName: 'Javi',
    pupAge: '3 yr old',
    breed: 'Goldendoodle',
  },
];
