import React from 'react';
import PropTypes from 'prop-types';
import { useKeyboardEvent } from '@hooks/use-keyboard-event';
import { Icon } from '@ui/icons';
import { VisuallyHidden } from '@web/atoms';
import logo from '../../images/Homepage/logo.svg';
import { ResponsiveImage } from '../ResponsiveImage';
import {
  HeaderContainer,
  CloseIcon,
  LogoImgContainer,
  OllieLogo,
} from './styles';

export const SlidingContainerHeader = ({ header = '', onExit }) => {
  useKeyboardEvent('Escape', onExit);

  return (
    <HeaderContainer>
      <CloseIcon aria-label="CloseIcon" onClick={() => onExit()}>
        <Icon iconName="close" alt="" size={1.4} id="close_sliding" />
        <VisuallyHidden>Close</VisuallyHidden>
      </CloseIcon>
      {/* If no header, render an empty span instead of an empty h2 */}
      {header ? <h2>{header}</h2> : <span></span>}
      <OllieLogo>
        <LogoImgContainer>
          <ResponsiveImage
            src={logo.src}
            alt="Ollie logo"
            width={logo.width}
            height={logo.height}
          />
        </LogoImgContainer>
      </OllieLogo>
    </HeaderContainer>
  );
};

SlidingContainerHeader.propTypes = {
  header: PropTypes.string,
  onExit: PropTypes.func.isRequired,
};
