import styled from 'styled-components';

export const StyledSimpleCalloutCards = styled.div`
  margin-top: ${props => props.theme.spacing(16)};
  margin-bottom: ${props => props.theme.spacing(16)};
`;

export const HeadlineWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing(6)};
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;

  padding-left: ${props => props.theme.spacing(6)};
  padding-right: ${props => props.theme.spacing(6)};
  @media ${props => props.theme.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${props => props.theme.spacing(12)};
    justify-content: center;
  }
  @media ${props => props.theme.laptop} {
    padding: 0px;
  }
`;

export const CtaWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(12)};
  display: flex;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  ${props => props.theme.getFocusOutline(props.$bgColor)}
`;

export const InputWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(12)};
  width: 110%;
  margin-left: -5%;
`;
